import * as Sentry from "@sentry/angular-ivy";

import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from "./guards/auth.guard";
import { AuthService } from "./services/auth.service";
import { AuthState } from "./action-state/states/auth.state";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BulkScheduleGuard } from "./guards/bulk-schedule.guard";
import { DateConvertPipe } from './pipes/date-time.pipe';
import { FacebookRedirectAuthComponent } from './pages/facebook-redirect-auth/facebook-redirect-auth.component';
import { FormsModule } from '@angular/forms';
import {GlobalErrorHandler} from "./global-error-handler";
import { HelperService } from "./services/helper.service";
import { IconsProviderModule } from './icons-provider.module';
import { InAppPurchaseState } from './action-state/states/in-app-purchase.state';
import { InactiveOrganizationComponent } from './pages/auth/inactive-organization/inactive-organization.component';
import { InstagramRedirectAuthComponent } from './pages/instagram-redirect-auth/instagram-redirect-auth.component';
import { JoyrideModule } from 'ngx-joyride';
import { LayoutComponent } from './layout/layout.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxStripeModule } from "ngx-stripe";
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { Resolver } from "./services/resolve";
import { Router } from "@angular/router";
import { SharedModule } from "./shared/shared.module";
import { SubscriptionComponent } from "./pages/auth/subscription/subscription.component";
import { SubscriptionGuard } from "./guards/subscription.guard";
import { SubscriptionState } from './action-state/states/subscription.state';
import { SubscriptionTeamGuard } from './guards/subscriptionteam.guard';
import { TeamState } from "./action-state/states/team.state";
import { TokenInterceptor } from './interceptor/token-interceptor';
import { WasabiState } from "./action-state/states/wasabi.state";
import en from '@angular/common/locales/en';
import { en_US } from 'ng-zorro-antd/i18n';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import { SortablejsModule } from 'ngx-sortablejs';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    FacebookRedirectAuthComponent,
    InstagramRedirectAuthComponent,
    SubscriptionComponent,
    InactiveOrganizationComponent
  ],
  imports: [
    // JoyrideModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    SharedModule,
    NgxsModule.forRoot([
      AuthState,
      SubscriptionState,
      WasabiState,
      InAppPurchaseState,
      TeamState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: 'auth.token'
    }),
    NgxSpinnerModule,
    NgxStripeModule.forRoot(environment.stripe_key),
    SortablejsModule.forRoot({ animation: 150 }),
    SortablejsModule,
    NgIdleKeepaliveModule.forRoot(),
    JoyrideModule.forRoot()
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    AuthGuard,
    SubscriptionGuard,
    SubscriptionTeamGuard,
    BulkScheduleGuard,
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AuthService,
    HelperService,
    Resolver,
    DateConvertPipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
