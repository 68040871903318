import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthState } from 'src/app/action-state/states/auth.state';
import { TeamState } from 'src/app/action-state/states/team.state';
import { HelperService } from 'src/app/services/helper.service';
import { SharedService } from 'src/app/services/shared.service';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-team-accounts',
  templateUrl: './team-accounts.component.html',
  styleUrls: ['./team-accounts.component.less']
})
export class TeamAccountsComponent implements OnInit {

  // isMobile = false;
  @Input() member_accounts: any = [];
  @Input() team_id = 0;
  @Input() team_account = '';
  team_label = '';
  loggedUserRole = '';
  userId;
  isUpdatePermission = false;
  isVisibleViewMembers = false;
  listOfMembers = [];
  delete_account_id = null;
  deleteTeamvisible = false;
  isDeleteAccountLoader = false;

  constructor(
    private teamService: TeamsService,
    private modal: NzModalService,
    public sharedService: SharedService,
    private router: Router,
    private helper: HelperService,
    private store: Store) { }

  // public getWidth() {
  //   let width = window.innerWidth

  //   if (width >= 767) {
  //     this.isMobile = false;
  //   } else {
  //     this.isMobile = true;
  //   }
  // }

  ngOnInit(): void {

    if (this.team_id == 0) {
      this.getData();
    }

    let listOfPermissions = this.store.selectSnapshot(TeamState.permissions);
    this.isUpdatePermission = this.helper.checkPermission(listOfPermissions, 'revoke_team_access');
    this.loggedUserRole = this.store.selectSnapshot(TeamState.role);
    this.userId = this.store.selectSnapshot(AuthState.user).id;

    if (this.member_accounts.length > 0) {
      this.team_label = 'this team';

    } else {
      this.team_label = 'all team';
    }

    // this.getWidth();
  }

  async getData() {
    this.member_accounts = await this.membersSocialAccounts();
  }

  membersSocialAccounts() {
    return new Promise(resolve => {
      this.teamService.memberSocialNetworks().subscribe((res) => {
        resolve(res.data);
      }, error => {
        resolve([]);
      });
    });
  }

  delete_social_account(ac_id) {
    this.deleteTeamvisible = true;
    this.delete_account_id = ac_id;
  }
  deleteTeamModalClose(): void {
    this.deleteTeamvisible = false;
  }

  deleteAccount(id) {
    this.isDeleteAccountLoader = true;
    let postValue = {
      team_id: this.team_id
    };
    this.teamService.deleteProfile(id, postValue).subscribe(res => {
      if (res.code === 200) {
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Success', res.message);
        this.isDeleteAccountLoader = false;
        this.deleteTeamvisible = false;
      } else {
        this.isDeleteAccountLoader = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    });
    this.getData();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  viewMembers(page_id) {
    this.teamService.getPageMembers(page_id).subscribe((res: any) => {
      this.isVisibleViewMembers = true;
      this.listOfMembers = res.data;

    });
  }

  cancelModal() {
    this.isVisibleViewMembers = false;
  }
}
