import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IndexDbService } from '../../services/indexDb.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.less']
})
export class ImagePreviewComponent implements OnChanges {

  @Input() imageUrl: any = null;
  @Input() class = '';
  @Input() isVideo = false;
  @Input() profilePic = false;
  @Input() teamProfile = false;
  @Input() autoplay = false;
  @Input() organizationProfile = false;
  @Input() disablePreview = true;
  @Input() mediaType = '';
  @Input() isPlaceholderImage = false;
  @Input() isInbox = false;
  @Input() forceAutoPlay = false;
  isSpinning = false;
  url = '../../assets/images/white.png';
  placeholder = '../../assets/images/loading.gif';

  constructor(private indexDbService: IndexDbService, private sharedService: SharedService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUrl();
  }

  async getUrl(): Promise<void> {
    if (this.imageUrl !== '' && this.imageUrl !== null && this.imageUrl !== undefined) {
      if (!this.imageUrl.includes('http') || !this.imageUrl.includes('https')) {
        this.isSpinning = true;
        this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
        if (this.url === undefined || this.url === null) {
          this.url = '../../assets/images/white.png';
          this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
        }
      } else {
        this.url = this.imageUrl;
      }
    } else {
      if (this.profilePic) {
        this.sharedService.profile.subscribe(async (res) => {
          if (res !== null) {
            this.isSpinning = true;
            this.url = '../../assets/images/Profile.png';
            this.url = await this.indexDbService.getPresignedImageUrl(res);
            if (this.url === undefined || this.url === null) {
              this.url = '../../assets/images/Profile.png';
              this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
            }
          } else {
            this.url = '../../assets/images/Profile.png';
          }
        });
      }else if(this.isPlaceholderImage){
        this.url = '../../assets/images/placeholder_image.png';
      } else {
        this.url = '../../assets/images/Profile.png';
      }
    }

    if (this.teamProfile) {
      if (this.imageUrl !== '' && this.imageUrl !== null && this.imageUrl !== undefined && this.imageUrl !== 'null') {
        if (!this.imageUrl.includes('http') || !this.imageUrl.includes('https')) {
          this.isSpinning = true;
          this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
          if (this.url === undefined || this.url === null) {
            this.url = '../../assets/images/team_default.png';
            this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
          }
        } else {
          this.url = this.imageUrl;
        }
      } else {
        this.url = '../../assets/images/team_default.png';
      }
    }

    if (this.organizationProfile) {
      if (this.imageUrl !== '' && this.imageUrl !== null && this.imageUrl !== undefined && this.imageUrl !== 'null') {
        if (!this.imageUrl.includes('http') || !this.imageUrl.includes('https')) {
          this.isSpinning = true;
          this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
          if (this.url === undefined || this.url === null) {
            this.url = '../../assets/images/org_default.png';
            this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
          }
        } else {
          this.url = this.imageUrl;
        }
      } else {
        this.url = '../../assets/images/org_default.png';
      }
    }

    setTimeout(() => {
      if(this.isInbox){
        this.checkMediaType(this.url);
      }

      this.isSpinning = false;
    }, 1500);
  }

  checkMediaType(url){
    //this.isVideo = false;

    this.isItVideo(url).then((res) => {
        this.isVideo = true;
      console.log("res checkMediaType isVideo : ",this.isVideo);
      }).catch((err) => {
        this.isVideo = false;
      console.log("res checkMediaType isVideo : ",this.isVideo);
      });
      //console.log("checkMediaType isVideo : ",this.isVideo);
  }

  isItVideo(url: string): Promise<any> {
    return new Promise<any>(
      function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var contentType = xhr.getResponseHeader('Content-Type');
          console.log("contentType : ",contentType);
          if(contentType.indexOf('video') !== -1){
            console.log("contentType : video");
            resolve(true);
          }else{
            console.log("contentType : img");
            reject(false);
          }
        };
        xhr.onerror = function () {
          reject(new Error('XMLHttpRequest Error: ' + this.statusText));
        };
        xhr.open('HEAD', url);
        xhr.send();
      });
  }


}
