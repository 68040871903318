import { Component} from '@angular/core';
import * as moment from 'moment';
import { differenceInCalendarDays, differenceInCalendarWeeks, isSameDay, } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-repeat-post',
  templateUrl: './repeat-post.component.html',
  styleUrls: ['./repeat-post.component.less']
})
export class RepeatPostComponent  {

  selectedRepeat = 'every-day';
  repeatPopup = false;
  totalRepeatdata:any = [];
  selectedDates:any = [];
  repeatPostError:any = [];
  repeatTime = null;
  repeatDate: Date[];
  repeatPost = false;
  repeatDateHasError = false;
  repeatDateError = '';

  constructor(
    private modal: NzModalService) { }

  // Start : Repeat Post Function start from here
  disabledDate = (current: Date): boolean => {

    if (this.selectedRepeat === 'every-day') {
      return (
        differenceInCalendarDays(current, new Date()) <= 0 ||
        differenceInCalendarDays(current, new Date()) > 30
      );
    }
    if (this.selectedRepeat === 'every-alternate-day') {
      return (
        differenceInCalendarDays(current, new Date()) <= 0 ||
        differenceInCalendarDays(current, new Date()) > 60
      );
    }
    if (
      this.selectedRepeat === 'every-week' ||
      this.selectedRepeat === 'every-month'
    ) {
      return (
        differenceInCalendarDays(current, new Date()) <= 0 ||
        differenceInCalendarDays(current, new Date()) > 90
      );
    }
  }

  openRepeatPopup(): void {
    this.repeatPopup = true;
    this.repeatTime = moment(new Date().setHours(0, 0, 0, 0)).toDate();
  }

  generateDateAndTime(): void {

    this.totalRepeatdata = [];
    this.repeatPostError = [];
    if (this.selectedRepeat === 'every-day') {
      this.getEveryDayData();
    }

    if (this.selectedRepeat === 'every-week') {
      this.getEveryWeekData();
    }

    if (this.selectedRepeat === 'every-month') {
      this.getEveryMonthData();
    }

    if (this.selectedRepeat === 'every-alternate-day') {
      this.getEveryAlternateDayData();
    }
  }

  getEveryDayData(): void {
    if (!this.repeatDate || this.repeatDate.length < 2
      || this.repeatDate[0] === undefined
      || this.repeatDate[1] === undefined) {
        console.error('this.repeatDate or its elements are undefined');
        return;
    }

    for (let i = 0; i <= differenceInCalendarDays(this.repeatDate[1], this.repeatDate[0]); i++) {
      const date = moment(this.repeatDate[0]).add(i, 'days').toDate();
      const time = this.repeatTime;
      this.totalRepeatdata.push({ date, time });
    }
  }

  getEveryWeekData(): void {
    // @ts-ignore
    const totalWeek = differenceInCalendarWeeks(this.repeatDate[1], this.repeatDate[0], { weekStartsOn: moment(this.repeatDate[0]).weekday() });
    for (let i = 0; i <= totalWeek; i++) {
      const date = moment(this.repeatDate[0]).add(i, 'weeks').toDate();
      const time = this.repeatTime;
      this.totalRepeatdata.push({ date, time });
    }
  }

  getEveryMonthData(): void {
    let month = moment(this.repeatDate[0]);
    while (month <= moment(this.repeatDate[1])) {
      const date = month.toDate();
      const time = this.repeatTime;
      month = month.add(1, 'month');
      this.totalRepeatdata.push({ date, time });
    }
  }

  getEveryAlternateDayData(): void {
    for (
      let i = 0;
      i <= differenceInCalendarDays(this.repeatDate[1], this.repeatDate[0]);
      i += 2
    ) {
      const date = moment(this.repeatDate[0]).add(i, 'days').toDate();
      const time = this.repeatTime;
      this.totalRepeatdata.push({ date, time });
    }
  }

  deleteRepeat(index): void {
    this.totalRepeatdata.splice(index, 1);
  }

  approveRepeat(): void {
    this.repeatPost = true;
    const data = [];
    this.totalRepeatdata.forEach((item) => {
      data.push({
        date: moment(item.date).format('yyyy-MM-DD'),
        time: moment(item.time).format('HH:mm'),
      });
    });

    this.selectedDates = data;
    this.modal.closeAll();
  }

  getErrorMessage(date): any {
    for (const dateKey in this.repeatPostError) {
      if (isSameDay(date, moment(dateKey).toDate())) {
        return this.repeatPostError[dateKey];
      }
    }
  }

  checkForError(date): boolean {
    for (const dateKey in this.repeatPostError) {
      if (isSameDay(date, moment(dateKey).toDate())) {
        return true;
      }
    }
    return false;
  }

  repeatSelectionChange(): void {
    this.totalRepeatdata = [];
    this.repeatDate = [];
  }

  isOpenChange(event, index = null): void {
    if (!event) {
      if (index === null) {
        const min = new Date(this.repeatTime).getMinutes();
        this.repeatTime = this.roundPostTime(min, this.repeatTime);
      } else {
        const min = new Date(this.totalRepeatdata[index].time).getMinutes();
        this.totalRepeatdata[index].time = this.roundPostTime(
          min,
          this.totalRepeatdata[index].time
        );
      }
    }
  }

  roundPostTime(min, time) {
    if (!min.toString().endsWith('5') || !min.toString().endsWith('0')) {
      const totalMin = Math.round(+min / 5) * 5;
      const minadded = totalMin - min;
      return moment(time).add(minadded, 'm').toDate();
    }
  }

  handleCancel(): void {
    this.repeatPopup = false;
    this.repeatDate = [];
    this.repeatTime = null;
    this.totalRepeatdata = [];
    this.selectedRepeat = 'every-day';
  }
  // End : Repeat Post Function start from here


  checkRepeatDateError(){
    this.repeatDateHasError = false;
    this.repeatDateError = '';
    if(this.repeatDate[0] === this.repeatDate[1]){
      this.repeatDateHasError = true;
      this.repeatDateError = 'End date cannot be same Start Date';

      return false;
    }else{
      return  true;
    }
  }
}
