import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { InAppPurchaseClear, InAppPurchaseGet } from '../actions/in-app-purchase.actions';


export interface InAppPurchaseStateModel {
  inAppPurchase: any | null;
  inAppPurchaseCancelAtOnce: boolean;
}

@State<InAppPurchaseStateModel>({
  name: 'inAppPurchase',
  defaults: {
    inAppPurchase: null,
    inAppPurchaseCancelAtOnce: false
  }
})

@Injectable()

export class InAppPurchaseState {
  @Selector()
  static inAppPurchase(state: InAppPurchaseStateModel): any {
    return state.inAppPurchase;
  }

  @Action(InAppPurchaseGet)
  InAppPurchaseGet(inAppPurchaseState: StateContext<InAppPurchaseStateModel>, action: InAppPurchaseGet): void {
    inAppPurchaseState.setState({
      inAppPurchase: action.payload.inAppPurchase,
      inAppPurchaseCancelAtOnce: action.payload.inAppPurchaseCancelAtOnce
    });
  }

  @Action(InAppPurchaseClear)
  InAppPurchaseClear(inAppPurchaseState: StateContext<InAppPurchaseStateModel>): void {
    inAppPurchaseState.setState({
      inAppPurchase: null,
      inAppPurchaseCancelAtOnce: false
    });
  }
}
