import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../../../action-state/states/auth.state';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzMessageService } from 'ng-zorro-antd/message';
import {SharedService} from "../../../services/shared.service";

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.less']
})
export class InviteComponent implements OnInit {

  registrationForm: FormGroup;
  passwordVisible = false;
  confirmPasswordVisible = false;
  @Input() isLogin = false;
  @Input() token = null;
  @Input() isRegister = false;
  @Input() inviteMemberData;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private sharedService: SharedService) {
    this.sharedService.setPageTitle(this.route.snapshot.data['title']);
  }

  ngOnInit(): void {

    this.isLogin = this.store.selectSnapshot(AuthState.token) !== null;
    if (this.isLogin) {
      //localStorage.clear();
      this.sharedService.setRememberMe();
      let token = this.token;
      window.location.reload();
    } else {
      if (this.isRegister) {
        this.router.navigate(['/', 'auth', 'login']);
      }
    }
    this.initForm();
  }

  initForm(): void {
    this.registrationForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      password_confirmation: [null, [Validators.required, this.confirmationValidator]]
    });
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.registrationForm.controls.password.value) {
      return { confirm: true };
    }
    return {};
  }

  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.registrationForm.controls.password_confirmation.updateValueAndValidity());
  }

  submitForm(): void {

  }
}
