import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformNumber',
})
export class TransformNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 10000) {
      if (value < 1000000) {
        return (value / 1000).toFixed(2) + 'K';
      } else {
        return (value / 1000000).toFixed(2) + 'M';
      }
    } else {
      return String(value.toFixed(2).replace(/\.00$/, ''));
    }
  }
}
