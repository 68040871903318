import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TwitterService } from '../../services/twitter.service';
import { LinkedinService } from '../../services/linkedin.service';
import { SharedService } from '../../services/shared.service';
import { YoutubeService } from '../../services/youtube.service';
import { TiktokService } from '../../services/tiktok.service';
import { PinterestService } from '../../services/pinterest.service';
import { SnapchatService } from '../../services/snapchat.service';
import { ThreadsService } from '../../services/threads.service';

@Component({
  selector: 'app-redirect-auth',
  templateUrl: './redirect-auth.component.html'
})
export class RedirectAuthComponent implements OnInit {
  public data = null;
  public isVisible = false;
  public selectedAccount = [];
  public accounts = [];
  public isLoading = false;
  public disabledAccounts = [];

  constructor(private twitterService: TwitterService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private linkedinService: LinkedinService,
    private youtubeService: YoutubeService,
    private tiktokService: TiktokService,
    private pinterestService: PinterestService,
    private snapchatService: SnapchatService,
    private threadsService: ThreadsService,
    public sharedService: SharedService,
    private route: ActivatedRoute){
      this.sharedService.setPageTitle(this.route.snapshot.data['title']);
  }

  ngOnInit(): void {
    this.initial();
  }

  async initial(): Promise<void> {
    this.activatedRoute.queryParamMap
      .subscribe((param: Params) => {
        let postValue = param.params;
        console.log("Auth called successfully",postValue);
        if (localStorage.getItem('reconnect_id') !== null) {

          postValue = {
            ...postValue,
            reconnect_id: +localStorage.getItem('reconnect_id')
          };
        }

        if (postValue.oauth_token !== undefined && postValue.oauth_verifier !== undefined) {

          this.twitterService.twitterAuthenticatedRequests(postValue).subscribe(async (res) => {
            if (res.code === 200) {
              if (res.toast === true) {
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }

              setTimeout(() => {
                this.sharedService.notification.next(true);
              }, 30000);
            }
            if (res.code === 500 && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
            }
            this.navigateToAccountList();
          }, error => {
            this.navigateToHome();
          });
        } else if (postValue.code !== undefined && postValue.scope !== undefined) {
          // added social id in request params
          const social_data = localStorage.getItem('reconnect_id');

          let social_id = '';
          if (social_data !== null) {
            social_id = social_data;
          }

          const socialData: any = { social_id: social_id };
          const postData = {
            ...postValue,
            ...socialData
          };

          this.youtubeService.youtubeAuthenticatedRequests(postData).subscribe((res) => {

            if (res.code === 200) {

              if (res.toast === true) {
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }

              setTimeout(() => {
                this.sharedService.notification.next(true);
              }, 30000);
            }

            if ((res.code === 400 || res.code === 500) && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
              this.navigateToAccounts();
            }
            this.navigateToHome();
          }, error => {
            this.navigateToHome();
          });
        } else if (postValue.code !== undefined && postValue.scopes !== undefined) {

          // added social id in request params
          const social_data = localStorage.getItem('reconnect_id');

          let social_id = '';
          if (social_data !== null) {
            social_id = social_data;
          }

          const socialData: any = { social_id: social_id };
          const postData = {
            ...postValue,
            ...socialData
          };

          this.tiktokService.tiktokAuthenticatedRequests(postData).subscribe((res) => {

            if (res.code === 200) {

              if (res.toast === true) {
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }

              setTimeout(() => {
                this.sharedService.notification.next(true);
              }, 30000);
            }

            if ((res.code === 400 || res.code === 500) && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
              this.navigateToAccounts();
            }

            if (social_id) {
              this.navigateToAccounts();
            } else {
              this.navigateToHome();
            }

          }, error => {
            this.navigateToHome();
          });
        } else if (postValue.code !== undefined && postValue.state !== undefined && postValue.code.length > 50) {
          // added social id in request params
          const social_data = localStorage.getItem('reconnect_id');

          let social_id = '';
          if (social_data !== null) {
            social_id = social_data;
          }

          const socialData: any = { social_id: social_id };

          const postData = {
            ...postValue,
            ...socialData
          };

          this.threadsService.threadsAuthenticatedRequests(postData).subscribe((res) => {

            if (res.code === 200) {

              if (res.toast === true) {
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }

              setTimeout(() => {
                this.sharedService.notification.next(true);
              }, 30000);
            }

            if ((res.code === 400 || res.code === 500) && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
              this.navigateToAccounts();
            }
            if (social_id) {
              this.navigateToAccounts();
            } else {
              this.navigateToHome();
            }

          }, error => {
            this.navigateToHome();
          });
        } else if (postValue.code !== undefined && postValue.state !== undefined) {
          // added social id in request params
          const social_data = localStorage.getItem('reconnect_id');

          let social_id = '';
          if (social_data !== null) {
            social_id = social_data;
          }

          const socialData: any = { social_id: social_id };

          const postData = {
            ...postValue,
            ...socialData
          };

          this.pinterestService.pinterestAuthenticatedRequests(postData).subscribe((res) => {

            if (res.code === 200) {

              if (res.toast === true) {
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }

              setTimeout(() => {
                this.sharedService.notification.next(true);
              }, 30000);
            }

            if ((res.code === 400 || res.code === 500) && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
              this.navigateToAccounts();
            }
            if (social_id) {
              this.navigateToAccounts();
            } else {
              this.navigateToHome();
            }

          }, error => {
            this.navigateToHome();
          });
        } else if (postValue.code !== undefined && postValue.code.length > 50) {
          // added social id in request params
          const social_data = localStorage.getItem('reconnect_id');

          let social_id = '';
          if (social_data !== null) {
            social_id = social_data;
          }

          const socialData: any = { social_id: social_id };

          const postData = {
            ...postValue,
            ...socialData
          };

          this.linkedinService.linkedinAuthenticatedRequests(postData).subscribe((res) => {

            if (res.code === 200) {

              if (social_id != '') {
                // reconnect
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
                this.navigateToAccounts();
              } else {
                // connect
                this.data = res.data;
                this.data.forEach((item, index) => {
                  this.data[index][`checked`] = false;
                });
                this.disabledAccounts = this.data.filter(item => {
                  return item.disabled;
                });
                this.isVisible = true;
              }
            }

            if ((res.code === 400 || res.code === 500) && res.toast === true) {
              //this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, '');
              this.navigateToAccounts();
            }

          }, error => {
            this.navigateToHome();
          });
        } else if (postValue.code !== undefined) {
          const social_data = localStorage.getItem('reconnect_id');

          let social_id = '';
          if (social_data !== null) {
            social_id = social_data;
          }

          const socialData: any = { social_id: social_id };

          const postData = {
            ...postValue,
            ...socialData
          };
          this.snapchatService.snapchatAuthenticatedRequests(postValue).subscribe(async (res) => {
            if (res.code === 200) {
              if (res.toast === true) {
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }

              setTimeout(() => {
                this.sharedService.notification.next(true);
              }, 30000);
            }
            if (res.code === 500 && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
            }
            this.navigateToAccountList();
          }, error => {
            this.navigateToHome();
          });
        }else {
          this.navigateToHome();
        }
      });
  }

  getTitle(): string {
    if (localStorage.getItem('reconnect_id') === null) {
      return 'Which Account do You want to connect?';
    } else {
      return 'Which Account do You want to reconnect?';
    }
  }

  navigateToHome(): void {
    if (localStorage.getItem('reconnect_id') !== null) {
      localStorage.removeItem('reconnect_id');
    }
    if (localStorage.getItem('error_display') !== null) {
      localStorage.removeItem('error_display');
    }
    this.router.navigate(['/', 'accounts']);
  }

  navigateToAccounts(): void {
    if (localStorage.getItem('reconnect_id') !== null) {
      localStorage.removeItem('reconnect_id');
    }
    if (localStorage.getItem('error_display') !== null) {
      localStorage.removeItem('error_display');
    }
    this.router.navigate(['/accounts']);
  }

  navigateToAccountList(): void {
    if (localStorage.getItem('reconnect_id') !== null) {
      localStorage.removeItem('reconnect_id');
    }
    if (localStorage.getItem('error_display') !== null) {
      localStorage.removeItem('error_display');
    }

    if (localStorage.getItem('is_from_create_post') !== null) {
      localStorage.removeItem('is_from_create_post');
      this.router.navigate(['/post/create-post']);
    }else{
      this.router.navigate(['/', 'accounts']);
    }

  }

  handleOk(): void {
    this.saveAccounts();
  }

  saveAccounts(): void {
    const postValue = { reconnect_id: null, accounts: this.selectedAccount };
    if (localStorage.getItem('reconnect_id') !== null) {
      postValue.reconnect_id = +localStorage.getItem('reconnect_id');
    }
    this.isLoading = true;
    this.linkedinService.saveLinkedinAccount(postValue)
      .subscribe(async (res) => {
        this.isLoading = false;
        if (res.code === 200) {
          if (res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          }
          setTimeout(() => {
            this.sharedService.notification.next(true);
          }, 30000);
          this.navigateToAccountList();
        }
        if (res.code === 500 && res.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
        }, error => {
        this.isLoading = false;
        this.navigateToHome();
      });
  }

  getAccount(event): void {
    this.selectedAccount = [];
    this.selectedAccount = event.accounts.filter(item => {
      if (!item.disabled) {
        return item.is_checked;
      }
    });
  }

  handleCancel(): void {
    this.isVisible = false;
    this.navigateToHome();
  }

  selectAll(){
    this.data = this.data.map(item => {
      if (!item.disabled) {
        return { ...item, is_checked: true };
      }
      return item;
    });

    this.selectedAccount = this.data.filter(item => {
      if (!item.disabled) {
        return item.is_checked;
      }
    });
  }

}
