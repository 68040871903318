import * as moment from 'moment';

import { Component, Input, OnInit, Output } from '@angular/core';
import { differenceInCalendarDays, isToday } from 'date-fns';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.less']
})
export class ScheduleComponent implements OnInit {
  @Input() postDate: Date = new Date();
  @Input() postTime: Date = new Date();
  @Input() id: string = null;
  @Output() changeDateTime = new Subject();
  @Input() hasTimeError = false;
  @Input() hasDateError = false;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.id) {
      this.postDate = new Date();
      // const currentDate = new Date();
      // const formattedDate = currentDate.toLocaleDateString('en-US'); // "5/9/2023" (for May 9th, 2023)
      this.setTime();
    }
  }

  setTime() {
    const minadded = this.getMinuteAdded();
    this.postTime = moment(new Date()).add(minadded, 'm').toDate();
  }

  getMinuteAdded() {
    const currentMin = new Date().getMinutes();
    const totalMin = (Math.ceil(currentMin / 5) * 5);
    return totalMin - currentMin + 15;
  }

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) < 0;
  }

  disabledMinutes = (hour: number): number[] => {
    let m = new Date().getMinutes();
    m = this.getMinuteAdded() + m;
    const result: number[] = [];
    if (this.isSameDate()) {
      if (!this.isCurrentHour(hour)) {
        for (let i = 0; i < 60; i++) {
          if (i < m) {
            result.push(i);
          }
        }
      } else {
        const currentHour = new Date().getHours();
        if (hour === (currentHour + 1)) {
          m = m - 60;
          for (let i = 0; i < 60; i++) {
            if (i < m) {
              result.push(i);
            }
          }
        }
      }
    }
    return result;
  }

  isCurrentHour(selectHour) {
    const currentHour = new Date().getHours();
    if (selectHour > currentHour) {
      return true;
    } else {
      return false;
    }
  }

  isCurrentMinute(selectMinute) {
    const currentMinute = new Date().getMinutes();
    if (selectMinute >= (currentMinute + 15)) {
      return false;
    } else {
      return true;
    }
  }

  disabledHours = (): number[] => {
    const d = new Date().getHours();
    const result: number[] = [];
    if (this.isSameDate()) {
      for (let i = 0; i < 24; i++) {
        if (i < d) {
          result.push(i);
        }
      }
    }
    return result;
  }

  isSameDate(): boolean {
    if (this.postDate) {
      return isToday(new Date(this.postDate));
    }
  }

  isOpenChange(event) {
    if (!event) {
      const hour = new Date(this.postTime).getHours();
      const min = new Date(this.postTime).getMinutes();
      if (this.isSameDate()) {
        if (!this.isCurrentHour(hour)) {
          if (this.isCurrentMinute(min)) {
            this.setTime();
          }
        }
      }
      this.roundPostTime(min);
      this.changeDateTime.next({ postDate: this.postDate, postTime: this.postTime });
    }
  }

  roundPostTime(min) {
    if (!min.toString().endsWith('5') || !min.toString().endsWith('0')) {
      const totalMin = (Math.round(+min / 5) * 5);
      const minadded = totalMin - min;
      this.postTime = moment(this.postTime).add(minadded, 'm').toDate();
    }
  }

  onDateChange(event) {
    if (!event) {
      if (this.isSameDate()) {
        const hour = new Date(this.postTime).getHours();
        if (!this.isCurrentHour(hour)) {
          const min = new Date(this.postTime).getMinutes();
          if (this.isCurrentMinute(min)) {
            this.setTime();
          }
        }
      }
      this.changeDateTime.next({ postDate: this.postDate, postTime: this.postTime });
    }
  }
}

