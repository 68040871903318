import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { AuthState } from '../../action-state/states/auth.state';
import { HelperService } from '../../services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { SharedService } from '../../services/shared.service';
import { Store } from '@ngxs/store';
import { UpdateProfile } from '../../action-state/actions/auth.action';
import { UserService } from '../../services/user.service';
import { WasabiService } from '../../services/wasabi.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-edit-profile-component',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.less']
})
export class EditProfileComponent implements OnInit {

  displayStartDate: Date;

  constructor(private formBuilder: FormBuilder,
    private userService: UserService,
    private store: Store,
    private messageService: NzMessageService,
    private spinnerService: NgxSpinnerService,
    private sharedService: SharedService,
    private wasabiService: WasabiService) {
  }

  loading = false;
  avatarUrl: any = null;
  editProfileForm: any;
  uploading = false;
  profilePic: File | any = null;
  //dob: moment.Moment | any = null;
  dob = null;
  deleteProfileImage = false;
  user: any = null;
  isLoading = false;

  ngOnInit(): void {
    this.initForm();
    this.setValue();
  }

  disabledDate = (current: Date): boolean => {
    // 13 years
    let minimumAge = new Date(new Date().setFullYear(new Date().getFullYear() - 13));
    return differenceInCalendarDays(current, minimumAge) > 0;
  }

  initForm(): void {
    this.editProfileForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.pattern(/^[a-zA-z\s]{0,30}$/)]],
      email: [{ value: null, disabled: true }, [Validators.required, Validators.email]],
      phone: [null, [Validators.pattern(/^[0-9]{10}$/)]],
      gender: [null],
      dob: [null],
    });
  }

  setValue(): void {
    this.user = this.store.selectSnapshot(AuthState.user);
    this.editProfileForm.get('email').patchValue(this.user.email);
    for (const i in this.editProfileForm.controls) {
      if(i==='dob') {
        console.log("--> dob :",this.user[i]);
        if(this.user[i] != null){
          let dateOfBirth = moment(this.user[i], 'MM-DD-YYYY').format('MM/DD/YYYY');
          this.editProfileForm.get('dob').patchValue(dateOfBirth);
        }else{
          this.displayStartDate = new Date();
          this.displayStartDate.setFullYear(this.displayStartDate.getFullYear() - 20);
        }
      }else{
        this.editProfileForm.get(i).patchValue(this.user[i]);
      }
    }
    if (this.user.profile_pic !== null && this.user.profile_pic !== '') {
      this.avatarUrl = this.user.profile_pic;
    }
  }

  beforeUpload = (file: NzUploadFile): boolean => {

    if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png' && file.type !== 'image/svg' && file.type !== 'image/svg+xml') {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Profile Photo', 'You can upload only image file');
      return false;
    }

    if (file.size! > 2 * 1024 * 1024) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Profile Photo', 'You can\'t upload greater then 2 MB image file');
      return false;
    }

    this.loading = true;
    this.profilePic = file;
    this.storeFile();
    return false; // key to manual upload
  }

  async storeFile(): Promise<void> {
    const folder = 'profile_pic/user-' + this.user.id + '/';
    var fileName = moment().unix() + '-' + this.profilePic.name;
    fileName = fileName.split(' ').join('_');
    const data = await this.wasabiService.uploadMedia(this.profilePic, folder, fileName);
    this.loading = false;
    if (data) {
      this.avatarUrl = folder + fileName;
    }
  }

  public setServeErrors(errorResponse): void {
    for (const controlKey of Object.keys(errorResponse)) {
      this.editProfileForm.get(controlKey).setErrors({ serverError: errorResponse[controlKey] });
    }
  }

  submitForm(): void {

    for (const i in this.editProfileForm.controls) {
      this.editProfileForm.controls[i].markAsDirty();
      this.editProfileForm.controls[i].updateValueAndValidity();
    }

    if (this.editProfileForm.valid) {

      const value = this.editProfileForm.value;
      if (value.dob !== null) {
        value.dob = moment(value.dob).format('yyyy-MM-DD');
      }

      console.log("dob : ",value.dob)

      const postValue = HelperService.createFormData(value);
      if (this.profilePic !== null) {
        postValue.append('profile', this.avatarUrl);
      }
      if (this.deleteProfileImage) {
        postValue.append('delete_image', '1');
      }

      this.isLoading = true;
      this.userService.updateProfile(postValue).subscribe((res) => {
        this.isLoading = false;

        if (res.code === 200) {
          console.log("updateProfile res:",res.data)
          this.store.dispatch(new UpdateProfile(res.data));
          this.deleteProfileImage = false;
          this.sharedService.username = res.data.name;
          this.sharedService.profile.next(res.data.profile_pic);
          this.avatarUrl = this.store.selectSnapshot(AuthState.user).profile_pic;
          if (res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          }
        }

        if (res.code === 500 && res.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }

        if (res.code === 400) {
          this.setServeErrors(res.message);
        }
      }, (error) => {
        this.isLoading = false;
      });
    }
  }

  deleteImage(): void {
    this.avatarUrl = null;
    if (this.user.profile_pic !== null && this.user.profile_pic !== '') {
      this.deleteProfileImage = true;
    }
  }
}
