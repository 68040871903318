import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SubscriptionState } from '../action-state/states/subscription.state';
import { AuthState } from '../action-state/states/auth.state';
import { InAppPurchaseState } from '../action-state/states/in-app-purchase.state';

@Injectable({
  providedIn: 'root'
})
export class InactiveOrganizationGuard implements CanActivate {

  constructor(private router: Router,private store: Store) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (
        this.store.selectSnapshot(SubscriptionState).subscription !== null ||
        (this.store.selectSnapshot(AuthState.user).free_user && this.store.selectSnapshot(AuthState.user).free_user !== null) ||
        this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null
      ) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
  }

}
