import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthState } from 'src/app/action-state/states/auth.state';
import { TeamState } from 'src/app/action-state/states/team.state';
import { HelperService } from 'src/app/services/helper.service';
import { SharedService } from 'src/app/services/shared.service';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.less']
})
export class TeamMembersComponent implements OnInit {

  // isMobile = false;
  @Input() members: any = [];
  @Input() team_id = 0;
  @Input() team_account = '';
  loggedUserRole = '';
  userId;
  isUpdatePermission = false;
  isDisabled = true
  deleteTeamvisible = false;
  team_id_delete = null;
  isLoading = false;
  isLoadingDelete = false;
  selectedPageIndex = 1;
  selectedPageSize = 10;
  totalData = 0;
  memberList = [];

  constructor(
    private teamService: TeamsService,
    private modal: NzModalService,
    public sharedService: SharedService,
    private router: Router,
    private helper: HelperService,
    private activeRoute: ActivatedRoute,
    private store: Store) {
  }

  // public getWidth() {
  //   let width = window.innerWidth

  //   if (width >= 767) {
  //     this.isMobile = false;
  //   } else {
  //     this.isMobile = true;
  //   }
  // }

  ngOnInit(): void {
    if (this.team_id == 0) {
      this.getData();
    }

    this.loggedUserRole = this.store.selectSnapshot(TeamState.role);

    let listOfPermissions = this.store.selectSnapshot(TeamState.permissions);
    this.isUpdatePermission = this.helper.checkPermission(listOfPermissions, 'revoke_team_access');
    this.userId = this.store.selectSnapshot(AuthState.user).id;
    // this.getWidth();
  }

  async getData() {
    this.members = await this.organizationMembers();
  }

  organizationMembers() {
    return new Promise(resolve => {
      this.teamService.getOrganizationMembersList().subscribe((res) => {
        resolve(res.data?.data);
        this.memberList = res.data?.data;
        this.totalData = res.data?.total;

      }, error => {
        resolve([]);
      });
    });
  }

  onQueryParamsChange(event) {
    this.selectedPageIndex = event.pageIndex;
    this.selectedPageSize = event.pageSize;
    this.organizationMembers()
  }

  checkAccess(member) {
    let flag = false;
    /*if (member.role.role_name != 'Super Admin' && this.userId != member.member_id) {
      flag = true;
    }
    if (this.loggedUserRole == 'Admin' && (member.role.role_name == 'Super Admin' || member.role.role_name == 'Admin')) {
      flag = false;
    }*/

    return flag;
  }

  deleteTeamModal(team_id): void {
    this.deleteTeamvisible = true;
    this.team_id_delete = team_id
  }

  deleteTeamModalClose(): void {
    this.deleteTeamvisible = false;
  }

  deleteMember(member_id) {
    let team_id = this.team_id;
    this.isLoadingDelete = true;
    this.teamService.deleteMember(member_id, team_id).subscribe(res => {
      if (res.code === 200) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageSuccess, res.message);
        this.isLoadingDelete = false;
        this.deleteTeamvisible = false;
      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        this.isLoadingDelete = false;
      }
    });

    this.getData();
  }
  userEditId = '';
  editMember(id) {
    this.modal.closeAll();
    this.userEditId = id;
    this.router.navigate(['/organization/member/edit/' + id]);
  }

  reloadCurrentRoute() {
    let currentUrl = this.activeRoute.snapshot.url;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

}
