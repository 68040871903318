<nz-modal [(nzVisible)]="isVisible" [nzFooter]="null" nzClassName="buy_addon_modal"
          (nzOnCancel)="cancel()">
  <div *nzModalContent class="card-modal">
    <!-- [nzTitle]="modalTitle" (nzOnCancel)="cancel()"-->

    <div class="addon_modal_main" [class.active]="isopenCheckout">
      <div class="modal_front_main">
        <div class="modal_front">
          <div class="modal_front_img">
            <!--<img alt="" src="../../../assets/images/social_profiles.svg">-->
            <img [src]="getAddonImageUrl(plan)" alt="">
          </div>
          <div class="modal_front_content">
            <h2>{{ plan?.name | titlecase}}</h2>
            <p>{{ plan?.description}}</p>
            <div class="amount_sec">
              <span>Total Amount</span>
              <h3>${{plan?.price}}</h3>
            </div>
            <button class="btn_primary" (click)="openCheckout()" >Proceed</button>
          </div>
        </div>
        <div class="addon_form">
          <app-payment-modal></app-payment-modal>
        </div>
      </div>
      <ng-container *ngIf="plan!=null">
        <div class="addon-modal-header" style="display: none;">
          <p class="addon_name">{{ plan?.name | titlecase}}</p>
          <p class="addon_price">${{plan?.price}}</p>
        </div>
        <div class="checkout_main">
          <div class="checkout_left">
            <app-card-data [allCard]="allCard"></app-card-data>
            <app-add-card (getCardDetails)="getAllCard()"></app-add-card>
          </div>
          <div class="checkout_right">
            <div class="checkout_right_in">
              <div class="plan_dtls">
                <div class="pay_anual">
                  <span>Summary</span>
                </div>
                <div class="plan_sub">
                  <div class="plan_sub_dtl">
                    <div class="plan_sub_dtl_in">
                      <p>{{plan?.name | titlecase}}</p>
                      <p>${{plan?.price}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="total_main">
                <div class="total_in">
                  <p><strong>Final Total</strong></p>
                  <p><strong>${{plan?.price}}</strong></p>
                </div>
              </div>

            </div>
            <div class="checkout_footer">
              <button nz-button nzType="default" class="btn_cancel" (click)="cancel()">Cancel</button>
              <button nz-button nzType="primary" [disabled]="isLoading" class="btn_paynow" (click)="submit()">
                {{ submitText }}
                <ng-container *ngIf="isLoading">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</nz-modal>
