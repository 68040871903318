import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch'
})
export class FilterSearchPipe implements PipeTransform {

  transform(followers: any = {}, searchText: string = '') {
    if (!followers) {
      return [];
    }

    if (searchText == '') {
      return followers;
    }

    return followers.filter(items => {
      return items.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }

}
