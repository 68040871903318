import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { Store } from '@ngxs/store';
import { WasabiState } from '../action-state/states/wasabi.state';
import {HttpErrorResponse, HttpClient, HttpRequest, HttpEvent, HttpEventType} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import {HelperService} from './helper.service';
import { last } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class WasabiService {

  uploading;
  constructor(private store: Store, private http: HttpClient, private helper: HelperService) {
  }

  getSignedUrl = mediaPath => {
    const s3Object = this.getS3Instance();
    const bucketName = this.hexToString(this.store.selectSnapshot(WasabiState.wasabi).WASABI_BUCKET);

    const config = {
      Bucket: bucketName,
      Key: mediaPath,
      Expires: 24 * 60 * 60 // 24 hours
    };

    let url = s3Object.getSignedUrl('getObject', config);
    return url;
  }

  getS3Instance = () => {
    return new S3({
      correctClockSkew: true,
      endpoint: this.hexToString(this.store.selectSnapshot(WasabiState.wasabi).WASABI_ENDPOINT),
      accessKeyId: this.hexToString(this.store.selectSnapshot(WasabiState.wasabi).WASABI_ACCESS_KEY),
      secretAccessKey: this.hexToString(this.store.selectSnapshot(WasabiState.wasabi).WASABI_SECRET_KEY),
      region: this.hexToString(this.store.selectSnapshot(WasabiState.wasabi).WASABI_DEFAULT_REGION),
      signatureVersion:'v4',
      // logger: console
    });
  }

  uploadMedia = (file, folderName, newFileName) => {
    const s3Object = this.getS3Instance();
    newFileName = newFileName.replaceAll(' ', '_');

    return new Promise(resolve => {
      this.uploading = s3Object.upload({
        Key: folderName + newFileName,
        Bucket: this.hexToString(this.store.selectSnapshot(WasabiState.wasabi).WASABI_BUCKET),
        Body: file
      }, (err, data) => {
        if (err) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  hexToString = (hex) => {
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return str;
  }

  cancelUpload(): void  {
    if (this.uploading) {
      this.uploading.abort();
    }
  }

  uploadMediaHTTP(file, folderName, newFileName): Observable<any> {

    newFileName = newFileName.replaceAll(' ', '_');

    let formData: any = new FormData();
    formData.append('file', file);
    formData.append('folder_name', folderName);
    formData.append('file_name', newFileName);
    return this.http.post(this.helper.createAPIUrl('upload_media'), formData,
      { reportProgress: true, observe: 'events' });
  }

  uploadPostMedia(file, folderName, newFileName): Observable<any> {
    newFileName = newFileName.replaceAll(' ', '_');

    let formData: any = new FormData();
    formData.append('file', file);
    formData.append('folder_name', folderName);
    formData.append('file_name', newFileName);

    return this.http.post(this.helper.createAPIUrl('upload_post_media'),
      formData, {
        reportProgress: true,
        observe: 'events' });
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
