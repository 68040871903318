<ng-container *ngIf="isLoading">
  <div *ngFor="let item of dummyItems">
    <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 1 }"></nz-skeleton>
  </div>
</ng-container>

<ng-container *ngIf="!isLoading">
  <ng-container *ngFor="let card of allCard">
  <div>
    <div class="all_card_list_main">
      <div class="icon_sec">
        <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M28.5 1.5H4.5C2.84315 1.5 1.5 2.84315 1.5 4.5V22.5C1.5 24.1569 2.84315 25.5 4.5 25.5H28.5C30.1569 25.5 31.5 24.1569 31.5 22.5V4.5C31.5 2.84315 30.1569 1.5 28.5 1.5Z"
            stroke="#423FD1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M16.5 10.524C16.1088 10.0497 15.618 9.66738 15.0625 9.40401C14.507 9.14063 13.9003 9.0027 13.2855 9C10.92 9 9 11.0145 9 13.5C9 15.9855 10.92 18 13.2855 18C14.5665 18 15.7155 17.4105 16.5 16.476M24 13.5C24 15.9855 22.08 18 19.7145 18C17.3475 18 15.429 15.9855 15.429 13.5C15.429 11.0145 17.3475 9 19.7145 9C22.08 9 24 11.0145 24 13.5Z"
            stroke="#423FD1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="details_sec">
        <div class="details_sec_left">
          <h3>{{card.billing_details.name | titlecase}}</h3>
          <span>{{card.card.brand | uppercase}}</span>
          <span><strong>****</strong>{{card.card.last4}}</span>
        </div>
        <div class="details_sec_right">
          <button nz-button class="default" nz-tooltip nzTooltipTitle="Default"
            *ngIf="card.metadata.default !== undefined && card.metadata.default">
            <span nz-icon nzType="check" nzTheme="outline"></span>
            <!-- Default -->
          </button>
          <button nz-button class="make-default" nz-tooltip nzTooltipTitle="Make Default"
            *ngIf="card.metadata.default === undefined || !card.metadata.default" (click)="makeCardDefault(card.id)">
            <span nz-icon nzType="check" nzTheme="outline"></span>
            <!-- Make Default -->
          </button>
          <button nz-button nz-tooltip nzTooltipTitle="Delete" class="delete-card margin-top-1"
            *ngIf="card.metadata.default === undefined || !card.metadata.default"
            (click)="deleteCard(card.id)" [class.disabled_while_loading]="isLoading">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
            <!-- Delete -->
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
</ng-container>
