<div class="app_select_team">
  <div class="org_option_main" (click)="openTeamModal()" *ngIf="teams?.length>0">
    <div class="org_option_left">
      <app-image-preview [imageUrl]="selectedTeam[0]?.icon" [organizationProfile]="true"
                         [class]="'organization-list-profile'" [isVideo]="false">
      </app-image-preview>
    </div>
    <!-- {{organizations | json}} -->
    <div class="org_option_right">
      <h3>{{ selectedTeam[0]?.team_name }}</h3>
    </div>
  </div>
</div>


<!-- Start select team modal -->
<nz-modal [(nzVisible)]="visible" nzClassName="org_option_modal">
  <ng-container *nzModalContent>
    <div class="org_optn_hdr">
      <h4>Teams</h4>
    </div>
    <div class="org_optn_body">
      <perfect-scrollbar style="max-height: 300px;">
        <ng-container *ngFor="let team of teams">
          <nz-radio-group class="org_optn_list_wrap" [(ngModel)]="team_id">
            <div class="org_optn_list">
              <div class="org_optn_list_in">
                <div class="org_optn_list_in_left">
                  <label nz-radio [nzValue]="team.id">
                    <div class="org_optn_list_main">
                      <div class="org_optn_list_left">
                        <app-image-preview [imageUrl]="team?.icon"
                                          [organizationProfile]="true" [class]="'organization-list-profile'" [isVideo]="false">
                        </app-image-preview>
                      </div>
                      <div class="org_optn_list_right">
                        <h3>{{ team?.team_name }}</h3>
                      </div>
                    </div>
                  </label>
                </div>
                <!--<div class="org_optn_list_in_right">
                  <a *ngIf="organization?.role_id !== 1" (click)="leaveOrganization(organization.id, 0)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#2D1BAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M16 17L21 12L16 7" stroke="#2D1BAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M21 12H9" stroke="#2D1BAC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                  <ng-container *ngIf="organization?.role_id === 1">
                    <a (click)="showOrganizationModal(organization?.organization)">
                      <img src="../../../../assets/images/edit_icon_2.svg" alt="" />
                    </a>
                  </ng-container>
                </div>-->
              </div>
            </div>
          </nz-radio-group>
        </ng-container>
      </perfect-scrollbar>

      <div class="btn_wrap">
        <button type="button" class="btn" (click)="closeTeamModal($event)">Cancel</button>
        <button type="button" class="btn btn_switch" (click)="switchTeam($event)" [disabled]="teams.length === 1">Switch Team</button>
      </div>

    </div>
  </ng-container>
</nz-modal>
<!-- End select team modal -->
