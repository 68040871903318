<ng-container *ngIf="isForUpdate && !selectedImage && !selectedImageURL && !isDirectUpload && !isCropped">
  <div class="gallery_title">
    <div class="selected_media_wrapper" *ngIf="selectedMedias.length>0 && page!=='create_post' ">
      <div class="selected_media_left">
        <span>{{selectedMedias.length}} Media selected</span>
      </div>
      <div class="selected_media_right">
        <ng-container *ngIf="currentTab===0">
          <!-- <button class="btn_primary" (click)="addToFavorite()">Add to favorite</button>
          <button class="btn_primary" (click)="addToAlbumVisible=true">Add to album</button>
          <button class="btn_primary" (click)="deleteImagevisible=true">Delete</button> -->
          <div class="selected_media_action">
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to favorite" (click)="addToFavorite()">
              <svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2_00000030473488031137604640000010881549889853806738_"><g id="favourite"><path d="m256 474.3c-6.6 0-12.9-2.6-17.6-7.2l-198.4-194.9-.2-.2c-43.7-43.6-52.4-111.2-21.2-164.4 1-1.7 1.7-2.8 1.9-3.1.3-.5.7-1 1.1-1.6 31.6-41.8 68.9-63.8 111-65.2 53.1-1.7 98.9 30.7 123.4 52.3 24.5-21.6 70.3-54 123.4-52.2 42.1 1.4 79.5 23.4 111 65.2.4.5.7 1 1.1 1.5.2.3.9 1.3 1.9 3.1 31.3 53.2 22.6 120.8-21.1 164.4l-.2.2-198.5 194.9c-4.7 4.6-11 7.2-17.6 7.2zm-180.8-237.8 180.8 177.5 180.8-177.5c27.5-27.4 32.9-69.9 13.3-103.4l-.4-.7c-21.8-28.5-46-43.5-71.9-44.4-.9 0-1.9 0-2.8 0-52.5 0-99.6 53.2-100.1 53.7-9 10.4-24.8 11.6-35.3 2.6-.9-.8-1.8-1.6-2.6-2.6-.5-.6-49.3-55.6-102.9-53.7-25.9.9-50.1 15.9-71.8 44.4-.1.2-.3.4-.4.8-19.6 33.4-14.1 75.9 13.3 103.3z" fill="rgb(0,0,0)"/></g></g></svg>
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to album" (click)="addToAlbumVisible=true">
              <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.6 6a.6.6 0 00-.6.6v11.2a.6.6 0 00.6.6h12.8a.6.6 0 00.6-.6V9a.6.6 0 00-.6-.6h-7.2a1 1 0 01-.832-.445L9.065 6H5.6zM3.762 4.762A2.6 2.6 0 015.6 4h4a1 1 0 01.832.445L11.735 6.4H18.4A2.6 2.6 0 0121 9v8.8a2.6 2.6 0 01-2.6 2.6H5.6A2.6 2.6 0 013 17.8V6.6c0-.69.274-1.35.762-1.838z" fill="currentColor"></path></svg>
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Delete" (click)="deleteImagevisible=true">
              <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.2a1 1 0 011-1h14.4a1 1 0 110 2H5a1 1 0 01-1-1z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 5a.6.6 0 00-.6.6v.6h4.4v-.6a.6.6 0 00-.6-.6h-3.2zm5.8 1.2v-.6A2.6 2.6 0 0013.8 3h-3.2A2.6 2.6 0 008 5.6v.6H6.6a1 1 0 00-1 1v11.2A2.6 2.6 0 008.2 21h8a2.6 2.6 0 002.6-2.6V7.2a1 1 0 00-1-1h-1.4zm-8.8 2v10.2a.6.6 0 00.6.6h8a.6.6 0 00.6-.6V8.2H7.6z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 10.2a1 1 0 011 1V16a1 1 0 11-2 0v-4.8a1 1 0 011-1zM13.8 10.2a1 1 0 011 1V16a1 1 0 01-2 0v-4.8a1 1 0 011-1z" fill="currentColor"></path></svg>
            </a>
            <a class="btn_cancel" href="javascript:;" (click)="resetSelection()">Cancel</a>
          </div>

        </ng-container>
        <ng-container *ngIf="currentTab===1">
          <!-- <button class="btn_primary" (click)="addToFavorite()">Add to favorite</button>
          <button class="btn_primary" (click)="removeMediaFromAlbum=true">Remove from album</button>
          <button class="btn_primary" (click)="deleteImagevisible=true">Delete</button> -->
          <div class="selected_media_action">
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to favorite" (click)="addToFavorite()">
              <svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2_00000030473488031137604640000010881549889853806738_"><g id="favourite"><path d="m256 474.3c-6.6 0-12.9-2.6-17.6-7.2l-198.4-194.9-.2-.2c-43.7-43.6-52.4-111.2-21.2-164.4 1-1.7 1.7-2.8 1.9-3.1.3-.5.7-1 1.1-1.6 31.6-41.8 68.9-63.8 111-65.2 53.1-1.7 98.9 30.7 123.4 52.3 24.5-21.6 70.3-54 123.4-52.2 42.1 1.4 79.5 23.4 111 65.2.4.5.7 1 1.1 1.5.2.3.9 1.3 1.9 3.1 31.3 53.2 22.6 120.8-21.1 164.4l-.2.2-198.5 194.9c-4.7 4.6-11 7.2-17.6 7.2zm-180.8-237.8 180.8 177.5 180.8-177.5c27.5-27.4 32.9-69.9 13.3-103.4l-.4-.7c-21.8-28.5-46-43.5-71.9-44.4-.9 0-1.9 0-2.8 0-52.5 0-99.6 53.2-100.1 53.7-9 10.4-24.8 11.6-35.3 2.6-.9-.8-1.8-1.6-2.6-2.6-.5-.6-49.3-55.6-102.9-53.7-25.9.9-50.1 15.9-71.8 44.4-.1.2-.3.4-.4.8-19.6 33.4-14.1 75.9 13.3 103.3z" fill="rgb(0,0,0)"/></g></g></svg>
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Remove from album" (click)="removeMediaFromAlbum=true">
              <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.6 6a.6.6 0 00-.6.6v11.2a.6.6 0 00.6.6h12.8a.6.6 0 00.6-.6V9a.6.6 0 00-.6-.6h-7.2a1 1 0 01-.832-.445L9.065 6H5.6zM3.762 4.762A2.6 2.6 0 015.6 4h4a1 1 0 01.832.445L11.735 6.4H18.4A2.6 2.6 0 0121 9v8.8a2.6 2.6 0 01-2.6 2.6H5.6A2.6 2.6 0 013 17.8V6.6c0-.69.274-1.35.762-1.838z" fill="currentColor"></path></svg>
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Delete" (click)="deleteImagevisible=true">
              <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.2a1 1 0 011-1h14.4a1 1 0 110 2H5a1 1 0 01-1-1z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 5a.6.6 0 00-.6.6v.6h4.4v-.6a.6.6 0 00-.6-.6h-3.2zm5.8 1.2v-.6A2.6 2.6 0 0013.8 3h-3.2A2.6 2.6 0 008 5.6v.6H6.6a1 1 0 00-1 1v11.2A2.6 2.6 0 008.2 21h8a2.6 2.6 0 002.6-2.6V7.2a1 1 0 00-1-1h-1.4zm-8.8 2v10.2a.6.6 0 00.6.6h8a.6.6 0 00.6-.6V8.2H7.6z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 10.2a1 1 0 011 1V16a1 1 0 11-2 0v-4.8a1 1 0 011-1zM13.8 10.2a1 1 0 011 1V16a1 1 0 01-2 0v-4.8a1 1 0 011-1z" fill="currentColor"></path></svg>
            </a>
            <a class="btn_cancel" href="javascript:;" (click)="resetSelection()">Cancel</a>
          </div>
        </ng-container>
        <ng-container *ngIf="currentTab===2">
          <!-- <button class="btn_primary" (click)="addToFavorite()">Remove from favorite</button>
          <button class="btn_primary" (click)="addToAlbumVisible=true">Add to album</button>
          <button class="btn_primary" (click)="deleteImagevisible=true">Delete</button> -->
          <div class="selected_media_action">
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Remove from favorite" (click)="addToFavorite(null,null,true)">
              <svg id="Layer_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2_00000030473488031137604640000010881549889853806738_"><g id="favourite"><path d="m256 474.3c-6.6 0-12.9-2.6-17.6-7.2l-198.4-194.9-.2-.2c-43.7-43.6-52.4-111.2-21.2-164.4 1-1.7 1.7-2.8 1.9-3.1.3-.5.7-1 1.1-1.6 31.6-41.8 68.9-63.8 111-65.2 53.1-1.7 98.9 30.7 123.4 52.3 24.5-21.6 70.3-54 123.4-52.2 42.1 1.4 79.5 23.4 111 65.2.4.5.7 1 1.1 1.5.2.3.9 1.3 1.9 3.1 31.3 53.2 22.6 120.8-21.1 164.4l-.2.2-198.5 194.9c-4.7 4.6-11 7.2-17.6 7.2zm-180.8-237.8 180.8 177.5 180.8-177.5c27.5-27.4 32.9-69.9 13.3-103.4l-.4-.7c-21.8-28.5-46-43.5-71.9-44.4-.9 0-1.9 0-2.8 0-52.5 0-99.6 53.2-100.1 53.7-9 10.4-24.8 11.6-35.3 2.6-.9-.8-1.8-1.6-2.6-2.6-.5-.6-49.3-55.6-102.9-53.7-25.9.9-50.1 15.9-71.8 44.4-.1.2-.3.4-.4.8-19.6 33.4-14.1 75.9 13.3 103.3z" fill="rgb(0,0,0)"/></g></g></svg>
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Add to album" (click)="addToAlbumVisible=true">
              <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.6 6a.6.6 0 00-.6.6v11.2a.6.6 0 00.6.6h12.8a.6.6 0 00.6-.6V9a.6.6 0 00-.6-.6h-7.2a1 1 0 01-.832-.445L9.065 6H5.6zM3.762 4.762A2.6 2.6 0 015.6 4h4a1 1 0 01.832.445L11.735 6.4H18.4A2.6 2.6 0 0121 9v8.8a2.6 2.6 0 01-2.6 2.6H5.6A2.6 2.6 0 013 17.8V6.6c0-.69.274-1.35.762-1.838z" fill="currentColor"></path></svg>
            </a>
            <a href="javascript:;" nz-tooltip nzTooltipTitle="Delete" (click)="deleteImagevisible=true">
              <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.2a1 1 0 011-1h14.4a1 1 0 110 2H5a1 1 0 01-1-1z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 5a.6.6 0 00-.6.6v.6h4.4v-.6a.6.6 0 00-.6-.6h-3.2zm5.8 1.2v-.6A2.6 2.6 0 0013.8 3h-3.2A2.6 2.6 0 008 5.6v.6H6.6a1 1 0 00-1 1v11.2A2.6 2.6 0 008.2 21h8a2.6 2.6 0 002.6-2.6V7.2a1 1 0 00-1-1h-1.4zm-8.8 2v10.2a.6.6 0 00.6.6h8a.6.6 0 00.6-.6V8.2H7.6z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M10.6 10.2a1 1 0 011 1V16a1 1 0 11-2 0v-4.8a1 1 0 011-1zM13.8 10.2a1 1 0 011 1V16a1 1 0 01-2 0v-4.8a1 1 0 011-1z" fill="currentColor"></path></svg>
            </a>
            <a class="btn_cancel" href="javascript:;" (click)="resetSelection()">Cancel</a>
          </div>
        </ng-container>
      </div>
    </div>


    <div class="gallery_title_action">
      <a href="javascript:;" (click)="sortModal()" aria-label="Sort by">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 5L5 1M5 1L9 5M5 1V15M19 11L15 15M15 15L11 11M15 15V1" stroke="#232C89" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </a>
      <ng-container *ngIf="removeFileTypeFile">
        <a href="javascript:;" (click)="FilterByModal()" aria-label="Filter by">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.83993 1H18.1601C18.3409 1.06494 18.5048 1.1714 18.6392 1.31123C18.7736 1.45105 18.875 1.62053 18.9356 1.80668C18.9962 1.99284 19.0143 2.19072 18.9887 2.38518C18.9631 2.57965 18.8944 2.76552 18.7878 2.92857L12.5108 10V19L7.48921 15.1429V10L1.21223 2.92857C1.10563 2.76552 1.03689 2.57965 1.01127 2.38518C0.985655 2.19072 1.00383 1.99284 1.06442 1.80668C1.12501 1.62053 1.2264 1.45105 1.36082 1.31123C1.49524 1.1714 1.65913 1.06494 1.83993 1Z"
              stroke="#232C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </a>
      </ng-container>
    </div>
  </div>

  <!-- Start demo -->
  <!-- <div>
    <div class="demo_grid">
      <ng-container *ngFor="let media of galleryFiles">
        <ng-container *ngFor="let mediadata of media; let i = index">
          <ng-container *ngFor="let galleryMedia of mediadata[1]; let j = index">
            <div class="demo_grid_iner">
              <app-gallery-image-preview [isVideo]="galleryMedia['file_type'] ==='video' "
              [imageUrl]="galleryMedia.media_file" [class]="'manage-post-video'">
              </app-gallery-image-preview>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
   </div>
  </div> -->
  <!-- End demo -->

  <div class="gallery_body upload-media-wrapper">
    <button nz-button nztype="primary" nzshape="round" class="btn_upload_media"
            (click)="sharedService.isMobile && currentTab == 1 ? openCreateAlbum() : showUploadModal()"
      *ngIf="page!=='create_post' ">
      <!-- {{permissions.gallery_upload}} -->

      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_2784_23583" fill="white"><path d="M0 10C0 9.4477 0.447701 9 1 9C1.5523 9 2 9.4477 2 10H0ZM10 2C9.4477 2 9 1.55228 9 1C9 0.447715 9.4477 0 10 0V2ZM2.5 18H17.5V20H2.5V18ZM18 17.5V2.5H20V17.5H18ZM2 10V17.5H0V10H2ZM17.5 2H10V0H17.5V2ZM17.5 18C17.7761 18 18 17.7761 18 17.5H20C20 18.8807 18.8807 20 17.5 20V18ZM2.5 20C1.1193 20 0 18.8807 0 17.5H2C2 17.7761 2.22385 18 2.5 18V20ZM18 2.5C18 2.22386 17.7761 2 17.5 2V0C18.8807 0 20 1.11928 20 2.5H18Z" /></mask>
        <path d="M0 10C0 9.4477 0.447701 9 1 9C1.5523 9 2 9.4477 2 10H0ZM10 2C9.4477 2 9 1.55228 9 1C9 0.447715 9.4477 0 10 0V2ZM2.5 18H17.5V20H2.5V18ZM18 17.5V2.5H20V17.5H18ZM2 10V17.5H0V10H2ZM17.5 2H10V0H17.5V2ZM17.5 18C17.7761 18 18 17.7761 18 17.5H20C20 18.8807 18.8807 20 17.5 20V18ZM2.5 20C1.1193 20 0 18.8807 0 17.5H2C2 17.7761 2.22385 18 2.5 18V20ZM18 2.5C18 2.22386 17.7761 2 17.5 2V0C18.8807 0 20 1.11928 20 2.5H18Z" fill="black" />
        <path d="M0 10V12H-2V10H0ZM2 10H4V12H2V10ZM10 2H12V4H10V2ZM10 0V-2H12V0H10ZM2.5 18H0.5V16H2.5V18ZM17.5 18V16H19.5V18H17.5ZM17.5 20H19.5V22H17.5V20ZM2.5 20V22H0.5V20H2.5ZM18 17.5V19.5H16V17.5H18ZM18 2.5H16V0.5H18V2.5ZM20 2.5V0.5H22V2.5H20ZM20 17.5H22V19.5H20V17.5ZM2 10V8H4V10H2ZM2 17.5H4V19.5H2V17.5ZM0 17.5V19.5H-2V17.5H0ZM0 10H-2V8H0V10ZM17.5 2H19.5V4H17.5V2ZM10 2V4H8V2H10ZM10 0H8V-2H10V0ZM17.5 0V-2H19.5V0H17.5ZM17.5 18H15.5V16H17.5V18ZM18 17.5H16V15.5H18V17.5ZM20 17.5V15.5H22V17.5H20ZM17.5 20V22H15.5V20H17.5ZM2.5 20H4.5V22H2.5V20ZM0 17.5H-2V15.5H0V17.5ZM2 17.5V15.5H4V17.5H2ZM2.5 18V16H4.5V18H2.5ZM18 2.5V4.5H16V2.5H18ZM17.5 2V4H15.5V2H17.5ZM17.5 0H15.5V-2H17.5V0ZM20 2.5H22V4.5H20V2.5ZM-2 10C-2 8.34313 -0.65687 7 1 7V11C1.55227 11 2 10.5523 2 10H-2ZM1 7C2.65687 7 4 8.34313 4 10H0C0 10.5523 0.447731 11 1 11V7ZM2 12H0V8H2V12ZM10 4C8.34311 4 7 2.65684 7 1H11C11 0.447735 10.5523 0 10 0V4ZM7 1C7 -0.656835 8.34311 -2 10 -2V2C10.5523 2 11 1.55227 11 1H7ZM12 0V2H8V0H12ZM2.5 16H17.5V20H2.5V16ZM19.5 18V20H15.5V18H19.5ZM17.5 22H2.5V18H17.5V22ZM0.5 20V18H4.5V20H0.5ZM16 17.5V2.5H20V17.5H16ZM18 0.5H20V4.5H18V0.5ZM22 2.5V17.5H18V2.5H22ZM20 19.5H18V15.5H20V19.5ZM4 10V17.5H0V10H4ZM2 19.5H0V15.5H2V19.5ZM-2 17.5V10H2V17.5H-2ZM0 8H2V12H0V8ZM17.5 4H10V0H17.5V4ZM8 2V0H12V2H8ZM10 -2H17.5V2H10V-2ZM19.5 0V2H15.5V0H19.5ZM17.5 16C16.6716 16 16 16.6716 16 17.5H20C20 18.8807 18.8807 20 17.5 20V16ZM18 15.5H20V19.5H18V15.5ZM22 17.5C22 19.9853 19.9853 22 17.5 22V18C17.7761 18 18 17.7761 18 17.5H22ZM15.5 20V18H19.5V20H15.5ZM2.5 22C0.0147305 22 -2 19.9853 -2 17.5H2C2 17.7761 2.22387 18 2.5 18V22ZM0 15.5H2V19.5H0V15.5ZM4 17.5C4 16.6716 3.32842 16 2.5 16V20C1.11928 20 0 18.8807 0 17.5H4ZM4.5 18V20H0.5V18H4.5ZM16 2.5C16 3.32842 16.6716 4 17.5 4V0C18.8807 0 20 1.1193 20 2.5H16ZM15.5 2V0H19.5V2H15.5ZM17.5 -2C19.9853 -2 22 0.0147129 22 2.5H18C18 2.22386 17.7761 2 17.5 2V-2ZM20 4.5H18V0.5H20V4.5Z" fill="white" mask="url(#path-1-inside-1_2784_23583)" />
        <path d="M19 15.5L13.6535 10.599C13.2806 10.2572 12.7111 10.2477 12.327 10.5769L6 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 13.5001L5.61325 11.1133C5.2614 10.7615 4.7043 10.7219 4.30615 11.0205L1 13.5001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 4H1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4 1V7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span>Upload Media</span>
    </button>

    <div [class]="page === 'create_post'? 'media-list-wrap' : 'gallery_wrapper' ">
      <nz-tabset class="gallery_tab" [nzSelectedIndex]="currentTab" (nzSelectedIndexChange)="tabChanged($event)">

        <!--Media-->
        <nz-tab nzTitle="Media" (nzClick)="openGallery()">

          <!--if condition used for improve speed -->
          <ng-container *ngIf="currentTab===0">

            <!-- skeleton -->
            <div class="empty_gallery no-data" *ngIf="galleryLoading">
                <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                  <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                </div>
            </div>


            <ng-container *ngIf="!galleryLoading">
                <!-- no data -->
                <ng-container *ngIf="galleryFiles.length === 0">
                  <span style="align-items: center;">
                    <app-empty [message]="'No Media Found !'"></app-empty>
                  </span>
                </ng-container>

                <!-- gallery media -->
                <div *ngIf="galleryFiles.length > 0" [class]="scrollClass" nz-col infiniteScroll [infiniteScrollDistance]="2"
                     [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                  <div class="gallery_main" *ngFor="let media of galleryFiles">
                    <div class="media_grid_in">
                      <ng-container *ngFor="let mediadata of media; let i = index">
                        <div class="title_sec">
                          <ng-container *ngIf="galleryDate === mediadata[0];else yesterDay">
                            <h5> Today </h5>
                          </ng-container>
                          <ng-template #yesterDay>
                            <ng-container *ngIf="yesterDayDate === mediadata[0]; else date">
                              <h5> Yesterday </h5>
                            </ng-container>
                          </ng-template>
                          <ng-template #date>
                            <h5>{{ mediadata[0] | date }}</h5>
                          </ng-template>
                        </div>

                        <div class="gallery_grid">

                          <label nz-checkbox [class.isOneMediaSelected]="selectedMedias.length !== 0"
                                 *ngFor="let galleryMedia of mediadata[1]; let j = index"
                                 [(ngModel)]="galleryMedia.is_checked"
                                 (ngModelChange)="selectMedias($event,galleryMedia,mediadata[1])"
                                 [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(galleryMedia)
                                 && selectedGalleryFiles.length+postMedias.length>0 && selectedGalleryFiles.length+postMedias.length >= 10">
                            <div class="gallery_grid_in" [class]="(this.selectedGalleryFile === galleryMedia && page==='create_post') ? 'post-content-item selected' : 'post-content-item' ">
                              <ng-container *ngIf="page !== 'create_post'">
                                <div class="hover_option">
                                  <div class="hover_option_icon" (click)="handleClickOnOptions($event)">
                                    <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2Z" stroke="#000000" stroke-width="2"></path><path d="M1 10C1 10.5523 1.44772 11 2 11C2.55228 11 3 10.5523 3 10C3 9.44772 2.55228 9 2 9C1.44772 9 1 9.44772 1 10Z" stroke="#000000" stroke-width="2"></path><path d="M1 18C1 18.5523 1.44772 19 2 19C2.55228 19 3 18.5523 3 18C3 17.4477 2.55228 17 2 17C1.44772 17 1 17.4477 1 18Z" stroke="#000000" stroke-width="2"></path></svg>
                                  </div>

                                  <ul>
                                    <li (click)="selectImageFromGallery(galleryMedia,mediadata[0],i,j,mediadata[1].length,$event)">
                                      <a href="javascript:;">View</a>
                                    </li>
                                    <li (click)="addToFavorite(galleryMedia,$event)">
                                      <a href="javascript:;">{{ galleryMedia.is_favorite ? "Remove from favorite" : 'Add to favorite' }}</a>
                                    </li>
                                    <li (click)="addToAlbum(galleryMedia?._id,$event)"><a href="javascript:;">Add to album</a></li>
                                    <li (click)="deleteMedia(galleryMedia?._id,$event)"><a href="javascript:;">Delete</a></li>
                                  </ul>
                                </div>
                              </ng-container>

                              <ng-container *ngIf="page === 'create_post'">
                                <div class="media_info">
                                  <a href="javascript:;" (click)="selectImageFromGallery(galleryMedia,mediadata[0],i,j,mediadata[1].length,$event)">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 23.625 23.625" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M11.812 0C5.289 0 0 5.289 0 11.812s5.289 11.813 11.812 11.813 11.813-5.29 11.813-11.813S18.335 0 11.812 0zm2.459 18.307c-.608.24-1.092.422-1.455.548a3.838 3.838 0 0 1-1.262.189c-.736 0-1.309-.18-1.717-.539s-.611-.814-.611-1.367c0-.215.015-.435.045-.659a8.23 8.23 0 0 1 .147-.759l.761-2.688c.067-.258.125-.503.171-.731.046-.23.068-.441.068-.633 0-.342-.071-.582-.212-.717-.143-.135-.412-.201-.813-.201-.196 0-.398.029-.605.09-.205.063-.383.12-.529.176l.201-.828c.498-.203.975-.377 1.43-.521a4.225 4.225 0 0 1 1.29-.218c.731 0 1.295.178 1.692.53.395.353.594.812.594 1.376 0 .117-.014.323-.041.617a4.129 4.129 0 0 1-.152.811l-.757 2.68a7.582 7.582 0 0 0-.167.736 3.892 3.892 0 0 0-.073.626c0 .356.079.599.239.728.158.129.435.194.827.194.185 0 .392-.033.626-.097.232-.064.4-.121.506-.17l-.203.827zm-.134-10.878a1.807 1.807 0 0 1-1.275.492c-.496 0-.924-.164-1.28-.492a1.57 1.57 0 0 1-.533-1.193c0-.465.18-.865.533-1.196a1.812 1.812 0 0 1 1.28-.497c.497 0 .923.165 1.275.497.353.331.53.731.53 1.196 0 .467-.177.865-.53 1.193z" style="" fill="#494adf" data-original="#030104" opacity="1" class=""></path></g></svg>
                                  </a>
                                </div>
                              </ng-container>

                              <!--[imageUrl]="galleryMedia.media_file"-->
                              <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                              <app-gallery-image-preview
                                [isVideo]="galleryMedia['file_type'] ==='video' " [class]="'manage-post-video'"
                                [thumbUrl]="galleryMedia.media_file_thumb">
                              </app-gallery-image-preview>

                              <!-- <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon"
                                *ngIf=" galleryMedia['file_type'] ==='video'"></i> -->
                              <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon" *ngIf=" galleryMedia['file_type'] ==='video'"></i>
                              <!-- <span nz-icon nzType="play-circle" nzTheme="outline" class="media_type_icon" *ngIf=" galleryMedia['file_type'] ==='video'"></span> -->
                            </div>
                          </label>

                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="text-center">
                    <ng-container *ngIf="noGalleryData">
                    <span style="align-items: center;">
                      <app-empty [message]="'No Media Found !'"></app-empty>
                    </span>
                    </ng-container>
                    <ng-template [ngIf]="scrollLoading">
                      <div class="empty_gallery no-data">
                        <ng-template [ngIf]="scrollLoading">
                          <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                            <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                          </div>
                        </ng-template>
                      </div>
                    </ng-template>
                  </div>
                </div>
            </ng-container>

          </ng-container>

        </nz-tab>

        <!--Albums-->
        <nz-tab nzTitle="Albums" (nzClick)="getAlbumList()">
          <!--if condition used for improve speed -->
          <div class="gallery_albums albums_main" *ngIf="currentTab===1">
            <div class="title_sec" *ngIf="!albumData && page !== 'create_post'">
              <h3>Albums</h3>
            </div>
            <div *ngIf="(albumList.length === 0 && page === 'create_post')" class="empty_gallery no-data">
              <ng-container *ngIf="!galleryLoading; else skeleton">
                <span style="align-items: center;">
                  <app-empty [message]="'No Album Found !'"></app-empty>
                </span>
              </ng-container>
              <ng-template #skeleton>
                <ng-container >
                  <!-- *ngIf="galleryLoading" -->
                  <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                    <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                  </div>
                </ng-container>
              </ng-template>
            </div>

            <div class="gallery_albums_main" *ngIf="!albumData">
              <div class="create_gallery_albums" *ngIf="page !=='create_post' && !sharedService.isMobile">
                <div class="create_gallery_albums_in">
                  <img src="../../../assets/images/create_gallery_album_img.png" alt="">
                  <button nz-button class="btn_album" (click)="openCreateAlbum()">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="#494ADF" />
                    </svg>
                    Create New Album
                  </button>
                </div>
              </div>

              <!--[class.empty_album]="albumData.album_media_count===0"-->
              <div class="gallery_albums_in" *ngFor="let albumData of albumList">
                <div class="img_sec">
                  <div (click)="allImageFromAlbum(albumData._id,albumData.album_name)">
                    <ng-container *ngIf="albumData.album_media_count?.length !== 0">

                      <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                      <app-gallery-image-preview [isVideo]="albumData.album_preview?.file_type === 'video'" class="manage-post-video"
                        [thumbUrl]="albumData.album_preview?.media_file_thumb"
                      ></app-gallery-image-preview>

                    </ng-container>
                    <ng-container *ngIf="albumData.album_media_count === 0">
                      <img src="../../../assets/images/placeholder_image.png" class="default_album_img" alt="">
                    </ng-container>
                  </div>
                </div>
                <div class="content_sec">
                  <div class="content_sec_left">
                    <h4 [title]="albumData.album_name">{{albumData.album_name}}</h4>
                    <span>{{albumData.album_media_count}} Items</span>
                  </div>
                  <div class="content_sec_right" *ngIf="page !=='create_post'">
                    <div class="user_header_action">
                      <div class="user_header_ac_main">
                        <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2Z" stroke="#000000" stroke-width="2" />
                          <path d="M1 10C1 10.5523 1.44772 11 2 11C2.55228 11 3 10.5523 3 10C3 9.44772 2.55228 9 2 9C1.44772 9 1 9.44772 1 10Z" stroke="#000000" stroke-width="2" />
                          <path d="M1 18C1 18.5523 1.44772 19 2 19C2.55228 19 3 18.5523 3 18C3 17.4477 2.55228 17 2 17C1.44772 17 1 17.4477 1 18Z" stroke="#000000" stroke-width="2" />
                        </svg>
                      </div>
                      <ul class="text-base left_side">
                        <li>
                          <a (click)="showAlbumModal(albumData)">
                            Rename album
                          </a>
                        </li>
                        <li>
                          <a (click)="deleteAlbumVisible(albumData._id)">
                            Delete album
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="albumData">
              <div class="title_sec">
                <h5><a (click)="goBack()"><i nz-icon nzType="arrow-left" nzTheme="outline"></i></a>
                  &nbsp;&nbsp;{{currentAlbumName}}</h5>
              </div>

              <div class="empty_gallery no-data">
                <ng-container *ngIf="galleryLoading">
                  <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                    <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                  </div>
                </ng-container>
              </div>

              <div *ngIf="!galleryLoading" class="gallery_result" nz-col infiniteScroll [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="0" (scrolled)="onScrollAllImageFromAlbum()" [scrollWindow]="false">

                <div class="gallery_main">
                  <div class="gallery_grid">
                    <!--[class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(galleryMedia?.media)
                    && selectedGalleryFiles.length+postMedias.length>0
                    && ( (type === 'image' && (galleryMedia['file_type'] === 'video' || selectedGalleryFiles.length+postMedias.length >= 10)) || (type === 'video'))"-->
                    <label nz-checkbox [class.isOneMediaSelected]="selectedMedias.length !== 0"
                           *ngFor="let galleryMedia of medialListFromAlbum;let i=index"
                           [class]="(this.selectedGalleryFile === galleryMedia?.media && page==='create_post') ? 'post-content-item selected' : 'post-content-item' "
                           [(ngModel)]="medialListFromAlbum[i]?.media.is_checked"
                           (ngModelChange)="selectMedias($event,galleryMedia?.media,medialListFromAlbum)"
                           [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(galleryMedia?.media)
                              && selectedGalleryFiles.length+postMedias.length >= 10">

                      <div class="gallery_grid_in">
                      <ng-container *ngIf="page !== 'create_post'">
                        <div class="hover_option">
                          <div class="hover_option_icon" (click)="handleClickOnOptions($event)">
                            <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2Z" stroke="#000000" stroke-width="2"></path><path d="M1 10C1 10.5523 1.44772 11 2 11C2.55228 11 3 10.5523 3 10C3 9.44772 2.55228 9 2 9C1.44772 9 1 9.44772 1 10Z" stroke="#000000" stroke-width="2"></path><path d="M1 18C1 18.5523 1.44772 19 2 19C2.55228 19 3 18.5523 3 18C3 17.4477 2.55228 17 2 17C1.44772 17 1 17.4477 1 18Z" stroke="#000000" stroke-width="2"></path></svg>
                          </div>
                          <ul>
                            <li (click)="selectImageFromAlbum(galleryMedia?.media,galleryMedia.length,$event)"><a href="javascript:;">View</a></li>
                            <li (click)="addToFavorite(galleryMedia?.media,$event)">
                              <a href="javascript:;">{{ galleryMedia?.media?.is_favorite ? "Remove from favorite" : 'Add to favorite' }}</a>
                            </li>
                            <li (click)="addToAlbum(galleryMedia.media_id,$event)"><a href="javascript:;">Add to album</a></li>
                            <li (click)="deleteMedia(galleryMedia.media_id,$event)"><a href="javascript:;">Delete</a></li>
                          </ul>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="page === 'create_post'">
                        <div class="media_info">
                          <a href="javascript:;" (click)="selectImageFromAlbum(galleryMedia,galleryMedia.length,$event)">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 23.625 23.625" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M11.812 0C5.289 0 0 5.289 0 11.812s5.289 11.813 11.812 11.813 11.813-5.29 11.813-11.813S18.335 0 11.812 0zm2.459 18.307c-.608.24-1.092.422-1.455.548a3.838 3.838 0 0 1-1.262.189c-.736 0-1.309-.18-1.717-.539s-.611-.814-.611-1.367c0-.215.015-.435.045-.659a8.23 8.23 0 0 1 .147-.759l.761-2.688c.067-.258.125-.503.171-.731.046-.23.068-.441.068-.633 0-.342-.071-.582-.212-.717-.143-.135-.412-.201-.813-.201-.196 0-.398.029-.605.09-.205.063-.383.12-.529.176l.201-.828c.498-.203.975-.377 1.43-.521a4.225 4.225 0 0 1 1.29-.218c.731 0 1.295.178 1.692.53.395.353.594.812.594 1.376 0 .117-.014.323-.041.617a4.129 4.129 0 0 1-.152.811l-.757 2.68a7.582 7.582 0 0 0-.167.736 3.892 3.892 0 0 0-.073.626c0 .356.079.599.239.728.158.129.435.194.827.194.185 0 .392-.033.626-.097.232-.064.4-.121.506-.17l-.203.827zm-.134-10.878a1.807 1.807 0 0 1-1.275.492c-.496 0-.924-.164-1.28-.492a1.57 1.57 0 0 1-.533-1.193c0-.465.18-.865.533-1.196a1.812 1.812 0 0 1 1.28-.497c.497 0 .923.165 1.275.497.353.331.53.731.53 1.196 0 .467-.177.865-.53 1.193z" style="" fill="#494adf" data-original="#030104" opacity="1" class=""></path></g></svg>
                          </a>
                        </div>
                      </ng-container>

                        <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                      <app-gallery-image-preview [isVideo]="galleryMedia['file_type'] ==='video'" class="manage-post-video"
                        [thumbUrl]="galleryMedia.media_file_thumb">
                      </app-gallery-image-preview>
                      <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon"
                        *ngIf=" galleryMedia['file_type'] ==='video'"></i>
                    </div>
                    </label>

                  </div>
                </div>

                <div class="text-center">
                  <div class="empty_gallery no-data">
                    <ng-template [ngIf]="scrollLoading">
                      <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                        <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                      </div>
                    </ng-template>
                  </div>
                </div>

                <ng-container *ngIf="noGalleryData">
                  <span style="align-items: center;">
                    <app-empty [message]="'No Media Found !'"></app-empty>
                  </span>
                </ng-container>
              </div>
            </div>

          </div>
        </nz-tab>

        <!--Favorites-->
        <nz-tab nzTitle="Favorites" (nzClick)="getIsFavoriteGallery()">
          <!--if condition used for improve speed -->
          <ng-container *ngIf="currentTab===2">
            <!-- Favorite Image Listing Start -->
            <div class="empty_gallery no-data">
              <ng-container *ngIf="galleryLoading">
                <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                  <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                </div>
              </ng-container>
            </div>
            <div *ngIf="!galleryLoading" class="gallery_result" nz-col infiniteScroll [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="0" (scrolled)="onScrollIsFav()" [scrollWindow]="false">
              <div class="gallery_main">
                <div class="gallery_grid">
                  <!-- [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(media)
                              && selectedGalleryFiles.length+postMedias.length>0
                              && ( (type === 'image' && (media['file_type'] === 'video' || selectedGalleryFiles.length+postMedias.length >= 10)) || (type === 'video'))" -->
                  <label nz-checkbox [class.isOneMediaSelected]="selectedMedias.length !== 0"
                         *ngFor="let media of galleryFilesFav"
                         [class]="(this.selectedGalleryFile === media && page==='create_post') ? 'post-content-item selected' : 'post-content-item' "
                         [(ngModel)]="media.is_checked"
                         (ngModelChange)="selectMedias($event,media,galleryFilesFav)"
                         [class.disabled]="page === 'create_post' && !selectedGalleryFiles.includes(media)
                            && selectedGalleryFiles.length+postMedias.length >= 10">

                    <div class="gallery_grid_in">
                      <ng-container *ngIf="page !== 'create_post'">
                        <div class="hover_option">
                          <div class="hover_option_icon" (click)="handleClickOnOptions($event)">
                            <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2Z" stroke="#000000" stroke-width="2"></path><path d="M1 10C1 10.5523 1.44772 11 2 11C2.55228 11 3 10.5523 3 10C3 9.44772 2.55228 9 2 9C1.44772 9 1 9.44772 1 10Z" stroke="#000000" stroke-width="2"></path><path d="M1 18C1 18.5523 1.44772 19 2 19C2.55228 19 3 18.5523 3 18C3 17.4477 2.55228 17 2 17C1.44772 17 1 17.4477 1 18Z" stroke="#000000" stroke-width="2"></path></svg>
                          </div>
                          <ul>
                            <li (click)="selectImageFromIsFav(media,$event)"><a href="javascript:;">View</a></li>
                            <li (click)="addToFavorite(media,$event)">
                              <a href="javascript:;">{{ media.is_favorite ? "Remove from favorite" : 'Add to favorite' }}</a>
                            </li>
                            <li (click)="addToAlbum(media._id,$event)"><a href="javascript:;">Add to album</a></li>
                            <li (click)="deleteMedia(media._id,$event)"><a href="javascript:;">Delete</a></li>
                          </ul>
                        </div>
                      </ng-container>


                    <ng-container *ngIf="page === 'create_post'">
                      <div class="media_info">
                        <a href="javascript:;" (click)="selectImageFromIsFav(media,$event)">
                          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 23.625 23.625" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M11.812 0C5.289 0 0 5.289 0 11.812s5.289 11.813 11.812 11.813 11.813-5.29 11.813-11.813S18.335 0 11.812 0zm2.459 18.307c-.608.24-1.092.422-1.455.548a3.838 3.838 0 0 1-1.262.189c-.736 0-1.309-.18-1.717-.539s-.611-.814-.611-1.367c0-.215.015-.435.045-.659a8.23 8.23 0 0 1 .147-.759l.761-2.688c.067-.258.125-.503.171-.731.046-.23.068-.441.068-.633 0-.342-.071-.582-.212-.717-.143-.135-.412-.201-.813-.201-.196 0-.398.029-.605.09-.205.063-.383.12-.529.176l.201-.828c.498-.203.975-.377 1.43-.521a4.225 4.225 0 0 1 1.29-.218c.731 0 1.295.178 1.692.53.395.353.594.812.594 1.376 0 .117-.014.323-.041.617a4.129 4.129 0 0 1-.152.811l-.757 2.68a7.582 7.582 0 0 0-.167.736 3.892 3.892 0 0 0-.073.626c0 .356.079.599.239.728.158.129.435.194.827.194.185 0 .392-.033.626-.097.232-.064.4-.121.506-.17l-.203.827zm-.134-10.878a1.807 1.807 0 0 1-1.275.492c-.496 0-.924-.164-1.28-.492a1.57 1.57 0 0 1-.533-1.193c0-.465.18-.865.533-1.196a1.812 1.812 0 0 1 1.28-.497c.497 0 .923.165 1.275.497.353.331.53.731.53 1.196 0 .467-.177.865-.53 1.193z" style="" fill="#494adf" data-original="#030104" opacity="1" class=""></path></g></svg>
                        </a>
                      </div>
                    </ng-container>

                      <!--[thumbUrl]="galleryMedia.media_file_thumb ? galleryMedia.media_file_thumb: galleryMedia.media_file"-->
                      <app-gallery-image-preview
                        [isVideo]="media['file_type'] ==='video'" class="manage-post-video"
                        [thumbUrl]="media['media_file_thumb']">
                      </app-gallery-image-preview>
                      <i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon"
                      *ngIf=" media['file_type'] ==='video'"></i>

                  </div>
                  </label>
                </div>
              </div>
              <div class="text-center">
                <div class="empty_gallery no-data">
                  <ng-template [ngIf]="scrollLoading">
                    <div class="feed_grid_main" *ngFor="let staticImg of staticGallerySkeleton">
                      <nz-skeleton-element nzType="image" [nzActive]="true"></nz-skeleton-element>
                    </div>
                  </ng-template>
                </div>
              </div>
              <ng-container *ngIf="noGalleryData">
                <span style="align-items: center;">
                  <app-empty [message]="'No Favorites Media Found !'"></app-empty>
                </span>
              </ng-container>
            </div>
            <!-- Favorite Image Listring end -->
          </ng-container>
        </nz-tab>

      </nz-tabset>
    </div>

    <div class="media-selected-wrap" *ngIf="page==='create_post' && selectedGalleryFile!== null ">
      <h2>Attachment Details</h2>
      <app-gallery-image-preview [class]="selectedGalleryFile?.file_type ==='video' ? 'manage-post-video':''"
        [isVideo]="selectedGalleryFile?.file_type ==='video'" [imageUrl]="selectedGalleryFile?.media_file"
        [class]="'play'">
      </app-gallery-image-preview>
      <h4>{{ selectedGalleryFileMeta?.file_name}}</h4>
      <h4>{{ selectedGalleryFile?.created_at | date: 'MMM dd, y'}}</h4>
      <h4> Size : {{ selectedGalleryFileMeta?.file_size}}</h4>
      <h4 *ngIf="selectedGalleryFile?.file_type ==='video' && selectedGalleryFileMeta?.duration">
        Duration : {{ selectedGalleryFileMeta?.duration}}</h4>
      <h4> Resolution : {{ selectedGalleryFileMeta?.width +'px x ' + selectedGalleryFileMeta?.height + 'px' }}</h4>
    </div>
  </div>
  <div class="multi_preview_gallery" *ngIf="this.allAccountProvider.includes(this.sharedService.instagramProvider)">
    <button class="btn_primary_outline" (click)="cancel()">Cancel</button>
    <button class="btn_primary" (click)="cropMultipleImageManuallyFromGallery()">ok</button>
  </div>
</ng-container>
<!-- preview of media start -->

<nz-modal [(nzVisible)]="galleryModalisVisible" nzClassName="gallery_modal" [nzFooter]=null
  *ngIf="page!=='create_post'">
  <ng-container *nzModalContent>
    <div class="gallery_modal_header">
      <a href="javascript:;" (click)="galleryCloseModal()">
        <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.7372 23.137C14.0653 22.8088 14.2496 22.3638 14.2496 21.8997C14.2496 21.4357 14.0653 20.9907 13.7372 20.6625L5.07471 12L13.7372 3.33749C14.056 3.00744 14.2324 2.56538 14.2284 2.10654C14.2244 1.64769 14.0404 1.20877 13.7159 0.884307C13.3914 0.559844 12.9525 0.375801 12.4937 0.371812C12.0348 0.367826 11.5928 0.544216 11.2627 0.862993L1.36296 10.7627C1.03489 11.0909 0.850585 11.5359 0.850585 12C0.850585 12.464 1.03489 12.9091 1.36296 13.2372L11.2627 23.137C11.5909 23.4651 12.0359 23.6494 12.5 23.6494C12.964 23.6494 13.409 23.4651 13.7372 23.137Z"
            fill="white" />
        </svg>
      </a>
    </div>
    <div class="gallery_modal_body">
      <div class="gallery_img_main">
        <nz-carousel [nzEffect]="effect" [nzDots]="false" [nzEnableSwipe]="false">
          <ng-container *ngIf="selectedGalleryFile">
            <div *ngIf="selectedGalleryFile !== ''">
              <div class="gallery_img_in">
                <app-gallery-image-preview [isVideo]="selectedGalleryFile?.file_type ==='video'"
                  [imageUrl]="selectedGalleryFile?.media_file" [preview]="isView">
                </app-gallery-image-preview>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedMediaFromAlbum">
            <div *ngIf="selectedMediaFromAlbum !== ''">
              <div class="gallery_img_in">
                <app-gallery-image-preview [isVideo]="selectedMediaFromAlbum?.file_type ==='video'"
                  [imageUrl]="selectedMediaFromAlbum?.media_file" [preview]="isView">
                </app-gallery-image-preview>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedMediaFromIsFav">
            <div *ngIf="selectedMediaFromIsFav !== ''">
              <div class="gallery_img_in">
                <app-gallery-image-preview [isVideo]="selectedMediaFromIsFav?.file_type ==='video'"
                  [imageUrl]="selectedMediaFromIsFav?.media_file" [preview]="isView">
                </app-gallery-image-preview>
              </div>
            </div>
          </ng-container>
        </nz-carousel>
        <div class="nzSliderBtnWrapper">
          <button class="prevBtn" nz-button (click)="step('prev')" [disabled]="subIndex===0"><i nz-icon nzType="left"
              nzTheme="outline"></i></button>
          <button class="nextBtn" nz-button (click)="step('next')" [disabled]="subIndex === lastIndex-1"><i nz-icon
              nzType="right" nzTheme="outline"></i></button>
        </div>
      </div>
    </div>
    <div class="gallery_modal_footer">
      <ng-container *ngIf="isFavIcon ">
        <!-- && permissions.gallery_fav -->
        <a (click)="addIsFavorite(selectedGalleryFile?._id || selectedMediaFromAlbum?._id)">
          <ng-container *ngIf="(selectedGalleryFile?.is_favorite || selectedMediaFromAlbum?.is_favorite )===true;else NotFav">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" style=" fill: red;"><path d="M6 1C3.239 1 1 3.216 1 5.95C1 8.157 1.875 13.395 10.488 18.69C10.6423 18.7839 10.8194 18.8335 11 18.8335C11.1806 18.8335 11.3577 18.7839 11.512 18.69C20.125 13.395 21 8.157 21 5.95C21 3.216 18.761 1 16 1C13.239 1 11 4 11 4C11 4 8.761 1 6 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
          </ng-container>
          <ng-template #NotFav>
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 1C3.239 1 1 3.216 1 5.95C1 8.157 1.875 13.395 10.488 18.69C10.6423 18.7839 10.8194 18.8335 11 18.8335C11.1806 18.8335 11.3577 18.7839 11.512 18.69C20.125 13.395 21 8.157 21 5.95C21 3.216 18.761 1 16 1C13.239 1 11 4 11 4C11 4 8.761 1 6 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
          </ng-template>
          <span>Favorite</span>
        </a>
      </ng-container>
      <ng-container *ngIf="isFavIconForFavMedia ">
        <!-- && permissions.gallery_fav -->
        <a (click)="addIsFavorite(selectedMediaFromIsFav._id)">
          <ng-container *ngIf="selectedMediaFromIsFav?.is_favorite===true;else NotFav">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" style=" fill: red;"><path d="M6 1C3.239 1 1 3.216 1 5.95C1 8.157 1.875 13.395 10.488 18.69C10.6423 18.7839 10.8194 18.8335 11 18.8335C11.1806 18.8335 11.3577 18.7839 11.512 18.69C20.125 13.395 21 8.157 21 5.95C21 3.216 18.761 1 16 1C13.239 1 11 4 11 4C11 4 8.761 1 6 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
          </ng-container>
          <ng-template #NotFav>
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 1C3.239 1 1 3.216 1 5.95C1 8.157 1.875 13.395 10.488 18.69C10.6423 18.7839 10.8194 18.8335 11 18.8335C11.1806 18.8335 11.3577 18.7839 11.512 18.69C20.125 13.395 21 8.157 21 5.95C21 3.216 18.761 1 16 1C13.239 1 11 4 11 4C11 4 8.761 1 6 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>
          </ng-template>
          <span>Favorite</span>
        </a>
      </ng-container>

      <a (click)="addToAlbum()" >
        <!-- *ngIf="permissions.gallery_albums" -->

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="path-1-inside-1_2834_25630" fill="white">
            <path d="M0 10C0 9.4477 0.447701 9 1 9C1.5523 9 2 9.4477 2 10H0ZM10 2C9.4477 2 9 1.55228 9 1C9 0.447715 9.4477 0 10 0V2ZM2.5 18H17.5V20H2.5V18ZM18 17.5V2.5H20V17.5H18ZM2 10V17.5H0V10H2ZM17.5 2H10V0H17.5V2ZM17.5 18C17.7761 18 18 17.7761 18 17.5H20C20 18.8807 18.8807 20 17.5 20V18ZM2.5 20C1.1193 20 0 18.8807 0 17.5H2C2 17.7761 2.22385 18 2.5 18V20ZM18 2.5C18 2.22386 17.7761 2 17.5 2V0C18.8807 0 20 1.11928 20 2.5H18Z" />
          </mask>
          <path d="M0 10C0 9.4477 0.447701 9 1 9C1.5523 9 2 9.4477 2 10H0ZM10 2C9.4477 2 9 1.55228 9 1C9 0.447715 9.4477 0 10 0V2ZM2.5 18H17.5V20H2.5V18ZM18 17.5V2.5H20V17.5H18ZM2 10V17.5H0V10H2ZM17.5 2H10V0H17.5V2ZM17.5 18C17.7761 18 18 17.7761 18 17.5H20C20 18.8807 18.8807 20 17.5 20V18ZM2.5 20C1.1193 20 0 18.8807 0 17.5H2C2 17.7761 2.22385 18 2.5 18V20ZM18 2.5C18 2.22386 17.7761 2 17.5 2V0C18.8807 0 20 1.11928 20 2.5H18Z" fill="black" />
          <path d="M0 10V12H-2V10H0ZM2 10H4V12H2V10ZM10 2H12V4H10V2ZM10 0V-2H12V0H10ZM2.5 18H0.5V16H2.5V18ZM17.5 18V16H19.5V18H17.5ZM17.5 20H19.5V22H17.5V20ZM2.5 20V22H0.5V20H2.5ZM18 17.5V19.5H16V17.5H18ZM18 2.5H16V0.5H18V2.5ZM20 2.5V0.5H22V2.5H20ZM20 17.5H22V19.5H20V17.5ZM2 10V8H4V10H2ZM2 17.5H4V19.5H2V17.5ZM0 17.5V19.5H-2V17.5H0ZM0 10H-2V8H0V10ZM17.5 2H19.5V4H17.5V2ZM10 2V4H8V2H10ZM10 0H8V-2H10V0ZM17.5 0V-2H19.5V0H17.5ZM17.5 18H15.5V16H17.5V18ZM18 17.5H16V15.5H18V17.5ZM20 17.5V15.5H22V17.5H20ZM17.5 20V22H15.5V20H17.5ZM2.5 20H4.5V22H2.5V20ZM0 17.5H-2V15.5H0V17.5ZM2 17.5V15.5H4V17.5H2ZM2.5 18V16H4.5V18H2.5ZM18 2.5V4.5H16V2.5H18ZM17.5 2V4H15.5V2H17.5ZM17.5 0H15.5V-2H17.5V0ZM20 2.5H22V4.5H20V2.5ZM-2 10C-2 8.34313 -0.65687 7 1 7V11C1.55227 11 2 10.5523 2 10H-2ZM1 7C2.65687 7 4 8.34313 4 10H0C0 10.5523 0.447731 11 1 11V7ZM2 12H0V8H2V12ZM10 4C8.34311 4 7 2.65684 7 1H11C11 0.447735 10.5523 0 10 0V4ZM7 1C7 -0.656835 8.34311 -2 10 -2V2C10.5523 2 11 1.55227 11 1H7ZM12 0V2H8V0H12ZM2.5 16H17.5V20H2.5V16ZM19.5 18V20H15.5V18H19.5ZM17.5 22H2.5V18H17.5V22ZM0.5 20V18H4.5V20H0.5ZM16 17.5V2.5H20V17.5H16ZM18 0.5H20V4.5H18V0.5ZM22 2.5V17.5H18V2.5H22ZM20 19.5H18V15.5H20V19.5ZM4 10V17.5H0V10H4ZM2 19.5H0V15.5H2V19.5ZM-2 17.5V10H2V17.5H-2ZM0 8H2V12H0V8ZM17.5 4H10V0H17.5V4ZM8 2V0H12V2H8ZM10 -2H17.5V2H10V-2ZM19.5 0V2H15.5V0H19.5ZM17.5 16C16.6716 16 16 16.6716 16 17.5H20C20 18.8807 18.8807 20 17.5 20V16ZM18 15.5H20V19.5H18V15.5ZM22 17.5C22 19.9853 19.9853 22 17.5 22V18C17.7761 18 18 17.7761 18 17.5H22ZM15.5 20V18H19.5V20H15.5ZM2.5 22C0.0147305 22 -2 19.9853 -2 17.5H2C2 17.7761 2.22387 18 2.5 18V22ZM0 15.5H2V19.5H0V15.5ZM4 17.5C4 16.6716 3.32842 16 2.5 16V20C1.11928 20 0 18.8807 0 17.5H4ZM4.5 18V20H0.5V18H4.5ZM16 2.5C16 3.32842 16.6716 4 17.5 4V0C18.8807 0 20 1.1193 20 2.5H16ZM15.5 2V0H19.5V2H15.5ZM17.5 -2C19.9853 -2 22 0.0147129 22 2.5H18C18 2.22386 17.7761 2 17.5 2V-2ZM20 4.5H18V0.5H20V4.5Z" fill="white" mask="url(#path-1-inside-1_2834_25630)" />
          <path d="M19 15.5L13.6535 10.599C13.2806 10.2572 12.7111 10.2477 12.327 10.5769L6 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 13.5L5.61325 11.1132C5.2614 10.7614 4.7043 10.7218 4.30615 11.0204L1 13.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7 4H1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 1V7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <!-- <span>Add to Album</span> -->
        <span>{{addToAlbumTag}}</span>
      </a>

      <ng-container *ngIf="selectedMediaFromAlbum != null ; else removeMediaElsePart">
        <a (click)="removeMediaToAlbum(selectedMediaFromAlbum?._id )">

          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_0_4" fill="white">
              <path
                d="M0 10C0 9.4477 0.447701 9 1 9C1.5523 9 2 9.4477 2 10H0ZM10 2C9.4477 2 9 1.55228 9 1C9 0.447715 9.4477 0 10 0V2ZM2.5 18H17.5V20H2.5V18ZM18 17.5V2.5H20V17.5H18ZM2 10V17.5H0V10H2ZM17.5 2H10V0H17.5V2ZM17.5 18C17.7761 18 18 17.7761 18 17.5H20C20 18.8807 18.8807 20 17.5 20V18ZM2.5 20C1.1193 20 0 18.8807 0 17.5H2C2 17.7761 2.22385 18 2.5 18V20ZM18 2.5C18 2.22386 17.7761 2 17.5 2V0C18.8807 0 20 1.11928 20 2.5H18Z" />
            </mask>
            <path
              d="M0 10C0 9.4477 0.447701 9 1 9C1.5523 9 2 9.4477 2 10H0ZM10 2C9.4477 2 9 1.55228 9 1C9 0.447715 9.4477 0 10 0V2ZM2.5 18H17.5V20H2.5V18ZM18 17.5V2.5H20V17.5H18ZM2 10V17.5H0V10H2ZM17.5 2H10V0H17.5V2ZM17.5 18C17.7761 18 18 17.7761 18 17.5H20C20 18.8807 18.8807 20 17.5 20V18ZM2.5 20C1.1193 20 0 18.8807 0 17.5H2C2 17.7761 2.22385 18 2.5 18V20ZM18 2.5C18 2.22386 17.7761 2 17.5 2V0C18.8807 0 20 1.11928 20 2.5H18Z"
              fill="black" />
            <path
              d="M0 10V12H-2V10H0ZM2 10H4V12H2V10ZM10 2H12V4H10V2ZM10 0V-2H12V0H10ZM2.5 18H0.5V16H2.5V18ZM17.5 18V16H19.5V18H17.5ZM17.5 20H19.5V22H17.5V20ZM2.5 20V22H0.5V20H2.5ZM18 17.5V19.5H16V17.5H18ZM18 2.5H16V0.5H18V2.5ZM20 2.5V0.5H22V2.5H20ZM20 17.5H22V19.5H20V17.5ZM2 10V8H4V10H2ZM2 17.5H4V19.5H2V17.5ZM0 17.5V19.5H-2V17.5H0ZM0 10H-2V8H0V10ZM17.5 2H19.5V4H17.5V2ZM10 2V4H8V2H10ZM10 0H8V-2H10V0ZM17.5 0V-2H19.5V0H17.5ZM17.5 18H15.5V16H17.5V18ZM18 17.5H16V15.5H18V17.5ZM20 17.5V15.5H22V17.5H20ZM17.5 20V22H15.5V20H17.5ZM2.5 20H4.5V22H2.5V20ZM0 17.5H-2V15.5H0V17.5ZM2 17.5V15.5H4V17.5H2ZM2.5 18V16H4.5V18H2.5ZM18 2.5V4.5H16V2.5H18ZM17.5 2V4H15.5V2H17.5ZM17.5 0H15.5V-2H17.5V0ZM20 2.5H22V4.5H20V2.5ZM-2 10C-2 8.34313 -0.65687 7 1 7V11C1.55227 11 2 10.5523 2 10H-2ZM1 7C2.65687 7 4 8.34313 4 10H0C0 10.5523 0.447731 11 1 11V7ZM2 12H0V8H2V12ZM10 4C8.34311 4 7 2.65684 7 1H11C11 0.447735 10.5523 0 10 0V4ZM7 1C7 -0.656835 8.34311 -2 10 -2V2C10.5523 2 11 1.55227 11 1H7ZM12 0V2H8V0H12ZM2.5 16H17.5V20H2.5V16ZM19.5 18V20H15.5V18H19.5ZM17.5 22H2.5V18H17.5V22ZM0.5 20V18H4.5V20H0.5ZM16 17.5V2.5H20V17.5H16ZM18 0.5H20V4.5H18V0.5ZM22 2.5V17.5H18V2.5H22ZM20 19.5H18V15.5H20V19.5ZM4 10V17.5H0V10H4ZM2 19.5H0V15.5H2V19.5ZM-2 17.5V10H2V17.5H-2ZM0 8H2V12H0V8ZM17.5 4H10V0H17.5V4ZM8 2V0H12V2H8ZM10 -2H17.5V2H10V-2ZM19.5 0V2H15.5V0H19.5ZM17.5 16C16.6716 16 16 16.6716 16 17.5H20C20 18.8807 18.8807 20 17.5 20V16ZM18 15.5H20V19.5H18V15.5ZM22 17.5C22 19.9853 19.9853 22 17.5 22V18C17.7761 18 18 17.7761 18 17.5H22ZM15.5 20V18H19.5V20H15.5ZM2.5 22C0.0147305 22 -2 19.9853 -2 17.5H2C2 17.7761 2.22387 18 2.5 18V22ZM0 15.5H2V19.5H0V15.5ZM4 17.5C4 16.6716 3.32842 16 2.5 16V20C1.11928 20 0 18.8807 0 17.5H4ZM4.5 18V20H0.5V18H4.5ZM16 2.5C16 3.32842 16.6716 4 17.5 4V0C18.8807 0 20 1.1193 20 2.5H16ZM15.5 2V0H19.5V2H15.5ZM17.5 -2C19.9853 -2 22 0.0147129 22 2.5H18C18 2.22386 17.7761 2 17.5 2V-2ZM20 4.5H18V0.5H20V4.5Z"
              fill="white" mask="url(#path-1-inside-1_0_4)" />
            <path d="M19 15.5L13.6535 10.599C13.2806 10.2571 12.7111 10.2476 12.327 10.5769L6 16" stroke="white"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 13.5L5.61325 11.1132C5.2614 10.7614 4.7043 10.7218 4.30615 11.0204L1 13.5" stroke="white"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7 4H1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <span>Remove from album</span>

        </a>
      </ng-container>
      <ng-template #removeMediaElsePart>
        <a (click)="deleteMedia(selectedGalleryFile?._id || selectedMediaFromIsFav?._id)">

        <!-- <a (click)="deleteMedia(selectedGalleryFile?._id || selectedMediaFromAlbum?.media_id ||  selectedMediaFromIsFav?._id)"> -->
          <!-- *ngIf="permissions.gallery_delete" -->

          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.5 4L6.044 2.368C6.17664 1.96975 6.43123 1.62333 6.77169 1.37781C7.11216 1.13229 7.52124 1.00011 7.941 1H10.058C10.4779 0.999899 10.8872 1.13198 11.2279 1.37752C11.5686 1.62305 11.8233 1.96959 11.956 2.368L12.5 4M3 4L3.934 17.071C3.95194 17.323 4.06462 17.5589 4.24939 17.7313C4.43417 17.9036 4.67733 17.9996 4.93 18H13.068C13.3208 17.9999 13.5643 17.904 13.7492 17.7316C13.9342 17.5592 14.047 17.3232 14.065 17.071L15 4H3ZM9 9V13V9ZM17 4H1H17Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <!-- <span>Delete</span> -->
          <span>{{deleteTag}}</span>
        </a>
      </ng-template>
    </div>
  </ng-container>
</nz-modal>
<!-- preview of media end -->

<!-- Start sort modal -->
<nz-modal [(nzVisible)]="visible" nzClassName="sort_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="sort_hdr">
      <h4>Sort by</h4>
    </div>
    <div class="sort_body">
      <div class="sort_option">

        <nz-radio-group [ngModel]="getFilterType()" (ngModelChange)="setFilterType($event)">
          <label nz-radio nzLabel="Newest" nzValue="DESC">Newest</label>
          <label nz-radio nzLabel="Oldest" nzValue="ASC">Oldest</label>
        </nz-radio-group>
      </div>
      <div class="btn_wrap">
        <button type="button" class="btn" (click)="close_sort_modal()">Cancel</button>
        <button type="button" class="btn btn_apply" (click)="filterDateChange()">Apply</button>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End sort modal -->

<!-- Start filter by modal -->
<nz-modal [(nzVisible)]="filterVisible" nzClassName="sort_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="sort_hdr">
      <h4>Filter by</h4>
    </div>
    <div class="sort_body">
      <div class="sort_option">
        <nz-radio-group [ngModel]="getFilterMediaType()" (ngModelChange)="setFilterMediaType($event)">
          <label nz-radio nzValue="" nzLabel="All">All</label>
          <label nz-radio nzValue="image" nzLabel="Image">Image</label>
          <label nz-radio nzValue="video" nzLabel="Video">Video</label>
          <label nz-radio nzValue="gif" nzLabel="GIF">GIF</label>
        </nz-radio-group>
      </div>
      <div class="btn_wrap">
        <button type="button" class="btn" (click)="close_filter_modal()">Cancel</button>
        <button type="button" class="btn btn_apply" (click)="filterFileTypeChange()">Apply</button>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End filter by modal -->


<!-- Start upload media -->
<nz-modal [(nzVisible)]="UploadModalisVisible" nzTitle="Upload Media" [nzClassName]="'upload-modal'"
  [nzOkDisabled]="loading" [nzCancelDisabled]="loading" [nzFooter]=null>
  <!-- (nzOnCancel)="handleCancel()" -->
  <ng-container *nzModalContent>
    <a class="close_modal" href="javascript:;" [class.disabled_while_loading]="isLoading" (click)="handleCancel()"><span
        nz-icon nzType="close" nzTheme="outline"></span></a>
    <div class="upload_img_msg">
      <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.9997 2.83325C11.6922 2.83325 9.4366 3.51749 7.51803 4.79944C5.59945 6.08139 4.10411 7.90347 3.22109 10.0353C2.33806 12.1671 2.10702 14.5129 2.55718 16.776C3.00735 19.0391 4.11849 21.1179 5.7501 22.7495C7.38171 24.3811 9.46051 25.4923 11.7236 25.9424C13.9867 26.3926 16.3325 26.1615 18.4643 25.2785C20.5961 24.3955 22.4182 22.9001 23.7002 20.9816C24.9821 19.063 25.6663 16.8074 25.6663 14.4999C25.6663 12.9678 25.3646 11.4507 24.7783 10.0353C24.192 8.61981 23.3326 7.33369 22.2493 6.25034C21.1659 5.16699 19.8798 4.30763 18.4643 3.72132C17.0489 3.13502 15.5318 2.83325 13.9997 2.83325ZM13.9997 23.8333C12.1537 23.8333 10.3492 23.2859 8.81436 22.2603C7.2795 21.2347 6.08322 19.7771 5.3768 18.0716C4.67039 16.3662 4.48556 14.4896 4.84568 12.6791C5.20581 10.8686 6.09473 9.20555 7.40002 7.90026C8.70531 6.59497 10.3683 5.70605 12.1788 5.34592C13.9893 4.98579 15.8659 5.17063 17.5714 5.87704C19.2768 6.58346 20.7345 7.77974 21.7601 9.3146C22.7856 10.8495 23.333 12.654 23.333 14.4999C23.333 16.9753 22.3497 19.3492 20.5993 21.0996C18.849 22.8499 16.475 23.8333 13.9997 23.8333Z"
          fill="#494ADF" />
        <path
          d="M13.9997 11.0001C14.644 11.0001 15.1663 10.4777 15.1663 9.83341C15.1663 9.18908 14.644 8.66675 13.9997 8.66675C13.3553 8.66675 12.833 9.18908 12.833 9.83341C12.833 10.4777 13.3553 11.0001 13.9997 11.0001Z"
          fill="#494ADF" />
        <path
          d="M13.9997 12.1667C13.6903 12.1667 13.3935 12.2897 13.1747 12.5085C12.9559 12.7272 12.833 13.024 12.833 13.3334V19.1667C12.833 19.4762 12.9559 19.7729 13.1747 19.9917C13.3935 20.2105 13.6903 20.3334 13.9997 20.3334C14.3091 20.3334 14.6058 20.2105 14.8246 19.9917C15.0434 19.7729 15.1663 19.4762 15.1663 19.1667V13.3334C15.1663 13.024 15.0434 12.7272 14.8246 12.5085C14.6058 12.2897 14.3091 12.1667 13.9997 12.1667Z"
          fill="#494ADF" />
      </svg>
      <p>Recommended video resolution is
        1280 x 720 px aspect ratio between 9:16,
        max. length 4 hours and max. size 2 GB</p>
    </div>
    <div class="upload_img_banner">
      <img src="../../../assets/images/upload_media.png" alt="">
    </div>
    <div class="upload_img_wrp">
      <ng-container>
        <div class="upload_img">
          <ng-container *ngIf="postMedias.length > 0">
            <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">

              <!-- <i class="icon" nz-icon nzType="close-circle" nzTheme="outline"
                (click)="deleteImage(i, image.media_file)"></i> -->
              <!-- <div class="icon" (click)="deleteImage(i, image.media_file)" > -->
              <button nz-button nzType="primary" class="icon" (click)="deleteImage(i, media.media_file)"
                [disabled]="isLoading">

                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                  class="delete-icon">
                  <path
                    d="M0.430947 0.431252C0.641916 0.220347 0.928012 0.101868 1.22632 0.101868C1.52463 0.101868 1.81073 0.220347 2.0217 0.431252L5.9997 4.40925L9.9777 0.431252C10.1899 0.226325 10.4741 0.112931 10.769 0.115494C11.064 0.118057 11.3462 0.236372 11.5547 0.444957C11.7633 0.653541 11.8816 0.935705 11.8842 1.23068C11.8868 1.52565 11.7734 1.80983 11.5684 2.022L7.59045 6L11.5684 9.978C11.7734 10.1902 11.8868 10.4744 11.8842 10.7693C11.8816 11.0643 11.7633 11.3465 11.5547 11.555C11.3462 11.7636 11.064 11.8819 10.769 11.8845C10.4741 11.8871 10.1899 11.7737 9.9777 11.5688L5.9997 7.59075L2.0217 11.5688C1.80952 11.7737 1.52534 11.8871 1.23037 11.8845C0.9354 11.8819 0.653235 11.7636 0.444651 11.555C0.236067 11.3465 0.117752 11.0643 0.115189 10.7693C0.112626 10.4744 0.226019 10.1902 0.430947 9.978L4.40895 6L0.430947 2.022C0.220042 1.81103 0.101562 1.52494 0.101562 1.22663C0.101562 0.928318 0.220042 0.642221 0.430947 0.431252Z"
                    fill="white"></path>
                </svg>

              </button>
              <!-- </div> -->
              <!-- <app-gallery-media-preview [imageUrl]="image.media_file" [class]="'post-image uploaded-img'" -->

              <!-- </div> -->
              <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img'"
                [isVideo]="media.file_type === 'video' ? true : false">
              </app-gallery-image-preview>
            </div>
          </ng-container>

          <div class="upload_file_main" [class.emptyUpload]="postMedias.length === 0 && currentUploading.length === 0"
            *ngIf="postMedias.length !== maxLength">
            <nz-upload nzName="post" nzListType="picture-card" #userPhoto  [nzShowUploadList]="false"  [nzRemove]="handleRemove"
              [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzDisabled]="isLoading"
              *ngIf="currentUploading.length === 0" (nzChange)="onChangeEventUpload($event)" [nzAccept]="supportedFileTypes.toString()">
              <!-- [nzLimit]="maxLength" -->
              <ng-container>
                <div class="ant-upload-text">
                  <img src="../../../assets/images/upload_media_icon.png" alt="">
                  <span>Add Media</span>
                </div>
              </ng-container>
            </nz-upload>
            <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false"
              [nzBeforeUpload]="beforeUpload" [nzMultiple]="true" [nzAccept]="supportedFileTypes.toString()"
              *ngFor="let uploading of currentUploading">
              <ng-container>
                <nz-progress class='nz_progress' [nzPercent]="uploading.percent" nzType="circle" [nzWidth]="80"
                  *ngIf="uploading.loading"></nz-progress>
                <div class="ant-upload-text" *ngIf="!uploading.loading">Upload</div>
              </ng-container>
            </nz-upload>
          </div>

        </div>
      </ng-container>
    </div>
    <div class="btn_wrap">
      <button class="btn" nz-button nzType="primary" (click)="handleOk()" [disabled]="isLoading">
        {{ albumId ? 'Add to Album' : 'Add to Gallery' }}
        <ng-container *ngIf="isLoading && loadingPostType==='addGallery'">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </ng-container>
</nz-modal>
<!-- End upload media -->

<!-- Create Album modal start  -->
<nz-modal nzClassName="organization_form_modal" [(nzVisible)]="openCreateAlbumModal" nzTitle="{{modalTitle}}"
  [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="create-team-modal">
      <form nz-form [formGroup]="albumForm">
        <nz-form-item>
          <nz-form-label [nzSpan]="24">Album Name</nz-form-label>
          <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Album name is required">
            <input nz-input formControlName="album_name" placeholder="Album Name" required />
          </nz-form-control>
        </nz-form-item>
      </form>
      <div class="organization_form_action">
        <span *ngIf="editAlbumId">Are you sure you want to make changes?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="closeAlbumModal()"
            [disabled]="isLoading">Cancel</button>
          <button nz-button nzType="primary" class="btn btn_apply" (click)="saveAlbum()"
            [disabled]="isLoading">{{modalButton}}
            <ng-container *ngIf="isLoading && loadingPostType==='albumChange'">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- Create Album modal end  -->

<!-- Add to album modal start -->
<nz-modal nzClassName="add_to_album_modal" [(nzVisible)]="addToAlbumVisible" nzTitle="{{addAlbumTitle}}"
  [nzFooter]=null (nzOnCancel)="closeAlbumModal()">
  <ng-container *nzModalContent>
    <div class="create-team-modal">

      <div *ngIf="albumList.length === 0 && !galleryLoading && !newAlbum">
        <app-empty [message]="'No Album Found !'"></app-empty>
      </div>

      <ng-container *ngIf="!newAlbum">
        <div class="create_new_album">
          <div class="action_wrapper">
            <a href="javascript:;" (click)="createNewAlbum()">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 9C17 9.55228 16.5523 10 16 10H10V16C10 16.5523 9.55228 17 9 17V17C8.44772 17 8 16.5523 8 16V10H2C1.44772 10 1 9.55228 1 9V9C1 8.44772 1.44772 8 2 8H8V2C8 1.44772 8.44772 1 9 1V1C9.55228 1 10 1.44772 10 2V8H16C16.5523 8 17 8.44772 17 9V9Z" fill="#423FD1"></path><path d="M10 10V9.5H9.5V10H10ZM8 10H8.5V9.5H8V10ZM8 8V8.5H8.5V8H8ZM10 8H9.5V8.5H10V8ZM16 9.5H10V10.5H16V9.5ZM9.5 10V16H10.5V10H9.5ZM8.5 16V10H7.5V16H8.5ZM8 9.5H2V10.5H8V9.5ZM2 8.5H8V7.5H2V8.5ZM8.5 8V2H7.5V8H8.5ZM9.5 2V8H10.5V2H9.5ZM10 8.5H16V7.5H10V8.5ZM9 1.5C9.27614 1.5 9.5 1.72386 9.5 2H10.5C10.5 1.17157 9.82843 0.5 9 0.5V1.5ZM8.5 2C8.5 1.72386 8.72386 1.5 9 1.5V0.5C8.17157 0.5 7.5 1.17157 7.5 2H8.5ZM1.5 9C1.5 8.72386 1.72386 8.5 2 8.5V7.5C1.17157 7.5 0.5 8.17157 0.5 9H1.5ZM9 16.5C8.72386 16.5 8.5 16.2761 8.5 16H7.5C7.5 16.8284 8.17157 17.5 9 17.5V16.5ZM9.5 16C9.5 16.2761 9.27614 16.5 9 16.5V17.5C9.82843 17.5 10.5 16.8284 10.5 16H9.5ZM2 9.5C1.72386 9.5 1.5 9.27614 1.5 9H0.5C0.5 9.82843 1.17157 10.5 2 10.5V9.5ZM16 8.5C16.2761 8.5 16.5 8.72386 16.5 9H17.5C17.5 8.17157 16.8284 7.5 16 7.5V8.5ZM16 10.5C16.8284 10.5 17.5 9.82843 17.5 9H16.5C16.5 9.27614 16.2761 9.5 16 9.5V10.5Z" fill="#423FD1"></path></svg>
              <span>Create New Album</span>
            </a>
          </div>
        </div>
        <ng-container *ngIf="isAlbumListLoadning">
          <div class="msg_list_skeleton">
            <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 1 }"
                         *ngFor="let hashtag of staticConversationHash">
            </nz-skeleton>
          </div>
        </ng-container>
        <ng-container *ngIf="!isAlbumListLoadning">
          <perfect-scrollbar style="max-height: 300px">
            <!--<ng-container *ngFor="let addToAlbum of albumList">
              <nz-radio-group class="org_optn_list_wrap" [(ngModel)]="albumId">
                <div class="org_optn_list">
                  <div class="org_optn_list_in">
                    <div class="org_optn_list_in_left">
                      <label nz-radio [nzValue]="addToAlbum?._id" [nzDisabled]="isLoading">
                        <div class="org_optn_list_main">
                          <div class="org_optn_list_right">
                            <h3> {{ addToAlbum?.album_name }}</h3>
                          </div>

                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </nz-radio-group>
            </ng-container>-->
            <!--[nzValue]="album?._id"-->

            <div class="album_list_wrap">
              <ng-container *ngFor="let album of albumList">
                <label nz-checkbox [(ngModel)]="album.is_checked" (ngModelChange)="selectAlbum($event)"
                       [nzDisabled]="isLoading || albumId && album._id === albumId">
                  <div class="org_optn_list_main">
                      <h3>{{ album?.album_name }}</h3>
                  </div>
                </label>
              </ng-container>
            </div>
          </perfect-scrollbar>
        </ng-container>


        <div class="organization_form_action" *ngIf="albumList.length>0">
          <span>Are you sure you want to add media into this album?</span>
          <div class="btn_group">

            <button nz-button nzType="primary" class="btn" (click)="closeAddAlbumModal()"
              [disabled]="isLoading">Cancel</button>

            <button nz-button nzType="primary" class="btn btn_apply" (click)="addToAlbumSubmit($event)"
                    [disabled]="isLoading || selectedAlbumIds.length===0">
                Add To Album
                <ng-container *ngIf="isLoading && loadingPostType==='addToAlbum'">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
            </button>

          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="newAlbum">
        <div class="new_album_form">
          <form nz-form [formGroup]="albumForm">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">Album Name</nz-form-label>
              <nz-form-control nzHasFeedback [nzSpan]="24" nzErrorTip="Album name is required">
                <input nz-input formControlName="album_name" placeholder="Album Name" required />
              </nz-form-control>
            </nz-form-item>
          </form>

          <div class="form_action">
            <button nz-button nzType="primary" class="btn" (click)="closeAlbumModal()"
              [disabled]="isLoading">Cancel</button>
            <button nz-button nzType="primary" class="btn btn_apply" (click)="saveAlbum()"
            [disabled]="isLoading">{{modalButton}}
              <ng-container *ngIf="isLoading && loadingPostType==='albumChange'">
                <span nz-icon nzType="loading"></span>
              </ng-container>
            </button>
          </div>
        </div>
      </ng-container>



    </div>

  </ng-container>
</nz-modal>
<!-- Add to album modal end -->

<!-- delete album start -->
<nz-modal [(nzVisible)]="albumDeletevisible" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Delete this Album?</h4>
    </div>
    <div class="delete_team_body">
      <p>Please note - when you delete this Album,
        all associated Images will be removed from this Album. </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">
      <div class="organization_form_action">
        <span>Are you sure you want to delete Album?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="deleteAlbumModalClose()"
            [disabled]="isLoading">Cancel</button>
          <button nz-button nzType="primary" class="btn btn_apply" (click)="deleteAlbum(albumDeleteId)"
            [disabled]="isLoading">Yes, Delete It
            <ng-container *ngIf="isLoading && loadingPostType==='deleteAlbum'">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- delete album end  -->

<!-- delete image from carosal preview start  -->
<nz-modal [(nzVisible)]="deleteImagevisible" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Delete this Media?</h4>
    </div>
    <div class="delete_team_body">
      <p>Please note - <b>Are you sure you want to delete this media?</b> </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">
      <div class="organization_form_action">
        <span>Are you sure you want to delete Media?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="deleteImageModalClose()"
            [disabled]="isLoading">Cancel</button>
          <button type="button" nz-button class="btn btn_apply" (click)="deleteImageSelected(deleteImageFromSelected)"
            [disabled]="isLoading">Yes, Delete It
            <ng-container *ngIf="isLoading && loadingPostType ==='deleteMedia'">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- delete image from carosal preview end  -->

<!-- Error Message -->
<ng-container *ngIf="error !== null">
  <div class="text-center error-color">
    <span>{{error}}</span>
  </div>
</ng-container>

<!--Crop Tool-->
<ng-container *ngIf="(selectedImage || selectedImageURL) && page==='create_post'">
  <div class="img_crop_wrapper">
    <div class="selected_images_wrapper" *ngIf="isDirectMultiUpload">
      <div class="crop_info">
        <img src="../../../assets/images/crop-guid.png" alt="">
        <div class="crop_info_msg">
          <h5>Image Selection and Cropping Guide</h5>
          <p>Please select each image to crop. After you have cropped each image, then click "OK".</p>
        </div>
      </div>
      <div class="selected_images_title">
        <span>Selected Images</span>
      </div>
      <div class="selected_images">
        <ng-container *ngIf="imagePreviews.length > 0">
          <div class="upload_img_in" [class.active]="i === selectedIndex" *ngFor="let image of imagePreviews; let i = index">
            <ng-container *ngIf="image; else skeleton">
              <img [src]="image.url" alt="" (click)="onImageClick(i)"/>
            </ng-container>
            <ng-template #skeleton>
              <div class="default_skeleton">
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <div class="image_radio">
        <div class="radio_title">
          <span>Aspect Ratio</span>
        </div>
        <div class="btn-group d-flex flex-nowrap crop_btns" data-toggle="buttons" *ngIf="cropTool" [class.disabled_while_loading]="!cropImgLoaded">
          <div class="aspec-ratio-btn-wrapper">
            <nz-space>
              <button *nzSpaceItem nz-button [nzType]="aspectRatio===1 ? 'primary' : 'default'"
                (click)="changeAspectRatio(1)">
                <img src="../../../assets/images/aspect_ratio_1_1.png">
                <span>1:1</span>
              </button>
              <button *nzSpaceItem nz-button [nzType]="aspectRatio===1.91 ? 'primary' : 'default'"
                (click)="changeAspectRatio(1.91)">
                <img src="../../../assets/images/aspect_ratio_1_91.png">
                <span>1.91:1</span>
              </button>
              <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.8 ? 'primary' : 'default'"
                (click)="changeAspectRatio(0.8)">
                <img src="../../../assets/images/aspect_ratio_4_5.png">
                <span>4:5</span>
              </button>

              <ng-container *ngIf="instagramType==='story_post'">
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.5625 ? 'primary' : 'default'"
                        (click)="changeAspectRatio(0.5625)">
                  <img src="../../../assets/images/aspect_ratio_4_5.png">
                  <span>9:16</span>
                </button>
              </ng-container>

            </nz-space>

            <div *ngIf="!isValidAspectRatio" class="error-color upload_img_error">
              <img src="../../../assets/images/error_icon.svg" alt="">
              <span>
                Instagram will not allow a mismatched crop ratio. Please choose another crop ratio.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
    <div class="img_cropper_wrapper">
      <div class="img-crop-wrap cropTool">

        <div class="title_sec">
          <img src="../../../assets/images/crop.png" alt="">
          <span>Crop</span>
        </div>

        <div class="crop_loader" *ngIf="cropTool && !cropImgLoaded">
          <img [src]="imageLoading">
        </div>
        <image-cropper
          *ngIf="cropTool"
          [imageBase64]="selectedImageBase64"
          [imageFile]="selectedImage"
          [imageURL]="selectedImageURL"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="false"
          [aspectRatio]="aspectRatio" format="jpeg"
          [cropperMinWidth]="200" class="img-cropper-wrap"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoadedForCrop($event)"
          crossorigin="anonymous"></image-cropper>

        <div class="image_radio" style="margin-top: 15px;" *ngIf="!isDirectMultiUpload">
          <div class="btn-group d-flex flex-nowrap text-center crop_btns" data-toggle="buttons" *ngIf="cropTool" [class.disabled_while_loading]="!cropImgLoaded">
            <div class="aspec-ratio-btn-wrapper">
              <nz-space>
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===1 ? 'primary' : 'default'"
                  (click)="changeAspectRatio(1)">
                  <img src="../../../assets/images/aspect_ratio_1_1.png">
                  <span>1:1</span>
                </button>
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===1.91 ? 'primary' : 'default'"
                  (click)="changeAspectRatio(1.91)">
                  <img src="../../../assets/images/aspect_ratio_1_91.png">
                  <span>1.91:1</span>
                </button>
                <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.8 ? 'primary' : 'default'"
                  (click)="changeAspectRatio(0.8)">
                  <img src="../../../assets/images/aspect_ratio_4_5.png">
                  <span>4:5</span>
                </button>
  
                <ng-container *ngIf="instagramType==='story_post'">
                  <button *nzSpaceItem nz-button [nzType]="aspectRatio===0.5625 ? 'primary' : 'default'"
                          (click)="changeAspectRatio(0.5625)">
                    <img src="../../../assets/images/aspect_ratio_4_5.png">
                    <span>9:16</span>
                  </button>
                </ng-container>
  
              </nz-space>
  
              <div *ngIf="!isValidAspectRatio" class="error-color upload_img_error">
                <img src="../../../assets/images/error_icon.svg" alt="">
                <span>
                  Instagram will not allow a mismatched crop ratio. Please choose another crop ratio.
                </span>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="isDirectMultiUpload">
          <div class="btn_crop" [class.disabled_while_loading]="!cropImgLoaded"  style="margin-top: 15px;">
            <!-- *ngIf="cropImgLoaded" -->
            <button class="btn_primary" (click)="multipleCropImage()">Crop</button>
          </div>
        </ng-container>
        
      </div>
    </div>
  </div>

  

  <!-- <div class="img-crop-button" *ngIf="cropTool">
      <button nz-button nzType="default" (click)="cancelCrop()" *ngIf="!isDirectUpload && !isEditImage" [class.disabled_while_loading]="!cropImgLoaded">
        <i nz-icon nzType="arrow-left" nzTheme="outline"></i> Back to Gallery
      </button>
      <button *ngIf="isDirectUpload" nz-button nzType="primary" [class.disabled_while_loading]="!cropImgLoaded" (click)="cancel()">Cancel</button>
      <ng-container *ngIf="isDirectMultiUpload; else elseContainer">
        <button nz-button nzType="primary" (click)="cropMultipleImageManually()" [class.disabled_while_loading]="!cropImgLoaded" [disabled]="!isValidAspectRatio">ok</button>
      </ng-container>
      <ng-template #elseContainer>
        <button nz-button nzType="primary" (click)="cropImage()" [class.disabled_while_loading]="!cropImgLoaded" [disabled]="!isValidAspectRatio">Crop</button>
      </ng-template>
  </div> -->

  <div class="img-crop-button" *ngIf="cropTool">
    <button nz-button nzType="default" (click)="cancelCrop()" *ngIf="!isDirectUpload && !isEditImage && !isGalleryMultiUpload" [class.disabled_while_loading]="!cropImgLoaded">
      <i nz-icon nzType="arrow-left" nzTheme="outline"></i> Back to Gallery
    </button>
    <ng-container *ngIf="hideMultiFirstAction">
      <button *ngIf="isDirectUpload || isGalleryMultiUpload" class="btn_primary_outline" [class.disabled_while_loading]="!cropImgLoaded" (click)="cancel()">Cancel</button>
      <ng-container *ngIf="isDirectMultiUpload; else elseContainer">
        <button nz-button nzType="primary" (click)="cropMultipleImageManually()" [class.disabled_while_loading]="!isValidAspectRatio || !cropImgLoaded" [disabled]="!isValidAspectRatio">ok</button>
      </ng-container>
      <ng-template #elseContainer>
        <button class="btn_primary" (click)="cropImage()" [class.disabled_while_loading]="!cropImgLoaded" [disabled]="!isValidAspectRatio">Crop</button>
      </ng-template>
    </ng-container>
  </div>

  <div class="img-crop-button" *ngIf="!cropTool && !isDirectMultiUpload">
      <button nz-button nzType="default" (click)="cancelCrop()">Cancel</button>
      <button nz-button nzType="primary" (click)="cropImageManually()">Ok</button>
  </div>

  <div class="generating_message">
    <div class="message_wrapper">
      <img src="../../../assets/images/upload-file.gif" style="height: 150px;" alt="">
      <h5>Your upload is on its way!</h5>
      <p>This will only take a moment...</p>
    </div>
    <div class="message_progress">
      <div class="progress-wrapper">
        <span class="progress-bar"></span>
      </div>
    </div>
  </div>

  <div class="ready_to_post">
    <img src="../../../assets/images/uploaded-done.png" alt="">
    <h5>Your media is ready to post...</h5>
  </div>

</ng-container>

<!--Cropped Preview-->
<!-- <ng-container *ngIf="isCropped && !selectedImage && !selectedImageURL && page==='create_post'">
  <div class="upload_img text-center">
    <ng-container *ngIf="postMedias.length > 0">
      <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">
        <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img'" [isVideo]="false">
        </app-gallery-image-preview>
      </div>
    </ng-container>
    <div *ngIf="postMedias.length !== maxLength" class="text-center upload_img_in">
      <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
        [nzDisabled]="loading" *ngFor="let uploading of currentUploading" [nzMultiple]="true">
        <ng-container>
          <nz-progress class='nz_progress' [nzPercent]="uploading.percent" nzType="circle" [nzWidth]="80"
                  *ngIf="uploading.loading"></nz-progress>
          <div class="ant-upload-text" *ngIf="!uploading.loading">Upload</div>
        </ng-container>
      </nz-upload>
    </div>
  </div>
</ng-container> -->

<ng-container *ngIf="!isGalleryMultiUpload && !isForUpdate && !isCropped && (page==='create_post' || page === 'bulk_post')">
  <div class="upload_img_wrp" [ngClass]="{upload_img_wrp_height: !isForUpdate}">

    <!--Image-->
   <!-- <ng-container *ngIf="type === 'image'">
      <div class="fef">
        <ng-container *ngIf="postMedias.length > 0">
          &lt;!&ndash; Drap Drop &ndash;&gt;
            <div class="upload_img" [sortablejs]="postMedias" [sortablejsOptions]="sortableOptions">

              <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">
                <div class="upload_img_action">
                  <i nz-icon nzType="edit" nz-tooltip nzTooltipTitle="Crop" nzTheme="outline" (click)="editImage(i, media.media_file)"
                    [class.disabled_while_loading]="isPosting" *ngIf="page !== 'bulk_post'"></i>
                  <i nz-icon nzType="close" nz-tooltip nzTooltipTitle="Remove" nzTheme="outline"
                    (click)="deleteImage(i, media.media_file)" *ngIf="!isFeedPost" [class.disabled_while_loading]="isPosting"></i>
                </div>
                <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img test-imggg'" [isVideo]="false">

                </app-gallery-image-preview>
              </div>
            </div>
        </ng-container>
      </div>
    </ng-container>-->

    <!--gif-->
    <!--<ng-container *ngIf="type === 'gif'">
      <div class="upload_img" [sortablejs]="postMedias" [sortablejsOptions]="sortableOptions">
        <ng-container *ngIf="postMedias.length > 0">
          <div class="upload_img_in" *ngFor="let media of postMedias; let i = index">
            <div class="upload_img_action">
              <i nz-icon nzType="close" nz-tooltip nzTooltipTitle="Remove" nzTheme="outline"
                (click)="deleteImage(i, postMedias[i])" *ngIf="!isFeedPost" [class.disabled_while_loading]="isPosting"></i>
            </div>
            <app-gallery-image-preview [imageUrl]="media.media_file" [class]="'post-image uploaded-img'" [isVideo]="false">
            </app-gallery-image-preview>
          </div>
        </ng-container>
        <div *ngIf="postMedias.length !== maxLength && isForUpdate" class="text-center upload_img_in">
          <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
            [nzDisabled]="loading">
            <ng-container>
              <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
              <div class="ant-upload-text">Upload</div>
            </ng-container>
          </nz-upload>
        </div>
      </div>
    </ng-container>-->

    <!--video-->
    <!--<ng-container *ngIf="type === 'video'">
      <div class="upload_video">
        <ng-container *ngIf="postMedias.length > 0">
          <div class="upload_video_in">


            <div class="hover_option" [class.disabled_while_loading]="isPosting">
              <div class="hover_option_icon">
                <img src="../../../assets/images/option_dots.svg" alt="">
              </div>
              <ul>
                <li>
                  <a href="javascript:;" (click)="deleteImage(0, images[0])">
                    Remove
                  </a>
                </li>
              </ul>
            </div>

            <app-gallery-image-preview [imageUrl]="images[0]" [class]="'post-video'" [isVideo]="true">
            </app-gallery-image-preview>
          </div>
        </ng-container>
        <div *ngIf="postMedias.length !== maxLength && isForUpdate" class="text-center upload_img_in">
          <nz-upload nzName="post" nzListType="picture-card" [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload"
            [nzDisabled]="loading">
            <ng-container>
              <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
              <div class="ant-upload-text">Upload</div>
            </ng-container>
          </nz-upload>
        </div>
      </div>
    </ng-container>-->


    <!--multi-->
    <!--*ngIf="type === 'multi'"-->
    <ng-container>
      <div class="fef">
        <ng-container *ngIf="postMedias.length > 0">
          <!-- Drap Drop -->
          <div class="upload_img" [sortablejs]="postMedias" [sortablejsOptions]="sortableOptions">

            <div class="upload_img_in multi_image_upload" *ngFor="let media of postMedias; let i = index"
                 [ngClass]="{'is_img_ratio_valid': media?.isValid === false}" >

              <div class="hover_option" [class.disabled_while_loading]="isPosting">
                <div class="hover_option_icon">
                  <img src="../../../assets/images/option_dots.svg" alt="">
                </div>
                <ul>
                  <li *ngIf="page !== 'bulk_post' && media.file_type==='image' && media.file_mime_type !== 'image/gif'" >
                    <a href="javascript:;" (click)="editImage(i, media.media_file)">
                      Crop
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" (click)="deleteImage(i, media.media_file)">
                      Remove
                    </a>
                  </li>
                </ul>
              </div>


              <a *ngIf="page !== 'bulk_post' && postMedias.length > 0 && media.file_type!=='video'" href="javascript:;"
                 class="btn_hash_tag" nz-tooltip nzTooltipTitle="Hashtag Detection" (click)="hashtagGenerate(i, media.media_file,false)"
                 [class.disabled_while_loading]="isPosting">
                <img src="../../../assets/images/ai-image-icon.svg" alt="">
              </a>


              <a *ngIf="page !== 'bulk_post' && postMedias.length > 0 && media.file_type==='video'" href="javascript:;"
                 class="btn_hash_tag" nz-tooltip nzTooltipTitle="Hashtag Detection" (click)="hashtagGenerate(0,media.media_file,true)"
                 [class.disabled_while_loading]="isPosting">
                <img src="../../../assets/images/ai-image-icon.svg" alt="">
              </a>

              <div class="img_ratio_warning">
                <span nz-icon nzType="warning" nzTheme="fill"></span>
              </div>

              <app-gallery-image-preview *ngIf="media.file_type==='image'"
                      [imageUrl]="media.media_file" [class]="'post-image uploaded-img '"
                      [isVideo]="false"></app-gallery-image-preview>

              <app-gallery-image-preview *ngIf="media.file_type==='video'"
                      [imageUrl]="media.media_file" [class]="'post-video'"
                      [isVideo]="true"></app-gallery-image-preview>
            </div>

          </div>
        </ng-container>
      </div>
    </ng-container>

  </div>

  <!--YouTube Thumbnail-->
  <!--*ngIf="allAccountProvider.includes(sharedService.youtubeProvider) && (type === 'video' || type==='multi')"-->
  <ng-container *ngIf="allAccountProvider.includes(sharedService.youtubeProvider) && postMedias.length > 0 && postMedias[0].file_type=== 'video'">
    <div class="upload_img_wrp yt_thumbnail">
      <div class="margin-top-2">
        <h3>YouTube Video Thumbnail</h3>
        <div class="upload_img" [class.disabled_while_loading]="isPosting">
          <ng-container *ngIf="youtubeThumbnails?.length > 0">
            <div class="upload_img_in multi_image_upload" *ngFor="let image of youtubeThumbnails; let i = index">

              <div class="hover_option" [class.disabled_while_loading]="isPosting">
                <div class="hover_option_icon">
                  <img src="../../../assets/images/option_dots.svg" alt="">
                </div>
                <ul>
                  <li *ngIf="!isFeedPost">
                    <a href="javascript:;" (click)="deleteThumbnailImage(i, images[i])">
                      Remove
                    </a>
                  </li>
                </ul>
              </div>

              <!-- <div class="upload_img_action">
                <i nz-icon nzType="close" nz-tooltip nzTooltipTitle="Remove" nzTheme="outline"
                  (click)="deleteThumbnailImage(i, postMedias[i])" [class.disabled_while_loading]="isPosting"></i>
              </div>
              -->
              <app-gallery-image-preview [imageUrl]="image" [class]="'post-image uploaded-img'" [isVideo]="false">
              </app-gallery-image-preview>
            </div>
          </ng-container>
        </div>
        <nz-upload *ngIf="youtubeThumbnails?.length === 0" nzName="post" nzListType="picture-card"
          [nzShowUploadList]="false" [nzBeforeUpload]="beforeUploadThumbnail" [nzDisabled]="loading"
          [class.disabled_while_loading]="isPosting" [nzAccept]="supportedThumbnailFileTypes.toString()">
          <ng-container>
            <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'" *ngIf="loading"></i>
            <img src="../../../assets/images/upload_media_icon.png" *ngIf="!loading" alt="">
            <div class="ant-upload-text">Upload</div>
          </ng-container>
        </nz-upload>
      </div>
    </div>
  </ng-container>

  <!-- && (type === 'video' || type==='multi')-->
  <!--Instagram Reels Thumbnail-->
  <ng-container *ngIf="allAccountProvider.includes(sharedService.instagramProvider)
    && instagramType ==='reel_post' && postMedias[0].file_type=== 'video'">
    <div class="upload_img_wrp yt_thumbnail">
      <div class="margin-top-2">
        <h3>Instagram Reel Thumbnail</h3>
        <div class="upload_img" [class.disabled_while_loading]="isPosting">
          <ng-container *ngIf="instaReelThumbnails?.length > 0">
            <div class="upload_img_in multi_image_upload" *ngFor="let image of instaReelThumbnails; let i = index">
              <div class="hover_option" [class.disabled_while_loading]="isPosting">
                <div class="hover_option_icon">
                  <img src="../../../assets/images/option_dots.svg" alt="">
                </div>
                <ul>
                  <li *ngIf="!isFeedPost">
                    <a href="javascript:;" (click)="deleteThumbnailImage(i, sharedService.instagramProvider)">
                      Remove
                    </a>
                  </li>
                </ul>
              </div>
              <app-gallery-image-preview [imageUrl]="image" [class]="'post-image uploaded-img'" [isVideo]="false">
              </app-gallery-image-preview>
            </div>
          </ng-container>
        </div>
        <nz-upload *ngIf="instaReelThumbnails?.length === 0" nzName="post" nzListType="picture-card"
                   [nzShowUploadList]="false" [nzBeforeUpload]="beforeUploadInstagramReelThumbnail"
                   [nzDisabled]="isInstaThumbLoading"
                   [class.disabled_while_loading]="isPosting"
                   [nzAccept]="supportedThumbnailFileTypes.toString()">
          <ng-container>
            <i class="upload-icon" nz-icon [nzType]="isInstaThumbLoading ? 'loading' : 'plus'" *ngIf="isInstaThumbLoading"></i>
            <img src="../../../assets/images/upload_media_icon.png" *ngIf="!isInstaThumbLoading" alt="">
            <div class="ant-upload-text">Upload</div>
          </ng-container>
        </nz-upload>
      </div>
    </div>
  </ng-container>



<!-- remove image from album start  -->
<nz-modal [(nzVisible)]="removeMediaFromAlbum" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Remove this Media?</h4>
    </div>
    <div class="delete_team_body">
      <p>Please note - <b>Are you sure you want to remove this media?</b> </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">
      <div class="organization_form_action">
        <span>Are you sure you want to remove Media?</span>
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="cancelRemoveMideaFromAlbum()" [disabled]="isLoading">
            Cancel
          </button>
          <button type="button" nz-button class="btn btn_apply" (click)="removeSelectedMediaFromAlbum()" [disabled]="isLoading">
            Yes, Remove It
            <ng-container *ngIf="isLoading ">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- remove image from album end  -->
</ng-container>
