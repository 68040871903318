<div class="seting_card">
  <div class="seting_card_header">
    <h3>Your Profile</h3>
  </div>
  <div class="edit_profile_main">
    <form nz-form [formGroup]="editProfileForm" (ngSubmit)="submitForm()">
      <div class="profile_form">
        <div class="profile_img_main">
          <div class="upload_img" *ngIf="!avatarUrl">
            <nz-upload class="avatar-uploader" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false"
              [nzDisabled]="loading || isLoading" [nzBeforeUpload]="beforeUpload">

              <ng-container>
                <img src="../../../assets/images/Profile.png" alt="">
              </ng-container>

            </nz-upload>
          </div>
          <div *ngIf="avatarUrl" class="image-wrap">
            <div class="img">
              <app-image-preview [imageUrl]="avatarUrl" [class]="'edit-profile-image'" [isVideo]="false">
              </app-image-preview>
            </div>
            <div class="icon">
              <i nz-icon nzType="delete" nzTheme="outline" class="delete-icon" (click)="deleteImage()"
              [class.disabled_while_loading]="isLoading"></i>
            </div>
          </div>
          <div class="icon_sec">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="20" fill="#F6F7FF" />
              <path
                d="M28.375 13.625H25.1875L24.4281 11.4969C24.3757 11.3513 24.2795 11.2254 24.1528 11.1364C24.0261 11.0475 23.8751 10.9999 23.7203 11H16.5297C16.2133 11 15.9297 11.1992 15.8242 11.4969L15.0625 13.625H11.875C10.8391 13.625 10 14.4641 10 15.5V26.1875C10 27.2234 10.8391 28.0625 11.875 28.0625H28.375C29.4109 28.0625 30.25 27.2234 30.25 26.1875V15.5C30.25 14.4641 29.4109 13.625 28.375 13.625ZM28.5625 26.1875C28.5625 26.2906 28.4781 26.375 28.375 26.375H11.875C11.7719 26.375 11.6875 26.2906 11.6875 26.1875V15.5C11.6875 15.3969 11.7719 15.3125 11.875 15.3125H16.2508L16.6516 14.1922L17.1883 12.6875H23.0594L23.5961 14.1922L23.9969 15.3125H28.375C28.4781 15.3125 28.5625 15.3969 28.5625 15.5V26.1875ZM20.125 16.8125C18.0531 16.8125 16.375 18.4906 16.375 20.5625C16.375 22.6344 18.0531 24.3125 20.125 24.3125C22.1969 24.3125 23.875 22.6344 23.875 20.5625C23.875 18.4906 22.1969 16.8125 20.125 16.8125ZM20.125 22.8125C18.8828 22.8125 17.875 21.8047 17.875 20.5625C17.875 19.3203 18.8828 18.3125 20.125 18.3125C21.3672 18.3125 22.375 19.3203 22.375 20.5625C22.375 21.8047 21.3672 22.8125 20.125 22.8125Z"
                fill="#3045F7" />
            </svg>
          </div>
        </div>
        <div class="profile_form_in">
          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.name?.length>0">
              <nz-form-control [nzErrorTip]="nameError">
                <input nz-input formControlName="name" id="name" [class.disabled_while_loading]="isLoading" />
                <ng-template #nameError let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    This Name Field is required!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('pattern')">
                    Name must include character only and maximum 30 character.
                  </ng-container>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">{{
                      serverError }}</div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Full Name</nz-form-label>
          </nz-form-item>
          <nz-form-item>
            <nz-input-group [class.hasclass]="user.email.length>0">
              <nz-form-control [nzErrorTip]="emailError">
                <input nz-input formControlName="email" id="email" />
                <ng-template #emailError let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    This Email Field is required!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('email')">
                    Please Enter Valid Email!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">{{
                      serverError }}</div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label nzFor="email">Email</nz-form-label>
          </nz-form-item>
          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.phone?.length>0">
              <nz-form-control [nzErrorTip]="phoneError">
                <input nz-input formControlName="phone" id="phone" [class.disabled_while_loading]="isLoading" />
                <ng-template #phoneError let-control>
                  <ng-container *ngIf="control.hasError('pattern')">
                    Please enter valid phone number
                  </ng-container>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">{{
                      serverError }}</div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Phone Number</nz-form-label>
          </nz-form-item>
          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.dob!==null">
              <nz-form-control [nzErrorTip]="dobError">
                <nz-date-picker formControlName="dob" nzFormat="MM/dd/yyyy" [nzPlaceHolder]="' '"
                  [nzDisabledDate]="disabledDate" [nzDefaultPickerValue]="displayStartDate" id="dob" [class.disabled_while_loading]="isLoading">
                </nz-date-picker>
                <ng-template #dobError let-control>
                  <ng-container *ngIf="control.hasError('serverError')">
                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverError">
                      {{serverError }}
                    </div>
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Date Of Birth</nz-form-label>
          </nz-form-item>

          <!-- <nz-date-picker nzFormat="MM/dd/yyyy"></nz-date-picker> -->

          <nz-form-item>
            <nz-input-group [class.hasclass]="editProfileForm.value?.gender?.length>0">
              <nz-select formControlName="gender" nzPlaceHolder="Select Gender"
              [nzDisabled]="isLoading">
                <nz-option nzValue="Male" nzLabel="Male"></nz-option>
                <nz-option nzValue="Female" nzLabel="Female"></nz-option>
                <nz-option nzValue="Transgender" nzLabel="Transgender"></nz-option>
                <nz-option nzValue="Prefer not to answer" nzLabel="Prefer not to answer"></nz-option>
              </nz-select>
            </nz-input-group>
            <nz-form-label>Gender</nz-form-label>
          </nz-form-item>
        </div>
        <div class="action_area ">
          <button type="submit" class="btn-primary" nz-button nzType="primary"
            [disabled]="loading || editProfileForm.invalid || isLoading">
            Update Profile
            <ng-container *ngIf="isLoading">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
