import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { WasabiClear, WasabiGet } from '../actions/wasabi.action';


export interface WasabiStateModel {
  wasabi: any | null;
}

@State<WasabiStateModel>({
  name: 'wasabi',
  defaults: {
    wasabi: null,
  }
})

@Injectable()

export class WasabiState {
  @Selector()
  static wasabi(state: WasabiStateModel): any {
    return state.wasabi;
  }

  @Action(WasabiGet)
  SubscriptionGet(wasabiState: StateContext<WasabiStateModel>, action: WasabiGet): void {
    wasabiState.setState({
      wasabi: action.payload,
    });
  }

  @Action(WasabiClear)
  SubscriptionClear(wasabiState: StateContext<WasabiStateModel>): void {
    wasabiState.setState({
      wasabi: null,
    });
  }
}
