import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private helper: HelperService) {
  }

  getDashboard(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('dashboard'));
  }

  updateProfile(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('update_profile'), postValue);
  }

  getPostCount(postValue) : Observable<any>{
    return this.http.post(this.helper.createAPIUrl('get_Post_Count_dashboard'),postValue);
  }

  getEngagementData(postValue) : Observable<any>{
    return this.http.post(this.helper.createAPIUrl('get_dashboard_engagement'),postValue);
  }

  getTwitterFeeds(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_twitter_feeds'));
  }

  getRecentAndSchedulePosts(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_recent_and_schedule_posts'));
  }

  getTotalPostCountDashboard(formData): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('get_total_post_count_dashboard'), formData);
  }

}
