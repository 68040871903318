import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthState } from '../action-state/states/auth.state';
import { Store } from '@ngxs/store';
// @ts-ignore
import { Resolver } from '../services/resolve';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private store: Store, private resolve: Resolver) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.store.selectSnapshot(AuthState.token)) {
      return this.resolve.resolve(route, state);
    } else {
      localStorage.setItem('last_url', state.url);
      this.router.navigate(['/', 'auth', 'login']);
      return false;
    }
  }
}
