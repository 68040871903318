import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-empty',
    templateUrl: './app-empty.component.html',
    styleUrls: ['./app-empty.component.less']
})

export class appEmptyComponent {
    @Input() message = "No Data Available";
}