<div class="repeat_form_main">
  <div class="repeat_form_in" [class.error_active]="hasDateError">
    <label>Date</label>
    <nz-date-picker [(ngModel)]="postDate" [nzDisabledDate]="disabledDate" nzSize="large" nzFormat="MM/dd/yyyy"
      (nzOnOpenChange)="onDateChange($event)" [nzAllowClear]="false">
    </nz-date-picker>
    <!-- nzInline -->
  </div>
  <div class="repeat_form_in" [class.error_active]="hasTimeError">
    <label>Time</label>
    <nz-time-picker [(ngModel)]="postTime" [nzMinuteStep]="5" nzSize="large" nzFormat="hh:mm a"
      [nzDisabledHours]="disabledHours" [nzUse12Hours]="true" [nzDisabledMinutes]="disabledMinutes"
      (nzOpenChange)="isOpenChange($event)" [nzAllowEmpty]="false" nzNowText=" "></nz-time-picker>
  </div>
  <!-- <div class="btn_group">
    <button type="button" class="btn">Cancel</button>
    <button type="button" class="btn btn_apply">Apply</button>
  </div> -->
</div>