import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzCarouselComponent, NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationModule, NzNotificationServiceModule } from 'ng-zorro-antd/notification';

import {AccountConnectComponent} from "../component/account-connect/account-connect.component";
import { AddCardComponent } from '../component/add-card/add-card.component';
import { AppAuthLoadingComponent } from '../component/app-auth-loading/app-auth-loading.component';
import { AppWarningModalComponent } from '../component/app-warning-modal/app-warning-modal.component';
import {CampaignComponent} from "../component/campaign/campaign.component";
import { CampaignLinkedinComponent } from '../component/campaign-linkedin/campaign-linkedin.component';
import { CampaignSnapchatComponent } from '../component/campaign-snapchat/campaign-snapchat.component';
import { CancelSubscriptionComponent } from '../component/cancel-subscription/cancel-subscription.component';
import { CardDataComponent } from '../component/card-data/card-data.component';
import { ChangePasswordComponent } from '../component/change-password/change-password.component';
import { DateConvertPipe } from '../pipes/date-time.pipe';
import { DndModule } from "ngx-drag-drop";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragulaModule } from 'ng2-dragula';
import { EditMemberInviteComponent } from '../pages/edit-member-invite/edit-member-invite.component';
import { EditProfileComponent } from '../component/edit-profile/edit-profile.component';
import { FilterSearchPipe } from '../pipes/filter-search.pipe';
import { GalleryComponent } from '../pages/gallery-page/gallery.component';
import { GalleryImagePreviewComponent } from "../component/gallery-image-preview/gallery-image-preview.component";
import { GalleryMediaPreviewComponent } from '../component/gallery-media-preview/gallery-media-preview.component';
import { GalleryModule } from '../pages/gallery-page/gallery.module';
import { GalleryPreviewComponent } from '../component/gallery/gallery-preview.component';
import {HasPermissionDirective} from "../directive/has-permission.directive";
import { ImageCropperModule } from "ngx-image-cropper";
import {ImageDirectiveDirective} from "../directive/image-directive.directive";
import { ImagePreviewComponent } from '../component/image-preview/image-preview.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InviteComponent } from '../pages/auth/invite/invite.component';
import { InviteStatusComponent } from '../component/invite-status/invite-status.component';
import { JoinOurTeamComponent } from '../pages/invite-accept/invite/join-our-team.component';
import { JoyrideModule } from 'ngx-joyride';
import { MemberInviteComponent } from '../component/invite/member-invite.component';
import {MemberPermissionComponent} from "../component/member-permission/member-permission.component";
//import { MentionModule } from 'angular-mentions';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgDragDropModule } from 'ng-drag-drop';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxStripeModule } from 'ngx-stripe';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import {NzSliderModule} from "ng-zorro-antd/slider";
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { OverviewChartComponent } from "../component/overview-chart/overview-chart.component";
import {PaymentModalComponent} from "../component/payment-modal/payment-modal.component";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {PlansComponent} from "../component/plans/plans.component";
import { PostComponent } from '../component/post/post.component';
import { PreviewPostComponent } from "../component/preview-post/preview-post.component";
import { RedirectAuthComponent } from '../pages/redirect-auth/redirect-auth.component';
import { RepeatPostComponent } from '../component/repeat-post/repeat-post.component';
import { ScheduleComponent } from '../component/schedule/schedule.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SelectAccountComponent } from "../component/select-account/select-account.component";
import { SortablejsModule } from 'ngx-sortablejs';
import { TeamAccountsComponent } from '../component/team-accounts/team-accounts.component';
import { TeamDetailsComponent } from '../pages/organization/teams/team-details/team-details.component';
import { TeamMembersComponent } from '../component/team-members/team-members.component';
import { TeamSocialMemberDetailsComponent } from '../component/team-social-member-details/team-social-member-details.component';
import { TransformNumberPipe } from "../pipes/transform-number.pipe";
import { appEmptyComponent } from '../component/app-empty/app-empty.component';
import {appProviderComponent} from "../component/app-provider/app-provider.component";
import {appSelectTeamComponent} from "../component/app-select-team/app-select-team.component";
import { myWorkComponent } from '../component/my-work/my-work.component';

const ANGULAR_COMMON_MODULE = [CommonModule, FormsModule, ReactiveFormsModule];

const NzModule = [
  NzGridModule,
  NzInputModule,
  NzButtonModule,
  NzFormModule,
  NzDropDownModule,
  NzCollapseModule,
  NzIconModule,
  NzCardModule,
  NzUploadModule,
  NzCheckboxModule,
  NzCarouselModule,
  NzTabsModule,
  NzDatePickerModule,
  NzRadioModule,
  NzTableModule,
  NzSwitchModule,
  NzSelectModule,
  NzImageModule,
  NzPaginationModule,
  NzTimePickerModule,
  NzTagModule,
  NzModalModule,
  NzEmptyModule,
  NzStepsModule,
  NzResultModule,
  NzSpinModule,
  NzBadgeModule,
  NzListModule,
  NzSkeletonModule,
  ScrollingModule,
  NzToolTipModule,
  NzBackTopModule,
  NzAlertModule,
  NzPopconfirmModule,
  NzCalendarModule,
  NzPopoverModule,
  NzInputNumberModule,
  NzProgressModule,
  DragDropModule,
  NzCommentModule,
  NzAvatarModule,
  NzNotificationModule,
  NzSliderModule
];

@NgModule({
  declarations: [
    ImagePreviewComponent,
    SelectAccountComponent,
    PostComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    CancelSubscriptionComponent,
    CardDataComponent,
    AddCardComponent,
    DateConvertPipe,
    PreviewPostComponent,
    GalleryImagePreviewComponent,
    RedirectAuthComponent,
    RepeatPostComponent,
    ScheduleComponent,
    FilterSearchPipe,
    TeamMembersComponent,
    TeamSocialMemberDetailsComponent,
    MemberInviteComponent,
    TeamAccountsComponent,
    EditMemberInviteComponent,
    InviteComponent,
    AppWarningModalComponent,
    TransformNumberPipe,
    OverviewChartComponent,
    GalleryPreviewComponent,
    GalleryMediaPreviewComponent,
    JoinOurTeamComponent,
    InviteStatusComponent,
    AppAuthLoadingComponent,
    appEmptyComponent,
    appProviderComponent,
    TeamDetailsComponent,
    MemberPermissionComponent,
    HasPermissionDirective,
    appSelectTeamComponent,
    ImageDirectiveDirective,
    myWorkComponent,
    AccountConnectComponent,
    PaymentModalComponent,
    CampaignComponent,
    CampaignSnapchatComponent,
    PlansComponent,
    CampaignLinkedinComponent,
  ],
  imports: [
    ...ANGULAR_COMMON_MODULE,
    ...NzModule,
    NzSpaceModule,
    NzDrawerModule,
    NzDividerModule,
    NzProgressModule,
    NgApexchartsModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    PickerModule,
    NgxStripeModule,
    NzCommentModule,
    NzAvatarModule,
    ImageCropperModule,
    DndModule,
    PerfectScrollbarModule,
    DragulaModule,
    NgDragDropModule.forRoot(),
    NgOtpInputModule,
    JoyrideModule.forRoot(),
    //MentionModule,
    SortablejsModule.forRoot({ animation: 150 }),
  ],
  exports: [
    ...ANGULAR_COMMON_MODULE,
    ...NzModule,
    NzCarouselComponent,
    NgApexchartsModule,
    ImagePreviewComponent,
    NzDrawerModule,
    SelectAccountComponent,
    PostComponent,
    PickerModule,
    EditProfileComponent,
    ChangePasswordComponent,
    CancelSubscriptionComponent,
    CardDataComponent,
    AddCardComponent,
    DateConvertPipe,
    PreviewPostComponent,
    GalleryImagePreviewComponent,
    DndModule,
    RepeatPostComponent,
    ScheduleComponent,
    PerfectScrollbarModule,
    FilterSearchPipe,
    TeamMembersComponent,
    TeamSocialMemberDetailsComponent,
    MemberInviteComponent,
    TeamAccountsComponent,
    EditMemberInviteComponent,
    InviteComponent,
    AppWarningModalComponent,
    TransformNumberPipe,
    OverviewChartComponent,
    GalleryPreviewComponent,
    GalleryMediaPreviewComponent,
    JoinOurTeamComponent,
    InviteStatusComponent,
    AppAuthLoadingComponent,
    NgOtpInputModule,
    appEmptyComponent,
    NzNotificationServiceModule,
    appProviderComponent,
    TeamDetailsComponent,
    MemberPermissionComponent,
    HasPermissionDirective,
    appSelectTeamComponent,
    ImageDirectiveDirective,
    myWorkComponent,
    AccountConnectComponent,
    PaymentModalComponent,
    CampaignComponent,
    CampaignSnapchatComponent,
    PlansComponent,
    CampaignLinkedinComponent,
  ],
  providers: [NzMessageService, NzModalService, TitleCasePipe, DateConvertPipe],
})
export class SharedModule { }
