import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-team-social-member-details',
  templateUrl: './team-social-member-details.component.html',
  styleUrls: ['./team-social-member-details.component.less']
})
export class TeamSocialMemberDetailsComponent {

  @Input() team_members: any = [];
  @Input() team_socials: any = [];
  @Input() team_id = 0;
  @Input() team_account = '';

  constructor() { }



}
