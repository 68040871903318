import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class SnapchatService {

  constructor(private http: HttpClient, private helper: HelperService) { }

  snapchatConnect(): Observable<any>{
    console.log("Snapchat api called!");
    return this.http.get(this.helper.createAPIUrl('snapchat_request_token'));
  }

  snapchatAuthenticatedRequests(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('snapchat_auth'), postValue);
  }

  getSnapchatOrganization(social_id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('snapchat_ad_accounts') + '?social_id=' + social_id);
  }

  getSnapchatCountries(social_id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('snapchat_countries') + '?social_id=' + social_id);
  }

  getSnapchatLanguages(social_id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('snapchat_languages') + '?social_id=' + social_id);
  }

  getSnapchatReach(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('snapchat_reach'),postValues);
  }

  snapchatAdCreate(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('snapchat_ad_create'), postValues);
  }

  snapchatAdUpdate(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('snapchat_ad_update'), postValues);
  }

  snapchatAdChangeStatus(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('snapchat_ad_change_status'), postValues);
  }

  snapchatCampaignDelete(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('snapchat_campaign_delete'), postValues);
  }

  getSnapchatCampaigns(social_id,page,limit = 10 ): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('snapchat_get_campaigns') + '?social_id=' + social_id + '&page=' + page + '&limit=' + limit );
  }
}
