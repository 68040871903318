<div class="auth_waiting" *ngIf="!isVisible">
  <bs-app-auth-loading></bs-app-auth-loading>
</div>

<nz-modal [nzClassName]="'auth_selection_modal'" [nzAutofocus]="null" [nzMaskClosable]="false" [(nzVisible)]="isVisible"
  [nzTitle]=null [nzFooter]="null">
          <!-- [nzTitle]="getTitle()" [nzOkDisabled]="selectedAccount.length===0 || isLoading"
          [nzOkText]="isLoading?'Saving...':'Ok'" (nzOnOk)="handleOk()" (nzOnCancel)="handleCancel()" [nzCancelDisabled]="isLoading"-->
  <ng-container *nzModalContent>
      <app-select-account
        [accounts]="data"
        (accountUpdate)="getAccount($event)"
        [type]="'connect_instagram'"
        [platform]="sharedService.instagramProvider"></app-select-account>

    <div class="auth_selection_footer ant-modal-footer">

      <div *ngIf="data.length !== disabledAccounts.length" class="auth_selection_header">
        <strong >Selected ({{selectedAccount.length}})</strong>
        <a href="javascript:;" (click)="selectAll()">Select All</a>
      </div>
      <div *ngIf="data.length === disabledAccounts.length" class="no_account_available">
        No account available for connect
      </div>

      <div class="auth_selection_action">
        <button class="ant-btn" (click)="handleCancel()" [disabled]="isLoading">Cancel</button>
        <button class="ant-btn ant-btn-primary" (click)="handleOk()" [disabled]="selectedAccount.length===0 || isLoading" >
          {{isLoading?'Saving...':'Ok'}}
        </button>
      </div>
    </div>
  </ng-container>
</nz-modal>
