<ng-container *ngIf="isDisplayChart">
  <div class="overview_chart">

    <div class="top_chart">
      <apx-chart [series]="[previousCountPercentage]" [chart]="radialBarChart1.chart"
                 [plotOptions]="radialBarChart1.plotOptions" [labels]="radialBarChart1.labels"
                 [fill]="radialBarChart1.fill">
      </apx-chart>
    </div>
    <div class="bottom_chart">
      <apx-chart [series]="[currentCountPercentage]" [chart]="radialBarChart2.chart"
                 [plotOptions]="radialBarChart2.plotOptions" [labels]="radialBarChart2.labels" [fill]="radialBarChart2.fill">
      </apx-chart>
    </div>

  </div>
  <div class="overview_chart_dtl">

    <!--Icon-->
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.75" cy="16" r="16" fill="#DDF0FD" />
      <path
        d="M21.4879 18.5313C20.9143 17.9572 20.2441 17.4887 19.5079 17.1472C20.546 16.3062 21.2083 15.0229 21.2083 13.5837C21.2083 11.0446 19.0908 8.96158 16.5517 9.00054C14.0515 9.03949 12.0371 11.0767 12.0371 13.5837C12.0371 15.0229 12.7017 16.3062 13.7375 17.1472C13.0012 17.4885 12.331 17.957 11.7575 18.5313C10.5063 19.7848 9.79589 21.4393 9.75006 23.2039C9.74945 23.2283 9.75374 23.2527 9.76268 23.2754C9.77161 23.2982 9.78502 23.319 9.80211 23.3365C9.81919 23.354 9.83961 23.3679 9.86216 23.3774C9.8847 23.3869 9.90892 23.3918 9.93339 23.3918H11.2167C11.3153 23.3918 11.3978 23.3139 11.4 23.2153C11.4436 21.8862 11.9821 20.6419 12.9286 19.6977C13.4129 19.2108 13.989 18.8249 14.6235 18.5621C15.258 18.2993 15.9383 18.165 16.625 18.1669C18.0206 18.1669 19.3337 18.71 20.3214 19.6977C21.2656 20.6419 21.8041 21.8862 21.85 23.2153C21.8523 23.3139 21.9348 23.3918 22.0333 23.3918H23.3166C23.3411 23.3918 23.3653 23.3869 23.3879 23.3774C23.4104 23.3679 23.4308 23.354 23.4479 23.3365C23.465 23.319 23.4784 23.2982 23.4874 23.2754C23.4963 23.2527 23.5006 23.2283 23.5 23.2039C23.4541 21.4393 22.7437 19.7848 21.4879 18.5313ZM16.625 16.517C15.8413 16.517 15.1034 16.2122 14.5511 15.6576C14.2739 15.3827 14.055 15.0547 13.9073 14.6932C13.7597 14.3318 13.6863 13.9443 13.6917 13.5539C13.6986 12.8023 13.9988 12.0759 14.5236 11.5373C15.0736 10.9736 15.8092 10.6597 16.5952 10.6505C17.3721 10.6436 18.126 10.9461 18.6806 11.4892C19.249 12.0461 19.5606 12.7908 19.5606 13.5837C19.5606 14.3675 19.2558 15.1031 18.7013 15.6576C18.4292 15.931 18.1056 16.1477 17.7492 16.2952C17.3928 16.4427 17.0107 16.5181 16.625 16.517Z"
        fill="#262C6C" />
    </svg>

    <!--Counts-->
    <h3>{{ previousCount | transformNumber }}</h3>
    <div class="current-count">
      <!--{{ sharedService.getPlusMinus(currentCount,previousCount) }}-->
      <span>
        {{ currentCount | transformNumber }}
      </span>
    </div>

    <!--Count Type Name-->
    <p>
      <span>
        {{ type }}
        <i *ngIf="tooltipTitle" nz-icon nzType="info-circle" nzTheme="fill"
           nzTooltipOverlayClassName="information" nz-tooltip
           nzTooltipPlacement="right" nzTooltipTrigger="hover"
           [nzTooltipTitle]="tooltipTitle"></i>
      </span>
    </p>
  </div>
</ng-container>
