import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { UserService } from '../../services/user.service';
import { Store } from '@ngxs/store';
import { AuthState } from '../../action-state/states/auth.state';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.less']
})
export class AddCardComponent implements OnInit {
  stripeForm: FormGroup | any;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#bfbfc1'
        },
      }
    },
    hidePostalCode: true
  };
  cardError: any = null;
  userId = null;
  @Output() getCardDetails = new Subject();
  @ViewChild(StripeCardComponent)
  card!: StripeCardComponent;
  addCardLoader = false;

  constructor(private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    public sharedService: SharedService,
    private router: Router,
    private messageService: NzMessageService,
    private userService: UserService,
    private store: Store,
    private stripeService: StripeService) {
  }

  ngOnInit(): void {
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z\s]*$/)]]
    });
    this.userId = this.store.selectSnapshot(AuthState.user).id;
  }

  handleOk(): void {
    this.stripeForm.markAllAsTouched();

    if (this.stripeForm.invalid) {
      return;
    }
    const name = this.stripeForm.get('name').value;
    this.addCard(name);
  }

  async addCard(name): Promise<void> {
    const paymentMethod = await this.createPaymentMethod(name);
    const cardAdded = await this.attachCardToUser(paymentMethod);
    if (cardAdded) {
      this.getAllCard();
      this.card.element.clear();
      this.stripeForm.reset();
    }
  }

  getAllCard(): void {
    this.getCardDetails.next(true);
  }

  createPaymentMethod(name): Promise<any> {
    // this.spinnerService.show();
    this.addCardLoader = true;
    return new Promise(resolve => {
      this.userService.paymentIntent().subscribe(res => {
        this.stripeService.confirmCardSetup(res.client_secret, {
          payment_method: {
            card: this.card.element,
            billing_details: { name }
          }
        }).subscribe(response => {
          // this.spinnerService.hide();
          this.addCardLoader = false;
          if (response.setupIntent) {
            this.cardError = null;
            resolve(response.setupIntent.payment_method);
          }
          if (response.error) {
            this.cardError = response.error.message;
          }
        });
      }, error => {
        // this.spinnerService.hide();
        this.addCardLoader = false;
      });
    });
  }

  attachCardToUser(paymentMethodId): Promise<boolean> {
    // this.spinnerService.show();
    this.addCardLoader = true;
    return new Promise(resolve => {
      this.userService.addCard({ payment_method_id: paymentMethodId }).subscribe(res => {
        // this.spinnerService.hide();
        this.addCardLoader = false;
        if (res.code === 200) {
          if (res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          }
          resolve(true);
        }
        if (res.code === 500 && res.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
          resolve(false);
        }
      }, error => {
        // this.spinnerService.hide();
        this.addCardLoader = false;
        resolve(false);
      });
    });
  }
}
