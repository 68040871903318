<section *ngIf="user.is_super_admin" class="conn_account"
         id="connect_account" joyrideStep="dash_s2" [stepContent]="customContent" stepPosition="right" >
  <ng-template #customContent>
    <div class="step_content_wrapper">
      <div class="icon_sec">
        <img src="../../../assets/images/tour_guid_1.png" alt="">
      </div>
      <div class="detail_sec">
        <!-- <h5>Connect Accounts</h5> -->
        <p> Simplify your social media experience by connecting your accounts with the featured platforms. Click “+ Add Account” next to the desired social network above.</p>
      </div>
    </div>
  </ng-template>

  <!-- <div class="title_sec">
    <h5 class="text-28">Connect Accounts</h5>
  </div> -->
  <div class="conn_account_block_main">

    <div class="conn_account_block facebook_connect" joyrideStep="dash_s3" [stepContent]="customFacebook">
      <img src="../../../assets/images/Facebook.png" alt="Facebook Logo">
      <p>Facebook</p>
      <span>Manage Facebook pages.</span>
      <button type="submit" nz-button nzBlock (click)="openFacebookModal()"
              class="btn btn_add btn_fb_add">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
      </button>
    </div>
    <ng-template #customFacebook>
      <div class="step_content_wrapper">
        <div class="icon_sec">
          <img src="../../../assets/images/summery_img_3.png" alt="">
        </div>
        <div class="detail_sec">
          <h5>Add a Social Network</h5>
          <p>Here you can add your social accounts! Also, you can see your list of connected social accounts. Click Next
            to see how you can connect your social account.</p>
        </div>
      </div>
    </ng-template>


    <div class="conn_account_block">
      <img src="../../../assets/images/Instagram.png" alt="Instagram Logo">
      <p>Instagram</p>
      <span>Manage Instagram business account, reels and posts.</span>
      <!-- <a class="btn" href="javascript:;">Connect</a> -->
      <button type="submit" nz-button nzBlock (click)="openInstagramModal()" class="btn btn_add">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
      </button>
    </div>
    <div class="conn_account_block">
      <img src="../../../assets/images/Twitter.png" alt="Twitter Logo">
      <p>{{ sharedService.xProvider }}</p>
      <span>Manage {{ sharedService.xProvider }} accounts and streams.</span>
      <!-- <a class="btn" href="javascript:;">Connect</a> -->
      <button type="submit" nz-button nzBlock (click)="twitterConnect()" class="btn btn_add"
              [disabled]="(isLoadingConnection && loadingConnectionProvider===sharedService.twitterProvider)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
        <ng-container *ngIf="isLoadingConnection && loadingConnectionProvider===sharedService.twitterProvider">
          <b nz-icon nzType="loading"></b>
        </ng-container>
      </button>
    </div>
    <div class="conn_account_block">
      <img src="../../../assets/images/LinkedIn.png" alt="LinkedIn Logo">
      <p>Linkedin</p>
      <span>Manage Linkeden profiles, and company pages.</span>
      <!-- <a class="btn btn_add" href="javascript:;">Connect</a> -->
      <button type="submit" nz-button nzBlock (click)="linkedinConnect()" class="btn btn_add"
              [disabled]="(isLoadingConnection && loadingConnectionProvider===sharedService.linkedinProvider)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
        <ng-container *ngIf="isLoadingConnection && loadingConnectionProvider===sharedService.linkedinProvider">
          <b nz-icon nzType="loading"></b>
        </ng-container>
      </button>
    </div>
    <div class="conn_account_block">
      <img src="../../../assets/images/YouTube.png" alt="YouTube Logo">
      <p>YouTube</p>
      <span>Publish videos to your YouTube account.</span>
      <!-- <a class="btn btn_add" href="javascript:;">Connect</a> -->
      <button type="submit" nz-button nzBlock (click)="youtubeConnect()" class="btn btn_add"
              [disabled]="(isLoadingConnection && loadingConnectionProvider===sharedService.youtubeProvider)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
        <ng-container *ngIf="isLoadingConnection && loadingConnectionProvider===sharedService.youtubeProvider">
          <b nz-icon nzType="loading"></b>
        </ng-container>
      </button>
    </div>
    <div class="conn_account_block">
      <img src="../../../assets/images/Pinterest.png" alt="Pinterest Logo">
      <p>Pinterest</p>
      <span>Post updates to your Pinterest boards.</span>
      <!-- <a class="btn btn_add" href="javascript:;">Connect</a> -->
      <button type="submit" nz-button nzBlock (click)="pinterestConnect()" class="btn btn_add"
              [disabled]="(isLoadingConnection && loadingConnectionProvider===sharedService.pinterestProvider)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
        <ng-container *ngIf="isLoadingConnection &&
            loadingConnectionProvider===sharedService.pinterestProvider">
          <b nz-icon nzType="loading"></b>
        </ng-container>
      </button>
    </div>

    <div class="conn_account_block">
      <img src="../../../assets/images/post_tiktok.png" alt="">
      <p>TikTok</p>
      <span>Manage Tiktok posts and pages.</span>
      <!-- <a class="btn btn_add" href="javascript:;">Connect</a> -->
      <button type="submit" nz-button nzBlock (click)="tiktokConnect()" class="btn btn_add"
              [disabled]="(isLoadingConnection && loadingConnectionProvider===sharedService.tiktokProvider)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
        <ng-container *ngIf="isLoadingConnection &&
            loadingConnectionProvider===sharedService.tiktokProvider">
          <b nz-icon nzType="loading"></b>
        </ng-container>
      </button>
    </div>



    <div class="conn_account_block">
      <img src="../../../assets/images/threads.png" alt="">
      <p>Threads</p>
      <span>Manage Threads posts and thread.</span>
      <button type="submit" nz-button nzBlock (click)="threadsConnect()" class="btn btn_add"
              [disabled]="(isLoadingConnection && loadingConnectionProvider===sharedService.threadsProvider)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
        <ng-container *ngIf="isLoadingConnection &&
            loadingConnectionProvider===sharedService.tiktokProvider">
          <b nz-icon nzType="loading"></b>
        </ng-container>
      </button>
    </div>
    
    <div class="conn_account_block">
      <img src="../../../assets/images/snapchat.png" alt="Snapchat Logo">
      <p>Snapchat</p>
      <span>Publish adds in your Snapchat account.</span>
      <button type="submit" nz-button nzBlock (click)="snapchatConnect()" class="btn btn_add"
              [disabled]="(isLoadingConnection && loadingConnectionProvider===sharedService.snapchatProvider)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6.75H6.75V12H5.25V6.75H0V5.25H5.25V0H6.75V5.25H12V6.75Z" fill="white"></path>
        </svg>
        <span>Add Account</span>
        <ng-container *ngIf="isLoadingConnection && loadingConnectionProvider===sharedService.snapchatProvider">
          <b nz-icon nzType="loading"></b>
        </ng-container>
      </button>
    </div>
  </div>
</section>




<!--Modals-->
<nz-modal [nzClassName]="'auth_con_guide'" [(nzVisible)]="showFacebookModal" nzTitle="Facebook Account Connection Guide"
          [nzFooter]="null" (nzOnCancel)="showFacebookModal = false">
  <ng-container *nzModalContent>
    <div class="auth_con_content">
      <p>Due to several changes by Facebook in 2018 to protect user privacy, publishing is not supported for Facebook
        personal profiles. You can however link your personal Facebook account that is connected to your Facebook Page,
        and publish directly to the page through OneForAll Social.</p>
      <p>If you are connecting to your FB account for the first time, no need to read further.</p>
      <p>If you are connecting your another page(s) from the same FB account, please make sure to select already
        connected pages with new ones.</p>
      <p>If you are not able to see the list of pages and directly redirect to the OneForAllSocial platform after
        the proceed button, then please click on “Edit Setting” to select pages.</p>
    </div>
    <div class="auth_con_content_btn">
      <button type="submit" nz-button nzType="primary" nzBlock (click)="facebookConnect()" class="btn btn_add"
              [disabled]="isLoadingConnection">
        Connect To Facebook
        <ng-container *ngIf="isLoadingConnection &&
            loadingConnectionProvider===sharedService.facebookProvider">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [nzClassName]="'auth_con_guide social_connect insta_modal'" [(nzVisible)]="showInstagramModal"
          nzTitle="Instagram Account Connection Guide" [nzFooter]="null" (nzOnCancel)="showInstagramModal = false">
  <ng-container *nzModalContent>
    <div class="auth_con_title">
      <!-- <h5>Let's get you to the finish line</h5> -->
      <p>Connect your Business profile to your Facebook Page. Here's how:</p>
    </div>
    <div class="auth_social_sec">
      <div class="auth_social_main">
        <div class="auth_social_in">
          <img src="../../../assets/images/Facebook.png" alt="Facebook Logo">
          <span>Log in to Facebook</span>
        </div>
        <div class="auth_social_in">
          <div class="selection_area">
            <div class="icon_sec">
              <img src="../../../assets/images/instagram_connect.png" alt="">
              <img src="../../../assets/images/facebook_flag.png" alt="">
            </div>
          </div>
          <div class="selection_area_dtl">
            <span>Select a Business profile<br> that's connected to a<br> Facebook Page</span>
          </div>
        </div>
        <div class="auth_social_in">
          <img src="../../../assets/images/oneforall_logo.jpeg" alt="One For All Social">
          <span>Add to One For All Social</span>
        </div>
      </div>
    </div>
    <div class="auth_con_content">
      <p>You can only connect Instagram <b>Business</b> account to the OneForAllSocial platform.</p>
      <p>If you are connecting to your Instagram account for the first time, smiply click on "Connect to Instagram"
        below.</p>
      <p>If you are connecting your Instagram account from an existing linked Facebook account, please make sure to
        select
        already
        connected pages and Instagram business accounts with new ones.</p>
      <p>If you have already connected at least one Instagram Business account, click "Edit Settings" to select an
        additional Instagram accounts, then the associated Facebook page. </p>
    </div>
    <div class="auth_con_content_btn">
      <button type="submit" nz-button nzType="primary" nzBlock (click)="instagramConnect()" class="btn btn_add"
              [disabled]="isLoadingConnection">
        Connect To Instagram
        <ng-container *ngIf="isLoadingConnection &&
            loadingConnectionProvider===sharedService.instagramProvider">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </ng-container>
</nz-modal>
