import * as moment from "moment";

import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

import {LinkedinService} from "../../services/linkedin.service";
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-campaign-linkedin-component',
  templateUrl: './campaign-linkedin.component.html',
  styleUrls: ['./campaign-linkedin.component.less']
})

export class CampaignLinkedinComponent implements OnInit, OnDestroy {

  @Input() socialAccount = null;
  @Input() post;
  @Input() campaign;

  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();

  socialId = '';
  public adForm: FormGroup;
  adAccounts = [];
  selectedAdAccount = null;
  isAdAccountLoading = false
  linkedinAdObjectives = [
    {
      'id': 'BRAND_AWARENESS',
      'title': 'Awareness',
      'icon': '../../../assets/images/awareness_icon.png',
      'description': 'Brand Awarness-get more impressions on your post.',
    },
    {
      'id': 'ENGAGEMENT',
      'title': 'Engagement',
      'icon': '../../../assets/images/engagement_icon.png',
      'description': 'Engagement-Get more clicks and social actions (likes,shares,comments,follows) on your post.',
    }
  ];
  selectedObjective = 'ENGAGEMENT';
  countries = [];
  countriesListInclude = [];
  countriesListExclude = [];

  selectedIncludedCountries = [];
  isCountriesLoading = false;
  selectedExcludedCountries = [];
  isExcludedCountriesLoading = false;

  selectedAgeGroup = [];
  gender = 'ALL';
  
  isLinkedinReachLoading = false;
  estimatedReach = '';
  selectedBudgetType = 'daily';

  isAdSaving = false;

  isAdPreviewLoading = false;
  adPreviews = [];

  httpSubscription: any = null;
  start_time = moment(new Date()).toDate();
  end_time = moment(this.start_time).add(7, 'days').toDate();

  httpSubscriptionReach: any = null;

  allChecked = false;
  indeterminate = true;
  ageOptions = [
    { label: 'Age 18-24', value: 'AGE_18_24' },
    { label: 'Age 25-34', value: 'AGE_25_34' },
    { label: 'Age 35-54', value: 'AGE_35_54' },
    { label: 'Age 55+', value: 'AGE_55_PLUS' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private linkedinService: LinkedinService,
    private sanitizer: DomSanitizer,
  ) {

  }

  ngOnInit(): void {
    this.adForm = this.formBuilder.group({
      social_id: [''],
      campaign_name: ['',Validators.required],
      ad_account_id: ['', Validators.required],
      objective: ['', Validators.required],
      included_countries: [[],Validators.required],
      excluded_countries: [],
      genders: ['all',Validators.required],
      age_group: [[],Validators.required],
      budget: ['',Validators.required],
      budget_type: ['',Validators.required],
      start_time: ['',Validators.required],
      end_time: [''],
    });
    if (this.campaign &&  this.campaign.budget_type === 'daily') {
      this.adForm.get('budget').disable();
    }
    if(this.post?.media_type ==  'video' || this.campaign?.media_type == 'video') {
      // Here For video ENGAGEMENT objective not support.
      this.linkedinAdObjectives.pop();
      const newObjective = {
      'id': 'VIDEO_VIEW',
      'title': 'Video View',
      'icon': '../../../assets/images/video_view.svg',
      'description': 'Video views-get more people to watch your video.',
      };
      this.linkedinAdObjectives.push(newObjective);
    }
    console.log("post data!",this.post);
    this.countriesListInclude = [
      {
      "name": "United States",
      "urn": "urn:li:geo:103644278",
      "facetUrn": "urn:li:adTargetingFacet:locations"
      }
    ];
    this.selectedIncludedCountries = [this.countriesListInclude[0]];

    console.log("1",this.countriesListInclude);
    console.log("2",this.selectedIncludedCountries);
    
    if(this.socialAccount){
      this.socialId = this.socialAccount?.social_id;
      if(this.campaign){
        this.setEditData();
        this.getAdPreview();
      }else{
        this.getLinkedinAdAccount();
      }
    }


  }

  ngOnDestroy(){
    this.campaign = null;
    // cancel previous http call
    if (this.httpSubscriptionReach !== null) {
      this.httpSubscriptionReach.unsubscribe();
    }
  }

  setEditData() {
    console.log("setEditData--> campaign",this.campaign)
      this.selectedAdAccount = {
        id: this.campaign.ad_account_id,
        currency_symbol : this.campaign.currency_symbol
      };
      console.log("setEditData selectedAdAccount", this.selectedAdAccount)

      this.adForm.patchValue({ campaign_name: this.campaign.name });

      this.post = { post_id: this.campaign.post_id };
      this.selectedObjective = this.campaign.objective;

      this.linkedinAdObjectives = this.linkedinAdObjectives.filter(objective => {
        return objective.id == this.campaign.objective;
      })
      this.gender = this.campaign?.genders;
      this.selectedBudgetType = this.campaign?.budget_type;

      if(this.campaign.budget_type === "daily"){
        this.adForm.patchValue({ budget: this.campaign.daily_budget });
      }else{
        this.adForm.patchValue({ budget: this.campaign.lifetime_budget });
      }

      this.countriesListInclude = this.campaign?.included_countries
      this.selectedIncludedCountries = [...this.countriesListInclude]

      this.countriesListExclude = this.campaign?.excluded_countries
      this.selectedExcludedCountries = [...this.countriesListExclude]

      const ageGroups = this.campaign.age_group.split(',');
      this.selectedAgeGroup = this.ageOptions.filter(item =>{
        return ageGroups.includes(item.value);
      });
      console.log("ageGroup",this.selectedAgeGroup);
      console.log("Include",this.selectedIncludedCountries);
      console.log("Exclude",this.selectedExcludedCountries);

      this.start_time = new Date(this.campaign?.start_time)
      this.end_time = new Date(this.campaign?.end_time)
  }

  getLinkedinAdAccount(){
    this.isAdAccountLoading = true;
    this.adAccounts = [];
    this.linkedinService.getLinkedinAdAccounts(this.socialId).subscribe(res => {
      this.isAdAccountLoading = false;
      if (res.code === 200) {
          this.adAccounts = res.data;
          console.log("adAccounts : ",this.adAccounts)
          if(this.campaign){
            console.log("campaign : ",this.campaign)
            this.setEditData();
          }
      }
    }, error => {
      this.isAdAccountLoading = false;
    });
  }

  async updateSocialAccount(event) {
    console.log("updateSocialAccount",this.selectedAdAccount)
  }

  getCountries(search: string){
    if(search) {
      this.isCountriesLoading = true;
      this.countriesListInclude = [];
      this.linkedinService.getLinkedinCountries(this.socialId, search).subscribe(res => {
        this.isCountriesLoading = false;
        if (res.code === 200) {
          this.countriesListInclude = res.data;
          console.log("getCountries : ", this.countriesListInclude)
        }
      }, error => {
        this.isCountriesLoading = false;
      });
    }
  }

  getCountriesExclude(search: string){
    if(search) {
      this.isExcludedCountriesLoading = true;
      this.countriesListExclude = [];
      this.linkedinService.getLinkedinCountries(this.socialId, search).subscribe(res => {
        this.isExcludedCountriesLoading = false;
        if (res.code === 200) {
          this.countriesListExclude = res.data;
          console.log("getCountriesExclude : ", this.countriesListExclude)

          /* if(this.countriesListExclude.length>0){
            this.countriesListExclude = this.countriesListExclude.filter(country => {
              return !this.selectedIncludedCountries.some(includedCountry => includedCountry.key === country.key);
            });
            this.countriesListExclude = this.countriesListExclude.filter(country => {
              return !this.selectedExcludedCountries.some(excludedCountry => excludedCountry.key === country.key);
            });
          } */
        }
      }, error => {
        this.isExcludedCountriesLoading = false;
      });
    }
  }

  updateLinkedinEstimatedReach(){
      this.isLinkedinReachLoading = true;
      this.estimatedReach = '';

      let postData = {
        ad_account : this.selectedAdAccount.id,
        social_id: this.socialId,
        genders: this.gender ? this.gender : '',
        age_group: this.selectedAgeGroup?.map(item => item.value).join(','),
        included_countries: this.selectedIncludedCountries?.map(item => item.urn).join(','),
        excluded_countries: this.selectedExcludedCountries?.map(item => item.urn).join(','),
      }
    // cancel previous http call
    if (this.httpSubscriptionReach !== null) {
      this.httpSubscriptionReach.unsubscribe();
    }
    const countries = this.selectedIncludedCountries?.map(item => item.urn).join(',');
    console.log(countries);
    this.httpSubscriptionReach = this.linkedinService.getLinkedinReach(postData).subscribe(res => {
        console.log("updateLinkedinEstimatedReach : ", res)
        this.isLinkedinReachLoading = false;
        if (res.code === 200) {
          let resData = res.data;
          this.estimatedReach = this.formatNumber(resData?.elements[0]?.total);
        } else if (res.code === 500){
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Campaigns', res.message);
        }
      }, error => {
        this.isLinkedinReachLoading = false;
      });
  }

  formatNumber(value) {
    if (value >= 1000000000) {
      const billionValue = (value / 1000000000).toFixed(2);
      return `${billionValue}B`;
    }else if (value >= 1000000) {
      const millionValue = (value / 1000000).toFixed(2);
      return `${millionValue}M`;
    } else if (value >= 1000) {
      const thousandValue = (value / 1000).toFixed(2);
      return `${thousandValue}K`;
    } else {
      return value.toString();
    }
  }

  saveLinkedinAd(){
    console.log("this.selectedAgeGroup",this.selectedAgeGroup);
    for (const i in this.adForm.controls) {
      this.adForm.controls[i].markAsDirty();
      this.adForm.controls[i].updateValueAndValidity();
    }

    if (this.adForm.valid) {
      this.isAdSaving = true;

      //const adFormData = new FormData();
      const adFormData = {};
      //const adFormData =  this.adForm.value;
      for (const i in this.adForm.controls) {
        const control = this.adForm.controls[i];
        adFormData[i] = control.value;
        //adFormData.append(i, control.value);
      }
      adFormData['ad_account_id'] = this.adForm.value?.ad_account_id?.id;
      adFormData['currency_code'] = this.selectedAdAccount?.currency;
      adFormData['currency_symbol'] = this.selectedAdAccount?.currency_symbol;
      adFormData['social_id'] = this.socialId;
      adFormData['age_group'] = this.selectedAgeGroup.map(item => item.value).join(',');
      
      if (this.post) {
        adFormData['post_id'] = this.post.post_id;
        adFormData['post_description'] = this.post.description;
        adFormData['post_thumb'] = this.post.media_link;
        adFormData['post_link'] = this.post.post_link;
        adFormData['media_type'] = this.post.media_type;
      }
      console.log("saveLinkedinAd adFormData:", adFormData)

      this.linkedinService.linkedinAdCreate(adFormData).subscribe(res => {
        console.log("saveLinkedinkAd : ", res)
        this.isAdSaving = false;
        if (res.code === 200) {
          this.onSave.emit();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Campaigns', res.message);
          let resData = res.data;
          this.closeModal()
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Campaigns', res.message);
        }
      }, error => {
        this.isAdSaving = false;
      });
    }

  }

  updateLinkedinAd(){
    console.log("updateLinkedinAd")
    //change validation rules for update
    this.adForm.get('ad_account_id').clearValidators();
    this.adForm.get('objective').clearValidators();
    this.adForm.get('budget_type').clearValidators();
    this.adForm.get('start_time').clearValidators();
    this.adForm.updateValueAndValidity();

    for (const i in this.adForm.controls) {
      this.adForm.controls[i].markAsDirty();
      this.adForm.controls[i].updateValueAndValidity();
    }

    for (const controlName in this.adForm.controls) {
      const control = this.adForm.get(controlName);
      if (control instanceof FormGroup) {
      } else {
        // If it's a FormControl, log its errors
        console.log(`Control: ${controlName}, Errors: `, control.errors);
      }
    }
    console.log("updateLinkedinAd")

    if (this.adForm.valid) {
      this.isAdSaving = true;
      //const adFormData = new FormData();
      const adFormData = {};
      for (const i in this.adForm.controls) {
        const control = this.adForm.controls[i];
        adFormData[i] = control.value;
      }

      adFormData['ad_account_id'] = this.campaign.ad_account_id;
      adFormData['campaign_id'] = this.campaign.campaign_id;
      adFormData['social_id'] = this.socialId;
      adFormData['age_group'] = this.selectedAgeGroup.map(item => item.value).join(',');
      console.log("updateLinkedinAd adFormData:", adFormData)

      this.linkedinService.linkedinAdUpdate(adFormData).subscribe(res => {
        console.log("updateLinkedinAd : ", res)
        this.isAdSaving = false;
        if (res.code === 200) {
          this.onSave.emit();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Campaigns', res.message);
          let resData = res.data;
          this.closeModal()
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Campaigns', res.message);
        }
      }, error => {
        this.isAdSaving = false;
      });
    }
  }

  closeModal(){
    this.onCancel.emit();
  }

  addAccount(){
    // Add Account url
    const routeURL = 'https://www.linkedin.com/help/lms/answer/a1319574';
    window.open(routeURL, "_blank");
  }

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) < 0;
  }

  getSafeHtml(preview): SafeHtml {
    console.log("getSafeHtml preview:",preview)
    return this.sanitizer.bypassSecurityTrustHtml(preview || '');
  }

  getAdPreview(){
    this.isAdPreviewLoading = true;
    this.adPreviews = [];
    let postData = {
      ad_account : this.selectedAdAccount?.id,
      social_id: this.socialId,
      campaign_id: this.campaign._id,
    }
    this.linkedinService.getLinkedinAdPreview(postData).subscribe(res => {
      console.log("getAdPreview : ", res)
      this.isAdPreviewLoading = false;
      if (res.code === 200) {
        this.adPreviews = res.data;
        console.log("adPreview loaded", this.adPreviews[0].preview);
        this.adPreviews.forEach((item,index) => {
          this.adPreviews[index].preview = this.getSafeHtml(item.preview);
        })
      }
    }, error => {
      this.isAdPreviewLoading = false;
    });
  }

}
