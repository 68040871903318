export class AccountTypes {
  static NONE = new AccountTypes(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 100);
  static TIWTTER = new AccountTypes(4, 0, 5, 25, 1, 512, 75, 132, 0.30, 280, 1, 15, 5, 280, 0);
  static LINKEDIN = new AccountTypes(9, 0, 5, 25, 1, 512, 75, 600, 3, 1300, 9, 8, 5, 1250, 0);
  static FACEBOOK = new AccountTypes(10, 0, 5, 25, 1, 512, 75, 14400, 3, 63206, 1, 15, 5, 8000, 0);
  static INSTAGRAM = new AccountTypes(1, 0, 5, 25, 1, 100, 75, 60, 3, 2200, 0, 0, 0, 2200, 0);
  static YOUTUBE = new AccountTypes(1, 0, 5, 25, 1, 100, 75, 60, 3, 5000, 0, 0, 0, 10000, 100);
  static PINTEREST = new AccountTypes(1, 0, 5, 25, 1, 100, 75, 60, 3, 500, 0, 0, 0, 2200, 100);
  static TIKTOK = new AccountTypes(1, 0, 5, 25, 1, 100, 75, 60, 3, 150, 0, 0, 0, 0, 0);
  static THREADS = new AccountTypes(10, 0, 8, 25, 1, 512, 75, 300, 1, 500, 0, 0, 0, 0, 0);

  static twitterVidTypes = [
    'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'
  ];
  static twitterImgTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  static instagramVidTypes = [
    'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'
  ];
  static instagramImgTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  static linkdinVidTypes = [
    'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'
  ];

  static linkdinImgTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  static facebookVidTypes = [
    'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'
  ];

  static facebookImgTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  static youtubeVidTypes = [
    'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'
  ];

  static tiktokVidTypes = [
    'video/mp4'
  ];

  static pinterestImgTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  static pinterestVidTypes = [
    'video/mp4'
  ];

  static threadsImgTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  static threadsVidTypes = [
    'video/mov','video/mp4'
  ];

  maxImgLength: number; // Total numbers of image
  minImgLength: number;
  maxImgSize: number; // In Mb
  minImgSize: number; // In Kb
  maxVidLength: number; // Total numbers of videos
  maxVidSize: number; // In Mb
  minVidSize: number; // In Kb
  maxVidDuration: number; // In Seconds
  minVidDuration: number; // In Seconds
  maxContentLength: number; // In Words
  maxGifLength: number; // Total numbers of gifs
  maxGifSize: number; // In Mb
  minGifSize: number; // In Kb
  maxCommentLength: number;
  maxTitleLength: number;

  constructor(
    maxImgLength, minImgLength, maxImgSize,
    minImgSize, maxVidLength, maxVidSize,
    minVidSize, maxVidDuration, minVidDuration,
    maxContentLength, maxGifLength, maxGifSize,
    minGifSize, maxCommentLength, maxTitleLength) {

    this.maxImgLength = maxImgLength;
    this.maxImgSize = maxImgSize;
    this.minImgSize = minImgSize;
    this.maxVidLength = maxVidLength;
    this.maxVidSize = maxVidSize;
    this.minVidSize = minVidSize;
    this.maxVidDuration = maxVidDuration;
    this.minVidDuration = minVidDuration;
    this.maxContentLength = maxContentLength;
    this.maxGifLength = maxGifLength;
    this.maxGifSize = maxGifSize;
    this.minGifSize = minGifSize;
    this.minImgLength = minImgLength;
    this.maxCommentLength = maxCommentLength;
    this.maxTitleLength = maxTitleLength;
  }

  static getObjectAccountRequest(account): AccountTypes {
    switch (account) {
      case 'LINKEDIN':
        return AccountTypes.LINKEDIN;
      case 'TWITTER':
        return AccountTypes.TIWTTER;
      case 'FACEBOOK':
        return AccountTypes.FACEBOOK;
      case 'INSTAGRAM':
        return AccountTypes.INSTAGRAM;
      case 'YOUTUBE':
        return AccountTypes.YOUTUBE;
      case 'PINTEREST':
        return AccountTypes.PINTEREST;
      case 'TIKTOK':
        return AccountTypes.TIKTOK;
      case 'THREADS':
        return AccountTypes.THREADS;
      default:
        return AccountTypes.NONE;
    }
  }

  static getVideoTypes(account, type): boolean {
    switch (account) {
      case 'LINKEDIN':
        return this.linkdinVidTypes.includes(type);
      case 'TWITTER':
        return this.twitterVidTypes.includes(type);
      case 'FACEBOOK':
        return this.facebookVidTypes.includes(type);
      case 'INSTAGRAM':
        return this.instagramVidTypes.includes(type);
      case 'YOUTUBE':
        return this.youtubeVidTypes.includes(type);
      case 'TIKTOK':
        return this.tiktokVidTypes.includes(type);
      case 'PINTEREST':
        return this.pinterestVidTypes.includes(type);
      case 'THREADS':
        return this.threadsVidTypes.includes(type);
      default:
        return false;
    }
  }

  static getImageTypes(account, type): boolean {
    switch (account) {
      case 'LINKEDIN':
        return this.linkdinImgTypes.includes(type);
      case 'TWITTER':
        return this.twitterImgTypes.includes(type);
      case 'FACEBOOK':
        return this.facebookImgTypes.includes(type);
      case 'INSTAGRAM':
        return this.instagramImgTypes.includes(type);
      case 'TIKTOK':
        return this.linkdinImgTypes.includes(type);
      case 'PINTEREST':
        return this.pinterestImgTypes.includes(type);
      case 'THREADS':
        return this.threadsImgTypes.includes(type);
      default:
        return false;
    }
  }

}
