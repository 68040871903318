import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-invite-status',
  templateUrl: './invite-status.component.html',
  styleUrls: ['./invite-status.component.less']
})
export class InviteStatusComponent {

  constructor(
    public sharedService: SharedService,
    private messageService: NzMessageService,
    private spinnerService: NgxSpinnerService,
    private teamService: TeamsService,
  ) { }
  inviteUserList: any = '';
  userEmail: any = '';
  isLoadingInvite = false;
  scrollLoading = false;
  galleryCurrentPage = 1;
  selectedPageIndex = 1;
  selectedPageSize = 10;
  totalData = 0;
  isLoadType = '';


  getInviteUserList() {
    this.galleryCurrentPage = 1;
    this.teamService.getInvitedUserList(this.selectedPageIndex, this.selectedPageSize).subscribe((res: any) => {
      if (res.code === 200) {
        this.inviteUserList = res.data?.data;
        this.totalData = res.data.total;
      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    })
  }

  onQueryParamsChange(event) {
    this.selectedPageIndex = event.pageIndex;
    this.selectedPageSize = event.pageSize;
    this.getInviteUserList()
  }




  resendEmail(userEmail, userId, userRole, invitToken) {
    this.isLoadingInvite = true;
    this.userEmail = userEmail;
    var postValue = {
      userEmail: this.userEmail,
      userId: userId,
      role: userRole,
    }
    this.isLoadType = 'resendInvite';

    this.teamService.resedUserInvitation(postValue).subscribe((res: any) => {
      this.isLoadingInvite = false;
      if (res.code === 200) {
        this.isLoadingInvite = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    })

  }

  deleteInvitation(userEmail, userId) {
    this.userEmail = userEmail;
    var postValue = {
      userEmail: this.userEmail,
      userId: userId,
    }
    this.isLoadingInvite = true;
    this.isLoadType = 'deleteInvitation';
    this.teamService.deleteInvitation(postValue).subscribe((res: any) => {
      this.isLoadingInvite = false;
      if (res.code === 200) {

        // this.messageService.success(res.message, { nzDuration: 1500 });
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Delete Pending Invitation', res.message);
        setTimeout(() => {
          this.getInviteUserList();
        }, 1500);

      } else {
        // this.messageService.remove();
        // this.messageService.error(res.message);
        this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, res.message);
      }

    })


  }

}
