import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AutoLogin, ChangePassword, Login, Logout, UpdateProfile, UpdateUser } from '../actions/auth.action';

export interface AuthStateModel {
  user: any | null;
  token: string | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: null,
    token: null
  }
})
@Injectable()

export class AuthState {
  @Selector()
  static user(state: AuthStateModel): any {
    return state.user;
  }

  @Selector()
  static token(state: AuthStateModel): string {
    return <string>state.token;
  }

  @Action(Login)
  login(authState: StateContext<AuthStateModel>, action: Login): void {
    authState.patchState({
      token: action.token,
      user: action.payload
    });
  }

  @Action(Logout)
  logout(authState: StateContext<AuthStateModel>): void {
    authState.setState({
      token: null,
      user: null
    });
  }

  @Action(AutoLogin)
  autologin(authState: StateContext<AuthStateModel>, action: AutoLogin): void {
    authState.patchState({
      user: action.  payload
    });
  }

  @Action(UpdateUser)
  UpdateUser(authState: StateContext<AuthStateModel>, action: UpdateUser): void {
    authState.patchState({
      user: action.payload
    });
  }

  @Action(ChangePassword)
  changePassword(authState: StateContext<AuthStateModel>, action: ChangePassword): void {
    authState.patchState({
      user: action.payload
    });
  }

  @Action(UpdateProfile)
  updateProfile(authState: StateContext<AuthStateModel>, action: UpdateProfile): void {
    authState.patchState({
      user: action.payload
    });
  }
}
