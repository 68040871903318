<!-- <nz-modal [(nzVisible)]="repeatPopup" nzTitle="Repeat Post" (nzOnCancel)="handleCancel()"
  [nzWrapClassName]="'repeat-modal'" [nzWidth]="900" [nzFooter]="null"> -->

<!-- <ng-container *nzModalContent> -->
<div class="repeat_form_warning">
  <nz-alert nzType="warning"
    nzMessage="To avoid duplicate post error, please keep a time gap of 24 hours in between each posts which contains same text and doesn't have any media attached.">
  </nz-alert>
</div>
<div class="repeat_form_main">
  <div class="repeat_form_in">
    <label>Repeat</label>
    <nz-select [(ngModel)]="selectedRepeat" nzPlaceHolder="Select a repeat option"
      (ngModelChange)="repeatSelectionChange()">
      <nz-option nzValue="every-day" nzLabel="Every Day"></nz-option>
      <nz-option nzValue="every-alternate-day" nzLabel="Every Alternate Day"></nz-option>
      <nz-option nzValue="every-week" nzLabel="Every Week"></nz-option>
      <nz-option nzValue="every-month" nzLabel="Every Month"></nz-option>
    </nz-select>
  </div>
  <div class="repeat_form_in">
    <label>Select Time</label>
    <nz-time-picker [nzNowText]="" [(ngModel)]="repeatTime" [nzMinuteStep]="5" nzSize="large"
      nzPlaceHolder="Select Time" nzFormat="hh:mm a" [nzUse12Hours]="true" [nzAllowEmpty]="false"
      (nzOpenChange)="isOpenChange($event)" (ngModelChange)="totalRepeatdata = []" required nzNowText=" ">
    </nz-time-picker>
  </div>
  <div class="repeat_form_in">
    <label>Select Date</label>
    <nz-range-picker [nzInputReadOnly]="true" [(ngModel)]="repeatDate" [nzDisabledDate]="disabledDate"
      (ngModelChange)="totalRepeatdata = []" [nzAllowClear]="false"></nz-range-picker>
  </div>
  <div class="btn_group">
    <button nz-button nzType="primary" (click)="generateDateAndTime()">
      Generate
    </button>
  </div>
</div>
<!-- <div class="margin-top-3"></div> -->

<ng-container *ngIf="totalRepeatdata.length > 0">
  <div class="repeat-data-main">
    <div nz-row class="margin-top-2 repeat-data">
      <div *ngFor="let data of totalRepeatdata; let i = index" class="margin-bottom-1" nz-col nzSpan="24">
        <div nz-row>
          <div nz-col nzSpan="11">
            <nz-date-picker [(ngModel)]="data.date" [nzDisabled]="true" nzSize="large" nzFormat="MM/dd/yyyy"
              [nzAllowClear]="false">
            </nz-date-picker>
          </div>

          <div nz-col nzSpan="11">
            <nz-time-picker [(ngModel)]="data.time" [nzNowText]="" nzNowText=" " [nzMinuteStep]="5" nzSize="large" nzFormat="hh:mm a"
              [nzUse12Hours]="true" [nzAllowEmpty]="false" (nzOpenChange)="isOpenChange($event, i)" ></nz-time-picker>
          </div>
          <div nz-col nzSpan="2">
            <button nz-button nzType="primary" nzDanger class="delete-repeat" (click)="deleteRepeat(i)">
              <i nz-icon nzType="close" nzTheme="outline"></i>
            </button>
          </div>
        </div>
        <div *ngIf="checkForError(data.date)" class="repeat-error">
          <ul>
            <li *ngFor="let message of getErrorMessage(data.date)">
              {{ message }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="btn_group">
      <button nz-button nzType="primary" (click)="approveRepeat()">
        Approve Repeat
      </button>
    </div>
  </div>
</ng-container>
<!-- </ng-container> -->

<!-- </nz-modal> -->
