import { HelperService } from './helper.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThreadsService {

  constructor(private http: HttpClient, private helper: HelperService ) {  }

  threadsRequestsToken(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('threads_request_token'));
  }

  threadsAuthenticatedRequests(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('threads_auth'), postValue);
  }
}
