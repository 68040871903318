import {BehaviorSubject, Observable} from 'rxjs';

import {AccountTypes} from '../models/account-types-model';
import {AuthState} from '../action-state/states/auth.state';
import {HelperService} from './helper.service';
import {HttpClient} from '@angular/common/http';
import {InAppPurchaseState} from '../action-state/states/in-app-purchase.state';
import {Injectable} from '@angular/core';
import {JoyrideService} from "ngx-joyride";
import {NgxSpinnerService} from 'ngx-spinner';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {SocialAccountService} from './social-account.service';
import {Store} from '@ngxs/store';
import {SubscriptionState} from '../action-state/states/subscription.state';
import {TeamState} from "../action-state/states/team.state";
import {Title} from "@angular/platform-browser";
import {UserService} from './user.service';
import moment from 'moment/moment';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Injectable({
  providedIn: 'root',
})
export class SharedService {

  public appName = 'One For All Social';
  public webBaseUrl = 'https://www.o4a.com/';
  public linkPrivacy = this.webBaseUrl+'privacy';
  public linkTerms = this.webBaseUrl+'terms-and-conditions';
  public linkContact = this.webBaseUrl+'contact-page';

  public twitterProvider = 'TWITTER';
  public xProvider = 'X';
  public linkedinProvider = 'LINKEDIN';
  public facebookProvider = 'FACEBOOK';
  public instagramProvider = 'INSTAGRAM';
  public youtubeProvider = 'YOUTUBE';
  public tiktokProvider = 'TIKTOK';
  public pinterestProvider = 'PINTEREST';
  public snapchatProvider = 'SNAPCHAT';
  public threadsProvider = 'THREADS';

  public group = 'GROUP';
  public page = 'PAGE';

  public messageTypeSuccess = 'success';
  public messageTypeInfo = 'info';
  public messageTypeError = 'error';
  public defaultMessageError = 'Oops, Something went wrong';
  public defaultPermissionError = 'Access Denied';
  public defaultMessageSuccess = 'Success';

  public allPlatforms = [
    this.twitterProvider,
    this.linkedinProvider,
    this.facebookProvider,
    this.instagramProvider,
    this.youtubeProvider,
    this.tiktokProvider,
    this.pinterestProvider,
    this.threadsProvider,
  ];

  public superAdminRole = 'Super Admin';
  public adminRole = 'Admin';
  public editorRole = 'Editor';
  public moderatorRole = 'Moderator';
  public guestRole = 'Guest';
  public customRole = 'Custom';

  public posted = 'POSTED';
  public schedule = 'SCHEDULE';
  public queue = 'QUEUE';
  public fail = 'FAIL';
  public draft = 'DRAFT';

  public deliveredPostNotification = 'DeliveredPost';
  public accessTokenExpireReminderNotification = 'AccessTokenExpireReminder';
  public undeliveredPostNotification = 'UndeliveredPost';
  public draftPostNotification = 'DraftPost';
  public mail = 'MAIL';
  public mobile = 'MOBILE';
  public notificationTypes = [
    'Scheduled post successfully delivered',
    'Access token expiry warning 7 days before the expiry',
    'Undelivered or failed post alert',
    'Draft Pending / Approve / Reject post alert',
  ];

  public username = null;
  public profile = new BehaviorSubject(null);
  public email = null;
  public notification = new BehaviorSubject(true);
  public loadingText = 'Please Wait...';
  //public need_tour = localStorage.getItem('need_tour')? parseInt(localStorage.getItem('need_tour')) : 0;
  public need_tour = 0;
  // public need_team_tour = 0;


  public isAccountsLoading = false;
  public isMobile = false;
  public isTablet = false;

  public calendarViewMonthly = 'Monthly';
  public calendarViewWeekly = 'Weekly';
  public calendarViewDaily = 'Daily';

  public socialAccessTypes = [
    'No Access',
    'Read Only',
    'Needs Approval',
    'Full Publishing',
  ];
  public socialAccessTypesDescription = [
    'User cannot see profile',
    'User can see profile but not post',
    'User can see profile and submit posts for approval',
    'User can see profile, request approval and reply',
    'User can publish without restriction',
  ];

  public categoryIcons = {
    'administration' : 'administrative.svg',
    'accounts' : 'acounts.svg',
    'posts' : 'post.svg',
    'social_feed' : 'social_feed.svg',
    'analytics' : 'analytics.svg',
    'messages' : 'messages.svg',
  };

  public priorities = [
      {
      "id":1,
      "name":"Low",
      "image":"low.svg",
      "class":"priop_low"
    },
    {
      "id":2,
      "name":"Med",
      "image":"med.svg",
      "class":"priop_med"
    },
    {
      "id":3,
      "name":"High",
      "image":"high.svg",
      "class":"priop_high"
    }
  ];

  //notification
  notifications: any = [];
  notificationCount = 0;
  reconnectCount = 0;
  unreadInboxCount = 0;
  httpSubscriptionNotification: any = null;

  public permissions:any;
  teamLists = [];


  public currentPlan = null;
  public planLimit = {
    'plan_1' : 10,
    'plan_2' : 20,
    'plan_3' : 30
  };
  public teamPlanIds = ['plan_2','plan_3'];

  public calendarClassName = {
    'SCHEDULE' : 'bg-twitter',
    'FAIL' : 'bg-danger',
    'POSTED' : 'bg-success',
    'QUEUE' : 'bg-info',
    'DRAFT' : 'bg-draft',
  };

  public trialDays = 60;

  public dropdownHeight = [
    "count_1",
    "count_2",
    "count_3",
    "count_4",
    "count_5",
    "count_more_than_5",
  ];

  currentSubscription = null;
  skipFreeTrialCouponCode = '25OFF';
  skipFreeTrialText = 'Subscribe Now, You get 25% OFF';

  hashtagGenerateModal = new BehaviorSubject<any>({
    isVisible: false,
    imageUrl: '',
    isVideo : false
  });
  isDirectMultiUpload = new BehaviorSubject(false);
  
  constructor(
    private socialAccountService: SocialAccountService,
    private store: Store,
    private spinnerService: NgxSpinnerService,
    private helper: HelperService,
    private http: HttpClient,
    private notificationServices: NzNotificationService,
    private userService: UserService,
    private _joyride:JoyrideService,
    private titleService: Title
  ) {
    console.log("-->sharedService constructor need_tour",this.need_tour)
  }

  public resetHashTagGenerateModal(){
    this.hashtagGenerateModal.next({
      isVisible: false,
      imageUrl: '',
      isVideo : false
    })
  }

  public getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  public chunkString(str, len): any[] {
    const input = str.trim().split(' ');
    let index = 0;
    const output: any = [];
    output[index] = '';
    input.forEach((word) => {
      const temp = `${output[index]} ${word}`.trim();
      if (temp.length <= len) {
        output[index] = temp;
      } else {
        index++;
        output[index] = word;
      }
    });
    return output;
  }

  getProvider(selectedAccounts): string {
    if (
      selectedAccounts.length &&
      selectedAccounts.some((acc) => acc.provider === this.instagramProvider)
    ) {
      return this.instagramProvider;
    } else if (
      selectedAccounts.length &&
      !selectedAccounts.some(
        (acc) => acc.provider === this.instagramProvider
      ) &&
      selectedAccounts.some((acc) => acc.provider === this.twitterProvider)
    ) {
      return this.twitterProvider;
    } else if (
      selectedAccounts.length &&
      !selectedAccounts.some(
        (acc) => acc.provider === this.instagramProvider
      ) &&
      !selectedAccounts.some((acc) => acc.provider === this.twitterProvider) &&
      selectedAccounts.some((acc) => acc.provider === this.linkedinProvider)
    ) {
      return this.linkedinProvider;
    } else if (
      selectedAccounts.length &&
      !selectedAccounts.some(
        (acc) => acc.provider === this.instagramProvider
      ) &&
      !selectedAccounts.some((acc) => acc.provider === this.twitterProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.linkedinProvider) &&
      selectedAccounts.some((acc) => acc.provider === this.facebookProvider)
    ) {
      return this.facebookProvider;
    } else if (
      selectedAccounts.length &&
      !selectedAccounts.some(
        (acc) => acc.provider === this.instagramProvider
      ) &&
      !selectedAccounts.some((acc) => acc.provider === this.twitterProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.linkedinProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.facebookProvider) &&
      selectedAccounts.some((acc) => acc.provider === this.youtubeProvider)
    ) {
      return this.youtubeProvider;
    } else if (
      selectedAccounts.length &&
      !selectedAccounts.some(
        (acc) => acc.provider === this.instagramProvider
      ) &&
      !selectedAccounts.some((acc) => acc.provider === this.twitterProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.linkedinProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.facebookProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.youtubeProvider) &&
      selectedAccounts.some((acc) => acc.provider === this.tiktokProvider)
    ) {
      return this.tiktokProvider;
    } else if (
      selectedAccounts.length &&
      !selectedAccounts.some((acc) => acc.provider === this.instagramProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.twitterProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.linkedinProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.facebookProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.youtubeProvider) &&
      !selectedAccounts.some((acc) => acc.provider === this.tiktokProvider) &&
      selectedAccounts.some((acc) => acc.provider === this.threadsProvider)
    ) {
      return this.threadsProvider;
    } else {
      return '';
    }
  }

  getContentLength(accountProvider): number {
    return AccountTypes.getObjectAccountRequest(accountProvider)
      .maxContentLength;
  }

  getCommentLength(accountProvider): number {
    return AccountTypes.getObjectAccountRequest(accountProvider)
      .maxCommentLength;
  }

  getTitleLength(accountProvider): number {
    return AccountTypes.getObjectAccountRequest(accountProvider).maxTitleLength;
  }

  getAllAccounts(): Promise<any> {
    return new Promise((resolve) => {
      this.isAccountsLoading = true;
      this.socialAccountService
        .getSocialAccount(this.store.selectSnapshot(AuthState.user).id)
        .subscribe(
          (res) => {
            this.isAccountsLoading = false;
            if (res.code === 200) {
              resolve(res.data);
            }
          },
          (error) => {
            this.isAccountsLoading = false;
          }
        );
    });
  }

  getPlanDetails(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_plan_details'));
  }

  showLoading() {
    this.spinnerService.show();
  }

  hideLoading() {
    this.spinnerService.hide();
  }

  timeAgo(dateTime) {
    return moment(dateTime).fromNow();
  }

  getTotalMatrixCounts(analyticData, metric): number {
    if (analyticData && analyticData.length > 0) {
      return analyticData
        .map((item) => (item.data[metric] ? item.data[metric] : 0))
        .reduce((prev, curr) => prev + curr, 0);
    } else {
      return 0;
    }
  }

  getPlusMinus(current, previous) {
    var symbol = '';
    if (current > previous) {
      symbol = '+';
    } else if (current < previous) {
      symbol = '+';
    }
    return symbol;
  }

  linkify(text, platform) {
    const reUrl =
      /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const reHash = /(?:\s|^)?#[A-Za-z0-9\-\.\_]+(?:\s|$)/g;

    var hashTag = 'https://twitter.com/hashtag';

    if (platform == this.facebookProvider) {
      hashTag = 'https://www.facebook.com/hashtag';
    } else if (platform == this.instagramProvider) {
      hashTag = 'https://www.instagram.com/explore/tags';
    } else if (platform == this.twitterProvider) {
      hashTag = 'https://twitter.com/hashtag';
    } else {
      hashTag = 'https://twitter.com/hashtag';
    }

    return text
      ?.replace(reUrl, (url) => `<a href="${url}" target="_blank">${url}</a>`)
      .replace(
        reHash,
        (hash) =>
          `<a href="${hashTag}/${hash
            .replace('#', '')
            .trim()}" target="_blank">${hash}</a>`
      );
  }

  sortByKeyAsc(array, key) {
    return array.sort(function (a, b) {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  sortByKeyDesc(array, key) {
    return array
      .sort(function (a, b) {
        const x = a[key];
        const y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      })
      .reverse();
  }

  getPercentage(value, total) {
    return ((value / total) * 100).toFixed(0);
  }

  displayAmount(number) {
    return parseFloat(number).toFixed(2);
  }

  displayNotification(type: string, title, discription) {

    // public messageTypeSuccess = 'success';
    // public messageTypeInfo = 'info';
    // public messageTypeError = 'error';
    // public defaultMessageError = 'Something went wrong';
    // public defaultPermissionError = 'Access Denied';
    // public defaultMessageSuccess = 'Success';



    // const ant-notification-success
    let customerClass;

    switch(type){
      case this.messageTypeSuccess:
        customerClass = 'ant-notification-success'
        break;
      case this.messageTypeInfo:
        customerClass = 'ant-notification-info'
        break;
      case this.messageTypeError:
        customerClass = 'ant-notification-danger'
        break;
      case this.defaultMessageError:
        customerClass = 'ant-notification-danger'
        break;
      case this.defaultPermissionError:
        customerClass = 'ant-notification-danger'
        break;
      case this.defaultMessageSuccess:
        customerClass = 'ant-notification-success'
        break;
      default:
        customerClass = 'ant-notification-success'
    }
     this.notificationServices.create(type, title, discription, {
      //nzDuration: 5000,
      nzDuration: 0,
      nzPauseOnHover: false,
      nzPlacement: 'bottomRight',
      nzClass:customerClass
    });

    // console.log("not",not);

    // this.notificationServices.remove();
  }

  createTemplate(postvalue) {
    return this.http.post(
      this.helper.createAPIUrl('create_template'),
      postvalue
    );
  }

  getAllTemplate() {
    return this.http.get(this.helper.createAPIUrl('get_all_template'));
  }

  deleteTemplate(postvalue) {
    return this.http.post(
      this.helper.createAPIUrl('delete_template'),
      postvalue
    );
  }

  updateTemplateData(postvalue) {
    return this.http.post(
      this.helper.createAPIUrl('update_template'),
      postvalue
    );
  }
  getWidth() {
    let width = window.innerWidth;

    if (width >= 767) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    // console.log("width",width);

    if (width >= 992) {
      this.isTablet = false;
    } else {
      this.isTablet = true;
    }

    // console.log("isTablet",this.isTablet);
    // console.log("isMobile",this.isMobile);


  }

  getTopNotification(): void {
    if (
      this.store.selectSnapshot(AuthState).user !== null &&
      (this.store.selectSnapshot(SubscriptionState).subscription !== null ||
        (this.store.selectSnapshot(AuthState.user).free_user &&
          this.store.selectSnapshot(AuthState.user).free_user !== null) ||
        this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null)
    ) {
      // cancel previous http call
      if (this.httpSubscriptionNotification !== null) {
        this.httpSubscriptionNotification.unsubscribe();
      }
      this.httpSubscriptionNotification = this.userService
        .getTopNotification()
        .subscribe((res) => {
          if (res.code === 200) {
            this.notifications = res.data.notification;
            this.notificationCount = res.data.unreadCount;
            this.unreadInboxCount = res.data.unreadInboxCount;
            this.reconnectCount = res.data.reconnectAccount;
          }
        },error => {
            console.error("getTopNotification error",error)
        });
    }
  }

  setRememberMe(): void {
    var rememberMe = localStorage.getItem('rememberCurrentUser');
    var email = localStorage.getItem('ofa_email');
    var password = localStorage.getItem('ofa_password');

    localStorage.clear();
    localStorage.setItem('rememberCurrentUser', rememberMe);
    localStorage.setItem('ofa_email', email);
    localStorage.setItem('ofa_password', password);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  formattedName(name) {
    //var newName = name.replace(/_/g, ' ');
    //newName = newName.toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase())
    return name.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key) {
      return key.toUpperCase()
    });
  }

  can(permissionName){
    //public permissions = this.store.selectSnapshot(TeamState.permissions);
    //console.log("can : ",this.permissions);
    let is_super_admin = this.store.selectSnapshot(AuthState.user)?.is_super_admin;
    this.permissions = this.store.selectSnapshot(TeamState.permissions);
    if(is_super_admin){ return true; }
    return this.permissions?.indexOf(permissionName) !== -1;
  }

  getTeamList(){
    this.teamLists =  this.store.selectSnapshot(TeamState).teams;
    return this.teamLists;
  }

  platformName(platform){
      if (platform == this.youtubeProvider) {
          return "YouTube";
      } else if (platform == this.twitterProvider) {
        return this.xProvider;
      }else{
          return platform
      }
  }

  timeAge(dateTime) {
    moment.locale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: '1s',
        ss: '%ss',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1M',
        MM: '%dM',
        y: '1Y',
        yy: '%dY'
      }
    })
    return moment(dateTime, '', 'en').fromNow(true);
  }

  getCurrentPlan(){

    this.currentPlan = this.store.selectSnapshot(SubscriptionState.subscription)?.plan;
    //console.log("shared services getCurrentPlan1 :",this.currentPlan)

    if(this.store.selectSnapshot(AuthState.user)?.free_user &&
      this.store.selectSnapshot(AuthState.user).free_user !== null){
      this.currentPlan = this.store.selectSnapshot(AuthState.user)?.free_user;
      //console.log("shared services getCurrentPlan2 :",this.currentPlan)
    }

    if(this.store.selectSnapshot(InAppPurchaseState)?.inAppPurchase !== null){
      this.currentPlan = this.store.selectSnapshot(InAppPurchaseState)?.inAppPurchase;
      //console.log("shared services getCurrentPlan3 :",this.currentPlan)
    }

    //console.log("shared services getCurrentPlan last :",this.store.selectSnapshot(SubscriptionState.subscription))

    return this.currentPlan;
  }


  canAccessTeam(){
    let user = this.store.selectSnapshot(AuthState.user);
    this.getCurrentPlan();
    // if(user.is_super_admin && this.teamPlanIds.includes(this.currentPlan?.plan_id)){
    if(this.teamPlanIds.includes(this.currentPlan?.plan_id)){
      return true;
    }
    return false;
  }

  onlyAccessAdmin(){
    let user = this.store.selectSnapshot(AuthState.user);
    if(user.is_super_admin){
      return true;
    }
    return false;
  }

/**
 * Get formated date
 * timestamp will be in miliseconds
 * @param timestamp
 * @returns
 */
  getFormattedDate(timestamp){
    let date = new Date(timestamp);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let year = date.getFullYear();
    let hour = ("0"+(date.getHours() > 12 ? date.getHours() - 12 : date.getHours())).slice(-2);
    let ampm = date.getHours() >= 12 ? "PM" : "AM";
    let minutes = ("0" + date.getMinutes()).slice(-2);
    return month+'/'+day+'/'+year+' '+hour + ":" + minutes + ' '+ampm;
  }

  openNzSelect(event){
    // console.log(event,"Open close call");
    const bodyElement = document.querySelector("body");
    bodyElement?.classList?.toggle("openNzSelect");
  }

  endTour(){
    console.log('sharedService endTour');
    this._joyride.closeTour();
    this.need_tour = 0;
    localStorage.removeItem('need_tour');
    this.userService.endTour().subscribe(res => {
      if (res.code === 200) {
          document.body.classList.toggle('isTourActive');
      }else{
        this.displayNotification(this.messageTypeError, this.defaultMessageError, res.message);
      }
    }, error => {
      console.error('endTour :',error)
    });
  }

  setPageTitle(title){
    if(title){
      this.titleService.setTitle(title + " | " + this.appName);
    }else{
      this.titleService.setTitle(this.appName);
    }
  }


  removeFacebookMentions(content,facebookMentions): string {
    console.log('removeFacebookMentions facebookMentions', facebookMentions);
    if (facebookMentions.length > 0) {
        const nameCounts: { [key: string]: number } = {};
        facebookMentions.forEach((facebookMention: { name: string, id: string }) => {
          const name = facebookMention.name;
          if (!nameCounts[name]) {
            nameCounts[name] = 0;
          }
          const pos = content.indexOf(name, nameCounts[name]);
          if (pos !== -1) {
            content = content.slice(0, pos) + content.slice(pos + name.length);
          }
        });
    }
    return content;
  }

  removeUsernames(content,usernames): string {
    //for Instagram, Twitter
    console.log('removeUsernames usernames', usernames);
    if (usernames.length > 0) {
      usernames.forEach((user,i) => {
        //content = content.replace(new RegExp(this.escapeRegExp(user), 'g'), '');
        content = content.replace(new RegExp(usernames[i]), '');
      });
    }
    return content;
  }

  public getPostContentByPlatform(content, provider,facebookMentions=[],instagramMentions=[],twitterMentions=[],threadsMentions=[],tiktokMentions=[]): string {
    console.log(`getPostContentByPlatform ${provider} before content`, content);
    console.log(`getPostContentByPlatform ${provider} before content facebookMentions`, facebookMentions);
    console.log(`getPostContentByPlatform ${provider} before content instagramMentions`, instagramMentions);
    console.log(`getPostContentByPlatform ${provider} before content twitterMentions`, twitterMentions);
    console.log(`getPostContentByPlatform ${provider} before content threadsMentions`, threadsMentions);
    console.log(`getPostContentByPlatform ${provider} before content tiktokMentions`, tiktokMentions);
    if (provider === this.facebookProvider) {

      if (facebookMentions.length > 0) {
        const nameCounts: { [key: string]: number } = {};
        facebookMentions.forEach((facebookMention: { name: string, id: string }) => {
          const name = facebookMention.name;
          const id = facebookMention.id;

          if (!nameCounts[name]) {
            nameCounts[name] = 0;
          }

          const pos = content.indexOf(name, nameCounts[name]);
          if (pos !== -1) {
            //content = content.slice(0, pos) + `@${id}` + content.slice(pos + name.length);
            //content = content.slice(0, pos) + `<a href='https://facebook.com/${id}' target='_blank'>`+name+"</a>" + content.slice(pos + name.length);
            content = content.slice(0, pos) + `<a href='https://facebook.com/${id}' target='_blank'>`+name+"</a>" + content.slice(pos + name.length);
          }
        });
      }

      //instagram
      content = this.removeUsernames(content,instagramMentions);
      //twitter
      content = this.removeUsernames(content,twitterMentions);
      //threads
      content = this.removeUsernames(content,threadsMentions);
      //tiktok
      content = this.removeUsernames(content,tiktokMentions);

    } else if (provider === this.instagramProvider) {
      //facebook
      content = this.removeFacebookMentions(content, facebookMentions);
      //twitter
      content = this.removeUsernames(content, twitterMentions);
      //threads
      content = this.removeUsernames(content, threadsMentions);
      //tiktok
      content = this.removeUsernames(content, tiktokMentions);

    }else if (provider === this.twitterProvider) {
      //facebook
      content = this.removeFacebookMentions(content,facebookMentions);
      //instagram
      content = this.removeUsernames(content,instagramMentions);
      //threads
      content = this.removeUsernames(content,threadsMentions);
      //tiktok
      content = this.removeUsernames(content,tiktokMentions);

    } else if (provider === this.threadsProvider) {
      //facebook
      content = this.removeFacebookMentions(content,facebookMentions);
      //instagram
      content = this.removeUsernames(content,instagramMentions);
      //twitter
      content = this.removeUsernames(content,twitterMentions);
      //tiktok
      content = this.removeUsernames(content,tiktokMentions);

    }else if (provider === this.tiktokProvider) {
      //facebook
      content = this.removeFacebookMentions(content,facebookMentions);
      //instagram
      content = this.removeUsernames(content,instagramMentions);
      //twitter
      content = this.removeUsernames(content,twitterMentions);
      //threads
      content = this.removeUsernames(content,threadsMentions);

    } else {
      //facebook
      content = this.removeFacebookMentions(content,facebookMentions);
      //instagram
      content = this.removeUsernames(content,instagramMentions);
      //twitter
      content = this.removeUsernames(content,twitterMentions);
      //threads
      content = this.removeUsernames(content,threadsMentions);
      //tiktok
      content = this.removeUsernames(content,tiktokMentions);
    }

    console.log(`getPostContentByPlatform ${provider} after content:`, content);
    content = this.linkifyContent(content,provider)
    return content;
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  linkifyContent(text, platform) {
    if (!text) {
      return text;
    }

    const reMention = /(?:\s|^)(@\w+)(?:\s|$)/g;

    let mainUrl = 'https://twitter.com/';
    if (platform === this.instagramProvider) {
      mainUrl = 'https://www.instagram.com/';
    } else if (platform === this.twitterProvider) {
      mainUrl = 'https://twitter.com/';
    }

    return text.replace(reMention, (mention, user) => {
      let username = platform === this.instagramProvider ? user.replace(/^@/, '') : user;
      return `<a href="${mainUrl}${username}" target="_blank">${mention}</a>`;
    });
  }

  getImageDimensions(file: NzUploadFile): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const nativeFile = file.originFileObj || file as unknown as File;
      const reader = new FileReader();

      reader.readAsDataURL(nativeFile);
      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          resolve({ width, height });
        };

        img.onerror = (error) => {
          reject(error);
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  validateImageRatios(height: number, width: number, fileName: string, mediaData: any[]): { errors: { message: string, fileName: string }[], mediaData: any[] } {
    const imageRatio = width / height;
    let errors: { message: string, fileName: string }[] = [];
    const tolerance = 0.001; // Tolerance for floating-point comparison
    const imgRatioArray = ["1:1", "1.91:1", "4:5"];

    const convertRatioStringToNumber = (ratioStr: string): number => {
        const [numerator, denominator] = ratioStr.split(':').map(Number);
        return numerator / denominator;
    };

    const isValidForCurrentProvider = imgRatioArray.some(ratioStr => {
      const predefinedRatio = convertRatioStringToNumber(ratioStr);
      console.log("predefinedRatio , imageRatio",predefinedRatio, imageRatio);
      return Math.abs(imageRatio - predefinedRatio) < tolerance;
    });

    if (!isValidForCurrentProvider) {
      errors.push({
          message: `Image has an invalid ratio. Supported ratios: ${imgRatioArray.join(', ')}`,
          fileName: fileName
      });

      /* mediaData.forEach((fi, index) => {
        let isValidMatch = fi.media_file === fileName;
        if (fi.isValid === undefined || (fi.isValid === true && isValidMatch)) {
          mediaData[index] = { ...fi, isValid: !isValidMatch };
        }
      }); */
    }
    console.log("validateImageRatio Before mediaData",mediaData);
    mediaData.forEach((fi, index) => {
      if (!fi.height || !fi.width) {
        mediaData[index] = { ...fi, height, width };
      }
      
      if (!isValidForCurrentProvider){
        let isValidMatch = fi.media_file === fileName;
        if (fi.isValid === undefined || (fi.isValid === true && isValidMatch)) {
          mediaData[index] = { ...fi, isValid: !isValidMatch,height,width};
        }
      }
    });
    console.log("validateImageRatio After mediaData",mediaData);
    return { errors, mediaData };
  }

}
