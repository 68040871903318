import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class TiktokService {

  constructor(private http: HttpClient, private helper: HelperService) { }

  tiktokConnect(): Observable<any>{
      return this.http.get(this.helper.createAPIUrl('tiktok_request_token'));
  }

  tiktokAuthenticatedRequests(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('tiktok_auth'), postValue);
  }

  getTiktokAccount(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_tiktok_accounts'));
  }

  getTikTokCreatorInfo(userSocialConnectionId): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_tiktok_creator_info') + '?social_id=' + userSocialConnectionId);
  }

}
