import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SubscriptionClear, SubscriptionGet, SubscriptionUpdate } from '../actions/subscription.action';


export interface SubscriptionStateModel {
  subscription: null ;
  subscriptionCancelAtOnce: boolean;
  isFirstPurchase: boolean;
}

@State<SubscriptionStateModel>({
  name: 'subscription',
  defaults: {
    subscription: null,
    subscriptionCancelAtOnce: false,
    isFirstPurchase: false
  }
})

@Injectable()

export class SubscriptionState {
  @Selector()
  static subscription(state: SubscriptionStateModel): any {
    return state.subscription;
  }

  @Action(SubscriptionGet)
  SubscriptionGet(subscriptionState: StateContext<SubscriptionStateModel>, action: SubscriptionGet): void {
    subscriptionState.setState({
      subscription: action.payload.subscription,
      subscriptionCancelAtOnce: action.payload.subscriptionCancelAtOnce,
      isFirstPurchase: action.payload.isFirstPurchase,
    });
  }

  @Action(SubscriptionClear)
  SubscriptionClear(subscriptionState: StateContext<SubscriptionStateModel>): void {
    subscriptionState.setState({
      subscription: null,
      subscriptionCancelAtOnce: false,
      isFirstPurchase: false
    });
  }

  @Action(SubscriptionUpdate)
  SubscriptionUpdate(subscriptionState: StateContext<SubscriptionStateModel>, action: SubscriptionUpdate): void {
    subscriptionState.patchState({
      subscription: action.payload,
    });
  }
}
