import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { APIPATH, general } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() {
  }

  static createFormData(object: object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    // tslint:disable-next-line:forin
    for (const property in object) {
      if (!object.hasOwnProperty(property) || !object[property]) {
        if (object[property] !== null && object[property] !== '' && object[property] !== 0) {
          continue;
        }
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, moment(object[property]).format('YYYY-MM-DD'));
      } else if (object[property] instanceof moment) {
        formData.append(formKey, moment(object[property]).format());
      } else if (object[property] === null || object[property] === '') {
        formData.append(formKey, '');
      } else if (object[property] === 0) {
        formData.append(formKey, '0');
      } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
        this.createFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

  createAPIUrl(endpoint) {
    return environment.api_url + general.current_api_version + '/' + APIPATH[endpoint];
  }

  checkPermission(permissionList, permission) {
    return permissionList[permission] ? permissionList[permission] : false;
  }

}
