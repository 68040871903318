import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TeamGet } from '../actions/team.action';

export interface TeamStateModel {
  role: any | null;
  permissions: any | null;
  organizations: any | null;
  organizationId: any | null;
  customPermissions: any | null;
  teams: any | null;
}

@State<TeamStateModel>({
  name: 'team',
  defaults: {
    role: null,
    permissions: null,
    organizations: null,
    organizationId: null,
    customPermissions: null,
    teams: null
  }
})
@Injectable()

export class TeamState {
  @Selector()
  static role(state: TeamStateModel): any {
    return state.role;
  }

  @Selector()
  static permissions(state: TeamStateModel): string {
    return state.permissions;
  }

  @Selector()
  static organizations(state: TeamStateModel): string {
    return state.organizations;
  }

  @Selector()
  static organizationId(state: TeamStateModel): string {
    return state.organizationId;
  }

  @Selector()
  static customPermissions(state: TeamStateModel): string {
    return state.customPermissions;
  }

  @Selector()
  static teams(state: TeamStateModel): string {
    return state.teams;
  }

  @Action(TeamGet)
  TeamGet(teamState: StateContext<TeamStateModel>, action: TeamGet): void {
    teamState.setState({
      role: action.payload.role,
      permissions: action.payload.permissions,
      organizations: action.payload.organizations,
      organizationId: action.payload.organizationId,
      customPermissions: action.payload.customPermissions,
      teams: action.payload.teams,
    });
  }
}
