import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SubscriptionClear } from '../../action-state/actions/subscription.action';
import { Logout } from 'src/app/action-state/actions/auth.action';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngxs/store';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { AppWarningModalComponent } from '../app-warning-modal/app-warning-modal.component';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.less']
})
export class CancelSubscriptionComponent implements OnInit {
  passwordVisible = false;
  passwordForm: FormGroup;

  constructor(private fb: FormBuilder,
    public sharedService: SharedService,
    private modal: NzModalService,
    private spinnerService: NgxSpinnerService,
    private messageService: NzMessageService,
    private store: Store,
    private userService: UserService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      password: [null, [Validators.required]]
    });
  }

  cancelSubscription(): void {
    this.passwordForm.controls.password.markAsDirty();
    this.passwordForm.controls.password.updateValueAndValidity();

    if (this.passwordForm.valid) {
      const password = this.passwordForm.controls.password.value;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.modal.confirm({
        nzTitle: 'Cancel Subscription?',
        nzClassName: 'app_warning_modal_main',
        nzContent: AppWarningModalComponent,
        nzComponentParams: {
          message: "Are you sure you want to cancel your Subscription?"
        },
        nzOkText: 'Yes, Cancel It',
        nzIconType: null,
        nzOkDanger: true,
        nzCentered: true,
        nzCancelText: 'Cancel',
        nzKeyboard: false,
        nzMaskClosable: false,
        nzFooter: null,
        nzAutofocus: null,
        nzOnOk: () => {
          this.spinnerService.show();
          this.userService.cancelSubscription({ password, timezone }).subscribe(res => {
            this.spinnerService.hide();
            if (res.code === 200) {
              localStorage.clear();
              this.modal.closeAll();
              if (res.toast === true) {
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
              }
              this.router.navigate(['/', 'auth', 'login']);
              this.store.dispatch(new Logout());
              this.store.dispatch(new SubscriptionClear());
            }
            if (res.code === 500 && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
            }
          }, error => {
            this.spinnerService.hide();
          });
        }
      });
    }
  }
}
