import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SharedService } from './services/shared.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit{
  constructor(public sharedService: SharedService, private cdr: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    window.addEventListener('beforeunload', function (event) {
      event.stopImmediatePropagation();
    });
  }
}
