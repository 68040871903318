import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { AuthService } from "../../services/auth.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from "../../services/shared.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { UserService } from "../../services/user.service";
import { WasabiService } from "../../services/wasabi.service";
import { HelperService } from "../../services/helper.service";
import { TeamsService } from "../../services/teams.service";
import { FormBuilder } from "@angular/forms";
import { SocialAccountService } from "../../services/social-account.service";
import { TeamState } from "../../action-state/states/team.state";

@Component({
  selector: 'app-select-team',
  templateUrl: './app-select-team.component.html',
  styleUrls: ['./app-select-team.component.less']
})

export class appSelectTeamComponent implements OnInit {

  visible = false;
  teams: any = [];
  team_id = null;
  selectedTeam = null;

  constructor(
    public router: Router,
    public store: Store,
    public sharedService: SharedService,
  ) {
  }

  ngOnInit(): void {

    this.teams = this.store.selectSnapshot(TeamState).teams;
    this.team_id = parseInt(localStorage.getItem('team_id'));

    this.selectedTeam = this.teams.filter((item) => {
      if (item.id === this.team_id) { return item; }
    });

    if (this.selectedTeam.length == 0) {
      this.selectedTeam = this.teams[0];
      localStorage.setItem('team_id', this.selectedTeam?.id);
    }

    //console.log("this.selectedTeam. : ",this.selectedTeam);
  }

  openTeamModal(): void {
    this.visible = true;
  }

  closeTeamModal(event) {
    this.visible = false
  }

  switchTeam(event) {
    localStorage.setItem('team_id', this.team_id);
    window.location.reload();
    this.visible = false
  }
}
