<!-- <ng-container *ngIf="isSpinning">
  <img src="../../../assets/images/loading2.png" alt="">
</ng-container> -->

<!-- <ng-container *ngIf="!isSpinning"> -->
  <ng-container *ngIf="thumbUrl === null">
    <img alt="One For All Social" nz-image [class]="class" *ngIf="!isVideo" [nzSrc]="url" [nzDisablePreview]="!preview"
    [nzPlaceholder]="placeholder"  (load)="mediaLoaded()"/>
  </ng-container>
  <ng-container *ngIf="thumbUrl !== null">
    <!--<img nz-image *ngIf="!isVideo" [class]="class" [nzSrc]="url" [nzDisablePreview]="!preview" [nzPlaceholder]="placeholder"  />-->
    <ng-container *ngIf="!isVideo">
      <nz-skeleton-element *ngIf="isLoading || isLoading2" nzType="image" class="isMediaLoading" [nzActive]="true"></nz-skeleton-element>
      <!-- <nz-skeleton-element nzType="image" class="isMediaLoading" [nzActive]="true"></nz-skeleton-element> -->
      <img alt="One For All Social" nz-image *ngIf="!isVideo && !isLoading" [class]="class" [nzDisablePreview]="!preview"
           [ngStyle]="{display:isLoading2?'none':''}" [nzSrc]="url"
           (load)="mediaLoaded()" />
    </ng-container>
  </ng-container>

  <ng-container *ngIf="url !== null && isVideo && thumbUrl === null">
    <video [src]="url" [class]="class" *ngIf="class === 'manage-post-video'"></video>
    <!-- <video [src]="url" [class]="class" *ngIf="class.indexOf('manage-post-video') >= 0"></video> -->
    <video [src]="url" [class]="class" controls *ngIf="class !== 'manage-post-video' && !autoplay"></video>
    <video autoplay [src]="url" [class]="class" controls loop *ngIf="class !== 'manage-post-video' && autoplay"></video>
  </ng-container>

  <ng-container *ngIf="url !== null && isVideo && thumbUrl !== null">
    <!--<img nz-image [class]="class" [nzSrc]="url" [nzPlaceholder]="placeholder" [nzDisablePreview]="!preview" />-->
    <ng-container>
      <nz-skeleton-element *ngIf="isLoading || isLoading2" class="isMediaLoading" nzType="image" [nzActive]="true"></nz-skeleton-element>
      <img alt="One For All Social" nz-image *ngIf="!isLoading" [class]="class" [nzDisablePreview]="!preview"
           [ngStyle]="{display:isLoading2?'none':''}" [nzSrc]="url"
           (load)="mediaLoaded()" />
    </ng-container>
  </ng-container>

<!-- </ng-container> -->


<!--<ng-container>
  <nz-skeleton-element *ngIf="isLoading || isLoading2" nzType="image" [nzActive]="true"></nz-skeleton-element>
  <img nz-image *ngIf="!isVideo && !isLoading" [class]="class" [nzDisablePreview]="!preview"
       [ngStyle]="{display:isLoading2?'none':''}" [nzSrc]="url"
       (load)="mediaLoaded()" />
</ng-container>-->
