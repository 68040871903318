import { Location } from '@angular/common';
import {Component, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/constants';
import { HelperService } from 'src/app/services/helper.service';
import { SharedService } from 'src/app/services/shared.service';
import { SocialAccountService } from 'src/app/services/social-account.service';
import { TeamsService } from 'src/app/services/teams.service';
import {Subject} from "rxjs";


@Component({
  selector: 'app-member-invite',
  templateUrl: './member-invite.component.html',
  styleUrls: ['./member-invite.component.less']
})
export class MemberInviteComponent implements OnInit {
  @Input() editId = 0;
  @Output() inviteChange = new Subject();
  orgMemberId = 0;
  inviteForm: any;
  step1Form: any;
  currentStep = 0;
  enabledSocialNetworks: any = [];
  droppedItems = [];
  selectedMedias: any = [];
  socialAccounts: any = [];
  memberType = '';
  customRoles = [];
  roleValue = '';
  teams: any = [];
  isDisabled = false;
  roleOptions = [
    { label: 'Admin', value: 'Admin', disabled: false, tooltip_text: 'Admins have all permissions. They are the only ones allowed to invite new team members and change team member roles.' },
    { label: 'Editor', value: 'Editor', disabled: false, tooltip_text: 'Editors can do everything moderators can do, plus they can approve and publish content, export reports and users, They can\'t invite team members' },
    { label: 'Moderator', value: 'Moderator', disabled: false, tooltip_text: 'Moderators can manage Inbox and Monitoring content. They can schedule drafts for approval but cannot publish content.' },
    { label: 'Guest', value: 'Guest', disabled: false, tooltip_text: 'Guests are read-only mode. They may view all areas, but they cannot edit or publish content.' },
  ];
  switchState = true;
  assignedSocialData: any;
  authoritiesList = [];
  event = null;
  emailCheck: any = '';
  isLoading = false;
  isLoadType = '';
  isLoadingUpdate = false;
  deleteDraftPost = false;
  userId: any = '';
  memberId = '';
  isLoader = false;

  permissionModal = false;
  categories = [];
  selectedPermissionIds = [];
  isLoadingInvite = false;
  isLoadingPermission = false;
  allSocialChecked = false;
  selectedSocialAccounts = [];

  inviteModal = false;
  team_ids = [];

  selectedTeams = [];
  socialAccountLoading = false;
  dummyItems = Array(5).fill(0).map((x,i)=>i);

  socialAction = false;

  specificSocialAccount = null;
  isLoadingTeam = false;



  constructor(
    private formBuilder: FormBuilder,
    private accountService: SocialAccountService,
    public sharedService: SharedService,
    private router: Router,
    private teamService: TeamsService,
    private _location: Location,
    private spinnerService:NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.getData();
    if (this.editId == 0) {
      this.initForm();
    }

    this.getTeams();
    this.getPermissions();
  }

  initForm() {

    this.inviteForm = this.formBuilder.group({
      users : this.formBuilder.array([])
    });
    this.addUser();
  }

  async getData() {

    this.isLoadingUpdate = true;
    this.isLoading = true;
    //this.getSocialActiveAccount();

    if (this.editId > 0) {
      this.assignedSocialData = await this.editMemberData();
      this.fillSocialRoles();
    } else {
      /*this.socialAccounts.forEach(element => {
        this.enabledSocialNetworks[element.id] = true;
      });*/
    }

    this.isLoadingUpdate = false;
    this.isLoading = false;
  }

  get users() {
    return this.inviteForm.controls["users"] as FormArray;
  }

  addUser() {
    this.validateInviteForm();
    if (this.inviteForm.valid) {
      const userForm = this.formBuilder.group({
        name: ['', [Validators.required,Validators.pattern("^[a-zA-Z]+[\-'\s]?[a-zA-Z ]+$")]],
        email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
      });
      this.users.push(userForm);
    }
  }


  deleteUser(index: number) {
    this.users.removeAt(index);
  }

  getTeams() {
    this.isLoadingTeam = true;
    this.teamService.getTeamList().subscribe((res) => {
      this.teams = res.data;
      this.isLoadingTeam = false;
    }, error => {
      this.isLoadingTeam = false;
    });
  }

  fillSocialRoles() {

    this.memberType = this.assignedSocialData.role.role.role_name;
    this.orgMemberId = this.assignedSocialData.role.id;
    this.roleValue = this.memberType;
    this.selectedRole(this.memberType);
    this.authoritiesList = this.assignedSocialData.access;
    let assignedMedias = this.assignedSocialData.access.map(d => d['social_connection_id']);
    this.socialAccounts.forEach(element => {
      let flag = assignedMedias.includes(element.id) ? true : false;
      this.enabledSocialNetworks[element.id] = flag;
    });

    if (this.memberType == 'Custom') {
      this.assignedSocialData.access.forEach(element => {
        if (this.enabledSocialNetworks[element['social_connection_id']]) {
          this.customRoles[element['social_connection_id']] = element['role']['role_name'];
        }
      });
    }
  }

  editMemberData() {
    const postValue = {
      user_id: this.editId
    };
    this.spinnerService.show();
    return new Promise(resolve => {
      this.teamService.getMemberData(postValue).subscribe((res) => {
        resolve(res.data);
        this.spinnerService.hide();
      }, error => {
        resolve([]);
        this.spinnerService.hide();
      });
    });
  }

  getSocialActiveAccount(team_ids) {


    this.socialAccounts = [];
    if(team_ids){
      this.socialAccountLoading = true;
      this.accountService.getSocialActiveAccountByTeam(team_ids).subscribe((res) => {
        this.socialAccountLoading = false;
        this.socialAccounts = res.data;
        this.socialAccounts.forEach(account => {
          account.social_access_id = 0;
        });
      }, error => {
        this.socialAccountLoading = false;
      });

    }
  }

  async updateInvite(step) {
    if (step == 0) {
      let allPageStatus = [];
      this.enabledSocialNetworks.forEach(element => {

        if (!element) {
          allPageStatus.push(false);
        }
      });

      if (allPageStatus.length == this.socialAccounts.length) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social profile', 'Please select atleast one social profile');
        return;
      }

      // reset selected items
      this.droppedItems = [];
      let errorMedia = this.showSelectedSocialMedia();
      if (errorMedia.length > 0) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Please select role', 'Please select atleast one role for ' + errorMedia[0].name + ' (' + errorMedia[0].provider + ') Profile');
        return;
      }

      // this logic for set in team on step2
      let selectedKeys = [];
      this.selectedMedias.forEach((ele, key) => {
        this.authoritiesList.forEach((element) => {
          if (ele.id == element.social_connection_id) {
            if (this.droppedItems[element.team_id] != null) {
              this.droppedItems[element.team_id].push(ele);
              selectedKeys.push(key);
            } else {
              this.droppedItems[element.team_id] = [ele];
              selectedKeys.push(key);
            }
          }
        });
      });

      // remove media from selected medias
      this.selectedMedias = this.selectedMedias.filter(function (value, index) {
        return selectedKeys.indexOf(index) == -1;
      });

      this.currentStep = 1;
      this.teams = await this.getTeams();

    } else {
      const formData = new FormData();
      formData.append('organization_role', this.memberType);
      formData.append('role', this.roleValue);
      formData.append('team_accounts', JSON.stringify(this.droppedItems));
      formData.append('user_id', this.editId.toString());
      formData.append('org_member_id', this.orgMemberId.toString());
      if (this.teams.length == 0) {
        this.isLoadingUpdate = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Create team', 'Please create team first!');
        this.isLoadingUpdate = false;
        return;
      }

      if (this.selectedMedias.length > 0) {
        this.isLoadingUpdate = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social profile', 'Please move all selected social profile in team');
        this.isLoadingUpdate = false;
        return;
      }

      var totalProfiles = 0;
      this.droppedItems.forEach((ele) => {
        if (ele.length > 0) {
          totalProfiles += ele.length;
        }
      });

      if (totalProfiles == 0) {
        this.isLoadingUpdate = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Assign account', 'Atleast assign one account to the team');
        this.isLoadingUpdate = false;
        return;
      }

      this.isLoadingUpdate = true;
      this.teamService.updateInvitation(formData).subscribe((res: any) => {
        if (res.code == 200) {
          if (res.data.draftPostCount > 0) {
            this.isLoadingUpdate = false;
            this.deleteDraftPost = true;
            this.userId = this.editId.toString();
            this.memberId = this.orgMemberId.toString();
          } else {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
            this.router.navigate(['/organization/teams']);
            this.isLoadingUpdate = false;
            this.deleteDraftPost = false;
          }
        } else {
          this.isLoadingUpdate = true;
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
      }, error => {
        this.isLoadingUpdate = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, Constants.ERROR_MSG);
      });
    }
  }

  updateTeams(event: any): void {
    console.log("updateTeams : ",event);
    this.selectedTeams = event;
    this.socialAccountLoading = false;
    console.log("updateTeams team_ids: ",this.team_ids);
    let teamIdsArray = this.selectedTeams.map(item=>{ return item.id; });

    let teamIds='';
    if(teamIdsArray.length>0){
      teamIds = teamIdsArray.join(",");
    }

    console.log("updateTeams teamIds: ",teamIds);
    this.getSocialActiveAccount(teamIds);
  }

  validateInviteForm(){
    for (const i in this.inviteForm.controls) {
      if (this.inviteForm.controls[i] instanceof FormArray) {
        //console.log("sendInvites FormArray")
        for (const j in this.inviteForm.controls[i].controls) {
          for (const jj in this.inviteForm.controls[i].controls[j].controls) {
            this.inviteForm.controls[i].controls[j].controls[jj].markAsDirty();
            this.inviteForm.controls[i].controls[j].controls[jj].updateValueAndValidity();
          }
        }
      }else{
        this.inviteForm.controls[i].markAsDirty();
        this.inviteForm.controls[i].updateValueAndValidity();
      }
    }
  }

  sendInvites(){

    this.validateInviteForm();
    if (this.inviteForm.valid) {

      if(this.selectedTeams.length === 0){
        this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please select at least one team');
        return;
      }

      // only checked
      if(this.selectedSocialAccounts.length === 0){
        this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please select at least one social profile');
        return;
      }

      let social_account_ids, social_access_ids = [];
      social_account_ids = this.selectedSocialAccounts.map(account=> { return account.id });
      social_access_ids = this.selectedSocialAccounts.map(account=> { return account.social_access_id });

      var team_ids = this.selectedTeams.map(element=> { return element.id });
      const inviteFormData = new FormData();
      inviteFormData.append('users', JSON.stringify(this.inviteForm.value.users));
      inviteFormData.append('team_ids', JSON.stringify(team_ids));
      inviteFormData.append('social_account_ids', JSON.stringify(social_account_ids));
      inviteFormData.append('social_access_ids', JSON.stringify(social_access_ids));

      this.isLoadingInvite = true;
      this.teamService.saveInvitation(inviteFormData).subscribe((res) => {
          this.isLoadingInvite = false;
          console.log("Submit saveInvitation res: ", res);
          if (res.code === 200) {

            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
            //window.location.href = '/organization/teams';
            this.team_ids = this.selectedTeams.map(element=> { return element.id });
            this.permissionModal = true;
            //this.router.navigate(['/', 'organization', 'teams']);
          }

          if (res.code === 500 && res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
          }

        },(error) => {
          console.log("Submit Error : ", error);
          this.isLoadingInvite = false;
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, error);
        }
      );

    }else{
      this.sharedService.displayNotification(this.sharedService.messageTypeInfo, this.sharedService.defaultMessageError, 'Please resolve errors');
      return;
    }

  }


  async saveInviteAccess(step) {

    for (const i in this.step1Form.controls) {
      this.step1Form.controls[i].markAsDirty();
      this.step1Form.controls[i].updateValueAndValidity();
    }

    if (step == 0) {
      if (this.step1Form.valid) {
        const value = this.step1Form.value;
        const postValue: any = HelperService.createFormData(value);

        // check member's email
        let memberResult: any = await new Promise(resolve => {
          this.isLoading = true;
          this.teamService.checkMember(postValue).subscribe((res) => {
            if (res.code == 400) {
              this.isLoading = false;
              resolve(res.message);
            } else {
              resolve('');
            }
          });
        });

        if (memberResult != '') {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, memberResult);
          return;
        } else {

          let allPageStatus = [];
          this.enabledSocialNetworks.forEach(element => {
            if (!element) {
              allPageStatus.push(false);
            }
          });

          if (allPageStatus.length == this.socialAccounts.length) {
            this.isLoading = true;
            this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social profile', 'Please select atleast one social profile');
            this.isLoading = false;
            return;
          }

          // reset selected items
          this.droppedItems = [];
          let errorMedia = this.showSelectedSocialMedia();
          if (errorMedia.length > 0) {
            this.isLoading = true;
            this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Select one role', 'Please select atleast one role for ' + errorMedia[0].name + ' (' + errorMedia[0].provider + ') Profile');
            this.isLoading = false;
            return;
          }
          this.currentStep = 1;
          this.isLoading = true;
          this.teams = await this.getTeams();
          this.isLoading = false;
        }
      }
    } else {
      const value = this.step1Form.value;
      this.emailCheck = value.email;
      const formData = new FormData();
      formData.append('email', value.email);
      formData.append('organization_role', this.memberType);
      formData.append('role', this.roleValue);
      formData.append('team_accounts', JSON.stringify(this.droppedItems));


      if (this.teams.length == 0) {
        this.isLoading = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, 'Please create team first!');
        this.isLoading = false;
        return;
      }

      if (this.selectedMedias.length > 0) {
        this.isLoading = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social profile', 'Please move all selected social profile in team');
        this.isLoading = false;
        return;
      }

      var totalProfiles = 0;
      this.droppedItems.forEach((ele) => {
        if (ele.length > 0) {
          totalProfiles += ele.length;
        }
      });

      if (totalProfiles == 0) {
        this.isLoading = true;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Assign account', 'Atleast assign one account to the team');
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      this.teamService.saveInvitation(formData).subscribe((res: any) => {
        if (res.code == 200) {
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
        this.isLoading = false;
      }, error => {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, Constants.ERROR_MSG);
        this.isLoading = true;

      });
    }
  }

  showSelectedSocialMedia() {
    this.isLoadingUpdate = true;
    let errosMedia = [];
    this.selectedMedias = [];
    if (this.roleValue == 'Admin' || this.roleValue == 'Editor' || this.roleValue == 'Moderator' || this.roleValue == 'Guest') {
      this.socialAccounts.forEach(element => {
        if (this.enabledSocialNetworks[element.id]) {
          element.role = this.roleValue;
          this.selectedMedias.push(element);
        }
      });

    } else {
      this.socialAccounts.forEach(element => {
        if (this.enabledSocialNetworks[element.id]) {
          const role = this.customRoles[element.id];
          if (role == undefined) {
            errosMedia.push({ name: element.name, provider: element.provider });
          } else {
            element.role = role;
          }
          this.selectedMedias.push(element);
        }
      });
    }
    this.isLoadingUpdate = false;
    return errosMedia;
  }

  toggleProfiles(state) {
    this.enabledSocialNetworks.forEach((element, index) => {
      this.enabledSocialNetworks[index] = state;
    });

    this.switchState = state;
  }

  clickSwitch(state, id) {

    let status = true;
    this.enabledSocialNetworks[id] = state;
    this.enabledSocialNetworks.forEach(ele => {
      if (!ele) {
        status = false;
      }
    })
    this.switchState = status;

  }

  changeOrganizationRole(role) {

    this.memberType = role;
    this.roleValue = role;
    if (role == 'Admin') {

      this.isDisabled = true;
      this.socialAccounts.forEach(element => {
        this.enabledSocialNetworks[element.id] = true;
      });
      this.disbleoptions(role);
    } else if (role == 'Member') {
      this.isDisabled = false;
      this.disbleoptions('Editor');
    } else {
      this.isDisabled = false;
      // for custom role we need to disable admin option
      this.disbleoptions('Editor');
    }
  }

  selectedRole(e: any) {
    if (e == 'Admin') {
      this.memberType = 'Admin';
      this.disbleoptions(e);
      if (this.editId != 0) {
        this.isDisabled = true
      }
    } else if (e == 'Editor' || e == 'Moderator' || e == 'Guest') {
      this.memberType = 'Member';
      this.disbleoptions(e);
    } else {
      this.memberType = 'Custom';
      // for custom option will be disable for admin
      this.disbleoptions('Editor');
    }
  }

  disbleoptions(role) {

    if (role == 'Admin') {
      this.roleOptions.forEach((element, index) => {
        if (element.label != 'Admin') {
          this.roleOptions[index].disabled = true;
        } else {
          this.roleOptions[index].disabled = false;
        }
      });
    }

    if (role == 'Editor' || role == 'Moderator' || role == 'Guest') {
      this.roleOptions.forEach((element, index) => {
        if (element.label == 'Admin') {
          this.roleOptions[index].disabled = true;
        } else {
          this.roleOptions[index].disabled = false;
        }
      });
    }
  }

  onItemDrop(e: any, team) {
    this.event = e;
    const count = this.droppedItems[team.id] ? this.droppedItems[team.id].length : 0;

    if (count == 0) {
      this.droppedItems[team.id] = [e.dragData];

    } else {
      const data = this.droppedItems[team.id].concat([e.dragData]);
      this.droppedItems[team.id] = data;

    }
    const index = this.selectedMedias.findIndex(edata => edata?.id == e.dragData?.id);
    if (index >= 0) {
      this.selectedMedias.splice(index, 1);
    }

  }

  onDragEnds(item, id) {
    this.removeItem(item, this.droppedItems[id]);
  }

  removeItem(item: any, list: Array<any>) {
    let index = list.findIndex(x => x?.id === item?.id);
    list.splice(index, 1);

    /*Move back to Selected Profile*/
    var existsInTeam = false;
    this.droppedItems.forEach(function(droppedItem) {
      droppedItem.forEach(function(subItem) {
        if(item?.id === subItem.id){
          existsInTeam = true;
        }
      });
    });
    if(!existsInTeam){
      this.selectedMedias.push(item);
    }
  }

  backStep() {
    this.currentStep = 0;
  }

  closeModal() {
    this._location.back();
  }

  deleteDeaftPostClose() {
    this.deleteDraftPost = false
  }

  deleteDraftPosts() {

    var postValue = {
      userId: this.userId,
      orgMemberId: this.memberId
    }

    this.isLoader = true;

    this.teamService.deleteDraftPost(postValue).subscribe((res: any) => {
      if (res.code === 200) {
        this.isLoader = false;
        this.deleteDraftPost = false;
        this.router.navigate(['/organization/teams']);
      } else {
        this.deleteDraftPost = true;
      }
    })

  }

  redirectToTeam(){

    window.location.href = '/organization/teams';
    this.getTeams();
    return false;
  }


  /*Permission*/
  getPermissions() {
    this.teamService.getPermissions().subscribe((res) => {
      this.categories = res.data;
    }, error => {});
  }

  updatePermissions(event: any): void {
    console.log("updatePermissions : ",event);
    this.selectedPermissionIds = [];
    this.categories.forEach((category, index) => {
      category.permissions.filter((item) => {
        if (item.is_checked) { this.selectedPermissionIds.push(item.id); }
      });
    });

  }

  cancelPermissionModal(){
    this.permissionModal = false;
    this.resetInvite();
    this.router.navigate(['/', 'organization', 'teams']);
  }

  resetInvite(){
    this.inviteChange.next({saved:true});
    this.inviteForm.reset();
    this.selectedTeams=[];
    this.selectedPermissionIds = [];
    //this.getSocialActiveAccount();
  }

  savePermissions(){

    var team_ids = this.selectedTeams.map(element=> { return element.id });
    let postData = {
      'users' : JSON.stringify(this.inviteForm.value.users),
      'team_ids' : JSON.stringify(team_ids),
      'permissions' : this.selectedPermissionIds,

    };
    this.isLoadingPermission = true;
    this.teamService.savePermissions(postData).subscribe((res: any) => {

      //console.log("res : ",res);
      this.isLoadingPermission = false;

      if (res.code === 200) {
        this.permissionModal = false;
        this.resetInvite();
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      }

      if (res.code === 500) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }

    }, error => {
      this.isLoadingPermission = false;
    });
  }


  selectAllSocialAccount(event){
    if(this.allSocialChecked){
      this.socialAccounts.forEach(account => { account.is_checked = true; });
    }else{
      this.socialAccounts.forEach(account => { account.is_checked = false; });
    }
    this.allSocialChecked = this.socialAccounts.every((item: any) => item.is_checked);
    this.selectedSocialAccounts = this.socialAccounts.filter(account=> { if(account.is_checked) return account });
  }

  selectSocialAccount(event){
    this.allSocialChecked = this.socialAccounts.every((item: any) => item.is_checked);
    this.selectedSocialAccounts = this.socialAccounts.filter(account=> { if(account.is_checked) return account });
  }

  permissionChange(event){
    if (event.code === 200) {
      this.permissionModal = false;
      this.resetInvite();

      if(event.message){
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, event.message);
      }
    }

    if (event.code === 500) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, event.message);
    }
  }

  permissionCancelEvent(){
    this.permissionModal = false;
    this.resetInvite();
  }


  socialProfileAction(accountData){
    this.socialAction = true;
    this.specificSocialAccount = accountData;
  }
  handleCancel(): void {
    this.socialAction = false;
  }



}
