import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class PinterestService {

  constructor(private http: HttpClient, private helper: HelperService) { }

  pinterestConnect(): Observable<any>{
      return this.http.get(this.helper.createAPIUrl('pinterest_request_token'));
  }

  pinterestAuthenticatedRequests(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('pinterest_auth'), postValue);
  }

  getPinterestAccount(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_pinterest_accounts'));
  }

  pinterestReconnect(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('reconnect_pinterest_account'), postValue);
  }

  pinterestBoards(socialId): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_pinterest_boards') + '?social_id=' + socialId);
  }

  createPinterestBoard(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('create_pinterest_board'), postValue);
  }

}
