import { Component, OnInit } from '@angular/core';
import {SharedService} from "../../../services/shared.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-inactive-organization',
  templateUrl: './inactive-organization.component.html',
  styleUrls: ['./inactive-organization.component.less']
})
export class InactiveOrganizationComponent implements OnInit {

  constructor(public sharedService: SharedService,
              private route: ActivatedRoute,) {
    this.sharedService.setPageTitle(this.route.snapshot.data['title']);
  }

  ngOnInit(): void {
    console.log("inactive-organization works!");
  }

}
