<div class="auth_waiting" *ngIf="!isVisible">
  <bs-app-auth-loading></bs-app-auth-loading>
</div>

<nz-modal [nzClassName]="'auth_selection_modal'" [nzAutofocus]="null" [nzMaskClosable]="false" [(nzVisible)]="isVisible"
          [nzTitle]=null [nzFooter]="null">
  <!-- [nzTitle]="getTitle()"  (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
  [nzOkDisabled]="(selectedAccount.length===0 && selectedGroup.length === 0)|| isLoading"
  [nzOkText]="isLoading?'Saving...':'Ok'" [nzCancelDisabled]="isLoading"  -->
  <ng-container *nzModalContent>

    <!-- <nz-tabset class="tabset_default">
      <nz-tab nzTitle="Pages">
        <app-select-account
          [accounts]="data"
          (accountUpdate)="getAccount($event)"
          [type]="'account_connect'"
          [platform]="sharedService.facebookProvider"
        ></app-select-account>
      </nz-tab>
      <nz-tab nzTitle="Groups">
        <app-select-account
          [accounts]="groups"
          (accountUpdate)="getGroups($event)"
          [type]="'account_connect'"
          [platform]="sharedService.facebookProvider"></app-select-account>
      </nz-tab>
    </nz-tabset> -->


    <!-- without groups-->
    <app-select-account
      [accounts]="data"
      (accountUpdate)="getAccount($event)"
      [type]="'account_connect'"
      [platform]="sharedService.facebookProvider"
    ></app-select-account>

    <div class="auth_selection_footer ant-modal-footer">

      <div *ngIf="data.length !== disabledAccounts.length" class="auth_selection_header">
        <strong >Selected ({{selectedAccount.length}})</strong>
        <a href="javascript:;" (click)="selectAll()">Select All</a>
      </div>
      <div *ngIf="data.length === disabledAccounts.length" class="no_account_available">
        No account available for connect
      </div>

      <div class="auth_selection_action">
        <button class="ant-btn" (click)="handleCancel()" [disabled]="isLoading">Cancel</button>
        <button class="ant-btn ant-btn-primary" (click)="handleOk()" [disabled]="(selectedAccount.length===0)|| isLoading" >
          {{isLoading?'Saving...':'Ok'}}
        </button>
      </div>
    </div>

  </ng-container>
</nz-modal>
