import * as moment from 'moment';
//moment.tz.setDefault("UTC");

export let line_chart_sentiment = {
  series: [
    {
      name: '',
      data: []
    },
    {
      name: '',
      data: []
    }
  ],
  chart: {
    height: 350,
    type: 'line',
    dropShadow: {
      enabled: false,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  tooltip: {
    enabled: true,
    followCursor: true,
    shared: true,
    intersect: false,
    onDatasetHover: {
      highlightDataSeries: true,
    },
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false,
    enabledOnSeries: undefined,
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'Messages by Sentiment',
    align: 'left'
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  markers: {
    size: 1
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      }
    },
    min: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    max: moment().subtract(90, 'd').format('YYYY-MM-DD'),
  },
  yaxis: {
    title: {
      text: ''
    },
    labels: {
      minWidth: 20,
      formatter: function (val) {
        return val ? val.toFixed(0) : 0;
      }
    },
    startAtZero: true,
    min: 0
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'right',
    fontFamily: 'Poppins',
    fontSize: '14px',
    markers: {
      fillColors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
      radius: 2,
    },
    labels: {
      colors: '#1d203cab',
    },
  },
};

export let line_chart_engagement = {
  series: [
    {
      name: '',
      data: []
    },
    {
      name: '',
      data: []
    }
  ],
  chart: {
    height: 350,
    type: 'line',
    id: 'line2',
    // dropShadow: {
    //   enabled: true,
    //   color: '#000',
    //   top: 18,
    //   left: 7,
    //   blur: 10,
    //   opacity: 0.2
    // },
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  tooltip: {
    x: {
      format: "dd MMM yyyy"
    },
    enabled: true,
    followCursor: true,
    shared: true,
    intersect: false,
    onDatasetHover: {
      highlightDataSeries: true,
    }
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: undefined,
  },
  stroke: {
    curve: 'smooth',
    colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0']
  },
  title: {
    text: 'Engagement By Activities',
    align: 'left'
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  markers: {
    size: 0
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: function (timestamp, value) {
        console.log("like timestamp : ",timestamp);
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      }
    },
    min: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    max: moment().subtract(90, 'd').format('YYYY-MM-DD'),
  },
  yaxis: {
    title: {
      text: ''
    },
    labels: {
      minWidth: 30,
      formatter: function (val) {
        return val ? val.toFixed(0) : 0;
      }
    },
    startAtZero: true,
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'right',
    fontFamily: 'Poppins',
    fontSize: '14px',
    markers: {
      fillColors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
      radius: 2,
    },
    labels: {
      colors: '#1d203cab',
    },
  },
};

export let line_chart_twitter_growth = {
  series: [
    {
      name: '',
      data: []
    },
    {
      name: '',
      data: []
    },
    {
      name: '',
      data: []
    },
  ],
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  chart: {
    height: 350,
    type: 'line',
    id: 'line_growth_twitter',
    dropShadow: {
      enabled: false,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  tooltip: {
    enabled: true,
    followCursor: true,
    shared: true,
    intersect: false,
    onDatasetHover: {
      highlightDataSeries: true,
    }
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: undefined,
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'Audience Growth',
    align: 'left'
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  markers: {
    size: 0
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: function (timestamp) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      }
    },
    min: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    max: moment().subtract(90, 'd').format('YYYY-MM-DD'),
  },
  yaxis: {
    title: {
      text: ''
    },
    labels: {
      minWidth: 20,
      formatter: function (val) {
        return val ? val.toFixed(0) : 0;
      }
    },
    startAtZero: true,
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'right',
    fontFamily: 'Poppins',
    fontSize: '14px',
    markers: {
      fillColors: ['#5267EF', '#ACAABB'],
      radius: 2,
    },
    labels: {
      colors: '#1d203cab',
    }
  },
};

export let area_chart_audience_growth = {
  series: [
    {
      name: '',
      data: []
    },
    {
      name: '',
      data: []
    }
  ],
  chart: {
    height: 350,
    type: 'area',
    id: 'area',
    dropShadow: {
      enabled: false,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'Audience Growth',
    align: 'left'
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  markers: {
    size: 0
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      }
    },
    min: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    max: moment().subtract(90, 'd').format('YYYY-MM-DD'),
  },
  yaxis: {
    title: {
      text: ''
    },
    labels: {
      minWidth: 20,
      formatter: function (val) {
        return val ? val.toFixed(0) : 0;
      }
    },
    startAtZero: true,
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'right',
    fontFamily: 'Poppins',
    fontSize: '14px',
    markers: {
      fillColors: ['#5267EF', '#ACAABB'],
      radius: 2,
    },
    labels: {
      colors: '#1d203cab',
    }
  },
};

export let column_chart_page_impressions = {
  series: [],
  chart: {
    height: 350,
    type: 'area',
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    colors: ['#58A7DC', '#CCC9FD', '#52BDA0']
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      }
    },
    min: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    max: moment().subtract(90, 'd').format('YYYY-MM-DD'),
  },
  yaxis: {
    labels: {
      minWidth: 20,
    }
  },
  title: {
    text: 'Page Impressions Report',
    align: 'left'
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'right',
    fontFamily: 'Poppins',
    fontSize: '14px',
    markers: {
      fillColors: ['#58A7DC', '#CCC9FD', '#52BDA0'],
      radius: 2,
    },
    labels: {
      colors: '#1d203cab',
    }
  },
};

export function resetChartXaxis(isLinkedin?: boolean) {
  const xaxis = {
    type: 'datetime',
    tickPlacement: 'on',
    labels: {
      formatter: function (timestamp) {
        if (isLinkedin) {
          return getFormattedDate(timestamp/ 1000);
          // return moment.unix(timestamp / 1000).format('MMM DD, yyyy');
        } else {
          return getFormattedDate(timestamp);
          // return moment.unix(timestamp).format('MMM DD, yyyy');  // The formatter function overrides format property
        }
      }
    },
    min: moment().subtract(1, 'd').format('YYYY-MM-DD'),
    max: moment().subtract(90, 'd').format('YYYY-MM-DD'),
  };

  return xaxis;
}

function getFormattedDate(timestamp){
  let date = new Date(timestamp * 1000);
  let month = date.toLocaleString('default', { month: 'short' });
  let day = date.getDate();
  let year = date.getFullYear();
  return month+' '+day+', '+year;
}

export let radial_bar_chart1 = {
  series: [],
  chart: {
    type: "radialBar",
    width: '300',
    height: '300',
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      hollow: {
        size: '85%',
      },
      track: {
        background: "#DDF0FD",
      },
      dataLabels: {
        show: false,
      }
    }
  },
  fill: {
    colors: ['#232C89'],
  },
  labels: []
};

export let radial_bar_chart2 = {
  series: [],
  chart: {
    type: "radialBar",
    width: '270',
    height: '270',
  },
  plotOptions: {
    radialBar: {
      startAngle: -120,
      endAngle: 120,
      hollow: {
        size: '78%',
      },
      track: {
        background: "#DDF0FD",
      },
      dataLabels: {
        show: false,
      }
    }
  },
  fill: {
    colors: ['#676DF4'],
  },
  labels: []
};

export let bubble_chart = {
  series: [],
  chart: {
    height: 270,
    type: "bubble",
    toolbar: {
      show: true,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false
  },
  fill: {
    colors: ['#5267EF', '#ACAABB']
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm"
    }
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'right',
    fontFamily: 'Poppins',
    fontSize: '14px',
    markers: {
      fillColors: ['#5267EF', '#ACAABB'],
      radius: 2,
    },
    labels: {
      colors: '#1d203cab',
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      },
    },
  },
};

export let summary_line_page_summary_chart = {
  series: [],
  chart: {
    height: 120,
    type: "area",
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false
  },
  fill: {
    colors: ['#ECF7FE', '#ECF7FE', '#ECF7FE']
  },
  stroke: {
    curve: "smooth",
    colors: ['#5062F2', '#58A7DC', '#FEC361']
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: false,
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      },
    },
  },
  yaxis: {
    labels: {
      show: false,
    }
  },
  grid: {
    show: false
  },
  legend: {
    show: false
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm"
    }
  }
};

export let summary_line_page_post_summary_chart = {
  series: [],
  chart: {
    height: 120,
    type: "area",
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false
  },
  fill: {
    colors: ['#ECF7FE', '#ECF7FE', '#ECF7FE']
  },
  stroke: {
    curve: "smooth",
    colors: ['#5062F2', '#58A7DC', '#FEC361']
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: false,
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      },
    },
  },
  yaxis: {
    labels: {
      show: false,
    }
  },
  grid: {
    show: false
  },
  legend: {
    show: false
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm"
    }
  }
};

export let summary_line_page_like_summary_chart = {
  series: [],
  chart: {
    height: 120,
    type: "area",
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false
  },
  fill: {
    colors: ['#ECF7FE', '#ECF7FE', '#ECF7FE']
  },
  stroke: {
    curve: "smooth",
    colors: ['#5062F2', '#58A7DC', '#FEC361']
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: false,
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      },
    },
  },
  yaxis: {
    labels: {
      show: false,
    }
  },
  grid: {
    show: false
  },
  legend: {
    show: false
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm"
    }
  }
};

export let summary_line_page_post_reach_summary_chart = {
  series: [],
  chart: {
    height: 120,
    type: "area",
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
      },
    }
  },
  colors: ['#5062F2', '#CCC9FD', '#58A7DC', '#52BDA0'],
  dataLabels: {
    enabled: false
  },
  fill: {
    colors: ['#ECF7FE', '#ECF7FE', '#ECF7FE']
  },
  stroke: {
    curve: "smooth",
    colors: ['#5062F2', '#58A7DC', '#FEC361']
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: false,
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      },
    },
  },
  yaxis: {
    labels: {
      show: false,
    }
  },
  grid: {
    show: false
  },
  legend: {
    show: false
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm"
    }
  }
};

export let insight_weekly_bar_chart = {

  series: [],
  chart: {
    type: "bar",
    width: '100%',
    height: 220,
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '50%',
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#7254FF'],
  fill: {
    opacity: 0.6,
  },
  states: {
    hover: {
      filter: {
        type: 'lighten'
      }
    }
  },
  xaxis: {
    categories: [
      "S",
      "M",
      "T",
      "W",
      "T",
      "F",
      "S",
    ],
    labels: {
      style: {
        colors: ['#1d203cab'],
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  },
  yaxis: {
    labels: {
      style: {
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
      formatter: function (val) {
        if (val == "Infinity") {
          return 10;
        } else if (val == 0) {
          return 0;
        } else {
          return val.toFixed(2);
        }
      }
    }
  },
  grid: {
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  responsive: [{
    breakpoint: 1024,
    options: {
      chart: {
        width: '100%',
        height: 220,
      },
    },
  }],
};

export let insight_post_type_bar_chart = {
  series: [
    {
      name: "basic",
      data: [2, 1, 2]
    }
  ],
  chart: {
    type: "bar",
    // width: 650,
    width: '100%',
    height: 220,
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '22px',
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#3045F7'],
  fill: {
    opacity: 0.6,
  },
  states: {
    hover: {
      filter: {
        type: 'lighten'
      }
    }
  },
  xaxis: {
    categories: [
      "Image",
      "Video",
      "Text"
    ],
    labels: {
      style: {
        colors: ['#1d203cab'],
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  },
  yaxis: {
    labels: {
      style: {
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
      formatter: function (val) {
        if (val == "Infinity") {
          return 10;
        } else if (val == 0) {
          return 0;
        } else {
          return val.toFixed(2);
        }
      }
    }
  },
  grid: {
    yaxis: {

      // lines: {
      //     show: false
      // }
    }
  },
  responsive: [{
    breakpoint: 1024,
    options: {
      chart: {
        width: '100%',
        height: 220,
      },
    },
  }],
};

export let insight_frequency_post_bar_chart = {
  series: [],
  chart: {
    type: "bar",
    // width: 650,
    width: '100%',
    height: 220,
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '37px',
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#58A7DC'],
  fill: {
    opacity: 0.6,
  },
  states: {
    hover: {
      filter: {
        type: 'lighten'
      }
    }
  },
  xaxis: {
    categories: [
      ['Once', 'daily'],
      ['Twice', 'daily'],
      ['Three times', 'daily'],
      ['Seven times', 'daily'],
      ['Nine times', 'daily'],
    ],
    labels: {
      style: {
        colors: ['#1d203cab'],
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  },
  yaxis: {
    labels: {
      style: {
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
      formatter: function (val) {
        if (val == "Infinity") {
          return 10;
        } else if (val == 0) {
          return 0;
        } else {
          return val.toFixed(2);
        }
      }
    }
  },
  grid: {
    yaxis: {

      // lines: {
      //     show: false
      // }
    }
  },
  responsive: [{
    breakpoint: 1024,
    options: {
      chart: {
        width: '100%',
        height: 220,
      },
    },
  }],
};

export let audience_gender_chart = {
  series: [],
  chart: {
    height: 350,
    type: "donut"
  },
  stroke: {
    width: 0,
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val.toFixed(2) + "%"
    },
  },
  legend: {
    show: true,
  },
  colors: ['#232C89', '#AFDDFB', '#58A7DC'],
  labels: ["Male", "Female", "Other"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        }
      }
    }
  ],
};

export let dashboard_engagement_chart = {

  series: [],
  chart: {
    height: 200,
    type: "area",
    toolbar: {
      show: false,
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#232C89', '#3A91CC'],
  fill: {
    colors: ['#232C89', '#3A91CC']
  },
  stroke: {
    curve: "smooth",
    colors: ['#232C89', '#3A91CC']
  },
  xaxis: {
    type: "datetime",
    labels: {
      formatter: function (timestamp, value) {
        return moment.unix(timestamp).format('MMM DD');  // The formatter function overrides format property
      },
    },
  },
  legend: {
    horizontalAlign: 'right',
    markers: {
      fillColors: ['#232C89', '#3A91CC'],
    },
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm"
    }
  },
};

export let dashboard_weekly_bar_chart = {
  series: [],
  chart: {
    type: "bar",
    height: 250,
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      horizontal: false
    }
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#232C89'],
  states: {
    hover: {
      filter: {
        type: 'lighten'
      }
    }
  },
  xaxis: {
    categories: [
      "S",
      "M",
      "T",
      "W",
      "T",
      "F",
      "S",
    ],
  },
  yaxis: {
    show: false,
  },
  grid: {
    yaxis: {
      lines: {
        show: false
      }
    }
  }
};

export let dashboard_total_post_chart = {

  series: [],
  chart: {
    //height: 500,
    height: 200,
    type: "area",
    toolbar: {
      show: false,
    },
    stacked: true,
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#232C89', '#3A91CC'],
  stroke: {
    curve: "smooth",
    colors: ['#232C89', '#3A91CC']
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
 /* markers: {
    size: 5,
    hover: {
      size: 10
    }
  },*/
  xaxis: {
    categories: [
      "S",
      "M",
      "T",
      "W",
      "T",
      "F",
      "S",
    ],
    //type: "datetime",
    /*type: "category",
    labels: {
      formatter: function (timestamp) {
        let dayOfWeek = moment.unix(timestamp).format('dddd');
        //dayOfWeek = dayOfWeek.charAt(0);
        const date = moment.unix(timestamp).format('MMM DD');
        return `${dayOfWeek} (${date})`;
      },
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexchart_xaxis_label',
      },
      rotate: -90,
      rotateAlways: true,
      // trim: true
      // hideOverlappingLabels: false
    },*/
    //tickAmount: 6,
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        //return val.toFixed(0);
        return parseInt(val);
      }
    },
    //decimalsInFloat: 0,
    //tickAmount: 2,
    //min: 0,
    //max: 100,
  },
  legend: {
    horizontalAlign: 'right',
    markers: {
      fillColors: ['#232C89', '#3A91CC'],
    },
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm"
    },
  },
};


export let audience_age_gender_chart = {
  series: [],
  chart: {
    type: 'bar',
    height: 250,
    //width: 200,
    stacked: true,
    stackType: '100%',
    toolbar: {
      show: false,
    }
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        total: {
          enabled: false,
          offsetX: 0,
          style: {
            fontSize: '13px',
            fontWeight: 900
          }
        }
      }
    },
  },
  colors: ['#232C89', '#AFDDFB', '#58A7DC'],
  dataLabels: {
    enabled: true,
    enabledOnSeries: undefined,
  },
  stroke: {
    width: 1,
    colors: ['#fff']
  },
  xaxis: {
    labels: {
      show: false,
    },
    categories : [],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: true
    }
  },
  grid: {
    show: false,
  },
  fill: {
    opacity: 1
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left',
    offsetX: 40
  }
};
