import { Component, EventEmitter, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'bs-my-work',
  templateUrl: './my-work.component.html',
  styleUrls: ['./my-work.component.less'],
})
export class myWorkComponent {
  assignedPosts = [];
  recentPosts = [];
  selectedTab = 0;
  @Output() selectedTabEvent = new EventEmitter<string>();
  constructor(private teamService: TeamsService, public sharedService: SharedService) {}

  ngOnInit() {
    this.getAssignedPosts();
  }

  getAssignedPosts() {
    this.teamService.getAssignedPosts().subscribe(
      (res) => {
        //console.log('getAssignedPosts : ', res.data);
        if (res.data) {
          let data = res.data;
          this.assignedPosts = data?.assigned;
          this.recentPosts = data?.recent;
        }
      },
      (error) => {}
    );
  }
  tabChanged(index) {
    this.selectedTab = index;
    this.selectedTabEvent.emit(index);
  }
}
