import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {SharedService} from "../services/shared.service";
import {TeamState} from "../action-state/states/team.state";
import {Store} from "@ngxs/store";
import {AuthState} from "../action-state/states/auth.state";

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input() permission:string;
  permissions:any;
  user = this.store.selectSnapshot(AuthState).user;

  constructor(private elmRef: ElementRef,
              public store: Store,
              public sharedService: SharedService,) { }

  ngOnInit() {
    this.permissions = this.store.selectSnapshot(TeamState.permissions);
    //if(!this.can(this.permission)){
    /*console.log("permission check :",this.sharedService.can(this.permission));*/
    //console.log("user is_super_admin: ",this.user.is_super_admin);
    if(this.user.is_super_admin==false) {
      if (!this.sharedService.can(this.permission)) {
        this.elmRef.nativeElement.style.display = "none";
      }
    }
  }

  /*can(permissionName){
    return this.permissions.indexOf(permissionName) !== -1;
  }*/

}
