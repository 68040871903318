import {Component, Input, OnInit} from '@angular/core';
import {SharedService} from "../../services/shared.service";
import { radial_bar_chart1,radial_bar_chart2} from '../../models/charts-options.model';
@Component({
  selector: 'app-overview-chart',
  templateUrl: './overview-chart.component.html',
  styleUrls: ['./overview-chart.component.less']
})
export class OverviewChartComponent implements OnInit {

  @Input() type:any='';
  @Input() currentCount:any=0;
  @Input() previousCount:any=0;
  @Input() tooltipTitle:any='';

  currentCountPercentage:any=0;
  previousCountPercentage:any=0;

  radialBarChart1:any;
  radialBarChart2:any;
  isDisplayChart=false;

  constructor(public sharedService: SharedService) { }

  ngOnInit(): void {
    this.getChatOptions();
    /*console.log("OverviewChartComponent currentCount : ",this.currentCount)
    console.log("OverviewChartComponent previousCount : ",this.previousCount)*/
  }

  getChatOptions(){
    this.radialBarChart1 = radial_bar_chart1;
    this.radialBarChart2 = radial_bar_chart2;

    /*console.log("currentCount 1: ",this.currentCount);
    console.log("previousCount 1: ",this.previousCount);*/
    this.currentCountPercentage = this.getPercentage(this.currentCount);
    this.previousCountPercentage = this.getPercentage(this.previousCount);

    /*console.log("currentCount 2: ",this.currentCount);
    console.log("previousCount 2: ",this.previousCount);*/

    //convert to percentages
    this.isDisplayChart = true;
  }

  getPercentage(num){
    let total = this.getDynamicTotal(this.currentCount,this.previousCount);
    let percentage = (num/total) * 100;
    //return Math.round(percentage);
    return percentage;
  }

  getDynamicTotal(chart1,chart2){
    let total = Math.max(chart1,chart2);
    let finalTotal;

    if (total <= 100) {
      finalTotal = 100;
    } else if (total <= 500) {
      finalTotal = 500;
    } else if (total <= 1000) {
      finalTotal = 1000;
    } else if (total <= 5000) {
      finalTotal = 5000;
    } else if (total <= 10000) {
      finalTotal = 10000;
    } else if (total <= 100000) {
      finalTotal = 100000;
    } else if (total <= 1000000) {
      finalTotal = 1000000;
    } else if (total <= 10000000) {
      finalTotal = 10000000;
    } else {
      finalTotal = total;
    }

    /*console.log("getDynamicTotal total: ",total);
    console.log("getDynamicTotal : ",finalTotal);*/

    return finalTotal;
  }
}
