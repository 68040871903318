import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SubscriptionState } from '../action-state/states/subscription.state';
import { AuthState } from '../action-state/states/auth.state';
import { InAppPurchaseState } from '../action-state/states/in-app-purchase.state';
import { TeamState } from '../action-state/states/team.state';
import {SharedService} from "../services/shared.service";

@Injectable()
export class SubscriptionTeamGuard implements CanActivate {

  constructor(private router: Router,
              private store: Store,
              private sharedService:SharedService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.store.selectSnapshot(SubscriptionState).subscription !== null ||
      (this.store.selectSnapshot(AuthState.user).free_user && this.store.selectSnapshot(AuthState.user).free_user !== null) ||
      this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null
    ) {

      /*if(this.store.selectSnapshot(TeamState).role == 'Super Admin'){
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }*/

      if(this.sharedService.canAccessTeam()){
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }

    } else {

      this.router.navigate(['/', 'subscription']);
      return false;
    }
  }
}
