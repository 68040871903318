<div class="add-card">
  <form nz-form [formGroup]="stripeForm" (ngSubmit)="handleOk()">
    <div class="form-group">
      <input nz-input type="text" formControlName="name" placeholder="Enter name" autocomplete="off">
      <div *ngIf="stripeForm.get('name').hasError('required') && stripeForm.get('name').touched" class="error-color">
        Name is Required
      </div>
      <div
        *ngIf="!stripeForm.get('name').hasError('required') && stripeForm.get('name').hasError('pattern') && stripeForm.get('name').touched"
        class="error-color">
        Please Enter Valid Name
      </div>
    </div>
    <div class="form-group">
      <ngx-stripe-card [options]="cardOptions"></ngx-stripe-card>
      <div *ngIf="cardError !== null" class="error-color">
        {{cardError}}
      </div>
    </div>
    <div class="btn-wrap">
      <button nz-button nzType="primary" type="submit" [class.disabled_while_loading]="addCardLoader">Add Card
        <ng-container *ngIf="addCardLoader">
          <span nz-icon nzType="loading"></span>
        </ng-container>
      </button>
    </div>
  </form>
</div>
