<div class="login_main">
  <div class="login_left">
      <div class="login_left_in" style="background-image: url('../../../../assets/images/login_bg.png');"></div>
  </div>
  <div class="login_right">
      <div class="login_right_in">
          <div class="logo_wrp">
              <a routerLink="/auth/login">
                  <img src="../../../../assets/images/logo.png" alt="">
              </a>
          </div>
          <div class="auth_content_main">
              <p>Welcome! Join us in making social media less time consuming!</p>
          </div>
          <div class="auth_form_main">
              <form nz-form [formGroup]="registrationForm" (ngSubmit)="submitForm()" *ngIf="!isLogin">
                  <div class="form_section">

                    <nz-form-item class="email_input">
                          <nz-form-label nzFor="email">Name</nz-form-label>
                        <nz-input-group>
                              <nz-form-control [nzErrorTip]="nameError">
                                  <input nz-input formControlName="name" id="name"/>
                                <ng-template #nameError let-control>
                                  <ng-container *ngIf="control.hasError('required')">
                                    This Name Field is required!
                                  </ng-container>
                                  <ng-container *ngIf="control.hasError('pattern')">
                                    This Name Field is Not Allow Number & Special characters
                                  </ng-container>
                                  <ng-container *ngIf="control.errors?.serverErrors">
                                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverErrors">{{
                                      serverError }}</div>
                                  </ng-container>
                                </ng-template>
                              </nz-form-control>
                          </nz-input-group>
                      </nz-form-item>

                      <nz-form-item class="email_input">
                          <nz-form-label nzFor="email">Email</nz-form-label>
                          <nz-input-group>
                              <nz-form-control [nzErrorTip]="emailError">
                                  <input nz-input formControlName="email" id="email"/>
                                <ng-template #emailError let-control>
                                  <ng-container *ngIf="control.hasError('required')">
                                    This Email Field is required!
                                  </ng-container>
                                  <ng-container *ngIf="control.hasError('email')">
                                    Please Enter Valid Email!
                                  </ng-container>
                                  <ng-container *ngIf="control.errors?.serverErrors">
                                    <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverErrors">{{
                                      serverError }}</div>
                                  </ng-container>
                                </ng-template>
                              </nz-form-control>
                          </nz-input-group>
                      </nz-form-item>
                      <nz-form-item class="email_input password_input">
                          <nz-form-label>Password</nz-form-label>
                          <nz-input-group [nzSuffix]="suffixPasswordTemplateInfo">
                            <nz-form-control [nzErrorTip]="passwordError">
                              <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                                     (click)="updateConfirmValidator()" />
                              <ng-template #passwordError let-control>
                                <ng-container *ngIf="control.hasError('required')">
                                  This Password Field is required!
                                </ng-container>
                                <ng-container *ngIf="control.hasError('minlength')">
                                  Your password needs to be at least 8 characters!
                                </ng-container>
                                <ng-container *ngIf="control.errors?.serverErrors">
                                  <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverErrors">{{
                                    serverError }}</div>
                                </ng-container>
                              </ng-template>
                            </nz-form-control>
                          </nz-input-group>
                        <ng-template #suffixPasswordTemplateInfo>
                          <i *ngIf="!passwordVisible" nz-icon nzType="eye" nzTheme="outline"
                             (click)="passwordVisible = !passwordVisible"></i>
                          <i *ngIf="passwordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
                             (click)="passwordVisible = !passwordVisible"></i>
                        </ng-template>
                      </nz-form-item>

                    <nz-form-item class="email_input password_input">
                          <nz-form-label>Confirm Password</nz-form-label>
                        <nz-input-group [nzSuffix]="suffixConfirmPasswordTemplateInfo">
                          <nz-form-control [nzErrorTip]="passwordConfirmError">
                            <input [type]="confirmPasswordVisible ? 'text' : 'password'" nz-input
                                   formControlName="password_confirmation" />
                            <ng-template #passwordConfirmError let-control>
                              <ng-container *ngIf="control.hasError('required')">
                                This Password Field is required!
                              </ng-container>
                              <ng-container *ngIf="control.hasError('confirm')">
                                Password and Confirm Password must match!
                              </ng-container>
                              <ng-container *ngIf="control.errors?.serverErrors">
                                <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverErrors">{{
                                  serverError }}</div>
                              </ng-container>
                            </ng-template>
                          </nz-form-control>
                        </nz-input-group>
                        <ng-template #suffixConfirmPasswordTemplateInfo>
                          <i *ngIf="!confirmPasswordVisible" nz-icon nzType="eye" nzTheme="outline"
                             (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                          <i *ngIf="confirmPasswordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
                             (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                        </ng-template>
                      </nz-form-item>

                      <div class="action_area">
                          <button type="submit" nz-button nzType="primary" nzBlock>Sign up</button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>
