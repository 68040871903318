import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location, TitleCasePipe } from '@angular/common';

import { AppWarningModalComponent } from 'src/app/component/app-warning-modal/app-warning-modal.component';
import { AuthState } from '../../../action-state/states/auth.state';
import { InAppPurchaseState } from '../../../action-state/states/in-app-purchase.state';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import {ActivatedRoute, Router} from '@angular/router';
import { SharedService } from '../../../services/shared.service';
import { SocialAccountService } from 'src/app/services/social-account.service';
import { Store } from '@ngxs/store';
import { SubscriptionGet } from '../../../action-state/actions/subscription.action';
import { SubscriptionState } from '../../../action-state/states/subscription.state';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.less']
})
export class SubscriptionComponent implements OnInit {
  conciergeForm!:FormGroup;
  isConcierge = false;
  isSeeMore = null;
  // isMobile = false;
  subscriptionType = 'monthly';
  allPlan = [];
  planDetails = [];
  isVisible = false;
  isSocialVisible = false;
  isConfirmLoading = false;
  userId = null;
  allCard = [];
  allAccounts = [];
  selectedPlanId = null;
  selectedPlanName = null;
  selectedPlanAmount = null;
  selectedPlan = null;
  subscription = null;
  subscriptionCancelAtOnce = null;
  socialDowngradeSelection = false;
  //accountSelection = { 'FACEBOOK': '', 'INSTAGRAM': '', 'TWITTER': '', 'LINKEDIN': '', 'YOUTUBE': '', 'TIKTOK': '', 'PINTEREST': '' };
  accountSelection = { 'FACEBOOK': [], 'INSTAGRAM': [], 'TWITTER': [], 'LINKEDIN': [], 'YOUTUBE': [], 'TIKTOK': [], 'PINTEREST': [] };
  inAppPurchase = null;
  inAppPurchaseCancelAtOnce = null;
  selectPlanLoading = false;
  isSubscribing = false;
  isPlanLoading = true;
  dummyItems = Array(3).fill(0).map((x,i)=>i);
  currentPlan = null;
  totalAccountList =[];
  totalAccountSelected = [];
  isFreeTrialSubscribing = false;

  couponCode = '';
  discountedAmount = 0;
  isFirstPurchase=false;
  isContactSubmitModal = false;

  conciergeOptionArray:any = [];
  isDisabled = false;
  isLoadning = false;





  constructor(
    private fb:FormBuilder,
    public sharedService: SharedService,
    private store: Store,
    private userService: UserService,
    private messageService: NzMessageService,
    private router: Router,
    private location: Location,
    public socialService: SocialAccountService,
    private titleCasePipe: TitleCasePipe,
    private modal: NzModalService,
    private route: ActivatedRoute,
  ) {
    this.sharedService.setPageTitle(this.route.snapshot.data['title']);
  }

  ngOnInit(): void {

    this.conciergeForm = this.fb.group({
      features: [],
      notes:[]
    })

    const userdata = this.store.selectSnapshot(AuthState.user);
    this.isFirstPurchase = this.store.selectSnapshot(SubscriptionState).isFirstPurchase;

    if(this.store.selectSnapshot(AuthState.user).free_user && this.store.selectSnapshot(AuthState.user).free_user !== null){
      this.router.navigate(['/']);
      return;
    }

    this.userId = userdata.id;
    this.currentPlan =this.store.selectSnapshot(SubscriptionState.subscription)?.plan;

    this.subscription = this.store.selectSnapshot(SubscriptionState).subscription;
    this.subscriptionCancelAtOnce = this.store.selectSnapshot(SubscriptionState).subscriptionCancelAtOnce;

    this.inAppPurchase = this.store.selectSnapshot(InAppPurchaseState).inAppPurchase;
    this.inAppPurchaseCancelAtOnce = this.store.selectSnapshot(InAppPurchaseState).inAppPurchaseCancelAtOnce;

    this.currentSubscriptionType();

    console.log("subscription : ",this.subscription);
    this.sharedService.getPlanDetails().subscribe(res => {
      this.isPlanLoading = false;
      if (res.code === 200) {
        this.allPlan = res.data;
        this.changePlan(this.subscriptionType);
      }

    },error => {
      this.isPlanLoading = false;
    });

    this.getAllCard();
  }

  planSelect(event){
    console.log("subscription planSelect event",event)
    this.selectedPlan = event.selectedPlan;
  }






  concierge_option(value: String[]): void {
    this.conciergeOptionArray = value;
    // console.log(this.conciergeOptionArray);
  }
  
  currentSubscriptionType() {
    let stype = 'monthly';
    if (this.subscription != null) {
      if (this.subscription.plan_type === 'yearly') {
        stype = 'annual';
      } else if (this.subscription.plan_type === 'six_months') {
        stype = 'six_months';
      }else {
        stype = 'monthly';
      }
    }
    this.subscriptionType = stype;
  }

  redirectToDashboard() {
    window.location.href = window.location.origin;
  }


  changePlan(event): void {
    console.log("all plan : ",this.allPlan)
    if (event === 'monthly') {
      this.planDetails = this.allPlan[`monthly_plan`];
    }

    if (event === 'six_months') {
      this.planDetails = this.allPlan[`six_months_plan`];
    }

    if (event === 'annual') {
      this.planDetails = this.allPlan[`yearly_plan`];
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.couponCode = '';
    this.discountedAmount = 0;
  }

  handleSocialModalCancel(): void {
    this.isSocialVisible = false;
    this.accountSelection = { 'FACEBOOK': [], 'INSTAGRAM': [], 'TWITTER': [], 'LINKEDIN': [], 'YOUTUBE': [], 'TIKTOK': [], 'PINTEREST': [] };
  }

  async selectPlan(planId, planName, planAmount, plan,isFreeTrial=false): Promise<void> {

    if (this.subscription !== null) {
      if (this.subscription.plan_type === 'yearly' && this.subscriptionType === 'monthly') {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, 'You can\'t downgrade from an annual to monthly plan directly. However, if you want to move to monthly plan, then cancel this yearly subscription. Once your billing cycle for current plan ends, you can subscribe to our monthly subscription.');
        return;
      }
    }

    if (this.inAppPurchase !== null) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, 'You can\'t upgrade or downgrade subscription from web when you have subscribed through in app purchase.');
      return;
    }

    this.selectedPlanId = planId;
    this.selectedPlanName = this.titleCasePipe.transform(planName);
    this.selectedPlanAmount = planAmount;

    this.selectedPlan = plan;

    if(!this.subscriptionCancelAtOnce && isFreeTrial){
      this.isFreeTrialSubscribing = true;
      this.subscribe();
    }else {
      // If current plan is gold and user choose to downgrade plan then need to give option to choose social media popup.
      await this.getAllSocialAcccounts();
      if (this.subscription != null && this.subscription.plan_name == 'advanced' && planName != 'advanced' && this.checkAccountsForDowngrade()) {
        this.isSocialVisible = true;
      } else {
        this.isVisible = true;
      }
    }

  }

  checkAccountsForDowngrade() {

    /*if (Object.keys(this.allAccounts).length &&
      this.allAccounts[this.sharedService.facebookProvider].length > 1 ||
      this.allAccounts[this.sharedService.instagramProvider].length > 1 ||
      this.allAccounts[this.sharedService.twitterProvider].length > 1 ||
      this.allAccounts[this.sharedService.linkedinProvider].length > 1 ||
      this.allAccounts[this.sharedService.tiktokProvider].length > 1 ||
      this.allAccounts[this.sharedService.pinterestProvider].length > 1 ||
      this.allAccounts[this.sharedService.youtubeProvider].length > 1) {
      return true;
    }*/

    console.log("checkAccountsForDowngrade totalAccountList : ",this.totalAccountList.length)
    console.log("checkAccountsForDowngrade selectedPlanId : ",this.selectedPlan)
    console.log("checkAccountsForDowngrade planLimit : ",this.sharedService.planLimit[this.selectedPlan])
    if(Object.keys(this.allAccounts).length
      && this.totalAccountList.length > this.sharedService.planLimit[this.selectedPlan]){
      return true;
    }

    return false;
  }

  subscribe(): void {
    this.subscribeToPlan();
  }

  getAllCard(): void {
    this.allCard = [];
    this.userService.getUserSaveCard().subscribe(res => {
      if (res.code === 200) {
        this.allCard = res.data;
      }
      if (res.code === 401 && res.toast === true) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      this.router.navigate(['/']);
    });
  }

  getAllSocialAcccounts(): Promise<any> {
    this.allAccounts = [];
    this.selectPlanLoading = true;
    return new Promise((resolve) => {
      this.userService.getSocialAccountList().subscribe(res => {

        this.selectPlanLoading = false;
        if (res.code === 200) {
          this.totalAccountList = res.data.totalAccountList;

          this.socialService.allSocialPlatforms.forEach((social) => {

            const socialRadioData = [];
            const accountList = res.data.accountList;
            const activeAccountList = res.data.activeAccountList;
            if (accountList[social]) {
              const socialAccounts = accountList[social];

              socialAccounts.forEach((element, index) => {
                const checked = index == 0 ? true : false;
                const objData = { label: element.name, value: element.id, image:element.image };
                socialRadioData.push(objData);

                // if alreday saved platform then need to be selected
                activeAccountList.forEach((acc) => {
                  if (acc.social_connection_id === element.id) {
                    //this.accountSelection[social] = acc.social_connection_id.toString();
                  }
                });
              });
            }
            this.allAccounts[social] = socialRadioData;
          });
        }

        if (res.code === 401 && res.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
        resolve(true);

      }, error => {
        this.allAccounts = [];
        resolve(true);
      });
    });

  }

  subscribeToPlan(): void {
    if (this.subscription === null) {
      this.isSubscribing = true;
      var click_id = localStorage.getItem('click_id');
      this.userService.subscribe(this.userId, {
        plan_id: this.selectedPlanId,
        click_id,
        coupon_code: this.couponCode
      }).subscribe(res => {
        this.isSubscribing = false;
        if (res.code === 200) {




          this.store.dispatch(new SubscriptionGet({
            subscription: res.data.subscription,
            subscriptionCancelAtOnce: res.data.subscriptionCancelAtOnce
          }));
          this.isVisible = false;
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Success', 'You have successfully subscribed to ' + this.selectedPlanName + ' ' + this.sharedService.formattedName(this.subscriptionType) + ' plan');
          setTimeout(() => {
            this.redirectToDashboard();
          }, 3000);
        }
        if (res.code === 401 && res.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
        if (res.code === 500 && res.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
      }, error => {
        this.isSubscribing = false;
        window.location.reload();
      });
    } else {
      const downgrade = this.getText() !== 'Upgrade';

      const objParams = {
        plan_id: this.selectedPlanId,
        current_plan_id: this.subscription.plan_id,
        current_plan_type: this.subscription.plan_type,
        downgrade
      };

      console.log("subscribeToPlan is socialDowngradeSelection: ",this.socialDowngradeSelection);
      if (this.socialDowngradeSelection) {
        this.socialService.allSocialPlatforms.forEach((social) => {
          console.log("subscribeToPlan is allAccounts: ",this.allAccounts);
          this.allAccounts[social].forEach((acc) => {
            console.log("subscribeToPlan is acc: ",acc);
            if (acc.is_checked) {
                this.accountSelection[social].push(acc.value);
              }
          });
        });

        console.log("subscribeToPlan final: ",this.accountSelection);
        objParams['selected_accounts'] = this.accountSelection;
        //objParams['selected_accounts'] = this.allAccounts;
      }

      if (this.subscriptionType === 'annual' && this.subscription.plan_type === 'monthly') {
        this.modal.confirm({
          nzTitle: 'Switch to annual plan?',
          nzClassName: 'app_warning_modal_main',
          nzContent: AppWarningModalComponent,
          nzComponentParams: {
            message: "Are you sure you want switch to annual plan?",
          },
          nzOkText: 'Yes',
          nzOkType: "primary",
          nzOkDanger: true,
          nzCentered: true,
          nzCancelText: 'No',
          nzKeyboard: false,
          nzMaskClosable: false,
          nzFooter: null,
          nzAutofocus: null,
          nzIconType: null,
          nzOnOk: () => {
            this.upgradePlan(objParams, downgrade);
          }
        });
      } else {
        this.upgradePlan(objParams, downgrade);
      }
    }
  }


  selectPlatformAccount(){
    this.totalAccountSelected = [];
    this.socialService.allSocialPlatforms.forEach((social) => {
      this.allAccounts[social].forEach((acc) => {
        if (acc.is_checked) {
          this.totalAccountSelected.push(acc.value);
        }
      });
    });

    console.log("selectPlatformAccount : ",this.totalAccountSelected);
  }

  upgradePlan(objParams, downgrade) {

    this.isSubscribing = true;
    this.userService.upgradeSubscribePlan(objParams).subscribe(res => {
      this.isSubscribing = false;
      if (res.code === 200) {
        this.isVisible = false;

        if (downgrade) {
          if (res.data.on_trial) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, 'You have successfully downgraded to ' + this.selectedPlanName + ' ' + this.sharedService.formattedName(this.subscriptionType) + ' plan');
          } else {
            const date = moment(this.subscription.renewal_date).format('MMM D, YYYY');
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, 'The downgrade will take effect from ' + date + ', and as per ' + this.selectedPlanName + ' plan, we will automatically remove all the extra social media accounts connected with your ' + this.sharedService.appName + ' account as of now.');
          }
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, 'You have successfully upgraded to ' + this.selectedPlanName + ' ' + this.sharedService.formattedName(this.subscriptionType) + ' plan');
        }
        setTimeout(() => {
          this.redirectToDashboard();
        }, 3000);
      }
      if (res.code === 401 && res.toast === true) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
      if (res.code === 500 && res.toast === true) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      this.isSubscribing = false;
    });
  }

  getText(plan = null): string {
    if (plan === null) {
      plan = this.selectedPlan;
    }
    let hasPlan = false;
    let currentPlan = '';
    this.planDetails.forEach(item => {
      if (this.subscription !== null && item.plan_id === this.subscription.stripe_price){
        hasPlan = true;
      }
    });

    for (const currentPlanKey in this.allPlan) {
      this.allPlan[currentPlanKey].forEach(item => {
        if (this.subscription !== null && item.plan_id === this.subscription.stripe_price) {
          currentPlan = item.plan;
        }
        if (this.inAppPurchase !== null && item.ios_plan_id === this.inAppPurchase.subscription_id && item.android_plan_id === this.inAppPurchase.subscription_id) {
          currentPlan = item.plan;
        }
      });
    }

    if (hasPlan) {
      if (currentPlan === 'plan_1') {
        return 'Upgrade';
      }
      if (currentPlan === 'plan_2') {
        if (plan === 'plan_1') {
          return 'Downgrade';
        }
        if (plan === 'plan_3') {
          return 'Upgrade';
        }
      }
      if (currentPlan === 'plan_3') {
        return 'Downgrade';
      }
    } else {
      if (this.subscriptionType === 'monthly') {
        if (currentPlan === 'plan_1') {
          if (plan === 'plan_1') {
            return 'Downgrade';
          }
          return 'Upgrade';
        }
        if (currentPlan === 'plan_2') {
          if (plan === 'plan_1' || plan === 'plan_2') {
            return 'Downgrade';
          }
          return 'Upgrade';
        }
        if (currentPlan === 'plan_3') {
          return 'Downgrade';
        }
      }

      if (this.subscriptionType === 'six_months') {
        if (currentPlan === 'plan_1') {
          return 'Upgrade';
        }
        if (currentPlan === 'plan_2') {
          if (plan === 'plan_1') {
            return 'Downgrade';
          }
          return 'Upgrade';
        }
        if (currentPlan === 'plan_3') {
          if (plan === 'plan_3') {
            return 'Upgrade';
          }
          return 'Downgrade';
        }
      }

      if (this.subscriptionType === 'annual') {
        if (currentPlan === 'plan_1') {
          return 'Upgrade';
        }
        if (currentPlan === 'plan_2') {
          if (plan === 'plan_1') {
            return 'Downgrade';
          }
          return 'Upgrade';
        }
        if (currentPlan === 'plan_3') {
          if (plan === 'plan_3') {
            return 'Upgrade';
          }
          return 'Downgrade';
        }
      }
    }
    return '';
  }

  goToBack(): void {
    this.location.back();
  }

  downgradeSelection() {
    //if (this.accountSelection.FACEBOOK == '' && this.accountSelection.LINKEDIN == '' && this.accountSelection.TWITTER == '' && this.accountSelection.INSTAGRAM == '' && this.accountSelection.YOUTUBE == '' && this.accountSelection.TIKTOK == '' && this.accountSelection.PINTEREST == '') {
    if (this.totalAccountSelected.length==0) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'Please select at least one social account');
    }else if(this.totalAccountSelected.length > this.sharedService.planLimit[this.selectedPlan]){
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'You can select only '+this.sharedService.planLimit[this.selectedPlan]+' social accounts!' );
    }else {
      this.socialDowngradeSelection = true;
      this.isSocialVisible = false;
      this.isVisible = true;
    }
  }


  getTotal() {
    return '$' + (parseInt(this.selectedPlanAmount));
  }

  hideorshowButton() {
    let status = false;
    const type = this.subscriptionType == 'annual' ? 'yearly' : 'monthly';
    if (this.subscription !== null) {
      if (this.subscription.plan_type === 'yearly' && this.subscriptionType === 'monthly') {
        status = false;
      } else if (this.subscription.plan_type === 'monthly') {
        status = true;
      } else if (this.subscription.plan_type === 'six_months') {
        status = true;
      } else if (this.subscription.plan_type === 'yearly' && this.subscriptionType === 'annual') {
        status = true;
      }
    }
    return status;
  }

  applyCouponCode(): void {

    let postData = {
      'coupon_code' : this.couponCode,
      'amount' : this.selectedPlanAmount
    }

    this.userService.applyCoupon(postData).subscribe(res => {
      console.log("applyCouponCode res :",res)
      if (res.code === 200) {
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
        //this.allCard = res.data;
        this.discountedAmount = res.data.discount_amount;
      }
      if (res.code === 401 && res.toast === true) {
        this.couponCode = '';
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      this.couponCode = '';
      this.router.navigate(['/']);
    });
  }


  saveConcierge(): void {

    this.isDisabled = true;
    this.isLoadning = true;


    // let postData = {
    //   'coupon_code' : this.couponCode,
    //   'amount' : this.selectedPlanAmount
    // }

    this.conciergeForm.get('features').setValue(this.conciergeOptionArray);
    let postData = this.conciergeForm.value;

    this.userService.saveContactRequest(postData).subscribe(res => {

      console.log(res);

      if (res.code === 200) {
        this.conciergeForm.reset();
        this.isConcierge = false;
        this.isContactSubmitModal = true;
        this.isDisabled = false;
        this.isLoadning = false;
        setTimeout(() => {
          this.isContactSubmitModal = false;
        }, 5000);
        // this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      }else{
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        this.isDisabled = false;
        this.isLoadning = false;
        this.conciergeForm.reset();
      }
    }, error => {
      //this.router.navigate(['/']);
    });
  }

  conciergeModal(){
    this.isConcierge = true;
  }



}
