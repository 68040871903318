<ng-container>
  <div class="permision_modal_hdr" *ngIf="mode!=0">
    <h4>Edit Permissions and Access</h4>
  </div>
  <div class="permision_modal_body">
    <ng-container *ngIf="isLoadingPermission">
      <div class="analytics_post_skeleton">
        <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 2 }" *ngFor="let item of dummyItems"></nz-skeleton>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoadingPermission">
      <!--Permission-->
      <div class="all_prmsn_main" *ngIf="mode!=0">
        <span>Select All Permissions</span>
        <label nz-checkbox [(ngModel)]="isCheckedAllPermissions"
              (ngModelChange)="selectAllPermissions($event)"></label>
      </div>
      <div class="permision_modal_body_in">

        <div class="permission_body">

          <ng-container *ngFor="let category of categories">
            <nz-collapse *ngIf="category.permissions.length>0">
              <ng-container>
                <nz-collapse-panel [nzHeader]="headerTemplate" [nzActive]="true" >
                  <ng-template #headerTemplate>
                    <div class="col_header">
                      <img [src]=" '../../../assets/images/'+sharedService.categoryIcons[category.name]" />
                      <span>{{sharedService.formattedName(category.name)}}</span>
                    </div>
                  </ng-template>

                  <div class="collapse_body">
                    <label nz-checkbox *ngFor="let permission of category.permissions"
                          [(ngModel)]="permission.is_checked" [disabled]="mode==0"
                          (ngModelChange)="updatePermissions($event)">
                      <div class="permission_detail">
                        <div class="title">
                          {{ sharedService.formattedName(permission.name) }}
                        </div>
                        <div class="description">
                          <p>{{permission.description}}</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </nz-collapse-panel>
              </ng-container>
            </nz-collapse>
          </ng-container>

        </div>

        <!--Profile Access-->
        <div class="social_profile_list" *ngIf="userSocialProfiles.length>0">
          <perfect-scrollbar style="max-height: 100%;">
            <nz-table #profilesWithPermissions [nzData]="userSocialProfiles" class="table_default tbl_sp_permision"
              [nzFrontPagination]="false">
              <thead>
                <tr>
                  <th>Social Profile</th>
                  <th class="text-right">Profile Permissions</th>
                </tr>
              </thead>
              <tbody *ngIf="profilesWithPermissions.data.length > 0">
                <tr *ngFor="let account of profilesWithPermissions.data">
                  <td>
                    <div class="tm_first_member">
                      <div class="tm_first_img">
                        <app-image-preview [imageUrl]="account?.social_account?.image"
                          [isVideo]="false"></app-image-preview>
                        <app-provider [provider]="account?.social_account?.provider"></app-provider>
                      </div>
                      <div class="tm_first_label">
                        <span>{{account?.social_account?.name}}</span>
                        <a class="margin-left-2" *ngIf="account?.social_account?.reconnect==1"
                           nz-tooltip nzTooltipTitle="Need Reconnect Account">
                          <i nz-icon [nzType]="'info-circle'"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="td_text_right" >
                    <div class="text-right">
                      <nz-select (nzOpenChange)="sharedService.openNzSelect($event)" nzDropdownClassName="rp_option_main"
                                 nzPlaceHolder="Select Roles & Permissions"
                        [nzCustomTemplate]="multipleTemplateRolesPermissions" [(ngModel)]="account.access_type"
                        [nzDisabled]="!sharedService.can('manage_user')
                        || mode==0
                        || account?.social_account?.reconnect==1">
                        <nz-option *ngFor="let accessType of sharedService.socialAccessTypes; let i=index;"
                          nzCustomContent [nzValue]="i">
                          <div class="rp_main">
                            <h5>{{ accessType }}</h5>
                            <span>{{ sharedService.socialAccessTypesDescription[i] }}</span>
                          </div>
                        </nz-option>
                      </nz-select>
                      <ng-template #multipleTemplateRolesPermissions let-selected>
                        <div class="ant-select-selection-item-content">
                          <span>{{sharedService.socialAccessTypes[selected.nzValue]}}</span>
                        </div>
                      </ng-template>
                    </div>
                  </td>

                </tr>
              </tbody>
            </nz-table>

            <div *ngIf="userSocialProfiles?.length === 0">
              <app-empty [message]="'No Profile Permission Found !'"></app-empty>
            </div>
          </perfect-scrollbar>

          <!--<div class="btn_wrp">
              <button class="btn_primary" (click)="updateUserSocialAccess()"
                      *ngIf="userSocialProfiles?.length > 0"
                      [disabled]="isUpdatingSocialAccess"
                      hasPermission [permission]="'manage_user'">
                Update Permission
              </button>
            </div>-->
        </div>
      </div>

      <!--Footer-->
      <div class="organization_form_action" *ngIf="mode!=0">
        <!--<span>Are you sure you want to delete Draft post?</span>-->
        <div class="btn_group">
          <button nz-button nzType="primary" class="btn" (click)="cancelPermissionModal()"
            [class.disabled_while_loading]="isSavePermission">Cancel</button>
          <button nz-button nzType="primary" class="btn btn_apply" (click)="savePermissions()"
            [class.disabled_while_loading]="isSavePermission">Save
            <ng-container *ngIf="isSavePermission">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
