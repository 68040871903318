import { Pipe, PipeTransform } from '@angular/core';
import 'moment-timezone';
import moment from 'moment';

@Pipe({name: 'dateConvertPipe'})

export class DateConvertPipe implements PipeTransform {
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  transform(date: Date | string | number, format: string = 'MM/DD/yyyy hh:mm A'): string {
    if (date) {
      let myDate: any = new Date(date);
      myDate = moment(myDate).tz(this.timeZone).format(format);
      return myDate;
    } else {
      return '';
    }
  }
}
