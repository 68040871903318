import { Location } from '@angular/common';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/constants';
import { HelperService } from 'src/app/services/helper.service';
import { SharedService } from 'src/app/services/shared.service';
import { SocialAccountService } from 'src/app/services/social-account.service';
import { TeamsService } from 'src/app/services/teams.service';
import { Subject } from "rxjs";


@Component({
  selector: 'app-member-permission',
  templateUrl: './member-permission.component.html',
  styleUrls: ['./member-permission.component.less']
})
export class MemberPermissionComponent implements OnInit {
  @Input() editId = 0;
  @Input() users = [];
  @Input() team_ids = [];
  @Input() user_permissions = [];
  @Input() userSocialProfiles = [];
  @Input() mode = 1; //0=only view, 1=edit
  @Output() permissionChange = new Subject();
  @Output() premissionCancel = new Subject();

  permissionModal = false;
  categories = [];
  selectedPermissionIds = [];
  isLoadingInvite = false;
  isLoadingPermission = true;
  allSocialChecked = false;
  selectedSocialAccounts = [];
  inviteModal = false;
  selectedTeams = [];
  socialAccounts = [];
  dummyItems = Array(5).fill(0).map((x, i) => i);
  isCheckedAllPermissions = true;

  constructor(
    private accountService: SocialAccountService,
    public sharedService: SharedService,
    private teamService: TeamsService,

  ) {
  }

  ngOnInit(): void {
    console.log("team_ids : ", this.team_ids);
    this.getPermissions();
    this.getSocialActiveAccount();
  }

  getSocialActiveAccount() {
    this.accountService.getSocialActiveAccount().subscribe((res) => {
      this.socialAccounts = res.data;
      this.socialAccounts.forEach(account => {
        account.social_access_id = 0;
      });
    }, error => {
    });
  }

  /*Permission*/
  getPermissions() {
    this.isLoadingPermission = true;
    this.teamService.getPermissions().subscribe((res) => {
      this.isLoadingPermission = false;
      this.categories = res.data;

      if (this.user_permissions.length > 0) {
        this.categories.forEach((category, index) => {
          category.permissions.filter((item) => {
            if (this.user_permissions.includes(item.id)) {
              item.is_checked = true;
              this.selectedPermissionIds.push(item.id);
            }else{
              this.isCheckedAllPermissions =false;
            }
            //if (item.is_checked) { this.selectedPermissionIds.push(item.id); }
          });
        });
      } else {
        this.isCheckedAllPermissions =false;
      }

    }, error => {
      this.isLoadingPermission = false;
    });
  }

  updatePermissions(event: any): void {
    console.log("updatePermissions : ", event);
    this.selectedPermissionIds = [];
    this.categories.forEach((category, index) => {
      category.permissions.filter((item) => {
        if (item.is_checked) { this.selectedPermissionIds.push(item.id); }
      });
    });
    this.isCheckedAllPermissions = this.categories.every(function(item){
      return  item.permissions.every(permission=> permission.is_checked)
    });
  }

  cancelPermissionModal() {
    this.permissionModal = false;
    this.resetInvite();
    // this.permissionChange.next({is_canceled:true});
    this.permissionChange.next({ code: 200, message: "" });
    // this.permissionChange.next({code:res.code,message:res.message});
  }

  resetInvite() {

    //this.inviteForm.reset();
    this.selectedTeams = [];
    this.selectedPermissionIds = [];
    this.getSocialActiveAccount();
  }
  isSavePermission = false;
  savePermissions() {
    var users, social_accounts;

    if (this.mode == 1) {
      users = this.users;
      social_accounts = this.userSocialProfiles;
    } else if (this.mode == 2) {
      users = this.users.map((item) => {
        return { 'id': item.id }
      });
      social_accounts = this.userSocialProfiles.map((item) => {
        return {
          'social_connection_id': item.social_connection_id,
          'access_type': item.access_type,

        }
      });
    }
    console.log("this.selectedPermissionIds:", this.selectedPermissionIds);

    let postData = {
      'users': JSON.stringify(users),
      'team_ids': JSON.stringify(this.team_ids),
      'permissions': this.selectedPermissionIds,
      'social_accounts': JSON.stringify(social_accounts),
    };

    console.log("postData mode: " + this.mode)
    console.log("postData : ", postData)

    // this.isLoadingPermission = true;
    this.isSavePermission = true
    this.teamService.savePermissions(postData).subscribe((res: any) => {
      this.permissionChange.next({ code: res.code, message: res.message });
      //console.log("res : ",res);
      // this.isLoadingPermission = false;
      this.isSavePermission = false;

      if (res.code === 200) {
        this.permissionModal = false;
        this.resetInvite();
        //this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      }

      if (res.code === 500 || res.code === 401) {
        //this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }

    }, error => {
      this.permissionChange.next({ code: 500, message: "Unable to update permissions" });
      // this.isLoadingPermission = false;
      this.isSavePermission = false;
    });
  }


  selectAllSocialAccount(event) {
    if (this.allSocialChecked) {
      this.socialAccounts.forEach(account => { account.is_checked = true; });
    } else {
      this.socialAccounts.forEach(account => { account.is_checked = false; });
    }
    this.allSocialChecked = this.socialAccounts.every((item: any) => item.is_checked);
  }

  selectSocialAccount(event) {
    this.allSocialChecked = this.socialAccounts.every((item: any) => item.is_checked);
    this.selectedSocialAccounts = this.socialAccounts.filter(account => { if (account.is_checked) return account });
  }

  selectAllPermissions(event){
    this.selectedPermissionIds = [];
    if(this.isCheckedAllPermissions){
      this.categories.forEach((category) => {
        category.permissions.forEach((item) => {
          item.is_checked = true;
          this.selectedPermissionIds.push(item.id);
        });
      });
    }else{
      this.categories.forEach((category) => {
        category.permissions.forEach((item) => {
          item.is_checked = false;
        });
      });
    }
  }
}
