import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SubscriptionState } from '../action-state/states/subscription.state';
import { AuthState } from '../action-state/states/auth.state';
import { InAppPurchaseState } from '../action-state/states/in-app-purchase.state';
import { SharedService } from "../services/shared.service";

@Injectable()
export class SubscriptionGuard implements CanActivate {

  constructor(private router: Router, private store: Store,
    public sharedService: SharedService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.store.selectSnapshot(SubscriptionState).subscription !== null ||
      (this.store.selectSnapshot(AuthState.user).free_user && this.store.selectSnapshot(AuthState.user).free_user !== null) ||
      this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null
    ) {
      return true;
    }
    else {
      if (!this.store.selectSnapshot(AuthState.user).is_super_admin) {
        this.router.navigate(['/', 'inactive-organization']);
      } else {
        //this.sharedService.displayNotification(this.sharedService.messageTypeError, "Subscription not found!", "Please subscribe the plan first");
        this.router.navigate(['/', 'subscription']);
      }
      return false;
    }
  }
}
