<img nz-image [class]="class" *ngIf="!isVideo" [nzSrc]="url" [nzDisablePreview]="disablePreview" appImageDirective [src]="url" alt="One For All Social" />
<i nz-icon nzType="video-camera" nzTheme="outline" class="media_type_icon" *ngIf="mediaType && mediaType==='video'"></i>
<!-- <i nz-icon nzType="file-image" nzTheme="outline" class="media_type_icon" *ngIf="mediaType && mediaType!='video'"></i> -->

<ng-container *ngIf="url !== null && isVideo">
  <!-- autoplay loop -->
  <video [src]="url" [class]="class" *ngIf="class === 'manage-post-video' && !forceAutoPlay"></video>
  <video [src]="url" [class]="class" controls *ngIf="class !=='manage-post-video' && !autoplay && !forceAutoPlay"></video>
  <video autoplay [src]="url" [class]="class" controls loop *ngIf="class !== 'manage-post-video' && autoplay && !forceAutoPlay"></video>
  <video autoplay [src]="url" [class]="class" controls loop *ngIf="forceAutoPlay"></video>
</ng-container>
