import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { FacebookService } from '../../services/facebook.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SharedService } from '../../services/shared.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-facebook-redirect-auth',
  templateUrl: './facebook-redirect-auth.component.html'
})
export class FacebookRedirectAuthComponent implements OnInit {

  public data = null;
  public disabledAccounts = [];
  public isVisible = false;
  public selectedAccount = [];
  public accounts = [];
  public isLoading = false;


  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private messageService: NzMessageService,
              private facebookService: FacebookService,
              private store: Store,
              public sharedService: SharedService,
              private route: ActivatedRoute,) {
    this.sharedService.setPageTitle(this.route.snapshot.data['title']);
  }

  ngOnInit(): void {


    this.activatedRoute.queryParamMap
      .subscribe((param: Params) => {
        const postValue = param.params;
        if (postValue.code !== undefined) {
          // added social id in request params
          const social_data = localStorage.getItem('reconnect_id');
          let social_id = '';
          if (social_data != null) {
            social_id = social_data;
          }

          const socialData: any = { 'social_id': social_id };
          const postData = {
            ...postValue,
            ...socialData
          };

          this.facebookService.facebookAuthenticatedRequests(postData).subscribe((res) => {
            if (res.code === 200) {
              if (social_id != '') {
                // reconnect
                this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
                this.navigateToAccountPage();
              } else {
                // connected
                this.data = res.data.pages;
                // this.groups = res.data.groups;
                this.data.forEach((item, index) => {
                  this.data[index][`checked`] = false;
                });
                this.disabledAccounts = this.data.filter(item => {
                  return item.disabled;
                });

                /* this.disabledGroups = this.groups.filter(item => {
                  return item.disabled;
                }); */

                this.isVisible = true;
              }
            }

            if ((res.code === 400 || res.code === 500) && res.toast === true) {
              this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
              this.navigateToAccountPage();
            }
          }, error => {
            this.navigateToHome();
          });
        } else {
          localStorage.setItem('has_reconnect_error', '0');
          this.navigateToHome();
        }
      });
  }

  getTitle(): string {
    if (localStorage.getItem('reconnect_id') === null) {
      return 'Which Account do You want to connect?';
    } else {
      return 'Which Account do You want to reconnect?';
    }
  }

  handleOk(): void {
    console.log("handle ok In");

    this.saveAccounts();
  }

  saveAccounts(): void {
    const postValue = { accounts:this.selectedAccount, reconnect_id: null };
    if (localStorage.getItem('reconnect_id') !== null) {
      postValue.reconnect_id = +localStorage.getItem('reconnect_id');
    }

    this.isLoading = true;
    this.facebookService.saveFacebookAccounts(postValue)
      .subscribe(async (res) => {
        this.isLoading = false;
        if (res.code === 200) {
          if (res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          }
          localStorage.setItem('has_reconnect_error', '0');
          setTimeout(() => {
            this.sharedService.notification.next(true);
          }, 30000);
          this.navigateToAccountList();
        }
        if (res.code === 500 && res.toast === true) {
          localStorage.setItem('has_reconnect_error', '1');
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
      }, error => {
        this.isLoading = false;
        this.navigateToHome();
      });
  }

  navigateToHome(): void {
    if (localStorage.getItem('reconnect_id') !== null) {
      localStorage.removeItem('reconnect_id');
    }
    if (localStorage.getItem('error_display') !== null) {
      localStorage.removeItem('error_display');
    }
    if (localStorage.getItem('reconnect_popup') !== null && localStorage.getItem('reconnect_popup') === '1') {
      window.close();
    }
    this.router.navigate(['/']);
  }

  navigateToAccountPage() {
    if (localStorage.getItem('reconnect_id') !== null) {
      localStorage.removeItem('reconnect_id');
    }
    if (localStorage.getItem('error_display') !== null) {
      localStorage.removeItem('error_display');
    }
    if (localStorage.getItem('reconnect_popup') !== null && localStorage.getItem('reconnect_popup') === '1') {
      window.close();
    }
    this.router.navigate(['/accounts']);
  }

  navigateToAccountList(): void {
    if (localStorage.getItem('reconnect_id') !== null) {
      localStorage.removeItem('reconnect_id');
    }
    if (localStorage.getItem('error_display') !== null) {
      localStorage.removeItem('error_display');
    }
    if (localStorage.getItem('reconnect_popup') !== null && localStorage.getItem('reconnect_popup') === '1') {
      window.close();
    }
    if (localStorage.getItem('is_from_create_post') !== null) {
      localStorage.removeItem('is_from_create_post');
      this.router.navigate(['/post/create-post']);
    }else{
      this.router.navigate(['/', 'accounts']);
    }
  }

  getAccount(event): void {
    this.selectedAccount = [];
    this.selectedAccount = event.accounts.filter(item => {
      if (!item.disabled) {
        return item.is_checked;
      }
    });
  }

/*   getGroups(event): void {
    this.selectedGroup = [];
    this.selectedGroup = event.accounts.filter(item => {
      if (!item.disabled) {
        return item.is_checked;
      }
    });
  } */

  handleCancel(): void {
    this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, "Account connection request cancelled!");
    localStorage.setItem('has_reconnect_error', '0');
    this.isVisible = false;
    this.navigateToHome();
  }

  selectAll(){
    this.data = this.data.map(item => {
      if (!item.disabled) {
        return { ...item, is_checked: true };
      }
      return item;
    });

    this.selectedAccount = this.data.filter(item => {
      if (!item.disabled) {
        return item.is_checked;
      }
    });

    /*Groups*/
    /* this.groups = this.groups.map(item => {
      if (!item.disabled && !item.app_install_link) {
        return { ...item, is_checked: true };
      }
      return item;
    });

    this.selectedGroup = this.groups.filter(item => {
      if (!item.disabled) {
        return item.is_checked;
      }
    }); */

  }
}
