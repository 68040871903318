import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Store } from '@ngxs/store';
import { SubscriptionGet } from '../action-state/actions/subscription.action';
import { TeamGet } from '../action-state/actions/team.action';
import { WasabiGet } from '../action-state/actions/wasabi.action';
import { AutoLogin, Logout } from '../action-state/actions/auth.action';
import { Injectable, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthState } from '../action-state/states/auth.state';
import { InAppPurchaseGet } from '../action-state/actions/in-app-purchase.actions';

@Injectable()

export class Resolver implements Resolve<Promise<boolean>> {
  accounts: any = [];
  constructor(private authService: AuthService,
    private store: Store,
    private spinnerService: NgxSpinnerService,
    private router: Router) {
  }


  // @ts-ignore
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    if (this.store.selectSnapshot(AuthState.token) !== null) {
      this.spinnerService.show();

      return new Promise(async (resolve) => {
        await this.authService.autoLogin().subscribe((res) => {

          const data = res.data.user_detail;
          data.error = res.data.error;

          this.store.dispatch(new AutoLogin(res.data.user_detail));
          this.store.dispatch(new SubscriptionGet({
            subscription: res.data.subscriptions,
            subscriptionCancelAtOnce: res.data.subscriptionCancelAtOnce,
            isFirstPurchase: res.data.isFirstPurchase
          }));

          this.store.dispatch(new WasabiGet(res.data.wasabi));
          this.store.dispatch(new InAppPurchaseGet({
            inAppPurchase: res.data.in_app_purchase,
            inAppPurchaseCancelAtOnce: res.data.in_app_purchase_cancel_at_once
          }));
          this.store.dispatch(new TeamGet({
            role: res.data.role,
            permissions: res.data.permissions,
            organizations: res.data.organizations,
            customPermissions: res.data.custom_permissions,
            teams: res.data.teams,
          }));
          resolve(true);
        }, error => {
          if (error.status === 401) {
            this.store.dispatch(new Logout());
            this.router.navigate(['/', 'auth', 'login']);
          }
        });
      });
    }
  }
}
