import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../services/user.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthState } from '../../action-state/states/auth.state';
import { AppWarningModalComponent } from '../app-warning-modal/app-warning-modal.component';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-card-data',
  templateUrl: './card-data.component.html',
  styleUrls: ['./card-data.component.less']
})
export class CardDataComponent implements OnInit {

  @Input() allCard: any = [];
  userId = null;
  isLoading=false;
  dummyItems = Array(4).fill(0).map((x, i) => i);
  constructor(private spinnerService: NgxSpinnerService,
    private userService: UserService,
    public sharedService: SharedService,
    private messageService: NzMessageService,
    private router: Router,
    private store: Store,
    private modal: NzModalService) { }

  ngOnInit(): void {
    this.userId = this.store.selectSnapshot(AuthState.user).id;
  }

  makeCardDefault(paymentMethodId): void {
    this.userService.makeCardDefault({ payment_method_id: paymentMethodId }).subscribe(res => {
      if (res.code === 200) {
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, 'You have successfully updated your default card');
        this.getAllCard();
      }
    }, error => {
      this.router.navigate(['/']);
    });
  }

  deleteCard(paymentMethodId): void {
    this.modal.confirm({
      nzTitle: 'Delete card?',
      nzClassName: 'app_warning_modal_main',
      nzContent: AppWarningModalComponent,
      nzComponentParams: {
        message: "Are you sure you want to delete this card?"
      },
      nzOkText: 'Yes, Delete It',
      nzOkDanger: true,
      nzCentered: true,
      nzCancelText: 'Cancel',
      nzIconType: null,
      nzAutofocus: null,
      nzOnOk: () => {
        //this.spinnerService.show();
        this.userService.deleteCard({ payment_method_id: paymentMethodId }).subscribe(res => {
          if (res.code === 200) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Success', res.message);
            this.getAllCard();
          }
          if (res.code === 500 && res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Something went wrong', res.message);
          }
        }, error => {
          //this.spinnerService.hide();
          this.router.navigate(['/']);
        });
      }
    });
  }

  getAllCard(): void {
    this.allCard = [];
    //this.spinnerService.show();
    this.isLoading=true;
    this.userService.getUserSaveCard().subscribe(res => {
      //this.spinnerService.hide();
      this.isLoading=false;
      if (res.code === 200) {
        this.allCard = res.data;
      }
    }, error => {
      //this.spinnerService.show();
      this.isLoading=false;
      this.router.navigate(['/']);
    });
  }
}
