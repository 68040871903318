import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class LinkedinService {

  constructor(private http: HttpClient, private helper: HelperService) { }

  linkedinConnect(): Observable<any>{
      return this.http.get(this.helper.createAPIUrl('linkedin_request_token'));
  }

  linkedinAuthenticatedRequests(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('linkedin_auth'), postValue);
  }

  saveLinkedinAccount(postValue): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('save_linkedin_accounts'), postValue);
  }

  getLinkedinAccount(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_linkedin_accounts'));
  }

  addCommentLinkedinPost(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('add_comment_linkedin_post'), postValues);
  }

  deleteLinkedinComment(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_linkedin_comment'), postValues);
  }

  markMessageAsSeen(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('linkedin_mark_comment_as_seen'), postValues);
  }
  // For Ads
  getLinkedinPosts(social_id, page=1): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('linkedin_posts') + '?social_id=' + social_id + '&page=' + page);
  }

  getLinkedinAdAccounts(social_id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('linkedin_ad_accounts') + '?social_id=' + social_id);
  }
  
  getLinkedinCountries(social_id, search): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('linkedin_countries') + '?social_id=' + social_id + '&search=' + search);
  }

  getLinkedinReach(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('linkedin_reach'),postValues);
  }

  linkedinAdCreate(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('linkedin_ad_create'),postValues);
  }

  linkedinAdUpdate(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('linkedin_ad_update'),postValues);
  }

  getLinkedinCampaigns(social_id,page,limit = 10): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('linkedin_get_campaigns') + '?social_id=' + social_id + '&page=' + page + '&limit=' + limit);
  }

  linkedinAdChangeStatus(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('linkedin_ad_change_status'),postValues);
  }

  getLinkedinAdPreview(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('linkedin_ad_preview'),postValues);
  }
}
