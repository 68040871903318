import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../action-state/states/auth.state';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Logout } from '../action-state/actions/auth.action';
import { SubscriptionClear } from '../action-state/actions/subscription.action';
import { TeamState } from '../action-state/states/team.state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.store.selectSnapshot(AuthState.token)) {
      const organization_id = localStorage.getItem('organization_id');
      const team_id = localStorage.getItem('team_id');
      const role = this.store.selectSnapshot(TeamState.role);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.store.selectSnapshot(AuthState.token)}`,
          'x-custom-organization-id': `${organization_id}`,
          //'x-custom-role': `${role}`,
          'x-custom-team-id': `${team_id}`,
        }
      });
    }
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            if (event.body?.message) {
            }
          }
        },
        (error) => {
          if (error.status === 401) {
            localStorage.clear();
            this.store.dispatch(new Logout());
            this.store.dispatch(new SubscriptionClear());
            this.router.navigate(['/', 'auth', 'login']);
          }
          if (error.status === 403) {
            localStorage.clear();
            this.store.dispatch(new Logout());
            this.store.dispatch(new SubscriptionClear());
            this.router.navigate(['/', 'auth', 'login']);
          }

        }
      )
    );
  }
}
