import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IndexDbService } from '../../services/indexDb.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-gallery-media-preview',
  templateUrl: './gallery-media-preview.component.html',
  styleUrls: ['./gallery-media-preview.component.less']
})
export class GalleryMediaPreviewComponent implements  OnChanges {

  @Input() imageUrl = null;
  @Input() class = '';
  @Input() isVideo = false;
  @Input() profilePic = false;
  @Input() autoplay = false;
  isSpinning = false;
  url = '../../assets/images/loading2.png';
  placeholder = '../../assets/images/loading2.png';
  isVisible = false;
  constructor(private indexDbService: IndexDbService, private sharedService: SharedService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getUrl();
  }


  async getUrl(): Promise<void> {
    if (this.imageUrl !== '' && this.imageUrl !== null && this.imageUrl !== undefined) {
      if (!this.imageUrl.includes('http') || !this.imageUrl.includes('https')) {
        this.isSpinning = true;
        this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
        if (this.url === undefined || this.url === null) {

          this.url = '../../assets/images/white.png';
          this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
        } else {
          if (this.isVideo) {
            this.isSpinning = false;
          }
        }
      } else {
        this.url = this.imageUrl;
      }
    }
  }

  onLoad() {

    if (this.placeholder !== this.url) {
      this.isSpinning = false;
    }
  }

  openVideoPreview(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  handleOk(): void {
    this.isVisible = false;
  }
}
