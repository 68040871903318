import { Component, Input } from "@angular/core";

@Component({
    selector: 'bs-app-warning-modal.component',
    templateUrl: './app-warning-modal.component.html',
    styleUrls: ['./app-warning-modal.component.less']
})

export class AppWarningModalComponent {
    @Input() message = "";
    @Input() path= "../../../assets/images/delete_img_1.png";

}
