import * as moment from 'moment';

import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Subject, debounceTime} from "rxjs";

import { AppWarningModalComponent } from '../app-warning-modal/app-warning-modal.component';
import { DateConvertPipe } from '../../pipes/date-time.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { IndexDbService } from '../../services/indexDb.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { SocialAccountService } from '../../services/social-account.service';

@Component({
  selector: 'app-preview-post',
  templateUrl: './preview-post.component.html',
  styleUrls: ['./preview-post.component.less'],
})
export class PreviewPostComponent implements OnInit,OnChanges {
  @Input() content = [];
  @Input() images: any = [];
  @Input() mediaData: any = [];
  @Input() postMedias: any = [];
  @Input() galleryMediaData: any = [];
  @Input() image = '';
  @Input() name: any = '';
  @Input() time = 'Just now';
  @Input() type = '';
  @Input() profilePic = '';
  @Input() edit = false;
  @Input() delete = false;
  @Input() clone = false;
  @Input() id = null;
  @Input() comment: any = '';
  @Input() provider = null;
  deleted = false;
  @Input() linkData = null;
  @Input() validationErrors: any = [];
  @Input() title = '';
  @Input() accounts = '';
  @Input() userData: any = [];
  @Input() firstComment = '';
  @Input() audience = 'false';
  @Input() from = '';
  @Input() YTThumbnail = '';
  @Input() displayPlatformDetails = true;
  @Input() displayActions = true;
  @Input() isYoutubePost = false;
  @Input() postId: any = '';
  @Input() boardName:any = '';
  @Input() isCommentVisible = true;
  @Input() displayAction = false;
  @Input() post;
  @Input() instaReelThumbnail = '';
  @Input() selectedLocation = null;
  @Input() page = '';
  @Output() deleteChange = new Subject();

  displaySliderItem = true;
  dangerousVideoUrl: any;
  youtubeVideoUrl: any;
  isIframeLoaded = false;
  currentStoryIndex = 0;


  // instagram reels
  @Input() instagramType : string = 'feed_post'; // feed_post, reel_post
  @Input() autoPlay=false;


  // mention
  @Input() facebookMentions = [];
  @Input() instagramMentions = [];
  @Input() twitterMentions = [];
  @Input() threadsMentions = [];
  @Input() tiktokMentions = [];

  facebookContent = '';
  instagramContent = '';
  twitterContent = '';
  threadsContent = '';
  tiktokContent = '';
  otherContent = '';
  availablePlatformForMention = [
      this.sharedService.facebookProvider,
    this.sharedService.instagramProvider, this.sharedService.twitterProvider,
    this.sharedService.threadsProvider,this.sharedService.tiktokProvider
  ];

  @Input() postErrors: any = [];
  @ViewChild(NzCarouselComponent, { static: false }) myCarousel: NzCarouselComponent;

  effect = 'scrollx';

  isContentLoading = false;
  private contentChanged$ = new Subject<void>();

  @Input() multi_images: any = [];

  constructor(private router: Router,
    private modal: NzModalService,
    private spinnerService: NgxSpinnerService,
    private messageService: NzMessageService,
    private dateConvertPipe: DateConvertPipe,
    private socialAccountService: SocialAccountService,
    private nzImageService: NzImageService,
    private indexDbService: IndexDbService,
    public sharedService: SharedService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    console.log("previewPost ngOnInit")

    this.getYouTubeUrl();

    /*Youtube*/
    if((this.YTThumbnail==='' || this.YTThumbnail===undefined)  && this.post?.youtube_thumbnail!=''){
      this.YTThumbnail = this.post?.youtube_thumbnail
    }

    //Instagram Reel
    if((this.instaReelThumbnail==='' || this.instaReelThumbnail===undefined) && this.post?.instaReelThumbnail!=''){
      this.instaReelThumbnail = this.post?.instagram_reel_thumbnail
    }

    if(this.YTThumbnail==undefined){
      this.YTThumbnail = '';
    }

    if(this.instaReelThumbnail==undefined){
      this.instaReelThumbnail = '';
    }

    /*console.log("instaReelThumbnail 2: ",this.instaReelThumbnail);
    console.log("instaReelThumbnail : ",this.post);*/

    this.getUpdatedContent();

    this.multi_images = this.mediaData.concat(this.galleryMediaData);
    console.log("multi_images in ngOnIt",this.multi_images);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("previewPost ngOnChanges");
    console.log(this.postMedias,"this.postMedias");
    
    if (changes.content) {
      console.log('Content has changed:', changes.content.currentValue);
      // You can also call a function or perform any other action here

      //this.getUpdatedContent();

      this.isContentLoading = true;
      this.contentChanged$.next();
    }
  }

  ngAfterViewInit() {
    //console.log("previewPost ngAfterViewInit")
    this.contentChanged$.pipe(
        debounceTime(200) // adjust the debounce time as needed
    ).subscribe(() => {
      //console.log("previewPost ngAfterViewInit subscribe-->")
      this.getUpdatedContent();
    });
  }

  getUpdatedContent(){
    //console.log("getUpdatedContent")
    if(this.page == "scheduler"){
      if(this.provider == this.sharedService.facebookProvider){
        this.facebookContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.facebookProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      } else if(this.provider == this.sharedService.instagramProvider){
        this.instagramContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.instagramProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      } else if(this.provider == this.sharedService.twitterProvider){
        this.twitterContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.twitterProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      } else if(this.provider == this.sharedService.threadsProvider){
        this.threadsContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.threadsProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      } else if(this.provider == this.sharedService.tiktokProvider){
        this.tiktokContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.tiktokProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      } else {
        this.otherContent = this.sharedService.getPostContentByPlatform(this.content,'',this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      }
    } else {
      this.facebookContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.facebookProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      this.instagramContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.instagramProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      this.twitterContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.twitterProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      this.threadsContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.threadsProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      this.tiktokContent = this.sharedService.getPostContentByPlatform(this.content,this.sharedService.tiktokProvider,this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
      this.otherContent = this.sharedService.getPostContentByPlatform(this.content,'',this.facebookMentions,this.instagramMentions,this.twitterMentions,this.threadsMentions,this.tiktokMentions);
    }

    //setTimeout(()=>{
      this.isContentLoading = false;
    //},150);
  }


  playReel(){
    this.instaReelThumbnail = '';
    this.autoPlay = true;
  }

  getClass(): string {

    let img_count = this.images.length;
    /*if(this.type==='multi'){
      img_count = this.mediaData.concat(this.galleryMediaData).filter((media) => media.file_type === 'image').length;
    }*/
    img_count = this.postMedias.length;

    if (img_count === 1) {
      return 'one_img';
    }
    if (img_count === 2) {
      return 'two_img';
    }
    if (img_count === 3) {
      return 'three_img';
    }
    if (img_count === 4) {
      return 'four_img';
    }
    if (img_count > 4) {
      return 'more_then_four_img';
    }
    return '';
  }
  getPlatformClass(platform): string {
    if (platform === this.sharedService.instagramProvider) {
      return 'post_instagram';
    }
    if (platform === this.sharedService.twitterProvider) {
      return 'post_twitter';
    }
    if (platform === this.sharedService.linkedinProvider) {
      return 'post_linkedin';
    }
    if (platform === this.sharedService.facebookProvider) {
      return 'post_facebook';
    }
    if (platform === this.sharedService.youtubeProvider) {
      return 'post_youtube';
    }
    if (platform === this.sharedService.tiktokProvider) {
      return 'post_tiktok';
    }
    if (platform === this.sharedService.pinterestProvider) {
      return 'post_pinterest';
    }
    if (platform === this.sharedService.threadsProvider) {
      return 'post_twitter';
    }
    return '';
  }

  editPost(id): void {
    this.modal.closeAll();
    this.router.navigate(['/', 'post', 'edit-post', id]);
  }

  deletePost(id): void {
    this.modal.confirm({
      nzTitle: 'Delete post?',
      nzClassName: 'app_warning_modal_main',
      nzContent: AppWarningModalComponent,
      nzComponentParams: {
        message: "Are you sure you want to delete this post?"
      },
      nzOkText: 'Yes, Delete It',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCentered: true,
      nzAutofocus: null,
      nzIconType: null,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        //this.spinnerService.show();
        this.socialAccountService.deletePost(id).subscribe(res => {
          //this.spinnerService.hide();
          if (res.code === 200) {
            if (res.toast === true) {
              this.deleteChange.next({deleted:true});
              this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);

            }
            this.deleted = true;
            this.modal.closeAll();
          }

        }, error => {
          //this.spinnerService.hide();
          this.modal.closeAll();
        });
      }
    });
  }

  checkTime(): boolean {
    const time2 = this.dateConvertPipe.transform(this.time);
    const cur = moment().format('MM/DD/yyyy hh:mm A');
    if (moment(time2).diff(cur, 'minutes') > 15) {
      return true;
    }
    return false;
  }

  /*preview(i): void {
    if (i === 4 && this.images.length > 4) {
      const images: any = [];
      this.spinnerService.show();
      this.images.forEach(async (item) => {
        let url = await this.indexDbService.getPresignedImageUrl(item);
        if (url === undefined || url === null) {
          url = await this.indexDbService.getPresignedImageUrl(item);
        }
        images.push({ src: url });
      });
      this.spinnerService.hide();
      console.log(images,"imagesimages");
      
      this.nzImageService.preview(images);
    }
  }*/

  preview(i): void {
    if (i === 4 && this.postMedias.length > 4) {
      const images: any = [];
      this.spinnerService.show();
      this.postMedias.forEach(async (item) => {
        if(item.file_type==='image'){
          let url = await this.indexDbService.getPresignedImageUrl(item.media_file);
          if (url === undefined || url === null) {
            url = await this.indexDbService.getPresignedImageUrl(item.media_file);
          }
          images.push({ src: url });
        }
      });
      this.spinnerService.hide();
      console.log(images,"imagesimages");
      
      this.nzImageService.preview(images);
    }
  }



  clonePost(id): void {
    this.modal.closeAll();
    this.router.navigate(['/', 'post', 'create-post'], {
      queryParams: {
        type: 'post', reference: id
      }
    });
  }

  previewSliderChanges(event) {
    this.displaySliderItem = false;
    setTimeout(() => {
      this.displaySliderItem = true;
    }, 10);
  }

  getYouTubeUrl() {
    this.dangerousVideoUrl = "https://www.youtube.com/embed/" + this.postId+"?autoplay=1";
    this.youtubeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVideoUrl);
  }

  playYTVideo(){
    //console.log('YTThumbnail',this.YTThumbnail);
    this.YTThumbnail = '';
    this.autoPlay = true;
  }

  onInstaStoryChange(event){
    this.currentStoryIndex = event;
  }

  get filteredPostMedias() {
    return this.postMedias.filter(item => item.file_type === 'image');
  }



}
