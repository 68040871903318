import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserGalleryService {

  constructor(private http: HttpClient, private helper: HelperService) {
  }
  // page= 1,
  // page=' + page + '&
  getUserGalleryOld(page= 1, fileType= 'image', date= 'desc  '): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_user_gallery_old') + '?page=' + page + '&file_type=' + fileType + '&date=' + date);
  }

  getUserGallery( fileType= '', date= 'desc', lastIndexDate = null): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_user_gallery') + '?file_type=' + fileType + '&order_by=' + date + '&last_index_date=' + lastIndexDate);
  }

  deleteMedia(data): Observable<any> {
    var fd = new FormData;
    //fd.append('media_file',JSON.stringify(data));
    fd.append('media_ids',JSON.stringify(data));

    return this.http.post(this.helper.createAPIUrl('delete_gallery_media'),fd);
  }

  uploadMedia(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('upload_media_to_gallery'), postValue,
      { reportProgress: true, observe: 'events' });
  }

  removeMediaOnCancel(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_gallery_media_cancel'), postValue);
  }

  createAlbum(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('create_album'),postValue);
  }

  getAlbumList(date= 'desc'): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_album_list')+ '?order_by='+date);
  }

  deleteAlbum(id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('album_delete') + '/' + id);
  }

  addMediaToAlbum(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('add_Media_to_album'),postValue);
  }

  isFavorite(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('is_favorite'),postValue);
  }

  getIsFavoriteGallery(page= 1, fileType= 'image', date= 'desc  '): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_isFavorite_gallery') + '?page=' + page + '&file_type=' + fileType + '&date=' + date);
  }

  allImageFromAlbum(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('allImageFromAlbum') ,postValue);
  }

  removeMediaFromAlbumList(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('remove_media_from_album') ,postValue);
  }

  addToFavorite(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('add_to_favorite'),postValue);
  }

  getAlbums(date= 'desc'): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_albums')+ '?order_by='+date);
  }


  generateImages(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('generate_images'),postValue);
  }


  generateImageVariant(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('generate_image_variant'),postValue);
  }

  generateHashtags(image): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('generate_hashtags')+ '?image='+image);
  }
  generateVideoHashtags(path): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('generate_video_hashtags')+ '?path='+path);
  }
}
