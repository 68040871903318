<perfect-scrollbar style="max-height: 100%;">
  <nz-table [nzData]="inviteUserList" class="accounts_list_table table_default" nzShowSizeChanger
    [nzFrontPagination]="false" [nzPageSize]="selectedPageSize" [nzPageIndex]="selectedPageIndex" [nzTotal]="totalData"
    [nzPageSizeOptions]=[10,20,50,100] (nzQueryParams)="onQueryParamsChange($event)">
    <thead>
      <tr>
        <th>Email</th>
        <th>Role</th>
        <th class="org_action">Status</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of inviteUserList">
        <tr>
          <td>
            <div class="user_account_name">
              <div class="name_wrp">
                <span>{{item.users?.email}}</span>
              </div>
            </div>
          </td>
          <td>{{item.role.role_name}}</td>
          <td>
            <div class="btn_wrap">
              <ng-container *ngIf="item.invite_status===0"><label class=" label_invite_status">Pending</label>
              </ng-container>
              <ng-container *ngIf="item.invite_status===1"><label class=" label_invite_status">Accepted</label>
              </ng-container>
              <ng-container *ngIf="item.invite_status===2"> <label class=" label_invite_status">Rejected</label>
              </ng-container>

              <button class="btn edit-account"
                (click)="resendEmail(item.users.email,item.users.id,item.role.role_name,item.invite_token)" nz-button
                nzType="primary" [class.disabled_while_loading]="isLoadingInvite && item.users?.email === this.userEmail && isLoadType == 'resendInvite'">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_369_698)">
                    <path
                      d="M12.9588 10.9115L8.77835 15.0919L1.81901 10.9284C1.77774 10.9051 1.73909 10.8773 1.70375 10.8457C1.17837 10.3514 1.38626 9.43643 2.10609 9.18329L21.5063 2.36395L14.6869 21.7641C14.4373 22.4762 13.5393 22.6869 13.0415 22.1834C12.9982 22.1373 12.9618 22.0851 12.9333 22.0286L10.5461 17.5667"
                      stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_369_698">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Resend Email
                  <ng-container *ngIf="isLoadingInvite && (item.users?.email === this.userEmail) && isLoadType == 'resendInvite'" >
                    <span nz-icon nzType="loading"></span>
                  </ng-container>
                </span>
              </button>
              <!-- (click)="deleteAccount(data.id)" -->
              <button class="btn" nz-button nzType="primary" [class.disabled_while_loading]="isLoadingInvite && item.users?.email === this.userEmail && isLoadType == 'deleteInvitation'" (click)="deleteInvitation(item.users.email,item.users.id)">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z"
                    stroke="#494ADF" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <span>Invitation
                  <ng-container *ngIf="isLoadingInvite && (item.users?.email === this.userEmail) && isLoadType == 'deleteInvitation'">
                    <span nz-icon nzType="loading"></span>
                  </ng-container>

                </span>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</perfect-scrollbar>
