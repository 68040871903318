import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Logout } from 'src/app/action-state/actions/auth.action';
import { SubscriptionClear } from 'src/app/action-state/actions/subscription.action';
import { AuthState } from 'src/app/action-state/states/auth.state';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-join-our-team',
  templateUrl: './join-our-team.component.html',
  styleUrls: ['./join-our-team.component.less']
})
export class JoinOurTeamComponent implements OnInit {

  passwordVisible = false;
  confirmPasswordVisible = false;
  isLogin = false;
  token = null;
  isRegister = false;
  inviteMemberData = {};
  acceptInvitationVisible = false;
  registrationForm: FormGroup;
  organizationId = null;
  tokendata: any = '';
  allOrganizations: any = [];
  organization_name: any = '';
  userDetails: any = '';
  email: any = '';
  UserId: any = '';
  loaderType = '';
  isLoading = false;
  isUserActive =  false;
  passwordPattern: any = {
    digit: false,
    uppercase: false,
    lowercase: false,
    special_char: false,
  };
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private store: Store,
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    var tokendata = this.route.queryParams
      .subscribe(params => {
        this.token = params.token;
        this.organizationId = params.organization_id;
        this.organization_name = params.organization_name
        this.email = params.email
        this.UserId = params.user_id
      });
    this.isLogin = this.store.selectSnapshot(AuthState.token) !== null;
    this.checkUserIsActive();
    this.initForm();
    //this.getInitData();
  }

  checkUserIsActive(){
    this.authService.isUserActive(this.UserId).subscribe(res => {
      if(res.code == 200){
        this.isUserActive = res.data;
        if(this.isUserActive){
          this.redirectToLogin();
        }
      }
    });
  }

  initForm(): void {
    this.registrationForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(8), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~])[A-Za-z\\d!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]{8,}$"), this.patternValidator]],
      password_confirmation: [null, [Validators.required, this.confirmationValidator]],
    });
  }

  patternValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }

    var pattern = {
      digit: false,
      uppercase: false,
      lowercase: false,
      special_char: false,
    }

    if (/\d/.test(control.value)) {
      pattern = { ...pattern, digit: true };
    }

    if (/[A-Z]/.test(control.value)) {
      pattern = { ...pattern, uppercase: true };
    }

    if (/[a-z]/.test(control.value)) {
      pattern = { ...pattern, lowercase: true };
    }

    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(control.value)) {
      pattern = { ...pattern, special_char: true };
    }

    this.passwordPattern = pattern;
    return {};

  }


  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.registrationForm.controls.password.value) {
      return { confirm: true };
    }
    return {};
  }
  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.registrationForm.controls.password_confirmation.updateValueAndValidity());
  }

  /*async getInitData() {
    var tokendata = await this.getTokenData();
    if (tokendata['code'] == 200) {
      this.isRegister = true;
      this.inviteMemberData = tokendata['data'];
    }else{
      this.isRegister = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, tokendata['message']);
      this.redirectToLogin();
    }
  }*/

  /*getTokenData() {
    return new Promise(resolve => {
      var postValue = {
        'token': this.token,
        'organization_id': this.organizationId
      }
      this.authService.verifyInviteToken(postValue).subscribe(res => {
        resolve(res);
        this.tokendata = res.data;
      });
    });
  }*/

  submitForm(): void {

    for (const i in this.registrationForm.controls) {
      this.registrationForm.controls[i].markAsDirty();
      this.registrationForm.controls[i].updateValueAndValidity();
    }
    if (this.registrationForm.valid) {
      //var postValue = this.registrationForm.value;
      var postValue = { ...{'token':this.token,'email':this.email}, ...this.registrationForm.getRawValue() };
      console.log("postValue 2: ",postValue)

      this.isLoading = true;
      this.authService.registerTeamMember(postValue).subscribe((res) => {
        if (res.code === 200) {
          if (res.toast === true) {
            this.isLoading = false;
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          }
          this.redirectToLogin();
        }
        if (res.code === 400 && res.toast === true) {
          for (const controlKey of Object.keys(res.message)) {
            this.registrationForm.get(controlKey)?.setErrors({ serverErrors: res.message[controlKey] });
          }
          this.isLoading = false;
        }
        if (res.code === 500 && res.toast === true) {
          this.isLoading = false;
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }
      }, (error) => {
        this.isLoading = false;
      });

    }
  }

  redirectToLogin() {
    this.isLogin = false;
    localStorage.clear();
    this.store.dispatch(new Logout());
    this.store.dispatch(new SubscriptionClear());
    this.router.navigate(['/', 'auth', 'login']);
  }

}
