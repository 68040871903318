import { SnapchatService } from '../../services/snapchat.service';
import * as moment from "moment";

import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output ,ChangeDetectorRef} from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { FacebookService } from "../../services/facebook.service";
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { differenceInCalendarDays,differenceInHours,addHours } from 'date-fns';
import { NzUploadFile } from "ng-zorro-antd/upload";
import { Store } from "@ngxs/store";
import { WasabiService } from "../../services/wasabi.service";
import { AuthState } from '../../action-state/states/auth.state';
import { HttpEvent, HttpEventType } from "@angular/common/http";





@Component({
  selector: 'app-campaign-snapchat-component',
  templateUrl: './campaign-snapchat.component.html',
  styleUrls: ['./campaign-snapchat.component.less']
})
export class CampaignSnapchatComponent implements OnInit, OnDestroy {

  @Input() socialAccount = null;
  // @Input() post;
  post;
  @Input() campaign;

  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();

  selectedAge = [18, 49];
  socialId = '';
  public adForm: FormGroup;
  selectedSocialAccount = null;
  adAccounts = [];
  organizations = [];
  selectedAdAccount = null;
  selectedOrganization = null;
  isAdAccountLoading = false;
  loading = false;
  currentUploading = [];
  images: any = []

  // Start upload images
  type = '';
  // fileMetaDatas = [];
  fileTypes = null;
  mediaFiles = null;
  previewMediaFiles = null;

  appiconMediaFiles = '';
  supportedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'video/mov', 'video/quicktime', 'video/mp4', 'video/m4v'];
  supportedMediaFiles = ".jpg, .jpeg, .png, .mov, .mp4, .m4v";
  supportedIconMediaFilesTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg'];
  isVideoUploaded = false;
  deleteIcon = false;
  appIconUrl = null;
  previewAppIconUrl = null;
  // End Upload Images

  snapchatAdObjectives = [
    {
      'id': 'SNAP_AD',
      'title': 'Awareness',
      'icon': '../../../assets/images/awarness.svg',
      'description': 'Increase awareness of your brand or product.',
    },
    {
      'id': 'APP_INSTALL',
      'title': 'App Installations',
      'icon': '../../../assets/images/app_install.svg',
      'description': 'Send Snapchatters to the App Store to download your app.',
    },
    {
      'id': 'WEB_VIEW',
      'title': 'Drive Traffic To Website',
      'icon': '../../../assets/images/website_url.svg',
      'description': 'Send Snapchatters directly to your website.',
    }
  ];
  selectedObjective = 'SNAP_AD';

  //countries = [];
  countries = [];
  countriesListInclude = [];
  countriesListExclude = [];
  filteredCountries = [];
  filteredCountriesExclude = [];

  selectedIncludedCountries = [];
  isCountriesLoading = false;

  selectedExcludedCountries = [];
  isExcludedCountriesLoading = false;

  //Languages
  languagesList = [];
  selectedLanguages = [];
  isLanguagesLoading = false;

  minAgeOptions = Array.from({ length: 37 }, (_, i) => i + 13); // Ages from 13 to 49
  maxAgeOptions = Array.from({ length: 37 }, (_, i) => i + 13); // Ages from 13 to 49

  gender = 'all';
  age_min = 18;
  age_max = 49;

  isFacebookReachLoading = false;
  estimatedReach = '';
  selectedBudgetType = 'daily';

  isAdSaving = false;

  targets = [];
  selectedTargets = [];
  reconnect = false;
  isOrganozationVisible = false;
  isAdPreviewLoading = false;
  adPreviews = [];

  httpSubscription: any = null;
  error: any = null;
  tooltipTitle = `This organization don't set address! Please set address in under business details.`;

  start_time = moment(new Date()).toDate();
  end_time = moment(this.start_time).add(7, 'days').toDate();

  httpSubscriptionReach: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private facebookService: FacebookService,
    private SnapchatService: SnapchatService,
    private sanitizer: DomSanitizer,
    private wasabiService: WasabiService,
    private store: Store,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.adForm = this.formBuilder.group({
      social_id: [''],
      name: [''],
      ad_account_id: ['', Validators.required],
      organization_id: ['', Validators.required],
      media: [''],
      objective: ['', Validators.required],
      campaign_name: ['',[Validators.required,Validators.maxLength(100)]],
      brand_name: ['', [Validators.required, Validators.maxLength(32)]],
      headline: ['', [Validators.required, Validators.maxLength(34)]],
      app_name: ['', [Validators.maxLength(30)]],
      ios_app_id: ['', [Validators.pattern('[0-9]+')]],
      android_app_url: ['', [Validators.pattern('^[a-zA-Z][a-zA-Z0-9_]*(\\.[a-zA-Z][a-zA-Z0-9_]*)+$')]],
      app_icon: [''],
      website_url: ['',[Validators.pattern('^(https?|ftp)://[\\w-]+(\\.[\\w-]+)+([\\w.,@?^=%&:/~+#-]*[\\w@?^=%&/~+#-])?$')]],
      countries: [[], Validators.required],
      exclude_countries: [],
      languages: [],
      genders: ['all', Validators.required],
      age_min: [''],
      age_max: [''],
      budget: ['', [Validators.required,Validators.max(450000)]],
      budget_type: ['', Validators.required],
      start_time: ['', Validators.required],
      end_time: [''],
      interests: [''],
      advanced_target: [[]],
    }, { validator: [this.headlineMatchValidator,this.requiredFieldsForObjective,this.endTimeValidator] });

    this.countriesListInclude = [{
      "id": "840",
      "name": "United states",
      "code": "us",
      "code2": "us"
    }];
    this.selectedIncludedCountries = [this.countriesListInclude[0]];
    this.countries = [];
    console.log("Select snapchat account", this.socialAccount);
    if (this.socialAccount) {
      this.socialId = this.socialAccount?.social_id;
      if (this.campaign) {
        this.setEditData()
      } else {
        this.getSnapchatOrganization();
      }
    }
  }

  ngOnDestroy() {
    this.campaign = null;
    if (this.httpSubscriptionReach !== null) {
      this.httpSubscriptionReach.unsubscribe();
    }
  }

  headlineMatchValidator(control: AbstractControl): any | null {
    const headlineControl = control.get('headline');
    const brandNameControl = control.get('brand_name');

    if (headlineControl && brandNameControl && headlineControl.value === brandNameControl.value) {
      return { headlineBrandNameMatch: true };
    }
    return null;
  }

  endTimeValidator(control: AbstractControl): any | null {
    const startControl = control.get('start_time');
    const endControl = control.get('end_time');
    console.log("startControl",startControl);
    if (startControl && endControl && startControl.value && endControl.value) {
      const startTime = new Date(startControl.value).getTime();
      const endTime = new Date(endControl.value).getTime();
      console.log("startTime",startTime);
      console.log("endTime",endTime);
      if (startTime > endTime) {
        return { endTimeInvalid: true };
      }
    }
    return null;
  }

  requiredFieldsForObjective(control: AbstractControl): any |null {
    const  objective = control.get('objective').value;
    const appName = control.get('app_name').value;
    const androidAppUrl = control.get('android_app_url').value;
    const iosAppId = control.get('ios_app_id').value;
    const website_url = control.get('website_url').value;
    if (objective === 'APP_INSTALL') {
      const errors: { [key: string]: any } = {};

      if (!androidAppUrl) {
        errors.androidAppUrlRequired = true;
      }
      if (!iosAppId) {
        errors.iosAppIdRequired = true;
      }
      if (!appName) {
        errors.appNameRequired = true;
      }

      return Object.keys(errors).length ? errors : null;
    } else if(objective === 'WEB_VIEW'){
      const errors: { [key: string]: any } = {};

      if (!website_url) {
        errors.websiteUrlRequired = true;
      }
      return Object.keys(errors).length ? errors : null;
    }

      return null;
  }
  setEditData() {
    console.log("setEditData--> campaign", this.campaign)
    /*let adAccount = this.adAccounts.filter(account => account.id === this.campaign.ad_account_id);
    if (adAccount.length>0) {
      this.selectedAdAccount = adAccount[0]*/

    this.selectedAdAccount = {
      id: this.campaign.ad_account_id,
      currency: this.campaign.currency_code,
      currency_symbol: this.campaign.currency_symbol
    };
    console.log("setEditData selectedAdAccount", this.selectedAdAccount)

    this.adForm.patchValue({ campaign_name: this.campaign.name });
    this.adForm.patchValue({ brand_name: this.campaign.brand_name });
    this.adForm.patchValue({ headline: this.campaign.headline });
    this.adForm.patchValue({ website_url: this.campaign.web_url });
    this.adForm.patchValue({ app_name: this.campaign.app_name });
    this.adForm.patchValue({ ios_app_id: this.campaign.ios_app_id });
    this.adForm.patchValue({ android_app_url: this.campaign.android_app_url });
    this.adForm.patchValue({ objective: this.campaign.objective });

    this.snapchatAdObjectives = this.snapchatAdObjectives.filter(objective => {
      return objective.id == this.campaign.objective;
    })
    // this.adForm.patchValue({ media: this.campaign?.media });
    console.log("snapchatAdObjectives",this.snapchatAdObjectives);
    // this.post = { post_id: this.campaign.post_id };
    this.selectedObjective = this.campaign.objective
    // this.getAdPreview();

    this.gender = this.campaign?.genders
    this.selectedBudgetType = this.campaign?.budget_type
    this.previewMediaFiles = this.campaign?.media
    this.fileTypes = this.campaign?.media_type
    this.previewAppIconUrl = this.campaign?.media_icon
    this.selectedAge = [this.campaign.age_min, this.campaign.age_max]

    if (this.campaign.budget_type === "daily") {
      this.adForm.patchValue({ budget: this.campaign.daily_budget });
    } else {
      this.adForm.patchValue({ budget: this.campaign.lifetime_budget });
    }
    // this.adForm.get('countries').patchValue(this.campaign?.included_countries);
    this.countriesListInclude = this.campaign?.included_countries
    // this.filteredCountries = this.campaign?.included_countries
    this.selectedIncludedCountries = this.campaign?.included_countries


    this.start_time = this.campaign?.start_time
    this.end_time = this.campaign?.end_time
    console.log("Country list", this.countriesListInclude);
    //}
  }

  getSnapchatOrganization() {
    this.isAdAccountLoading = true;
    this.organizations = [];
    console.log("Snapchat account loading...");
    this.SnapchatService.getSnapchatOrganization(this.socialId).subscribe(res => {
      this.isAdAccountLoading = false;
      if (res.code === 200) {
        console.log("adAccounts : ", res.data)
        this.organizations = res.data;
        if (this.campaign) {
          console.log("campaign : ", this.campaign)
          this.setEditData();
        }
      } else {
        if(res.data == 'reconnect'){
          this.reconnect = true;
        }
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Snapchat', res.message);
      }
    }, error => {
      this.isAdAccountLoading = false;
    });
  }
  updateOrganization($event) {
    this.adAccounts = [];
    this.selectedAdAccount = null;
    if(!this.selectedOrganization.postal_code){
      this.isOrganozationVisible = true;
    } else{
      this.isOrganozationVisible = false;
    }
    if (this.selectedOrganization?.ad_accounts) {
      this.adAccounts = this.selectedOrganization.ad_accounts
      console.log("Ads account", this.adAccounts)
    }
  }

  updateSocialAccount(event) {
    this.selectedSocialAccount = event;
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): any => {
    if (this.supportedFileTypes.includes(file.type)) {

      const supportedImageTypes: any = ['image/jpg', 'image/png'];
      const supportedVideoTypes: any = ['video/mov', 'video/mp4'];

      if (supportedImageTypes.includes(file.type)) {
        this.type = 'image';
        if (file.size > (5 * 1024 * 1024) || file.size < 2 * 1024) {
          this.error = 'Image size should be between 2KB and 5MB';
          return false;
        }
      } else if (supportedVideoTypes.includes(file.type)) {

        this.type = 'video';
        this.isVideoUploaded = true;
      }


      const size = file.size;
      const fileMimeType = file.type;
      const type = file.type.split('/')[0];

      let ext = file.name.substring(file.name.lastIndexOf(".") + 1);
      let folder = 'campaign/user-' + this.store.selectSnapshot(AuthState.user).id + '/';
      let fileName = moment().unix() + '_' + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) + '.' + ext;
      let fileMeta = { file_size: size, file_type: type, file_mime_type: fileMimeType, media_file: folder + fileName };

      this.loading = true;
      if (!this.fileExistsInUploading(fileName)) {
        this.currentUploading.push({ file_name: fileName, percent: 0, loading: true });
      }

      var index = 0;
      var currentPercent = 0;
      var intervalArray = [];
      let data = false;
      this.type = '';
      this.fileTypes = null;
      this.mediaFiles = null;
      this.previewMediaFiles = null;
      this.wasabiService.uploadPostMedia(file, folder, fileName)
        .subscribe((event: HttpEvent<any>) => {
          console.log("uploadMediaHTTP event.type:", event.type)
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              index = this.currentUploading.findIndex(x => x.file_name === fileName);
              if (index >= 0) {

                intervalArray[index] = setInterval(() => {
                  index = this.currentUploading.findIndex(x => x.file_name === fileName);
                  if (currentPercent !== 99) {
                    currentPercent++;
                    if (this.currentUploading[index]) {
                      this.currentUploading[index].percent = currentPercent;
                    }
                  }
                }, 1000);
              }
              break;
            case HttpEventType.DownloadProgress:
              if (this.currentUploading[index]) {
                this.currentUploading[index].percent = 100;
                clearInterval(intervalArray[index]);
              }
              break;
            case HttpEventType.Response:
              console.log("upload event HttpEventType.Response :", HttpEventType.Response)
              if (this.currentUploading[index]) {
                this.currentUploading[index].percent = 100;
              }
              // setTimeout to display media is uploaded
              setTimeout(() => {
                data = true;
                if (data) {

                  let media_file = folder + fileName;
                  this.images.push(media_file);
                  this.fileTypes = type;
                  this.mediaFiles = media_file;
                  this.previewMediaFiles = media_file;

                  console.log("This mediaFiles", this.mediaFiles)
                  console.log("This fileTypes", this.fileTypes)
                  // delete from uploading list
                  index = this.currentUploading.findIndex(x => x.file_name === fileName);

                  this.currentUploading[index].loading = false;
                  this.currentUploading.splice(index, 1);

                  if (this.currentUploading.length === 0) {
                    this.loading = false;
                    for (var i = 1; i < this.images.length; i++) {
                      clearInterval(intervalArray[i]);
                    }
                    return false;
                  }
                }
              }, 1000);
          }
        }, error => {

        });
      return false;
    } else {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', 'We can\'t quite use that type of file. Could you try one of the following instead: JPG, PNG, MOV, MP4?');
    }
    return false;
  }
  deleteImageMedia() {
    this.type = '';
    this.fileTypes = null;
    this.mediaFiles = null;
    this.previewMediaFiles = null;
  }
  fileExistsInUploading(fileName): boolean {
    return this.currentUploading.some(el => {
      return el.file_name === fileName;
    });
  }

  getLanguages() {
    this.isLanguagesLoading = true;
    this.languagesList = [];
    this.SnapchatService.getSnapchatLanguages(this.socialId).subscribe(res => {
      this.isLanguagesLoading = false;
      if (res.code === 200) {
        this.languagesList = res.data;
        if (this.languagesList.length > 0) {
          this.languagesList = this.languagesList.filter(language => {
            return !this.selectedLanguages.some(selectedLanguage => selectedLanguage.id === language.languages.id);
          });
        }
      }
    }, error => {
      this.isLanguagesLoading = false;
    });
  }

  getCountries() {
    this.isCountriesLoading = true;
    this.countriesListInclude = [];
    this.filteredCountries = [];
    this.SnapchatService.getSnapchatCountries(this.socialId).subscribe(res => {
      this.isCountriesLoading = false;
      if (res.code === 200) {
        this.countriesListInclude = res.data;
        console.log("getCountries : ", this.countriesListInclude)

        if (this.countriesListInclude.length > 0) {
          this.countriesListInclude = this.countriesListInclude.filter(country => {
            return !this.selectedIncludedCountries.some(includedCountry => includedCountry.id === country.id);
          });
        }
      }
    }, error => {
      this.isCountriesLoading = false;
    });
  }

  getSearchCountries(event) {
    if (event != null) {
      this.filteredCountries = this.countriesListInclude.filter(country =>
        country.name.toLowerCase().includes(event.toLowerCase())
      );
      console.log("Filter country", this.filteredCountries);
    }
    this.cdr.detectChanges();
  }
  getSearchCountriesExclude(event) {
    if (event != null) {
      this.filteredCountriesExclude = this.countriesListExclude.filter(country =>
        country.name.toLowerCase().includes(event.toLowerCase())
      );
      console.log("Filter country", this.filteredCountriesExclude);
    }
  }

  getCountriesExclude() {
    this.isExcludedCountriesLoading = true;
    this.countriesListExclude = [];
    this.SnapchatService.getSnapchatCountries(this.socialId).subscribe(res => {
      this.isExcludedCountriesLoading = false;
      if (res.code === 200) {
        this.countriesListExclude = JSON.parse(res.data);
        console.log("getCountriesExclude : ", this.countriesListExclude)

        if (this.countriesListExclude.length > 0) {
          this.countriesListExclude = this.countriesListExclude.filter(country => {
            return !this.selectedIncludedCountries.some(includedCountry => includedCountry.id === country.id);
          });
          /* this.countriesListExclude = this.countriesListExclude.filter(country => {
            return !this.selectedExcludedCountries.some(excludedCountry => excludedCountry.key === country.key);
          }); */
        }
        console.log("getCountriesExclude filter : ", this.countriesListExclude)

      }
    }, error => {
      this.isExcludedCountriesLoading = false;
    });
  }

  updateSnapchatReach() {
    console.log("updateSnapchatReach selectedIncludedCountries:", this.selectedIncludedCountries)
    this.isFacebookReachLoading = true;
    this.estimatedReach = '';
    let postData = {
      ad_account: this.selectedAdAccount.id,
      social_id: this.socialId,
      genders: this.gender ? this.gender : '',
      age_min: this.selectedAge[0],
      age_max: this.selectedAge[1],
      countries: this.selectedIncludedCountries?.map(item => item.code2).join(','),
      excluded_countries: this.selectedExcludedCountries?.map(item => item.code2).join(','),
      languages: this.selectedLanguages?.map(item => item.id).join(','),
    }


    // cancel previous http call
    if (this.httpSubscriptionReach !== null) {
      this.httpSubscriptionReach.unsubscribe();
    }
    console.log("Post data : ", postData)
    this.httpSubscriptionReach = this.SnapchatService.getSnapchatReach(postData).subscribe(res => {
      // console.log("updateSnapchatReach : ", res)
      this.isFacebookReachLoading = false;
      if (res.code === 200) {
        let resData = res.data.audience_size;
        this.estimatedReach = this.formatNumber(resData.audience_size_minimum) + " - " + this.formatNumber(resData.audience_size_maximum);
      } else if(res.code === 500){
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'People reach', res.message);
      }
    }, error => {
      this.isFacebookReachLoading = false;
    });

    //clear the last search
    /*setTimeout(()=>{
      this.countriesListInclude = [];
      this.countriesListExclude = [];
    },1000);*/

  }

  formatNumber(value) {
    if (value >= 1000000000) {
      const billionValue = (value / 1000000).toFixed(2);
      return `${billionValue}B`;
    }else if (value >= 1000000) {
      const millionValue = (value / 1000000).toFixed(2);
      return `${millionValue}M`;
    } else if (value >= 1000) {
      const thousandValue = (value / 1000).toFixed(2);
      return `${thousandValue}K`;
    } else {
      return value.toString();
    }
  }

  getSafeHtml(preview): SafeHtml {
    console.log("getSafeHtml preview:", preview)
    return this.sanitizer.bypassSecurityTrustHtml(preview || '');
  }

  createSnapchatAd() {
    for (const i in this.adForm.controls) {
      this.adForm.controls[i].markAsDirty();
      this.adForm.controls[i].updateValueAndValidity();
    }

    if (this.adForm.valid) {
      this.isAdSaving = true;

      //const adFormData = new FormData();
      const adFormData = {};
      console.log("Form value", this.adForm.value);
      //const adFormData =  this.adForm.value;
      for (const i in this.adForm.controls) {
        const control = this.adForm.controls[i];
        adFormData[i] = control.value;
      }

      adFormData['ad_account_id'] = this.adForm.value?.ad_account_id?.id;
      adFormData['organization_id'] = this.adForm.value?.organization_id?.id;
      adFormData['media'] = this.mediaFiles;
      adFormData['media_type'] = this.fileTypes;
      adFormData['app_icon'] = this.appIconUrl;
      adFormData['currency_code'] = this.selectedAdAccount?.currency;
      adFormData['social_id'] = this.socialId;
      adFormData['age_min'] = this.selectedAge[0];
      adFormData['age_max'] = this.selectedAge[1];

      adFormData['included_countries'] = this.selectedIncludedCountries.map(item => ({ id: item.id, name: item.name, code: item.code, code2: item.code2 }));
      console.log("save Snapchat Ad adFormData:", adFormData)

      this.SnapchatService.snapchatAdCreate(adFormData).subscribe(res => {
        console.log("saveFacebookAd : ", res)
        this.isAdSaving = false;
        if (res.code === 200) {
          this.onSave.emit();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Campaigns', res.message);
          let resData = res.data;
          this.closeModal()
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Campaigns', res.message);
        }
      }, error => {
        this.isAdSaving = false;
      });
    }

  }

  updateSnapchatAd() {
    //change validation rules for update
    this.adForm.get('ad_account_id').clearValidators();
    this.adForm.get('organization_id').clearValidators();
    this.adForm.get('objective').clearValidators();
    this.adForm.get('budget_type').clearValidators();
    this.adForm.updateValueAndValidity();

    for (const i in this.adForm.controls) {
      this.adForm.controls[i].markAsDirty();
      this.adForm.controls[i].updateValueAndValidity();
    }

    for (const controlName in this.adForm.controls) {
      const control = this.adForm.get(controlName);

      if (control instanceof FormGroup) {
      } else {
        // If it's a FormControl, log its errors
        console.log(`Control: ${controlName}, Errors: `, control.errors);
      }
    }
    console.log("updateFacebookAd")

    if (this.adForm.valid) {
      this.isAdSaving = true;

      const adFormData = {};
      for (const i in this.adForm.controls) {
        const control = this.adForm.controls[i];
        adFormData[i] = control.value;
      }

      adFormData['campaign_id'] = this.campaign.campaign_id;

      adFormData['media'] = this.mediaFiles;
      adFormData['media_type'] = this.fileTypes;
      adFormData['app_icon'] = this.appIconUrl;
      adFormData['currency_code'] = this.selectedAdAccount?.currency;
      adFormData['social_id'] = this.socialId;
      adFormData['age_min'] = this.selectedAge[0];
      adFormData['age_max'] = this.selectedAge[1];

      adFormData['included_countries'] = this.selectedIncludedCountries.map(item => ({ id: item.id, name: item.name, code: item.code, code2: item.code2 }));

      console.log("updateSnapchatAd adFormData:", adFormData)

      this.SnapchatService.snapchatAdUpdate(adFormData).subscribe(res => {
        console.log("updateSnapchatAd : ", res)
        this.isAdSaving = false;
        if (res.code === 200) {
          this.onSave.emit();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Campaigns', res.message);
          let resData = res.data;
          this.closeModal()
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Campaigns', res.message);
        }
      }, error => {
        this.isAdSaving = false;
      });
    }
  }

  appIconBeforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): boolean => {
    const supportedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg'];
    const maxFileSize = 2 * 1024 * 1024; // 2 MB

    if (!supportedFileTypes.includes(file.type)) {
      this.displayFileError('You can upload only image files');
      return;
    }

    if (file.size > maxFileSize) {
      this.displayFileError('You can\'t upload files greater than 2 MB');
      return;
    }
    this.loading = true;
    this.storeFile(file);
    return true;
  }

  async storeFile(file): Promise<void> {
    const folder = `campaign/app-icon/user-${this.store.selectSnapshot(AuthState.user).id}/`;
    const fileName = this.generateFileName(file);
    // let fileMeta = { file_size: size, file_type: type, file_mime_type: fileMimeType, media_file: folder + fileName };
    const data = await this.wasabiService.uploadMedia(file, folder, fileName);
    if (data) {
      this.appIconUrl = folder + fileName;
      this.previewAppIconUrl = this.appIconUrl;
      this.deleteIcon = false;
      console.log("Icon url", this.appIconUrl);
    }
    this.loading = false;
  }

  generateFileName(file: NzUploadFile): string {
    const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
    return `${moment().unix()}_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}.${ext}`;
  }

  displayFileError(message: string): void {
    this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Media files', message);
  }

  deleteIconImage(): void {
    this.appIconUrl = null;
    this.previewAppIconUrl = null;
  }


  closeModal() {
    this.onCancel.emit();
  }

  addAccount() {
    const routeURL = 'https://businesshelp.snapchat.com/s/article/create-ad-account';
    window.open(routeURL, "_blank");
  }
  setAddress() {
    const routeURL = 'https://businesshelp.snapchat.com/s/article/verify-email';
    window.open(routeURL, "_blank");
  }
  reconnectAccount(){
    console.log("reconnect function called!.");
    this.router.navigate(['/accounts']);
  }

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) < 0;
  }
  disabledEndDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) < 0;
  }
  onDateChange(start_time){
    const startTime = new Date(start_time).getTime();
    if(this.end_time){
      const endTime = new Date(this.end_time).getTime();
      if(startTime > endTime) {
        this.end_time = moment(this.start_time).add(1, 'days').toDate();
      }
    }
  }
}
