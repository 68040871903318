import { HelperService } from './helper.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  constructor(private http: HttpClient, private helper: HelperService) {
  }

  facebookRequestsToken(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_request_token'));
  }

  facebookAuthenticatedRequests(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('facebook_auth'), postValues);
  }

  saveFacebookAccounts(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('save_facebook_accounts'), postValues);
  }

  getFacebookAccounts(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_facebook_accounts'));
  }

  getConversations(postData): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_conversations'),postData);
  }

  loadMoreConversations(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('load_more_conversations'), postValues);
  }
  sendFacebookMessage(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('send_fb_message'), postValues);
  }
  markMessageAsSeen(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('mark_message_as_seen'), postValues);
  }

  addCommentFacebookPost(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('add_comment_facebook_post'), postValues);
  }

  likeToFacebookPostComment(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('like_comment_facebook_post'), postValues);
  }

  deleteFacebookComment(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_facebook_comment'), postValues);
  }

  markAsResolve(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('mark_as_resolve'), postValues);
  }

  getFacebookAdAccounts(social_id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_ad_accounts') + '?social_id=' + social_id);
  }

  getFacebookCountries(social_id, search): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_countries') + '?social_id=' + social_id + '&search=' + search);
  }

  getFacebookReach(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('facebook_reach'),postValues);
  }

  getFacebookAdPreview(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('facebook_ad_preview'),postValues);
  }

  facebookAdCreate(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('facebookAd_create'), postValues);
  }

  facebookAdUpdate(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('facebookAd_update'), postValues);
  }

  facebookAdChangeStatus(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('facebookAd_change_status'), postValues);
  }

  getFacebookPosts(social_id, page=1): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_posts') + '?social_id=' + social_id + '&page=' + page);
  }

  getFacebookTargeting(social_id,ad_account_id,search): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_targeting_search') + '?social_id=' + social_id + '&ad_account_id='+ad_account_id+'&search=' + search);
  }

  getFacebookCampaigns(social_id,page,limit = 10): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('facebook_get_campaigns') + '?social_id=' + social_id + '&page=' + page + '&limit=' + limit );
  }

  facebookAdDelete(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('facebook_delete_campaign'), postValues);
  }
}
