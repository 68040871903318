<div class="can_subscription_form">
  <form [formGroup]="passwordForm">
    <nz-form-item>
      <nz-form-control class="delete_form" [nzErrorTip]="error">
        <nz-input-group [nzSuffix]="suffixConfirmPasswordTemplateInfo">
          <input [type]="passwordVisible ? 'text' : 'password'" formControlName="password" nz-input
            placeholder="Enter Password" ngModel />
          <ng-template #error let-control>
            <ng-container *ngIf="control.hasError('required')">
              Password is required
            </ng-container>
          </ng-template>
        </nz-input-group>
        <ng-template #suffixConfirmPasswordTemplateInfo>
          <i *ngIf="!passwordVisible" nz-icon nzType="eye" nzTheme="outline"
            (click)="passwordVisible = !passwordVisible"
             [class.disabled_while_loading]="passwordForm.controls?.password?.value?.length===0"></i>
          <i *ngIf="passwordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
            (click)="passwordVisible = !passwordVisible"
             [class.disabled_while_loading]="passwordForm.controls?.password?.value?.length===0"></i>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div class="btn_wrp">
      <button type="button" (click)="cancelSubscription()" nz-button>Cancel Subscription</button>
    </div>
  </form>
</div>
