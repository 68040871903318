<nz-spin [nzSpinning]="isSpinning" [class.img-height]="!isVideo" >

  <img nz-image [class]="class" *ngIf="!isVideo" [nzSrc]="url" [nzPlaceholder]="placeholder" (load)="onLoad()"  />
  <ng-container *ngIf="url !== null && isVideo">
    <button class="btn_video_play">
      <i nz-icon nzType="play-circle" nzTheme="outline"></i>
    </button>
    <video [src]="url" [class]="class" (click)="openVideoPreview()">

    </video>
  </ng-container>

</nz-spin>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Preview" (nzOnCancel)="handleCancel()" [nzFooter]="null"
  [nzClassName]="'upload-modal'" >
  <ng-container *nzModalContent>

    <div class="upload_video text-center">
      <ng-container>
        <div class="upload_video_in ">

          <video [src]="url" controls loop class="modal_video_preview"></video>

        </div>
      </ng-container>
    </div>

  </ng-container>
</nz-modal>
