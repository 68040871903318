import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-change-password-component',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less']
})
export class ChangePasswordComponent implements OnInit {

  passwordVisible = false;
  oldPasswordVisible = false;
  confirmPasswordVisible = false;
  changePassword: FormGroup;
  isLoading = false;
  passwordPattern:any={
    digit: false,
    uppercase: false,
    lowercase: false,
    special_char: false,
  };

  constructor(
    private userService: UserService,
    public sharedService: SharedService,
    private formBuilder: FormBuilder,
    private messageService: NzMessageService,
    private store: Store,
    private spinnerService: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.changePassword = this.formBuilder.group({
      oldPassword: [null, [Validators.required]],
      //password: [null, [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/)]],
      password: [null, [Validators.required, Validators.minLength(8), Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~])[A-Za-z\\d!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]{8,}$"), this.patternValidator]],
      password_confirmation: [null, [Validators.required, this.confirmationValidator]]
    });
  }

  patternValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    }

    var pattern = {
      digit: false,
      uppercase: false,
      lowercase: false,
      special_char: false,
    }

    if (/\d/.test(control.value)) {
      pattern = { ...pattern, digit: true };
    }

    if (/[A-Z]/.test(control.value)) {
      pattern = { ...pattern, uppercase: true };
    }

    if (/[a-z]/.test(control.value)) {
      pattern = { ...pattern, lowercase: true };
    }

    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (format.test(control.value)) {
      pattern = { ...pattern, special_char: true };
    }

    this.passwordPattern = pattern;
    return {};

  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.changePassword.controls.password.value) {
      return { confirm: true };
    }
    return {}
  }

  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.changePassword.controls.password_confirmation.updateValueAndValidity());
  }

  onSubmit(): void {

    for (const i in this.changePassword.controls) {
      this.changePassword.controls[i].markAsDirty();
      this.changePassword.controls[i].updateValueAndValidity();
    }

    if (this.changePassword.valid) {


        const postValue = HelperService.createFormData(this.changePassword.value);
        this.isLoading = true;
        this.userService.changePassword(postValue).subscribe((res) => {

        this.isLoading = false;

        if (res.code === 400 && res.toast === true) {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
        }

        if (res.code === 200) {
          if (res.toast === true) {
            this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
          }
          this.router.navigate(['/', 'auth', 'login']);
        }
      }, (error) => {
          this.isLoading = false;
      });

    }
  }

}
