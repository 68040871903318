export class SubscriptionGet {
  static readonly type = '[Subscription] Get';

  constructor(public payload: any) { }
}

export class SubscriptionClear {
  static readonly type = '[Subscription] Clear';
}

export class SubscriptionUpdate {
  static readonly type = '[Subscription] Update';

  constructor(public payload: any) { }
}
