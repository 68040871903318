import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TeamsService {

  constructor(private http: HttpClient, private helper: HelperService) {
  }

  getOrganizationList(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('organization_list'));
  }

  getSuperAdminOrg(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('super_admin_org'));
  }

  getTeamList(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('team_list'));
  }

  saveOrganizationData(postvalue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('save_organization'), postvalue);
  }

  saveTeamData(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('save_team'), postValue);
  }

  checkMember(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('check_member'), postValue);
  }

  memberSocialNetworks(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('organization_social_networks'));
  }

  getOrganizationMembersList(): Observable<any> {
    //return this.http.get(this.helper.createAPIUrl('organization_members')+ '?page=' + page + '&limit='+limit);
    return this.http.get(this.helper.createAPIUrl('organization_members'));
  }

  teamSocialNetworks(teamId): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('team_social_networks') + '/' + teamId);
  }

  teamMembersList(teamId): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('team_members') + '/' + teamId);
  }

  saveInvitation(formdata): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('save_invitation'), formdata);
  }

  updateInvitation(formdata): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('update_invitation'), formdata);
  }

  deleteMember(id, teamId, type= 0): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('delete_member') + '/' + id + '?org_id=' + teamId + '&type=' + type);
  }

  deleteTeam(id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('delete_team') + '/' + id);
  }

  getMemberData(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('member_detail'), postValue);
  }

  deleteProfile(id, postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_team_social_account') + '/' + id, postValue);
  }

  getPageMembers(page_id) {
    return this.http.get(this.helper.createAPIUrl('view_page_member') + '/' + page_id);
  }

  getInvitedUserList(page,limit) {
    return this.http.get(this.helper.createAPIUrl('invited_user_list')+ '?page=' + page + '&limit='+limit);
  }

  resedUserInvitation(postValue){
    return this.http.post(this.helper.createAPIUrl('resend_user_invitation'),postValue );
  }

  deleteDraftPost(postValue){
    return this.http.post(this.helper.createAPIUrl('delete_draft_post'),postValue );
  }

  deleteInvitation(postValue){
    return this.http.post(this.helper.createAPIUrl('delete_invitation'),postValue );
  }

  getPermissions(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('permissions'));
  }

  savePermissions(postValue){
    return this.http.post(this.helper.createAPIUrl('save_permissions'),postValue );
  }

  teamDetails(id) {
    return this.http.get(this.helper.createAPIUrl('team_details')+ '/'+id);
  }

  deleteFromTeam(postValue){
    return this.http.post(this.helper.createAPIUrl('delete_from_team'),postValue );
  }

  getUserPermission(postData) {
    return this.http.post(this.helper.createAPIUrl('get_user_permission'),postData);
  }

  addToTeamData(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('add_to_team'), postValue);
  }

  getUserSocialProfiles(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_user_social_profiles'), postValue);
  }

  updateUserSocialAccess(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('update_user_social_access'), postValue);
  }

  allOrgMemember(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('all_organization_member'), postValue);
  }

  getAssignedPosts(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_assigned_posts'));
  }

}
