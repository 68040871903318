import { Component, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { AuthState } from 'src/app/action-state/states/auth.state';
import { SharedService } from 'src/app/services/shared.service';
import { SocialAccountService } from 'src/app/services/social-account.service';
import {TeamState} from "../../action-state/states/team.state";
import {SubscriptionState} from "../../action-state/states/subscription.state";
import {InAppPurchaseState} from "../../action-state/states/in-app-purchase.state";
import {InstagramService} from "../../services/instagram.service";
import {FacebookService} from "../../services/facebook.service";

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.less']
})
export class SelectAccountComponent implements OnInit {
  @Input() accounts: any = [];
  @Input() type: any = '';
  @Input() platform: any = '';
  public userId = null;
  public allChecked = false;
  @Output() accountUpdate = new Subject();
  public allDisabledAccounts: any = [];
  isAccountLoading = false;
  dummyItems = Array(5).fill(0).map((x,i)=>i);

  permissions:any = [];
  showPermissionsPopup = false;
  isLoadingConnection = false;
  loadingConnectionProvider = '';
  stripePlan = null;
  isPlanLimitBypass = false;
  modalAccount = null;

  filteredAccounts: any = [];
  accountSearchText='';

  constructor(
    private socialAccountService: SocialAccountService,
    private spinnerService: NgxSpinnerService,
    private store: Store,
    private instagramService: InstagramService,
    private facebookService: FacebookService,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.userId = this.store.selectSnapshot(AuthState.user).id;
    this.isPlanLimitBypass = this.store.selectSnapshot(AuthState).user.is_plan_limit_bypass;

    if (this.store.selectSnapshot(AuthState.user).free_user && this.store.selectSnapshot(AuthState.user).free_user !== null) {
      this.stripePlan = this.store.selectSnapshot(AuthState.user).free_user?.plan_id;
    } else if (this.store.selectSnapshot(SubscriptionState).subscription !== null) {
      this.stripePlan = this.store.selectSnapshot(SubscriptionState).subscription?.plan_id;
    } else if (this.store.selectSnapshot(InAppPurchaseState).inAppPurchase !== null) {
      this.stripePlan = this.store.selectSnapshot(InAppPurchaseState).inAppPurchase?.plan_id;
    } else {
      this.stripePlan = null;
    }

    if (this.accounts.length === 0) {
      if ( this.type === 'bulk_post'){
        //this.getBulkPostAccounts();
      }
    }

    //this.allChecked = this.accounts.every(item => item.is_checked);
    this.accounts.forEach((item, index) => {
      this.accounts[index][`is_checked`] = false;
    });

    this.allDisabledAccounts = this.accounts.filter(item => item.disabled);
  }

  async getAccounts(): Promise<void> {
    await this.getActiveAccounts();
  }

  getActiveAccounts(): Promise<boolean> {
    this.spinnerService.show();
    return new Promise(resolve => {
      this.socialAccountService.getSocialActiveAccount().subscribe((res) => {
        this.spinnerService.hide();
        if (res.code === 200) {
          this.accounts = res.data;
          this.accounts.forEach((item, index) => {
            this.accounts[index][`is_checked`] = false;
          });
        }

        resolve(true);
      }, error => {
        this.spinnerService.hide();
        resolve(true);
      });
    });
  }

  getBulkPostAccounts(): Promise<void> {
    this.isAccountLoading = true;
    return new Promise(resolve => {
      this.socialAccountService.bulkPostSocialAccount().subscribe((res) => {
        this.isAccountLoading = false;
        if (res.code === 200) {
          this.accounts = res.data;
          this.accounts.forEach((item, index) => {
            this.accounts[index][`is_checked`] = false;
          });
          this.allChecked = this.accounts.every(item => item.is_checked);
        }
      }, error => {
        this.isAccountLoading = false;
      });
    });
  }

  updateSingleChecked(account=null): void {
    if (this.accounts.every(item => !item.is_checked)) {
      this.allChecked = false;
    } else {
      this.allChecked = this.accounts.every(item => item.is_checked);
    }

    if( (account.provider === this.sharedService.instagramProvider ||
      account.provider === this.sharedService.facebookProvider) &&
      account.not_have_permission !== undefined && account.not_have_permission.length > 0){
      this.openPermissionModel(account);
    }else{
      this.accountUpdate.next({ accounts: this.accounts });
    }

  }

  updateAllChecked(): void {
    var need_reconnect = false;
    if (this.allChecked) {
      this.accounts = this.accounts.map(item => {

        if(item.disabled){
          return {
            ...item,
            is_checked: false
          };
        }

        if((item.provider === this.sharedService.instagramProvider ||
          item.provider === this.sharedService.facebookProvider) &&
          item.not_have_permission !== undefined && item.not_have_permission.length > 0){
          need_reconnect = true;
          this.openPermissionModel(item);

          return {
            ...item,
            is_checked: false
          };
        }else{
          return {
            ...item,
            is_checked: true
          };
        }

      });
    } else {
      this.accounts = this.accounts.map(item => {
        return {
          ...item,
          is_checked: false
        };
      });
    }

    if(!need_reconnect){
      this.accountUpdate.next({ accounts: this.accounts });
    }

  }

  openPermissionModel(account): void {
    //console.log("openPermissionModel : ",account);
    this.modalAccount = account;
    this.permissions['have_permission'] = account?.user_social_connection_permission;
    this.permissions['have_not_permission'] = account?.not_have_permission;
    this.permissions['modules'] = account?.module_not_work;
    this.showPermissionsPopup = true;
    window.scrollTo(0, 0);
  }

  cancelPermission(): void {
    this.showPermissionsPopup = false;
    this.accounts.map(account=>{
      if(this.modalAccount.id === account.id){
        account.is_checked = false;
      }
    });

  }

  reconnectAccount() {
    if (this.modalAccount && this.modalAccount.provider === this.sharedService.instagramProvider) {
      this.instagramConnect();
    } else if (this.modalAccount && this.modalAccount.provider === this.sharedService.facebookProvider) {
      this.facebookConnect();
    }
  }

  /*Instagram*/
  instagramConnect(): void {
    this.showPermissionsPopup = false
    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.instagramProvider;
    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2') {
      this.instagramService.getInstagramAccounts().subscribe(res => {
        if (res.code === 200) {

          if (res.data.length >= 1 && !this.isPlanLimitBypass) {
            this.isLoadingConnection = false;
            this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Instagram account', 'you can connect only one instagram account');

          } else {
            this.instaAuth();
          }
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    } else if (this.stripePlan === 'plan_3') {
      if (this.accounts.length >= 10 && !this.isPlanLimitBypass) {
        this.isLoadingConnection = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social accounts', 'you can connect only 10 social accounts');

      } else {
        this.instaAuth();
      }
    } else {

      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');

    }
  }

  instaAuth(){
    this.instagramService.instagramRequestsToken().subscribe((response) => {
      if (response.code === 200) {
        localStorage.setItem('error_display', '0');
        window.location.href = response.data.url;
      }
    }, error => {
      this.isLoadingConnection = false;
    });
  }


  /*Facebook*/
  facebookConnect(): void {
    this.isLoadingConnection = true;
    this.loadingConnectionProvider = this.sharedService.facebookProvider;
    if (this.stripePlan === 'plan_1' || this.stripePlan === 'plan_2') {
      this.facebookService.getFacebookAccounts().subscribe(res => {
        this.isLoadingConnection = false;
        if (res.code === 200) {

          if (res.data.length >= 1 && !this.isPlanLimitBypass) {

            this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Facebook account', 'you can connect only one facebook account');
          } else {
            this.facebookAuth();
          }
        }
      }, error => {
        this.isLoadingConnection = false;
      });
    } else if (this.stripePlan === 'plan_3') {

      if (this.accounts.length >= 10 && !this.isPlanLimitBypass) {
        this.isLoadingConnection = false;
        this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social accounts', 'you can connect only 10 social accounts');

      } else {
        this.facebookAuth();
      }
    } else {

      this.isLoadingConnection = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Social account', 'you can not connect any social account');

    }
  }

  facebookAuth(){
    this.facebookService.facebookRequestsToken().subscribe((response) => {
      if (response.code === 200) {
        localStorage.setItem('error_display', '0');
        window.location.href = response.data.url;
      }
    }, error => {
      this.isLoadingConnection = false;
    });
  }

  accountSearch() {
    console.log("accountSearch : ",this.accountSearchText)
    const regex = new RegExp(this.accountSearchText, 'i');
    this.filteredAccounts = this.accounts.filter(item => regex.test(item.name));
  }


}
