import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { IndexDbService } from '../../services/indexDb.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-gallery-image-preview',
  templateUrl: './gallery-image-preview.component.html',
  styleUrls: ['./gallery-image-preview.component.less']
})
export class GalleryImagePreviewComponent implements  OnChanges {

  @Input() imageUrl = null;
  @Input() thumbUrl = null;
  @Input() class = '';
  @Input() isVideo = false;
  @Input() profilePic = false;
  @Input() autoplay = false;
  @Input() preview = false;
  @Output() isLoadingChange = new EventEmitter<boolean>();
  isSpinning = false;
  // url = '../../assets/images/loading2.png';
  url = "../../../assets/images/loading-url.gif";

  placeholder = '../../../assets/images/loading-placeholder.gif';
  isLoading = true;
  isLoading2 = true;

  constructor(private indexDbService: IndexDbService, private sharedService: SharedService) {
  }

  ngOnInit(): void {
    //console.log("GalleryImagePreviewComponent ngOnInit imageUrl",this.imageUrl)

    /*console.log("GalleryImagePreviewComponent ngOnInit thumbUrl",this.thumbUrl)
    console.log("GalleryImagePreviewComponent ngOnInit class",this.class)
    console.log("GalleryImagePreviewComponent ngOnInit isVideo",this.isVideo)
    console.log("GalleryImagePreviewComponent ngOnInit preview",this.preview)*/

    this.getUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.preview){
      this.getUrl();
    }
  }


  async getUrl(): Promise<void> {

    if (this.imageUrl !== '' && this.imageUrl !== null && this.imageUrl !== undefined) {
      if (!this.imageUrl.includes('http') || !this.imageUrl.includes('https')) {
        this.isSpinning = true;
        this.isLoadingChange.emit(true);
        if(this.thumbUrl != null){
          this.url = await this.indexDbService.getPresignedImageUrl(this.thumbUrl);
        }else{
          this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
        }

        if (this.url === undefined || this.url === null) {
          this.url = '../../assets/images/white.png';
          console.log("GalleryImagePreviewComponent url is loading 2..");
          this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
        } else {
          this.isSpinning = false;
          this.isLoadingChange.emit(false);
        }
      } else {
        this.url = this.imageUrl;
      }
    }

    if (this.thumbUrl !== '' && this.thumbUrl !== null && this.thumbUrl !== undefined) {
      if (!this.thumbUrl.includes('http') || !this.thumbUrl.includes('https')) {
        this.isSpinning = false;

        if (this.thumbUrl != null) {
          this.isLoadingChange.emit(true);
          this.url = await this.indexDbService.getPresignedImageUrl(this.thumbUrl);
          //console.log("GalleryImagePreviewComponent getUrl url:",this.url)

          if (this.url === undefined || this.url === null) {
            this.url = '../../assets/images/white.png';
            //console.log("GalleryImagePreviewComponent getUrl thumbUrl if");
            this.url = await this.indexDbService.getPresignedImageUrl(this.imageUrl);
            //console.log("GalleryImagePreviewComponent getUrl url2 if :",this.url)
            this.isLoading = false;
          } else {
            //console.log("GalleryImagePreviewComponent getUrl url else");
            this.isSpinning = false;
            this.isLoading = false;
            this.isLoadingChange.emit(false);
          }
        } else {
          this.url = this.imageUrl;
        }
      }
    }

  }

  onLoad() {
    if (this.placeholder !== this.url) {
      this.isSpinning = false;
    }
  }

  mediaLoaded() {
    //console.log("GalleryImagePreviewComponent imageLoaded")
    this.isLoading = false;
    this.isLoading2 = false;
    this.isLoadingChange.emit(this.isLoading); 
  }
}
