import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SubscriptionState } from '../action-state/states/subscription.state';
import { Resolver } from '../services/resolve';
import { AuthState } from '../action-state/states/auth.state';
import { InAppPurchaseState } from '../action-state/states/in-app-purchase.state';


@Injectable()
export class BulkScheduleGuard implements CanActivate {

  constructor(private router: Router, private store: Store, private resolve: Resolver) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const plan = this.checkSubscription();
    if (plan !== 'plan_1') {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  checkSubscription() {
    let plan = null;
    if (this.store.selectSnapshot(AuthState.user)?.free_user !== null) {
      plan = this.store.selectSnapshot(AuthState.user)?.free_user?.plan_id;
    } else {
      if (this.store.selectSnapshot(SubscriptionState)?.subscription !== null) {
        plan = this.store.selectSnapshot(SubscriptionState)?.subscription?.plan_id;
      }
      else {
        if (this.store.selectSnapshot(InAppPurchaseState)?.inAppPurchase !== null) {
          plan = this.store.selectSnapshot(InAppPurchaseState)?.inAppPurchase?.plan_id;
        }
      }
    }
    return plan;
  }
}
