<div class="subscription_section">
  <div class="title_sec">
    <h5>{{subscription !== null ? "Change Plan" : "Subscribe"}}</h5>
    <div class="btn-wrap">
      <button nz-button nzType="primary" (click)="goToBack()" *ngIf="subscription !== null">
        <i nz-icon nzType="left" nzTheme="outline"></i>
        Back
      </button>
    </div>
  </div>

  <div class="subscription_content_main">

    <app-plans (planSelect)="planSelect($event)" [isLogin]="true"></app-plans>


    <!--<div class="subscription_tab">
      <div class="subscription_tab_wrp">
        <nz-radio-group [(ngModel)]="subscriptionType" nzButtonStyle="solid" (ngModelChange)="changePlan($event)">
          <label nz-radio-button nzValue="monthly">Monthly</label>
          <label nz-radio-button nzValue="annual">Yearly</label>
        </nz-radio-group>
      </div>
    </div>
    <div class="o4a_subscription">

      <ng-container *ngIf="!isPlanLoading">
        <ng-container *ngFor="let plan of planDetails; let i = index">
          <div class="o4a_subscription_main">
          <div class="o4a_subscription_in">
            <div class="header_sec">
              <span *ngIf="i===0">All for One</span>
              <span *ngIf="i===1">One for all</span>
              &lt;!&ndash;<span *ngIf="i===2">Just for you</span>&ndash;&gt;
            </div>
            <div class="body_sec">

              <div class="body_title_1" *ngIf="subscriptionType === 'monthly'">
                <h2>{{plan['planType']}}</h2>
                <h2>${{plan['amount']}}<span>USD/month</span></h2>
                <h4><strong>*Billed Monthly</strong></h4>
              </div>

              <div class="body_title_1" *ngIf="subscriptionType === 'annual'">
                <h2>{{plan['planType']}}</h2>
                <h2>${{plan['amount']/12 | number:'1.2-2'}}<span>USD/month*</span></h2>
                <h4><strong>*Billed Annually</strong></h4>
              </div>

              <div class="body_title_2">
                <h3>
                  <svg viewBox="-42 0 512 512.002" xmlns="http://www.w3.org/2000/svg"><path d="m210.351562 246.632812c33.882813 0 63.222657-12.152343 87.195313-36.128906 23.972656-23.972656 36.125-53.304687 36.125-87.191406 0-33.875-12.152344-63.210938-36.128906-87.191406-23.976563-23.96875-53.3125-36.121094-87.191407-36.121094-33.886718 0-63.21875 12.152344-87.191406 36.125s-36.128906 53.308594-36.128906 87.1875c0 33.886719 12.15625 63.222656 36.132812 87.195312 23.976563 23.96875 53.3125 36.125 87.1875 36.125zm0 0" /><path d="m426.128906 393.703125c-.691406-9.976563-2.089844-20.859375-4.148437-32.351563-2.078125-11.578124-4.753907-22.523437-7.957031-32.527343-3.308594-10.339844-7.808594-20.550781-13.371094-30.335938-5.773438-10.15625-12.554688-19-20.164063-26.277343-7.957031-7.613282-17.699219-13.734376-28.964843-18.199219-11.226563-4.441407-23.667969-6.691407-36.976563-6.691407-5.226563 0-10.28125 2.144532-20.042969 8.5-6.007812 3.917969-13.035156 8.449219-20.878906 13.460938-6.707031 4.273438-15.792969 8.277344-27.015625 11.902344-10.949219 3.542968-22.066406 5.339844-33.039063 5.339844-10.972656 0-22.085937-1.796876-33.046874-5.339844-11.210938-3.621094-20.296876-7.625-26.996094-11.898438-7.769532-4.964844-14.800782-9.496094-20.898438-13.46875-9.75-6.355468-14.808594-8.5-20.035156-8.5-13.3125 0-25.75 2.253906-36.972656 6.699219-11.257813 4.457031-21.003906 10.578125-28.96875 18.199219-7.605469 7.28125-14.390625 16.121094-20.15625 26.273437-5.558594 9.785157-10.058594 19.992188-13.371094 30.339844-3.199219 10.003906-5.875 20.945313-7.953125 32.523437-2.058594 11.476563-3.457031 22.363282-4.148437 32.363282-.679688 9.796875-1.023438 19.964844-1.023438 30.234375 0 26.726562 8.496094 48.363281 25.25 64.320312 16.546875 15.746094 38.441406 23.734375 65.066406 23.734375h246.53125c26.625 0 48.511719-7.984375 65.0625-23.734375 16.757813-15.945312 25.253906-37.585937 25.253906-64.324219-.003906-10.316406-.351562-20.492187-1.035156-30.242187zm0 0" /></svg>
                  <span>{{plan['description'][0]}}</span>
                </h3>
                <h3>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g transform="matrix(1.04,0,0,1.04,-2.5600000000000023,-2.5600000000000023)"><path fill="#000000" d="M124 64c0 5.12-6.29 9.34-7.55 14.06-1.3 4.88 1.99 11.68-.48 15.95-2.51 4.34-10.06 4.86-13.58 8.38s-4.04 11.07-8.38 13.58c-4.27 2.47-11.07-.82-15.95.48C73.34 117.71 69.12 124 64 124s-9.34-6.29-14.06-7.55c-4.88-1.3-11.68 1.99-15.95-.48-4.34-2.51-4.86-10.06-8.38-13.58s-11.07-4.04-13.58-8.38c-2.47-4.27.82-11.07-.48-15.95C10.29 73.34 4 69.12 4 64s6.29-9.34 7.55-14.06c1.3-4.88-1.99-11.68.48-15.95 2.51-4.34 10.06-4.86 13.58-8.38s4.04-11.07 8.38-13.58c4.27-2.47 11.07.82 15.95-.48C54.66 10.29 58.88 4 64 4s9.34 6.29 14.06 7.55c4.88 1.3 11.68-1.99 15.95.48 4.34 2.51 4.86 10.06 8.38 13.58s11.07 4.04 13.58 8.38c2.47 4.27-.82 11.07.48 15.95C117.71 54.66 124 58.88 124 64z" opacity="1" data-original="#2568ef" class=""></path><path fill="#fffcee" d="M81.34 46.12 58.5 68.96 46.66 57.13a6.585 6.585 0 0 0-9.31 0 6.585 6.585 0 0 0 0 9.31l16.61 16.61a6.41 6.41 0 0 0 9.06 0l27.62-27.62a6.585 6.585 0 0 0 0-9.31 6.573 6.573 0 0 0-9.3 0z" opacity="1" data-original="#fffcee"></path></g></svg>
                  <span>{{plan['description'][1]}}</span>
                </h3>
              </div>

              <div class="o4a_body_action">
                &lt;!&ndash;<button class="btn_primary">
                  {{ subscriptionCancelAtOnce ? 'Subscribe to ' + plan['planType'] + ' Plan' : 'Start Your ' +
                  plan['trial'] + ' Days Free Trial'}}
                </button>&ndash;&gt;

                <button type="submit" nz-button nzType="primary" nzBlock class="btn_primary" *ngIf="subscription === null"
                        (click)="selectPlan(plan['plan_id'], plan['planType'], plan['amount'], plan['plan'],false)">
                  <ng-container *ngIf="isFreeTrialSubscribing && selectedPlanId==plan['plan_id']">
                    <span nz-icon nzType="loading"></span>
                  </ng-container>
                  {{(subscriptionCancelAtOnce || inAppPurchaseCancelAtOnce)  ? 'Subscribe to ' + plan['planType'] + ' Plan' : 'Start Your ' +
                  plan['trial'] + ' Days Free Trial'}}
                </button>

                <button type="submit" class="btn_primary" *ngIf="hideorshowButton()"
                        [disabled]="((subscription !== null && subscription.stripe_price === plan['plan_id'])) || selectPlanLoading"
                        (click)="selectPlan(plan['plan_id'], plan['planType'], plan['amount'], plan['plan'])">
                  <span *ngIf="(subscription !== null && subscription.stripe_price !== plan['plan_id'])">
                      {{getText(plan['plan']) + ' To ' + plan['planType'] | titlecase}}
                  </span>
                  <span *ngIf="(subscription !== null && subscription.stripe_price === plan['plan_id']) ">
                    Current Plan
                  </span>
                </button>

              </div>

              <div class="o4a_subscription_list">

                <ul>
                  <li *ngFor="let des of plan['description'].slice(2), let i = index">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z" fill="#423FD1"></path></svg>
                    <span [innerHTML]="des"></span>
                  </li>
                </ul>

              </div>
            </div>
          </div>

            &lt;!&ndash;<div class="o4a_extra">
            <h5>
              <img src="../../../../assets/images/direction.svg" alt="">
              <span>Need a little something more?</span>
            </h5>
            <ul>
              <li>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                    fill="#423FD1"></path>
                </svg>
                <span>Each additional user:<strong>$5/mo</strong></span>
              </li>
              <li>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                    fill="#423FD1"></path>
                </svg>
                <span>Each additional 10 social profiles:<strong>$5/mo</strong></span>
              </li>
              <li>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                    fill="#423FD1"></path>
                </svg>
                <span>Packs of 25 ChatGPT queries:<strong>$5/mo</strong></span>
              </li>
              <li>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                    fill="#423FD1"></path>
                </svg>
                <span>Social Ads management:<strong>$25/mo</strong></span>
              </li>
            </ul>
          </div>&ndash;&gt;

        </div>

          &lt;!&ndash;<div class="o4a_subscription_main">
          <div class="o4a_subscription_in">
            <div class="header_sec">
              <span>One for All</span>
            </div>
            <div class="body_sec">
              <div class="body_title_1">
                <h2>Teams</h2>
                <h2>$125<span>USD/month*</span></h2>
                <h4>*Billed Monthly</h4>
              </div>
              <div class="body_title_2">
                <h3>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" viewBox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;"
                    xml:space="preserve">
                    <g>
                      <g>
                        <path d="M438.09,273.32h-39.596c4.036,11.05,6.241,22.975,6.241,35.404v149.65c0,5.182-0.902,10.156-2.543,14.782h65.461
            c24.453,0,44.346-19.894,44.346-44.346v-81.581C512,306.476,478.844,273.32,438.09,273.32z" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M107.265,308.725c0-12.43,2.205-24.354,6.241-35.404H73.91c-40.754,0-73.91,33.156-73.91,73.91v81.581
            c0,24.452,19.893,44.346,44.346,44.346h65.462c-1.641-4.628-2.543-9.601-2.543-14.783V308.725z" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M301.261,234.815h-90.522c-40.754,0-73.91,33.156-73.91,73.91v149.65c0,8.163,6.618,14.782,14.782,14.782h208.778
            c8.164,0,14.782-6.618,14.782-14.782v-149.65C375.171,267.971,342.015,234.815,301.261,234.815z" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M256,38.84c-49.012,0-88.886,39.874-88.886,88.887c0,33.245,18.349,62.28,45.447,77.524
            c12.853,7.23,27.671,11.362,43.439,11.362c15.768,0,30.586-4.132,43.439-11.362c27.099-15.244,45.447-44.28,45.447-77.524
            C344.886,78.715,305.012,38.84,256,38.84z" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M99.918,121.689c-36.655,0-66.475,29.82-66.475,66.475c0,36.655,29.82,66.475,66.475,66.475
            c9.298,0,18.152-1.926,26.195-5.388c13.906-5.987,25.372-16.585,32.467-29.86c4.98-9.317,7.813-19.946,7.813-31.227
            C166.393,151.51,136.573,121.689,99.918,121.689z" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M412.082,121.689c-36.655,0-66.475,29.82-66.475,66.475c0,11.282,2.833,21.911,7.813,31.227
            c7.095,13.276,18.561,23.874,32.467,29.86c8.043,3.462,16.897,5.388,26.195,5.388c36.655,0,66.475-29.82,66.475-66.475
            C478.557,151.509,448.737,121.689,412.082,121.689z" />
                      </g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                    <g>
                    </g>
                  </svg>
                  <span>10 User</span>
                </h3>
                <h3>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g transform="matrix(1.04,0,0,1.04,-2.5600000000000023,-2.5600000000000023)"><path fill="#000000" d="M124 64c0 5.12-6.29 9.34-7.55 14.06-1.3 4.88 1.99 11.68-.48 15.95-2.51 4.34-10.06 4.86-13.58 8.38s-4.04 11.07-8.38 13.58c-4.27 2.47-11.07-.82-15.95.48C73.34 117.71 69.12 124 64 124s-9.34-6.29-14.06-7.55c-4.88-1.3-11.68 1.99-15.95-.48-4.34-2.51-4.86-10.06-8.38-13.58s-11.07-4.04-13.58-8.38c-2.47-4.27.82-11.07-.48-15.95C10.29 73.34 4 69.12 4 64s6.29-9.34 7.55-14.06c1.3-4.88-1.99-11.68.48-15.95 2.51-4.34 10.06-4.86 13.58-8.38s4.04-11.07 8.38-13.58c4.27-2.47 11.07.82 15.95-.48C54.66 10.29 58.88 4 64 4s9.34 6.29 14.06 7.55c4.88 1.3 11.68-1.99 15.95.48 4.34 2.51 4.86 10.06 8.38 13.58s11.07 4.04 13.58 8.38c2.47 4.27-.82 11.07.48 15.95C117.71 54.66 124 58.88 124 64z" opacity="1" data-original="#2568ef" class=""></path><path fill="#fffcee" d="M81.34 46.12 58.5 68.96 46.66 57.13a6.585 6.585 0 0 0-9.31 0 6.585 6.585 0 0 0 0 9.31l16.61 16.61a6.41 6.41 0 0 0 9.06 0l27.62-27.62a6.585 6.585 0 0 0 0-9.31 6.573 6.573 0 0 0-9.3 0z" opacity="1" data-original="#fffcee"></path></g></svg>
                  <span>50 Social profiles</span>
                </h3>
              </div>
              <div class="o4a_body_action">
                <button class="btn_primary">
                  Start your free 30-day
                </button>
              </div>
              <div class="o4a_subscription_list">
                <ul>
                  <li>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                        fill="#423FD1"></path>
                    </svg>
                    <span>Unlimited ChatGPT</span>
                  </li>
                  <li>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                        fill="#423FD1"></path>
                    </svg>
                    <span>Team oversight</span>
                  </li>
                  <li>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                        fill="#423FD1"></path>
                    </svg>
                    <span>Team approval structure</span>
                  </li>
                  <li>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                        fill="#423FD1"></path>
                    </svg>
                    <span>Brand management tools</span>
                  </li>
                  <li>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                        fill="#423FD1"></path>
                    </svg>
                    <span>Everything from Launch</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="o4a_extra">
            <h5>
              <img src="../../../../assets/images/direction.svg" alt="">
              <span>Need a little something more?</span>
            </h5>
            <ul>
              <li>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                    fill="#423FD1"></path>
                </svg>
                <span>Each additional user:<strong>$5/mo</strong></span>
              </li>
              <li>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                    fill="#423FD1"></path>
                </svg>
                <span>Each additional 10 social profiles:<strong>$10/mo</strong></span>
              </li>
              <li>
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.2871 0.947384C17.4065 0.825525 17.5491 0.728717 17.7064 0.662629C17.8637 0.596541 18.0326 0.5625 18.2032 0.5625C18.3739 0.5625 18.5428 0.596541 18.7001 0.662629C18.8574 0.728717 18.9999 0.825525 19.1194 0.947384C19.6199 1.45313 19.6269 2.27038 19.1369 2.78488L8.78911 15.0174C8.67163 15.1464 8.52906 15.2501 8.37012 15.3221C8.21119 15.3941 8.03923 15.433 7.86477 15.4362C7.69031 15.4395 7.51702 15.4072 7.3555 15.3412C7.19397 15.2752 7.04761 15.1769 6.92536 15.0524L0.628861 8.67188C0.386026 8.42423 0.25 8.09123 0.25 7.74438C0.25 7.39754 0.386026 7.06453 0.628861 6.81688C0.748291 6.69502 0.890833 6.59822 1.04814 6.53213C1.20545 6.46604 1.37436 6.432 1.54499 6.432C1.71561 6.432 1.88452 6.46604 2.04183 6.53213C2.19914 6.59822 2.34168 6.69502 2.46111 6.81688L7.80211 12.2296L17.2521 0.985885C17.263 0.97236 17.2747 0.959503 17.2871 0.947384Z"
                    fill="#423FD1"></path>
                </svg>
                <span>Social Ads management:<strong>$25/mo</strong></span>
              </li>
            </ul>
          </div>
        </div>&ndash;&gt;
        </ng-container>
        <div class="o4a_subscription_main">
          <div class="o4a_subscription_in just_for_you" style="background-image: url('../../../../assets/images/forgot_bg.png');">
            &lt;!&ndash;<div class="header_sec">
              <span>Just for You</span>
            </div>&ndash;&gt;
            <div class="titles2_sec">
              <h5>Concierge</h5>
              <p>Need more than you see here?</p>
            </div>
            <div class="titles3_sec">
              <p>Tell us a little about your needs and we can build the perfect tool for you.</p>
            </div>
            <div class="btn_sec">
              <button (click)="conciergeModal()" class="btn_primary">Just want us to call you?</button>
            </div>
            &lt;!&ndash; <div class="jfy_img">
              <img src="../../../../assets/images/forgot_bg.png" alt="">
            </div> &ndash;&gt;
          </div>
        </div>
      </ng-container>
    </div>
    <div class="subscription_block">
      <ng-container  *ngIf="isPlanLoading && planDetails?.length === 0">
        <ng-container *ngFor="let item of dummyItems; let i = index">
          <div class="subscription_block_in">
            <nz-skeleton [nzAvatar]="false" [nzParagraph]="{ rows: 4 }" *ngFor="let item of dummyItems"></nz-skeleton>
          </div>
        </ng-container>
      </ng-container>
    </div>-->

  </div>

  <!-- End new layout -->


</div>



<!--

<nz-modal [(nzVisible)]="isVisible" [nzTitle]="subscription === null ? 'Subscribe' : getText() +' Subscription'"
  (nzOnCancel)="handleCancel()" nzClassName="subscribe_modal">
  <div *nzModalContent class="card-modal">
    <div class="addon-modal-header" style="display: none;">
      <p class="addon_name">{{selectedPlanName | titlecase}}</p>
      <p class="addon_price">${{selectedPlanAmount}} <span>/{{ sharedService.formattedName(subscriptionType) |
          titlecase}}</span></p>
      &lt;!&ndash; <p class="addon_total"><span>{{getTotal()}}</span></p> &ndash;&gt;
    </div>
    <div class="checkout_main">
      <div class="checkout_left">
        <app-card-data [allCard]="allCard"></app-card-data>
        <app-add-card (getCardDetails)="getAllCard()"></app-add-card>
      </div>
      <div class="checkout_right">
        <div class="checkout_right_in">
          <div class="plan_dtls">
            <div class="pay_anual">
              <span>Summary</span>
            </div>
            <div class="plan_sub">
              <div class="plan_sub_dtl">
                <div class="plan_sub_dtl_in">
                  <p>{{selectedPlanName | titlecase}} {{ sharedService.formattedName(subscriptionType) | titlecase}}</p>
                  <p>${{selectedPlanAmount}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="coupn_code_main" *ngIf="isFirstPurchase">
            <div class="input_main">
              <input type="text" nz-input [(ngModel)]="couponCode" placeholder="Please enter coupon code here"
                [disabled]="discountedAmount!=0" />
            </div>
            <div class="btn_main">
              <button class="btn_primary" nz-button nzType="primary" (click)="applyCouponCode()"
                [class.disabled_while_loading]="couponCode==='' || discountedAmount">Apply</button>
            </div>
          </div>

          <div class="total_main">
            <div class="total_in" *ngIf="discountedAmount">
              <p>Discounted Amount</p>
              <p>${{discountedAmount}}</p>
            </div>
            <div class="total_in">
              <p><strong>Final Total</strong></p>
              <p><strong>${{selectedPlanAmount-discountedAmount}}</strong></p>
            </div>
          </div>
          &lt;!&ndash; <div class="btn_proceed">
            <button class="btn_primary" nz-button nzType="primary">Proceed</button>
          </div> &ndash;&gt;
        </div>
      </div>
    </div>
  </div>
  <div *nzModalFooter class="subscription_footer">
    <button nz-button nzType="primary" type="submit" [disabled]="allCard.length === 0 || isSubscribing"
      class="btn-subscribe" (click)="subscribe()"> {{subscription === null ? 'Subscribe' : getText() +' Subscription'}}
      <ng-container *ngIf="isSubscribing">
        <span nz-icon nzType="loading"></span>
      </ng-container>
    </button>
  </div>
</nz-modal>

<nz-modal nzClassName="down_member_modal" [(nzVisible)]="isSocialVisible"
  nzTitle="Select your social accounts which you want to keep after downgrade membership"
  (nzOnCancel)="handleSocialModalCancel()">
  <div *nzModalContent class="card-modal">
    <nz-alert nzType="error"
      nzMessage="*All other account will be removed and its scheduled post will be canceled/deleted"></nz-alert>
    <div class="bottom_space"></div>
    <div class="user_info_collapse_wrp">
      <perfect-scrollbar style="max-height: calc(100vh - 420px);">
        <nz-collapse>
          <nz-collapse-panel *ngFor="let account of allAccounts | keyvalue" nzHeader="{{account.key}}" nzActive="true">
            <ng-container *ngIf="account.value.length > 0;else noAccounts">
              <div class="down_member_list">

                &lt;!&ndash; <nz-radio-group [(ngModel)]="accountSelection[account.key]" nzName="{{account.key}}">
                  <ng-container *ngFor="let account_list of account.value">
                    <label nz-radio nzValue="{{account_list.value}}">
                      {{account_list.label}}
                    </label>
                  </ng-container>
                </nz-radio-group> &ndash;&gt;



                <ng-container *ngFor="let account_list of account.value">
                  <label nz-checkbox [(ngModel)]="account_list.is_checked" nzValue="{{account_list.value}}"
                    (ngModelChange)="selectPlatformAccount()">
                    <div class="user_info_main">
                      <div class="user_img">
                        <app-image-preview [imageUrl]="account_list.image" [class]="'account-list-image'"
                          [isVideo]="false"></app-image-preview>
                      </div>
                      <div class="user_dtl">
                        <span>{{account_list.label}}</span>
                      </div>
                    </div>
                  </label>
                </ng-container>


              </div>
            </ng-container>
            <ng-template #noAccounts>
              <p>No Accounts Found</p>
            </ng-template>
          </nz-collapse-panel>
        </nz-collapse>
      </perfect-scrollbar>
    </div>

  </div>
  <div *nzModalFooter>
    &lt;!&ndash;[disabled]="accountSelection.FACEBOOK === '' && accountSelection.LINKEDIN === '' && accountSelection.TWITTER === '' && accountSelection.INSTAGRAM === '' && accountSelection.YOUTUBE === '' && accountSelection.TIKTOK === '' && accountSelection.PINTEREST === ''"&ndash;&gt;
    <button nz-button nzType="primary" type="button" class="btn-subscribe"
      (click)="downgradeSelection()">Downgrade</button>
  </div>
</nz-modal>

<nz-modal [(nzVisible)]="isConcierge" nzTitle="Book a Demo" nzClassName="o4a_extra_form_modal" [nzFooter]="null" (nzOnCancel)="isConcierge = false">
  &lt;!&ndash; (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" &ndash;&gt;
  <ng-container *nzModalContent>
    <div class="o4a_extra_form" [formGroup]="conciergeForm">
      <div class="form-group">
        <nz-checkbox-wrapper (nzOnChange)="concierge_option($event)">
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Affiliate/Referral Partnership">Affiliate/Referral Partnership</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Automated Responses (e.g. computer/bot automated answers to common questions)">Automated Responses (e.g. computer/bot automated answers to common questions)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Additional Social Accounts">Additional Social Accounts</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Additional Users">Additional Users</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="A.I. Generated Art Content">A.I. Generated Art Content</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Integration/API connection to additional tools">Integration/API connection to additional tools</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Integration to your internal report system">Integration to your internal report system</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Custom reports generated by O4A">Custom reports generated by O4A</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Connect to another social media platform (e.g. Mastodon, Twitch, Quora, etc.)">Connect to another social media platform (e.g. Mastodon, Twitch, Quora, etc.)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="CRM integration (Monday, Hubspot, Salesforce, etc.)">CRM integration (Monday, Hubspot, Salesforce, etc.)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Dedicated Customer Success Manager (Human Social Media Manager for your account)">Dedicated Customer Success Manager (Human Social Media Manager for your account)</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Content generation by our team">Content generation by our team</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Custom analysis of results">Custom analysis of results</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Specialized Meta Data Analysis of Topic Area">Specialized Meta Data Analysis of Topic Area</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Industry Research and Gap Analysis">Industry Research and Gap Analysis</label>
          <label nz-checkbox [nzDisabled]="isDisabled" nzValue="Video production services">Video production services</label>
        </nz-checkbox-wrapper>
      </div>
      <div class="form-group">
        <nz-form-item>
          <nz-form-label nzFor="email">Email</nz-form-label>
          <nz-input-group>
            <nz-form-control [nzErrorTip]="emailError">
              <input nz-input formControlName="email" id="email2" [disabled]="isDisabled" />
              <ng-template #emailError let-control>
                <ng-container *ngIf="control.hasError('required')">
                  This Email Field is required!
                </ng-container>
                <ng-container *ngIf="control.hasError('email')">
                  Please Enter Valid Email!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-input-group>
        </nz-form-item>
      </div>
      <div class="form-group">
        <label>Notes</label>
        <textarea nz-input nzAutosize formControlName="notes" [disabled]="isDisabled"></textarea>
      </div>
      <div class="o4a_extra_form_action">
        <button class="btn_primary_outline" (click)="isConcierge = false" [disabled]="isDisabled">Cancel</button>
        <button class="btn_primary" (click)="saveConcierge()" [disabled]="isDisabled">
          <span>Save</span>
          <span nz-icon nzType="loading" nzTheme="outline" *ngIf="isLoadning"></span>
        </button>
      </div>
  </div>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="isContactSubmitModal" nzClassName="contact_conf_modal" [nzFooter]="null" (nzOnCancel)="isContactSubmitModal = false">
  <ng-container *nzModalContent>
    <div class="reachus_layout">
      <img src="../../../../assets/images/contact_submit.png" alt="">
      <h2>Contact request sent</h2>
      <p>"Thanks for reaching out! We'll be in touch soon."</p>
    </div>
  </ng-container>
</nz-modal>
-->
