import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {Router} from "@angular/router";
import {SharedService} from "../../services/shared.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.less']
})
export class PaymentModalComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() modalTitle: string;
  @Input() submitText: string;
  @Input() plan:any = null;

  @Output() onCancel = new EventEmitter<any>();
  @Output() onSubmit = new EventEmitter<any>();

  isLoading=false;
  isopenCheckout = false;

  constructor(
    public sharedService: SharedService,
    private userService: UserService,
    private router: Router,
  ) { }


  allCard = [];
  ngOnInit(): void {

    var body = document.body;
    body.classList.add('addonModalBody');

    this.getAllCard();
  }

  getAllCard(): void {
    this.allCard = [];
    this.userService.getUserSaveCard().subscribe(res => {
      if (res.code === 200) {
        this.allCard = res.data;
      }
      if (res.code === 401 && res.toast === true) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      this.router.navigate(['/']);
    });
  }

  getAddonImageUrl(plan){
    
    // console.log(plan);
    
    if(plan?.plan_id == 'social_profiles'){
      return '../../../../assets/images/social_profiles.svg'
    }else if(plan?.plan_id == 'chatgpt'){
      return '../../../../assets/images/chatgpt.svg'
    }else if(plan?.plan_id == 'addon_user'){
      return '../../../../assets/images/user.svg'
    }else{
      return '../../../../assets/images/org_default.png'
    }
  }

  cancel() {
    this.onCancel.emit();
    this.isopenCheckout = false;
    var body = document.body;
    setTimeout(() => {
      body.classList.remove('addonModalBody');
    }, 500);
  }

  submit() {
    let postData = {
      plan_id : this.plan.plan_id
    }
    this.isLoading = true;
    this.userService.buyAddon(postData).subscribe(res => {
      console.log(res);
      this.isLoading = false;
      this.isopenCheckout = false;
      if (res.code === 200) {
        this.onSubmit.emit({'status':1});
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      }else{
        this.onSubmit.emit({'status':0});
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      //this.router.navigate(['/']);
    });
  }

  openCheckout(){
    this.isopenCheckout = true;
  }

}
