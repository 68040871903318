import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-member-invite',
  templateUrl: './edit-member-invite.component.html',
  styleUrls: ['./edit-member-invite.component.less']
})
export class EditMemberInviteComponent implements OnInit {

  id = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private _location: Location,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {

    this.activeRoute.paramMap.subscribe(
      (params: ParamMap) => {
        this.id = params.get('id');
      });

  }

  goBack() {
    this.spinnerService.hide();
    this._location.back();
  }

}
