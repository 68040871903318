import * as moment from "moment";

import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

import {FacebookService} from "../../services/facebook.service";
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-campaign-component',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.less']
})
export class CampaignComponent implements OnInit, OnDestroy {

  @Input() socialAccount = null;
  @Input() post;
  @Input() campaign;

  @Output() onSave = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();

  selectedAge = [18, 65];
  socialId = '';
  public adForm: FormGroup;
  selectedSocialAccount = null;
  adAccounts = [];
  selectedAdAccount = null;
  isAdAccountLoading = false
  facebookAdObjectives = [
    {
      'id': 'OUTCOME_ENGAGEMENT',
      'title': 'Engagement',
      'icon': '../../../assets/images/engagement_icon.png',
      'description': 'Get more messages, purchases through messaging, video views, post engagement, Page likes or event responses.',
    },
    /*{
      'id': 'OUTCOME_TRAFFIC',
      'title': 'Traffic',
      'icon': '../../../assets/images/traffic_icon.png',
      'description': 'Send people to a destination, such as your website, app, Instagram profile or Facebook event.',
    },*/
    {
      'id': 'OUTCOME_AWARENESS',
      'title': 'Awareness',
      'icon': '../../../assets/images/awareness_icon.png',
      'description': 'Show your ads to people who are most likely to remember them.',
    }
  ];
  selectedObjective = 'OUTCOME_ENGAGEMENT';

  //countries = [];
  countries = [];
  countriesListInclude = [];
  countriesListExclude = [];

  selectedIncludedCountries = [];
  isCountriesLoading = false;


  selectedExcludedCountries = [];
  isExcludedCountriesLoading = false;

  minAgeOptions = Array.from({ length: 53 }, (_, i) => i + 13); // Ages from 13 to 65
  maxAgeOptions = Array.from({ length: 53 }, (_, i) => i + 13); // Ages from 13 to 65
  // selectedMinAge = 18;
  // selectedMaxAge = 65;


  gender = 'all';
  age_min = 18;
  age_max = 65;


  isFacebookReachLoading = false;
  estimatedReach = '';
  selectedBudgetType = 'daily';

  isAdSaving = false;

  targets = [];
  selectedTargets = [];
  selectedInterests = [];
  selectedBehaviors = [];
  selectedDemographics = [];

  isAdPreviewLoading = false;
  adPreviews = [];

  httpSubscription: any = null;


  start_time = moment(new Date()).toDate();
  end_time = moment(this.start_time).add(7, 'days').toDate();

  httpSubscriptionReach: any = null;

  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private facebookService: FacebookService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.adForm = this.formBuilder.group({
      social_id: [''],
      name: [''],
      ad_account_id: ['', Validators.required],
      objective: ['', Validators.required],
      countries: [[],Validators.required],
      exclude_countries: [],
      genders: ['all',Validators.required],
      age_min: [''],
      age_max: [''],
      budget: ['',Validators.required],
      budget_type: ['',Validators.required],
      start_time: [''],
      end_time: [''],
      interests: [''],
      advanced_target: [[]],
    });

    /*this.countries = [{
      "country_code": "US",
      "country_name": "United States",
      "key" : "US",
      "name" : "United States",
      "supports_city" : true,
      "supports_region" : true,
      "type" : "country"
    }]*/

    this.countriesListInclude = [{
      "key" : "US",
      "name" : "United States",
    }];

    this.selectedIncludedCountries = [this.countriesListInclude[0]];
    //this.countries = [];

    if(this.socialAccount){
      // console.log(this.socialAccount,"this.socialAccount");
      this.socialId = this.socialAccount?.social_id;

      if(this.campaign){
        this.setEditData()
      }else{
        this.getFacebookAdAccount();
      }
    }


  }

  ngOnDestroy(){
    this.campaign = null;

    // cancel previous http call
    if (this.httpSubscriptionReach !== null) {
      this.httpSubscriptionReach.unsubscribe();
    }
  }

  setEditData() {
    console.log("setEditData--> campaign",this.campaign)
    /*let adAccount = this.adAccounts.filter(account => account.id === this.campaign.ad_account_id);
    if (adAccount.length>0) {
      this.selectedAdAccount = adAccount[0]*/

      this.selectedAdAccount = {
        id: this.campaign.ad_account_id,
        symbol : this.campaign.currency_code
      };
      console.log("setEditData selectedAdAccount", this.selectedAdAccount)

      this.adForm.patchValue({ name: this.campaign.name });

      this.post = { post_id: this.campaign.post_id };
      this.selectedObjective = this.campaign.objective
      this.getAdPreview();

      this.gender = this.campaign?.genders
      this.selectedBudgetType = this.campaign?.budget_type

      this.selectedAge = [this.campaign.target.age_min,this.campaign.target.age_max]

      if(this.campaign.budget_type === "daily"){
        this.adForm.patchValue({ budget: this.campaign.daily_budget/100 });
      }else{
        this.adForm.patchValue({ budget: this.campaign.lifetime_budget/100 });
      }

      this.countriesListInclude = this.campaign?.included_countries
      this.selectedIncludedCountries = this.campaign?.included_countries

      this.countriesListExclude = this.campaign?.excluded_countries
      this.selectedExcludedCountries = this.campaign?.excluded_countries

      console.log('edit target -->',this.campaign?.target.flexible_spec[0])
      console.log('edit target interests-->',this.campaign?.target.flexible_spec[0]['interests'])

      const flexibleSpecKeys = Object.keys(this.campaign?.target?.flexible_spec[0] || {});
      console.log('edit target flexibleSpecKeys-->',flexibleSpecKeys)
      //interests,behaviors,education_schools,

      let targets = [];
      flexibleSpecKeys.forEach((target_key,index)=>{

        this.campaign?.target?.flexible_spec[0][target_key]?.forEach((item,index)=>{
          targets.push({
            type: target_key,
            id: item.id,
            name: item.name,
          })
        });

      });

      this.targets = targets;
      this.selectedTargets = targets;
      this.updateTarget('event');
      console.log('flexible_spec targets--->',targets)

      this.start_time = this.campaign?.start_time
      this.end_time = this.campaign?.end_time
    //}
  }

  getFacebookAdAccount(){
    this.isAdAccountLoading = true;
    this.adAccounts = [];
    this.facebookService.getFacebookAdAccounts(this.socialId).subscribe(res => {
      this.isAdAccountLoading = false;
      if (res.code === 200) {
          this.adAccounts = res.data;
          console.log("adAccounts : ",this.adAccounts)
          if(this.campaign){
            console.log("campaign : ",this.campaign)
            this.setEditData();
          }
      }
    }, error => {
      this.isAdAccountLoading = false;
    });
  }

  async updateSocialAccount(event) {
    //this.selectedSocialAccount = event;
    console.log("updateSocialAccount",this.selectedSocialAccount)
    this.getAdPreview();
  }

  getCountries(search: string){
    if(search) {
      this.isCountriesLoading = true;
      this.countriesListInclude = [];
      this.facebookService.getFacebookCountries(this.socialId, search).subscribe(res => {
        this.isCountriesLoading = false;
        if (res.code === 200) {
          this.countriesListInclude = res.data;
          console.log("getCountries : ", this.countriesListInclude)

          if(this.countriesListInclude.length>0){
            this.countriesListInclude = this.countriesListInclude.filter(country => {
              return !this.selectedIncludedCountries.some(includedCountry => includedCountry.key === country.key);
            });
            this.countriesListInclude = this.countriesListInclude.filter(country => {
              return !this.selectedExcludedCountries.some(excludedCountry => excludedCountry.key === country.key);
            });
          }
          console.log("getCountries filter : ", this.countriesListInclude)

        }
      }, error => {
        this.isCountriesLoading = false;
      });
    }
  }

  getCountriesExclude(search: string){
    if(search) {
      this.isExcludedCountriesLoading = true;
      this.countriesListExclude = [];
      this.facebookService.getFacebookCountries(this.socialId, search).subscribe(res => {
        this.isExcludedCountriesLoading = false;
        if (res.code === 200) {
          this.countriesListExclude = res.data;
          console.log("getCountriesExclude : ", this.countriesListExclude)

          if(this.countriesListExclude.length>0){
            this.countriesListExclude = this.countriesListExclude.filter(country => {
              return !this.selectedIncludedCountries.some(includedCountry => includedCountry.key === country.key);
            });
            this.countriesListExclude = this.countriesListExclude.filter(country => {
              return !this.selectedExcludedCountries.some(excludedCountry => excludedCountry.key === country.key);
            });
          }
          console.log("getCountriesExclude filter : ", this.countriesListExclude)

        }
      }, error => {
        this.isExcludedCountriesLoading = false;
      });
    }
  }

  getTargeting(search: string){
    if(search) {
      if (this.httpSubscription !== null) {
        this.httpSubscription.unsubscribe();
      }
      this.isCountriesLoading = true;
      this.targets = [];
      this.httpSubscription = this.facebookService.getFacebookTargeting(this.socialId, this.selectedAdAccount.id, search).subscribe(res => {
        this.isCountriesLoading = false;
        if (res.code === 200) {
          this.targets = res.data;
          console.log("getTargeting : ", this.countries)
        }
      }, error => {
        this.isCountriesLoading = false;
      });
    }
  }

  updateTarget(event){
    console.log("updateTarget event",event)
    console.log("updateTarget selectedTargets",this.selectedTargets)
    let targetList = ['interests','behaviors']
    this.selectedInterests = this.selectedTargets.filter(item => item.type === 'interests');
    this.selectedBehaviors = this.selectedTargets.filter(item => item.type === 'behaviors');
    this.selectedDemographics = this.selectedTargets.filter(item => !targetList.includes(item.type));
    console.log("updateTarget selectedInterests",this.selectedInterests)
    this.updateFacebookEstimatedReach();

  }

  updateMaxAgeOptions() {
    //this.maxAgeOptions = Array.from({ length: 53 - this.selectedMinAge + 1 }, (_, i) => i + this.selectedMinAge);
    //this.selectedMaxAge = this.selectedMinAge; // Reset selectedMaxAge to match selectedMinAge
  }

  updateFacebookEstimatedReach(){
      console.log("updateFacebookEstimatedReach selectedIncludedCountries:",this.selectedIncludedCountries)
      console.log("updateFacebookEstimatedReach selectedAdAccount:",this.selectedAdAccount)
      this.isFacebookReachLoading = true;
      this.estimatedReach = '';
      let postData = {
        ad_account : this.selectedAdAccount.id,
        social_id: this.socialId,
        genders: this.gender ? this.gender : '',
        // age_min: this.selectedMinAge,
        // age_max: this.selectedMaxAge,

        age_min: this.selectedAge[0],
        age_max: this.selectedAge[1],

        countries: this.selectedIncludedCountries?.map(item => item.key).join(','),
        excluded_countries: this.selectedExcludedCountries?.map(item => item.key).join(','),

        interests: this.selectedInterests.map(item => ({ id: item.id, name: item.name })),
        behaviors: this.selectedBehaviors.map(item => ({ id: item.id, name: item.name })),
        demographics: this.selectedDemographics.map(item => ({ id: item.id, name: item.name, type: item.type })),
      }


    // cancel previous http call
    if (this.httpSubscriptionReach !== null) {
      this.httpSubscriptionReach.unsubscribe();
    }

    this.httpSubscriptionReach = this.facebookService.getFacebookReach(postData).subscribe(res => {
        console.log("updateFacebookEstimatedReach : ", res)
        this.isFacebookReachLoading = false;
        if (res.code === 200) {
          let resData = res.data;
          this.estimatedReach = resData.users_lower_bound +" - " + resData.users_upper_bound;
        }
      }, error => {
        this.isFacebookReachLoading = false;
      });

    //clear the last search
    /*setTimeout(()=>{
      this.countriesListInclude = [];
      this.countriesListExclude = [];
    },1000);*/

  }


  getSafeHtml(preview): SafeHtml {
    console.log("getSafeHtml preview:",preview)
    return this.sanitizer.bypassSecurityTrustHtml(preview || '');
  }

  getAdPreview(){
    console.log("getAdPreview selectedIncludedCountries:",this.selectedIncludedCountries)
    console.log("getAdPreview post:",this.post)
    this.isAdPreviewLoading = true;
    this.adPreviews = [];
    let postData = {
      ad_account : this.selectedAdAccount?.id,
      social_id: this.socialId,
      post_id: this.post.post_id,
    }
    this.facebookService.getFacebookAdPreview(postData).subscribe(res => {
      console.log("getAdPreview : ", res)
      this.isAdPreviewLoading = false;
      if (res.code === 200) {
        this.adPreviews = res.data;
        this.adPreviews.forEach((item,index)=>{
          this.adPreviews[index].preview = this.getSafeHtml(item.preview);
        })
      }
    }, error => {
      this.isAdPreviewLoading = false;
    });
  }

  saveFacebookAd(){
    //adFormData.append('first_comment', this.adForm.value.first_comment);
    for (const i in this.adForm.controls) {
      this.adForm.controls[i].markAsDirty();
      this.adForm.controls[i].updateValueAndValidity();
    }

    if (this.adForm.valid) {
      this.isAdSaving = true;

      //const adFormData = new FormData();
      const adFormData = {};
      //const adFormData =  this.adForm.value;
      for (const i in this.adForm.controls) {
        const control = this.adForm.controls[i];
        adFormData[i] = control.value;
        //adFormData.append(i, control.value);

      }

      adFormData['ad_account_id'] = this.adForm.value?.ad_account_id?.id;
      //adFormData['currency_code'] = this.selectedAdAccount?.currency;
      adFormData['currency_code'] = this.selectedAdAccount?.symbol;
      adFormData['social_id'] = this.socialId;
      adFormData['age_min'] = this.selectedAge[0];
      adFormData['age_max'] = this.selectedAge[1];

      // adFormData['objective'] = this.adForm.value?.objective?.id;
      //adFormData['countries'] = this.selectedIncludedCountries.map(item => item.key).join(',');
      //adFormData['excluded_countries'] = this.selectedExcludedCountries.map(item => item.key).join(',');

      adFormData['interests'] = this.selectedInterests.map(item => ({ id: item.id, name: item.name}));
      adFormData['behaviors'] = this.selectedBehaviors.map(item => ({ id: item.id, name: item.name}));
      adFormData['demographics'] = this.selectedDemographics.map(item => ({
        id: item.id,
        name: item.name,
        type: item.type
      }));

      adFormData['included_countries'] = this.selectedIncludedCountries.map(item => ({ key: item.key, name: item.name }));
      adFormData['excluded_countries'] =  this.selectedExcludedCountries.map(item => ({ key: item.key, name: item.name }));

      if (this.post) {
        adFormData['post_id'] = this.post.post_id;
        adFormData['post_description'] = this.post.description;
        adFormData['post_thumb'] = this.post.media_link;
        adFormData['post_link'] = this.post.post_link;
      }

      /*adFormData.append('social_id', this.socialId);
      adFormData.append('objective', this.adForm.get('objective')?.value?.id);*/
      console.log("saveFacebookAd adFormData:", adFormData)

      this.facebookService.facebookAdCreate(adFormData).subscribe(res => {
        console.log("saveFacebookAd : ", res)
        this.isAdSaving = false;
        if (res.code === 200) {
          this.onSave.emit();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Campaigns', res.message);
          let resData = res.data;
          this.closeModal()
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Campaigns', res.message);
        }
      }, error => {
        this.isAdSaving = false;
      });
    }

  }

  updateFacebookAd(){
    console.log("updateFacebookAd")

    //change validation rules for update
    this.adForm.get('ad_account_id').clearValidators();
    this.adForm.get('objective').clearValidators();
    this.adForm.get('budget_type').clearValidators();
    this.adForm.updateValueAndValidity();

    for (const i in this.adForm.controls) {
      this.adForm.controls[i].markAsDirty();
      this.adForm.controls[i].updateValueAndValidity();
    }

    for (const controlName in this.adForm.controls) {
      const control = this.adForm.get(controlName);

      if (control instanceof FormGroup) {
      } else {
        // If it's a FormControl, log its errors
        console.log(`Control: ${controlName}, Errors: `, control.errors);
      }
    }
    console.log("updateFacebookAd")

    if (this.adForm.valid) {
      this.isAdSaving = true;

      //const adFormData = new FormData();
      const adFormData = {};
      //const adFormData =  this.adForm.value;
      for (const i in this.adForm.controls) {
        const control = this.adForm.controls[i];
        adFormData[i] = control.value;
        //adFormData.append(i, control.value);

      }

      adFormData['campaign_id'] = this.campaign.campaign_id;

      //adFormData['c urrency_code'] = this.selectedAdAccount?.currency;
      adFormData['currency_code'] = this.selectedAdAccount?.symbol;
      adFormData['social_id'] = this.socialId;
      adFormData['age_min'] = this.selectedAge[0];
      adFormData['age_max'] = this.selectedAge[1];

      // adFormData['objective'] = this.adForm.value?.objective?.id;
      //adFormData['countries'] = this.selectedIncludedCountries.map(item => item.key).join(',');
      //adFormData['excluded_countries'] = this.selectedExcludedCountries.map(item => item.key).join(',');

      adFormData['interests'] = this.selectedInterests.map(item => ({ id: item.id, name: item.name}));
      adFormData['behaviors'] = this.selectedBehaviors.map(item => ({ id: item.id, name: item.name}));
      adFormData['demographics'] = this.selectedDemographics.map(item => ({
        id: item.id,
        name: item.name,
        type: item.type
      }));

      adFormData['included_countries'] = this.selectedIncludedCountries.map(item => ({ key: item.key, name: item.name }));
      adFormData['excluded_countries'] =  this.selectedExcludedCountries.map(item => ({ key: item.key, name: item.name }));



      /*adFormData.append('social_id', this.socialId);
      adFormData.append('objective', this.adForm.get('objective')?.value?.id);*/
      console.log("updateFacebookAd adFormData:", adFormData)

      this.facebookService.facebookAdUpdate(adFormData).subscribe(res => {
        console.log("updateFacebookAd : ", res)
        this.isAdSaving = false;
        if (res.code === 200) {
          this.onSave.emit();
          this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, 'Campaigns', res.message);
          let resData = res.data;
          this.closeModal()
        } else {
          this.sharedService.displayNotification(this.sharedService.messageTypeError, 'Campaigns', res.message);
        }
      }, error => {
        this.isAdSaving = false;
      });
    }
  }

  closeModal(){
    this.onCancel.emit();
  }

  addAccount(){
    const routeURL = 'https://www.facebook.com/business/help/132073386867900?id=160022731342707';
    window.open(routeURL, "_blank");
  }

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) < 0;
  }


}
