import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private helper: HelperService) {
  }

  plans(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('plans'),);
  }

  register(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('register'), postValue);
  }

  login(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('login'), postValue);
  }

  forgotPassword(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('forgot_password'), postValue);
  }

  resetPassword(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('reset_password'), postValue);
  }

  autoLogin(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('authenticate'));
  }

  logout(): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('logout'), {});
  }

  verifyEmail(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('verify_email'), postValue);
  }

  resendVerifyEmail(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('resend_verify_email_link'), postValue);
  }

  verifyInviteToken(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('verify_invite_token'), postValue);
  }

  registerTeamMember(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('register_team_member'), postValue);
  }

  rejectIvitation(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('reject_invitation'), postValue);
  }

  acceptIvitation(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('accept_invitation'), postValue);
  }

  verifyOtp(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('otp_verify'), postValue);
  }

  verifyOtpChangePassword(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('otp_verify_change_password'), postValue);
  }

  UserCheck(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('user_check'), postValue);
  }

  saveToken(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('save_token'), postValue);
  }

  getOrganisationSubscription(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_organisation_subscription'));
  }

  isUserActive(userId): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('is_user_active')+'/'+userId);
  }
}
