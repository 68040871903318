<div class="campaign_header">
  <div class="campaign_header_left">
    <div class="boost_post_main">
      <app-provider [className]="'social_icon'" [provider]="socialAccount.provider"></app-provider>
      <span>
        {{ socialAccount.provider | titlecase }} Ads
      </span>
    </div>
  </div>
  <div class="campaign_header_right">


    <div class="campaign_info">
      <div class="campaign_info_left">
        <app-image-preview [isVideo]="false" [imageUrl]="socialAccount.image" [class]="'account-list-image'"
          *ngIf="socialAccount.image !== null"></app-image-preview>
        <app-provider [className]="'social_icon'" [provider]="socialAccount.provider"></app-provider>
      </div>
      <div class="campaign_info_right">
        <h5>{{ socialAccount.name }}</h5>
        <p>{{ socialAccount.type | titlecase }}</p>
      </div>
    </div>
    <a href="javascript:;" class="close_icon" (click)="closeModal()" [class.disabled_while_loading]="isAdSaving || loading">
      <img src="../../../assets/images/close_icon.svg" alt="">
    </a>
  </div>
</div>
<div class="campaign_wrapper">


  <!--<ng-container *ngIf="isAdAccountLoading">
    <div class="account_loading_card">
      <div class="campaign_card">
        <div class="campaign_card_body">
          <div class="before_preview_wrapper ">
            <div class="before_preview">
              <img src="../../../assets/images/break-time.png" alt="">
              <span>Account is loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>-->

  <!--<ng-container *ngIf="!isAdAccountLoading">-->
  <ng-container>

    <ng-container *ngIf="!campaign && !isAdAccountLoading && organizations.length === 0">
      <div class="campaign_form">
        <div class="camp_title">
          <h5>Organization and Ad Account</h5>
          <p>Now let's get going! Where should create advertisement?</p>
        </div>
      </div>
      <div class="campaign_page">
        <div class="campaign_page_left">
          <app-image-preview [isVideo]="false" [imageUrl]="socialAccount.image" [class]="'account-list-image'"
            *ngIf="socialAccount.image !== null"></app-image-preview>
          <app-provider [className]="'social_icon'" [provider]="socialAccount.provider"></app-provider>
        </div>
        <div class="campaign_page_right">
          <h5>{{ socialAccount.name }}</h5>
          <p>{{ socialAccount.type | titlecase }}</p>
        </div>
      </div>
      <ng-container *ngIf="!reconnect">
        <div class="over_warning">
          <img src="../../../assets/images/info_icon.svg" alt="">
          <span>Your account don't have any ad accounts connected to the selected Account.
            Add an ad account to start campaigning.</span>
        </div>
        <div>
          <button class="btn_primary" (click)="addAccount()">Add an ad account</button>
        </div>
      </ng-container>
      <ng-container *ngIf="reconnect">
        <div class="over_warning">
          <img src="../../../assets/images/info_icon.svg" alt="">
          <span>Your Snapchat account token has expired. Please reconnect your account.</span>
        </div>
        <div>
            <button class="btn_primary" (click)="reconnectAccount()">Reconnect account</button>
        </div>
      </ng-container>
    </ng-container>

    <!-- <ng-container *ngIf="isAdAccountLoading">
      test
    </ng-container> -->

    <ng-container *ngIf="campaign || (isAdAccountLoading || organizations.length > 0)">
      <div class="campaign_wrapper_in" [class.bg-blur]="isAdAccountLoading">
        <div class="campaign_wrapper_left">
          <div class="campaign_form">
            <div class="camp_title">
              <h5>Organization and Ad Account</h5>
              <p>Now let's get going! Where should create advertisement?</p>
            </div>
            <form [formGroup]="adForm">
              <div class="campaign_card">

                <ng-container *ngIf="!campaign">
                  <div class="campaign_card_header">
                    <h5>Organization</h5>
                  </div>
                  <div class="campaign_card_body">
                    <div class="form-group">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please select organization!">
                          <nz-select [nzDisabled]="isAdAccountLoading" nzDropdownClassName="default_dropdown_border"
                            formControlName="organization_id" [nzPlaceHolder]="'Select Organization'"
                            [(ngModel)]="selectedOrganization" (ngModelChange)="updateOrganization($event)">
                            <nz-option *ngFor="let account of organizations" [nzLabel]="account.name" [nzValue]="account" nzCustomContent>
                              <span>{{account.name}}</span>
                              <i nz-icon nzType="info-circle" *ngIf="account && !account?.postal_code" nzTheme="fill" class="margin-left-2" nz-tooltip [nzTooltipTitle]="tooltipTitle"></i>
                            </nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div *ngIf="adAccounts">
                    <div class="campaign_card_header">
                      <h5>Ads Account</h5>
                    </div>
                    <div class="campaign_card_body">
                      <div class="form-group">
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please select ad account!">
                            <nz-select [nzDisabled]="isAdAccountLoading" nzDropdownClassName="default_dropdown_border"
                              formControlName="ad_account_id" [nzPlaceHolder]="'Please select ad account'"
                              [(ngModel)]="selectedAdAccount" (ngModelChange)="updateSocialAccount($event)"
                              (nzOpenChange)="sharedService.openNzSelect($event)" (disabled)="adAccounts.length <= 0">
                              <nz-option *ngFor="let account of adAccounts"
                                         [nzValue]="account" [nzLabel]="account.name"></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>


              <ng-container *ngIf="selectedAdAccount && !isOrganozationVisible">

                <div class="campaign_card">
                  <div class="campaign_card_body">
                    <div class="form-group">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24">Upload Media <span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="The image and video dimensions should be 1080 pixels in width and 1920 pixels in height, maintaining a 9:16 aspect ratio."
                          nz-icon nzType="info-circle" nzTheme="fill"></span></nz-form-label>
                        <div *ngIf="!previewMediaFiles || previewMediaFiles === 'null'">
                          <nz-upload class="avatar-uploader" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false" formControlName="media" [nzMultiple]="false" [nzAccept]="supportedMediaFiles" [nzBeforeUpload]="beforeUpload" ngDefaultControl>
                            <ng-container>
                              <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'" *ngIf="loading"></i>
                              <img src="../../../assets/images/upload_media_icon.png" *ngIf="!loading" alt="">
                              <div class="ant-upload-text">Upload</div>
                            </ng-container>
                          </nz-upload>
                        </div>
                        <div *ngIf="previewMediaFiles && previewMediaFiles !== 'null'" class="create-team-image-wrap">
                          <div class="img">
                            <app-image-preview [imageUrl]="previewMediaFiles" [class]="'organization-icon'" [isVideo]="fileTypes ==='video'">
                            </app-image-preview>
                            <div class="icon" [class.disabled_while_loading]="loading">
                              <svg class="delete-icon" (click)="deleteImageMedia()" width="12" height="12" viewBox="0 0 12 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M0.430947 0.431252C0.641916 0.220347 0.928012 0.101868 1.22632 0.101868C1.52463 0.101868 1.81073 0.220347 2.0217 0.431252L5.9997 4.40925L9.9777 0.431252C10.1899 0.226325 10.4741 0.112931 10.769 0.115494C11.064 0.118057 11.3462 0.236372 11.5547 0.444957C11.7633 0.653541 11.8816 0.935705 11.8842 1.23068C11.8868 1.52565 11.7734 1.80983 11.5684 2.022L7.59045 6L11.5684 9.978C11.7734 10.1902 11.8868 10.4744 11.8842 10.7693C11.8816 11.0643 11.7633 11.3465 11.5547 11.555C11.3462 11.7636 11.064 11.8819 10.769 11.8845C10.4741 11.8871 10.1899 11.7737 9.9777 11.5688L5.9997 7.59075L2.0217 11.5688C1.80952 11.7737 1.52534 11.8871 1.23037 11.8845C0.9354 11.8819 0.653235 11.7636 0.444651 11.555C0.236067 11.3465 0.117752 11.0643 0.115189 10.7693C0.112626 10.4744 0.226019 10.1902 0.430947 9.978L4.40895 6L0.430947 2.022C0.220042 1.81103 0.101562 1.52494 0.101562 1.22663C0.101562 0.928318 0.220042 0.642221 0.430947 0.431252Z"
                                  fill="white" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </nz-form-item>
                    </div>
                  </div>
                </div>
                <div class="campaign_card">
                  <div class="campaign_card_header">
                    <h5>Campaign Objective</h5>
                  </div>
                  <div class="campaign_card_body">
                    <div class="cam_title" *ngIf="!campaign">
                      <p>What is the objective that best fits your marketing goals?</p>
                    </div>
                    <div class="cam_obj_list">
                      <div class="cam_obj_list_in">
                        <nz-form-item *ngIf="!campaign">
                          <nz-form-control nzErrorTip="Please select campaign objective">
                            <nz-radio-group formControlName="objective" [(ngModel)]="selectedObjective">
                              <ng-container *ngFor="let objective of snapchatAdObjectives">
                                <label nz-radio nzValue="{{ objective.id }}" *ngIf="!campaign">
                                  <div class="cam_obj_main">
                                    <div class="cam_obj_left">
                                      <img src="{{ objective.icon }}" alt="">
                                    </div>
                                    <div class="cam_obj_right">
                                      <h5>{{ objective.title }}</h5>
                                      <span>{{ objective.description }}</span>
                                    </div>
                                  </div>
                                </label>
                              </ng-container>
                            </nz-radio-group>
                          </nz-form-control>
                        </nz-form-item>
                        <ng-container *ngIf="campaign">
                          <ng-container *ngFor="let objective of snapchatAdObjectives">
                            <div class="cam_obj_main">
                              <div class="cam_obj_left">
                                <img src="{{ objective.icon }}" alt="">
                              </div>
                              <div class="cam_obj_right">
                                <h5>{{ objective.title }}</h5>
                                <span>{{ objective.description }}</span>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                    <div>
                      <div class="campaign_card_header" >
                        <h5>Campaign name<span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="Up to 100 characters with spaces applied."
                          nz-icon nzType="info-circle" nzTheme="fill"></span></h5>
                      </div>
                      <div class="campaign_card_body">
                          <div class="form-group ">
                            <nz-form-item>
                              <nz-form-control>
                                <input class="ant-number" nz-input placeholder="Campaign name" type="text" formControlName="campaign_name" />
                                <div *ngIf="adForm.get('campaign_name').hasError('maxlength') && (adForm.get('campaign_name').dirty || adForm.get('campaign_name').touched)">
                                  <div class="error-message">Maximum length exceeded (maximum 100 characters).</div>
                                </div>
                                <div *ngIf="adForm.get('campaign_name').hasError('required') && (adForm.get('campaign_name').dirty || adForm.get('campaign_name').touched)">
                                  <div class="error-message">Please enter Campaign name.</div>
                                </div>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                      </div>
                    </div>
                    <div>
                      <div class="campaign_card_header" >
                        <h5 >Brand Name <span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="Up to 32 characters with spaces applied."
                          nz-icon nzType="info-circle" nzTheme="fill"></span></h5>
                      </div>
                      <div class="campaign_card_body">
                        <div class="form-group ">
                          <nz-form-item>
                              <nz-form-control>
                                <input class="ant-number" nz-input placeholder="Replace profile name" type="text" formControlName="brand_name" />
                                <div *ngIf="adForm.hasError('headlineBrandNameMatch') && (adForm.get('brand_name').dirty || adForm.get('brand_name').touched)">
                                  <div class="error-message">Headline and Brand Name should not match.</div>
                                </div>
                                <div *ngIf="adForm.get('brand_name').hasError('maxlength') && (adForm.get('brand_name').dirty || adForm.get('brand_name').touched)">
                                  <div class="error-message">Maximum length exceeded (maximum 32 characters).</div>
                                </div>
                                <div *ngIf="adForm.get('brand_name').hasError('required') && (adForm.get('brand_name').dirty || adForm.get('brand_name').touched)">
                                  <div class="error-message">Please enter Brand name.</div>
                                </div>
                              </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="campaign_card_header" >
                        <h5>Headline <span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="Up to 34 characters with spaces applied."
                          nz-icon nzType="info-circle" nzTheme="fill"></span></h5>
                      </div>
                      <div class="campaign_card_body">
                        <div class="form-group ">
                          <nz-form-item>
                            <nz-form-control>
                              <input class="ant-number" nz-input placeholder="Headline to display in ad" type="text" formControlName="headline" />
                              <div *ngIf="adForm.hasError('headlineBrandNameMatch') && (adForm.get('headline').dirty || adForm.get('headline').touched)">
                                <div class="error-message">Headline and Brand Name should not match.</div>
                              </div>
                              <div *ngIf="adForm.get('headline').hasError('maxlength') && (adForm.get('headline').dirty || adForm.get('headline').touched)">
                                <div class="error-message">Maximum length exceeded (maximum 34 characters).</div>
                              </div>
                              <div *ngIf="adForm.get('headline').hasError('required') && (adForm.get('headline').dirty || adForm.get('headline').touched)">
                                <div class="error-message">Please enter Headline.</div>
                              </div>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </div>

                    <!-- For Web View -->
                    <div *ngIf="selectedObjective === 'WEB_VIEW'">
                      <div class="campaign_card_header" >
                        <h5>Website URL</h5>
                      </div>
                      <div class="campaign_card_body">
                        <div class="form-group ">
                          <nz-form-item>
                            <nz-form-control>
                              <input class="ant-number" nz-input placeholder="https://www.example.com" type="text" formControlName="website_url" />
                              <div *ngIf="adForm.hasError('websiteUrlRequired') && (adForm.get('website_url').dirty || adForm.get('website_url').touched)">
                                <div class="error-message">Website url is required.</div>
                              </div>
                              <div *ngIf="adForm.get('website_url').hasError('pattern') && (adForm.get('website_url').dirty || adForm.get('website_url').touched)">
                                <div class="error-message">Invalid Website url.</div>
                              </div>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </div>
                    <!-- End Web view -->
                    <!-- For App install -->
                    <div *ngIf="selectedObjective === 'APP_INSTALL'">
                      <div>
                        <div class="campaign_card_header" >
                          <h5>App name<span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="Up to 30 character allowed."
                            nz-icon nzType="info-circle" nzTheme="fill"></span></h5>
                        </div>
                        <div class="campaign_card_body">
                          <div class="form-group ">
                            <nz-form-item>
                              <nz-form-control>
                                <input class="ant-number" nz-input placeholder="Your App Name, i.e. Snapchat" type="text" formControlName="app_name" />
                                <div *ngIf="adForm.get('app_name').hasError('maxLength') && (adForm.get('app_name').dirty || adForm.get('app_name').touched)">
                                  <div class="error-message">Maximum length exceeded (maximum 30 characters).</div>
                                </div>
                                <div *ngIf="adForm.hasError('appNameRequired') && (adForm.get('app_name').dirty || adForm.get('app_name').touched)">
                                  <div class="error-message">App name is required.</div>
                                </div>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="campaign_card_header" >
                          <h5>IOS App Id</h5>
                        </div>
                        <div class="campaign_card_body">
                          <div class="form-group ">
                            <nz-form-item>
                              <nz-form-control>
                                <input class="ant-number" nz-input placeholder="i.e. 1580387684" type="text" formControlName="ios_app_id" />
                                <div *ngIf="adForm.get('ios_app_id').hasError('pattern')">
                                  <div class="error-message">Please enter a valid IOS App Id.</div>
                                </div>
                                <div *ngIf="adForm.hasError('iosAppIdRequired') && (adForm.get('ios_app_id').dirty || adForm.get('ios_app_id').touched)">
                                  <div class="error-message">IOS App id is requird.</div>
                                </div>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="campaign_card_header" >
                          <h5>Android App Url</h5>
                        </div>
                        <div class="campaign_card_body">
                          <div class="form-group ">
                            <nz-form-item>
                              <nz-form-control>
                                <input class="ant-number" nz-input placeholder="i.e. com.app.basalsocial" type="text" formControlName="android_app_url"/>
                                <div *ngIf="adForm.get('android_app_url').hasError('pattern')">
                                  <div class="error-message">Please enter a valid Android app URL.</div>
                                </div>
                                <div *ngIf="adForm.hasError('androidAppUrlRequired') && (adForm.get('android_app_url').dirty || adForm.get('android_app_url').touched)">
                                  <div class="error-message">Android app url id is requird..</div>
                                </div>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="campaign_card_body">
                          <div class="form-group">
                            <nz-form-item>
                              <nz-form-label [nzSpan]="24">App Icon<span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="Required by Snapchat. A minimum dimension of 200px by 200px and maximum dimension of 2000px by 2000px version of your app icon. Icon must be 1:1 ratio. 2MB max file size."
                                nz-icon nzType="info-circle" nzTheme="fill"></span></nz-form-label>
                              <div *ngIf="!previewAppIconUrl || previewAppIconUrl ==='null'">
                                <nz-upload class="avatar-uploader" nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false"
                                           [nzBeforeUpload]="appIconBeforeUpload" [nzDisabled]="loading" [nzAccept]="supportedIconMediaFilesTypes">
                                  <ng-container>
                                    <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'" *ngIf="loading"></i>
                                    <img src="../../../assets/images/upload_media_icon.png" *ngIf="!loading" alt="">
                                    <div class="ant-upload-text">Upload</div>
                                  </ng-container>
                                </nz-upload>
                              </div>
                              <div *ngIf="previewAppIconUrl && previewAppIconUrl !== 'null'" class="create-team-image-wrap">
                                <div class="img">
                                  <app-image-preview [imageUrl]="previewAppIconUrl" [class]="'organization-icon'" [isVideo]="false">
                                  </app-image-preview>
                                  <div class="icon" [class.disabled_while_loading]="loading">
                                    <svg class="delete-icon" (click)="deleteIconImage()" width="12" height="12" viewBox="0 0 12 12" fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M0.430947 0.431252C0.641916 0.220347 0.928012 0.101868 1.22632 0.101868C1.52463 0.101868 1.81073 0.220347 2.0217 0.431252L5.9997 4.40925L9.9777 0.431252C10.1899 0.226325 10.4741 0.112931 10.769 0.115494C11.064 0.118057 11.3462 0.236372 11.5547 0.444957C11.7633 0.653541 11.8816 0.935705 11.8842 1.23068C11.8868 1.52565 11.7734 1.80983 11.5684 2.022L7.59045 6L11.5684 9.978C11.7734 10.1902 11.8868 10.4744 11.8842 10.7693C11.8816 11.0643 11.7633 11.3465 11.5547 11.555C11.3462 11.7636 11.064 11.8819 10.769 11.8845C10.4741 11.8871 10.1899 11.7737 9.9777 11.5688L5.9997 7.59075L2.0217 11.5688C1.80952 11.7737 1.52534 11.8871 1.23037 11.8845C0.9354 11.8819 0.653235 11.7636 0.444651 11.555C0.236067 11.3465 0.117752 11.0643 0.115189 10.7693C0.112626 10.4744 0.226019 10.1902 0.430947 9.978L4.40895 6L0.430947 2.022C0.220042 1.81103 0.101562 1.52494 0.101562 1.22663C0.101562 0.928318 0.220042 0.642221 0.430947 0.431252Z"
                                        fill="white" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </nz-form-item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End App install -->
                  </div>
                </div>

                <div class="campaign_card">
                  <div class="campaign_card_header">
                    <h5>Audience</h5>
                  </div>
                  <div class="campaign_card_body">
                    <div class="cam_title">
                      <p>Select the location, age and gender of you want to reach with your ad.</p>
                    </div>
                    <div class="location_title">
                      <h5>Location</h5>
                      <p>Target people by including their location</p>
                    </div>
                    <div class="form_input_wrp location_inputs">
                      <div class="form-group">
                        <div class="search_icon">
                          <span nz-icon nzType="search" nzTheme="outline"></span>
                        </div>
                        <nz-form-item>
                          <nz-form-label>
                            <div class="location_label">
                              <img src="../../../assets/images/location-pin.svg" alt="">
                              <span>
                                Include
                              </span>
                            </div>
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please select include location">
                            <nz-select class="tag_items_layout" [nzAllowClear]="false" nzDropdownClassName="default_dropdown_border"
                              nzShowSearch nzAllowClear formControlName="countries" nzMode="multiple"
                              [nzCustomTemplate]="cp_country" [nzPlaceHolder]="'Type to add more include location'"
                              [(ngModel)]="selectedIncludedCountries" (ngModelChange)="updateSnapchatReach()"
                              (nzOpenChange)="getCountries()" nzServerSearch (nzOnSearch)="getSearchCountries($event)" (nzOpenChange)="sharedService.openNzSelect($event)">

                              <ng-container *ngIf="filteredCountries.length > 0">
                                <nz-option *ngFor="let data of filteredCountries" nzCustomContent [nzValue]="data">
                                  <div class="default_font">
                                    <h5>{{ data.name }}</h5>
                                  </div>
                                </nz-option>
                              </ng-container>

                              <ng-container *ngIf="filteredCountries.length === 0">
                                <nz-option *ngFor="let data of countriesListInclude" nzCustomContent [nzValue]="data">
                                  <div class="default_font">
                                    <h5>{{ data.name }}</h5>
                                  </div>
                                </nz-option>
                              </ng-container>

                              <nz-option *ngIf="isCountriesLoading" nzDisabled nzCustomContent>
                                <span nz-icon nzType="loading" class="loading-icon"></span>
                                Loading Data...
                              </nz-option>

                            </nz-select>
                          </nz-form-control>
                          <ng-template #cp_country let-selected>
                            {{ selected.nzValue.name}}
                          </ng-template>
                        </nz-form-item>
                      </div>

                      <!-- <div class="form-group">
                        <div class="search_icon">
                          <span nz-icon nzType="search" nzTheme="outline"></span>
                        </div>
                        <nz-form-item>
                          <nz-form-label>
                            <div class="location_label">
                              <img src="../../../assets/images/location-pin.svg" alt="">
                              <span>
                                Exclude
                              </span>
                            </div>
                          </nz-form-label>
                          <nz-form-control nzErrorTip="Please select exclude location">
                            <nz-select class="tag_items_layout" [nzAllowClear]="false" nzDropdownClassName="default_dropdown_border"
                              nzShowSearch nzAllowClear nzServerSearch formControlName="exclude_countries"
                              nzMode="multiple" [nzCustomTemplate]="cp_country_ex"
                              [nzPlaceHolder]="'Type to add more exclude location'"
                              [(ngModel)]="selectedExcludedCountries" (ngModelChange)="updateSnapchatReach()"
                              (nzOpenChange)="getCountriesExclude()" (nzOnSearch)="getSearchCountriesExclude($event)" (nzOpenChange)="sharedService.openNzSelect($event)">
                              <nz-option *ngFor="let data of countriesListExclude" nzCustomContent [nzValue]="data?.country?.country">
                                <div class="default_font">
                                  <h5>{{ data.country.country.name }}</h5>
                                </div>
                              </nz-option>
                              <nz-option *ngIf="isExcludedCountriesLoading" nzDisabled nzCustomContent>
                                <span nz-icon nzType="loading" class="loading-icon"></span>
                                Loading Data...
                              </nz-option>
                            </nz-select>
                          </nz-form-control>
                          <ng-template #cp_country_ex let-selected>
                            {{ selected.nzValue.name}}
                          </ng-template>
                        </nz-form-item>
                      </div> -->
                    </div>
                    <!-- <div class="location_title">
                      <h5>Language</h5>
                    </div>
                    <div>
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please select language">
                          <nz-select class="tag_items_layout" [nzAllowClear]="false" nzDropdownClassName="default_dropdown_border"
                           nzAllowClear formControlName="languages"  nzMode="multiple"
                           [nzCustomTemplate]="cp_language_ex"  [nzPlaceHolder]="'Select languages'"
                            [(ngModel)]="selectedLanguages" (nzOpenChange)="getLanguages()" (ngModelChange)="updateSnapchatReach()" (nzOpenChange)="sharedService.openNzSelect($event)">
                            <nz-option *ngFor="let data of languagesList" nzCustomContent [nzValue]="data.languages" >
                              <div class="default_font">
                                <h5>{{ data.languages.name }}</h5>
                              </div>
                            </nz-option>
                            <nz-option *ngIf="isLanguagesLoading" nzDisabled nzCustomContent>
                              <span nz-icon nzType="loading" class="loading-icon"></span>
                              Loading Data...
                            </nz-option>
                          </nz-select>
                        </nz-form-control>
                        <ng-template #cp_language_ex let-selected>
                          {{ selected.nzValue.name}}
                        </ng-template>
                      </nz-form-item>
                    </div> -->
                    <div class="location_title">
                      <h5>Gender</h5>
                      <p>Show your ads to either men or women, or select "All" for both.</p>
                    </div>
                    <div class="gender_selection">
                      <nz-form-item>
                        <nz-form-control nzErrorTip="Please select gender">
                          <nz-radio-group formControlName="genders" [(ngModel)]="gender"
                            (ngModelChange)="updateSnapchatReach()">
                            <label nz-radio nzValue="all">All</label>
                            <label nz-radio nzValue="MALE">Male</label>
                            <label nz-radio nzValue="FEMALE">Female</label>
                          </nz-radio-group>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div class="location_title">
                      <h5>Age</h5>
                      <p>Select the minimum and maximum age of the people who will find your ad relevant.</p>
                    </div>
                    <div class="age_slider">
                      <span class="age_count age_count_left">{{ selectedAge[0]}}</span>
                      <nz-slider nzRange [nzMin]="13" [nzMax]="49" [(ngModel)]="selectedAge"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="updateSnapchatReach()"
                                 [nzTooltipVisible]="'never'"></nz-slider>
                      <span class="age_count age_count_right">{{ selectedAge[1]===49 ? "49+" : selectedAge[1] }}</span>
                    </div>
                  </div>
                </div>
                <div class="campaign_card">
                  <div class="campaign_card_header">
                    <h5>Total Budget</h5>
                  </div>
                  <div class="campaign_card_body">
                    <div>
                      <p>The maximum amount you'll spend on your ad until you stop running it.</p>
                    </div>
                    <div class="budget_main">
                      <div class="form-group budget_input">
                        <nz-form-item>
                          <nz-form-control>
                            <input class="ant-number" nz-input placeholder="Budget"
                                   type="number" formControlName="budget" max="450000"/>
                            <span class="dollar_icon">{{ selectedAdAccount.currency_symbol }}</span>
                          </nz-form-control>
                            <div *ngIf="adForm.get('budget').hasError('required') && (adForm.get('budget').dirty || adForm.get('budget').touched)">
                              <div class="error-message">Please add your total budget.</div>
                            </div>
                            <div *ngIf="adForm.get('budget').hasError('max') && (adForm.get('budget').dirty || adForm.get('budget').touched)">
                              <div class="error-message">Maximum {{ selectedAdAccount.currency_symbol }}450000 you spent in ads.</div>
                            </div>
                        </nz-form-item>
                      </div>

                      <div class="form-group">
                        <nz-form-item>
                          <nz-form-control nzErrorTip="Please select budget type">
                            <nz-radio-group formControlName="budget_type" [(ngModel)]="selectedBudgetType" [nzDisabled]="campaign">
                              <label nz-radio nzValue="daily">Daily</label>
                              <label nz-radio nzValue="lifetime">Lifetime <span style="margin-left: 7px;" nz-tooltip nzTooltipTitle="Life time budget 5 times of number of different days."
                                nz-icon nzType="info-circle" nzTheme="fill"></span></label>
                            </nz-radio-group>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                    </div>

                    <!--<ng-container *ngIf="selectedBudgetType==='lifetime'">-->
                    <ng-container>
                      <div class="location_title">
                        <h5>Duration</h5>
                        <p>Enter how long you want run your ad.</p>
                      </div>
                      <div class="ent_time_picker">
                        <nz-form-item>
                          <nz-form-label nzErrorTip="Please select start time">From</nz-form-label>
                          <nz-form-control nzErrorTip="Please select start time">
                            <nz-date-picker [nzDisabledDate]="disabledDate" nzFormat="yyyy-MM-dd h:mm a"
                                            [(ngModel)]="start_time" [class.disabled_while_loading]="campaign"
                                            [nzShowTime]="{ nzFormat: 'h:mm a', nzUse12Hours: true }"
                                            formControlName="start_time" (ngModelChange)="onDateChange($event)"></nz-date-picker>
                          </nz-form-control>
                        </nz-form-item>

                        <nz-form-item>
                          <nz-form-label>To</nz-form-label>
                          <nz-form-control nzErrorTip="Please select date">
                            <nz-date-picker [nzDisabledDate]="disabledEndDate"
                                            [(ngModel)]="end_time"
                                            nzFormat="yyyy-MM-dd h:mm a"
                                            [nzShowTime]="{ nzFormat: 'h:mm a', nzUse12Hours: true }"
                                            formControlName="end_time"></nz-date-picker>
                          </nz-form-control>
                          <div *ngIf="adForm.hasError('endTimeInvalid')">
                            <div class="error-message">End time cannot be earlier than start time</div>
                          </div>
                        </nz-form-item>
                      </div>
                    </ng-container>

                  </div>
                </div>
                <div class="campaign_card">
                  <div class="campaign_card_header">
                    <h5>Payment</h5>
                  </div>
                  <div class="campaign_card_body">
                    <div>
                      <p>Snapchat bills the Snapchat ads account associated with your Snapchat account when you create ads. If
                        you want to check or change your payment settings, go to Snapchat Ads Manager. <a
                          target="_blank" href="https://businesshelp.snapchat.com/s/article/payment-methods">Learn More.</a>
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isOrganozationVisible">
                <div class="over_warning">
                  <img src="../../../assets/images/info_icon.svg" alt="">
                  <span>Your organizaton don't select address. Please set address in your organization.</span>
                </div>
                <div>
                    <button class="btn_primary" (click)="setAddress()">Set Address</button>
                </div>
              </ng-container>

            </form>
          </div>
        </div>
        <div class="campaign_wrapper_right">
          <!-- *ngIf="selectedAdAccount" -->
          <div class="campaign_wrapper_right_in">

            <!--No Ad Account-->
            <div class="campaign_card" *ngIf="!selectedAdAccount || isOrganozationVisible">
              <div class="campaign_card_body">
                <div class="before_preview_wrapper">
                  <div class="before_preview">
                    <img src="../../../assets/images/add_account.png" alt="">
                    <span>Select an Organization and Ad Account to publish ad.</span>
                  </div>
                </div>
              </div>
            </div>
            <!--Potential reach-->
            <div class="campaign_card" *ngIf="selectedAdAccount && !isOrganozationVisible">

              <div class="campaign_card_header">
                <h5>Potential reach</h5>
              </div>
              <div class="campaign_card_body">
                <div>
                  <p>Your audience targeting is incomplete. Select at least one location, or choose a Custom Audience.
                  </p>
                </div>
                <div class="location_title">
                  <h5>Reach<span style="margin-left: 7px;" nz-tooltip
                                 nzTooltipTitle="This is the number of Accounts Centre accounts that we estimate you'll reach in your audience each day. This has to do with factors such as your bid, budget, targeting criteria and ad placements. Your actual reach may be higher or lower than this estimate. Learn more"
                                 nz-icon nzType="info-circle" nzTheme="fill"></span></h5>
                </div>
                <div class="reach_count">
                  <ng-container *ngIf="isFacebookReachLoading">
                    <div class="est_reach_skeleton">
                      <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
                      <div class="dash"> - </div>
                      <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 0 }"></nz-skeleton>
                    </div>
                  </ng-container>
                  <span>{{ estimatedReach }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="campaign_wrapper_right">
   <!--  <ng-container *ngIf="campaign">
      <app-image-preview [isVideo]="false" [imageUrl]="campaign.media[0]" [class]="'account-list-image'"
          *ngIf="campaign.media[0] !== null"></app-image-preview>
    </ng-container> -->
    <!-- <ng-template #beforePreview>
      <div class="before_preview_wrapper">
        <div class="before_preview">
          <img src="../../../assets/images/add_account.png" alt="">
          <span>Select an Ad Account to preview this ad</span>
        </div>
      </div>
    </ng-template> -->
  </div>
</div>
<div class="campaign_footer">
  <div class="campaign_footer_left">
    <button class="btn_primary_outline" (click)="closeModal()"
            [class.disabled_while_loading]="isAdSaving">Cancel</button>
  </div>
  <div class="campaign_footer_right">

    <button class="btn_primary" *ngIf="!campaign"
      [class.disabled_while_loading]="isAdSaving || loading"
      (click)="createSnapchatAd()">
      Save
      <ng-container *ngIf="isAdSaving">
        <span nz-icon nzType="loading"></span>
      </ng-container>
    </button>

    <button class="btn_primary" *ngIf="campaign"
            [class.disabled_while_loading]="isAdSaving || loading"
            (click)="updateSnapchatAd()">
      Update
      <ng-container *ngIf="isAdSaving">
        <span nz-icon nzType="loading"></span>
      </ng-container>
    </button>

  </div>
</div>
