<div class="seting_card">
  <div class="seting_card_header">
    <h3>Change Password</h3>
  </div>

  <div class="">
    <form nz-form [formGroup]="changePassword" (ngSubmit)="onSubmit()">
      <div class="form_section">
        <div class="form_section_in">
          <nz-form-item class="password_input">
            <nz-input-group [nzSuffix]="suffixOldPasswordTemplateInfo"
              [class.hasclass]="changePassword.value?.oldPassword?.length>0">
              <nz-form-control [nzErrorTip]="oldPasswordError">
                <input nz-input [type]="oldPasswordVisible ? 'text' : 'password'" formControlName="oldPassword" />
                <ng-template #oldPasswordError let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    This Password Field is required!
                  </ng-container>
                </ng-template>
                <ng-template #suffixOldPasswordTemplateInfo>
                  <i *ngIf="!oldPasswordVisible" nz-icon nzType="eye" nzTheme="outline"
                    (click)="oldPasswordVisible = !oldPasswordVisible"></i>
                  <i *ngIf="oldPasswordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
                    (click)="oldPasswordVisible = !oldPasswordVisible"></i>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Current Password</nz-form-label>
          </nz-form-item>
          <nz-form-item class="password_input">
            <nz-input-group [nzSuffix]="suffixPasswordTemplateInfo"
              [class.hasclass]="changePassword.value?.password?.length>0">
              <nz-form-control [nzErrorTip]="passwordError">
                <input nz-input [type]="passwordVisible ? 'text' : 'password'" formControlName="password"
                  (ngModelChange)="updateConfirmValidator()" />
                <ng-template #passwordError let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    This New Password Field is required!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('minlength')">
                    Your password needs to be at least 8 characters!
                  </ng-container>
                  <ng-container *ngIf="!control.hasError('minlength') && control.hasError('pattern')">
                    Password  should contain at least one number,one uppercase character and one special character
                  </ng-container>
                </ng-template>
                <ng-template #suffixPasswordTemplateInfo>
                  <i *ngIf="!passwordVisible" nz-icon nzType="eye" nzTheme="outline"
                    (click)="passwordVisible = !passwordVisible"></i>
                  <i *ngIf="passwordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
                    (click)="passwordVisible = !passwordVisible"></i>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>New Password</nz-form-label>
          </nz-form-item>
          <nz-form-item class="password_input">
            <nz-input-group [nzSuffix]="suffixConfirmPasswordTemplateInfo"
              [class.hasclass]="changePassword.value?.password_confirmation?.length>0">
              <nz-form-control [nzErrorTip]="passwordConfirmError">
                <input nz-input [type]="confirmPasswordVisible ? 'text' : 'password'"
                  formControlName="password_confirmation" />
                <ng-template #passwordConfirmError let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    This Confirm New Password Field is required!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('confirm')">
                    New Password and Confirm New Password must match!
                  </ng-container>
                </ng-template>
                <ng-template #suffixConfirmPasswordTemplateInfo>
                  <i *ngIf="!confirmPasswordVisible" nz-icon nzType="eye" nzTheme="outline"
                    (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                  <i *ngIf="confirmPasswordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
                    (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                </ng-template>
              </nz-form-control>
            </nz-input-group>
            <nz-form-label>Confirm New Password</nz-form-label>
          </nz-form-item>
        </div>

        <div class="strong_password">
          <h3>Please use at least:</h3>
          <div class="strong_password_list">
            <div class="strong_password_list_in" [class.active]="changePassword.value.password?.length>=8">
              <span>8 characters</span>
            </div>
            <div class="strong_password_list_in" [class.active]="passwordPattern.special_char">
              <span>1 special character</span>
            </div>
            <div class="strong_password_list_in" [class.active]="passwordPattern.digit">
              <span>1 number</span>
            </div>
            <div class="strong_password_list_in" [class.active]="passwordPattern.uppercase">
              <span>1 uppercase letter</span>
            </div>
            <div class="strong_password_list_in" [class.active]="passwordPattern.lowercase">
              <span>1 lowercase letter</span>
            </div>
          </div>
        </div>
        <div class="action_area">
          <button nz-button nzType="primary" type="submit" [disabled]="isLoading">Update Password
            <ng-container *ngIf="isLoading">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
