<ng-container>
  <div class="login_wrapper">
    <div class="login_main">
      <div class="login_left">
        <div class="auth_logo">
          <a href="javascript:;">
            <svg width="250" height="33" viewBox="0 0 250 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M57.9181 15.2877H67.0325C67.5472 15.2877 67.7808 15.5214 67.7808 16.0361C67.7737 16.228 67.691 16.4092 67.5508 16.5405C67.4106 16.6717 67.2243 16.7423 67.0325 16.7371H57.9181V31.0459C57.9225 31.2379 57.8514 31.4239 57.7199 31.5639C57.5885 31.7039 57.4073 31.7866 57.2155 31.7943C56.7024 31.7943 56.4688 31.559 56.4688 31.0459V0.980556C56.4759 0.78898 56.5585 0.607979 56.6982 0.476768C56.838 0.345557 57.0238 0.274709 57.2155 0.279575H67.0325C67.2243 0.274271 67.4106 0.344925 67.5508 0.476181C67.691 0.607438 67.7737 0.788691 67.7808 0.980556C67.7808 1.49526 67.5472 1.72729 67.0325 1.72729H57.9181V15.2877Z"
                fill="#232C89" />
              <path
                d="M70.6328 8.60347C70.6328 3.22765 72.8763 0.283203 77.3175 0.283203C81.8044 0.283203 84.0478 3.18027 84.0478 8.60347V23.4221C84.0478 26.927 83.1606 28.9384 81.8518 30.1999C80.5429 31.4613 78.8599 31.7897 77.3175 31.7897C75.8224 31.7897 74.1394 31.4629 72.8305 30.1999C71.5217 28.9368 70.6328 26.9319 70.6328 23.4221V8.60347ZM72.0822 23.4221C72.0822 28.1427 73.8109 30.3404 77.3109 30.3404C80.7227 30.3404 82.5397 28.2832 82.5397 23.4221V8.60347C82.5397 3.78811 80.8109 1.73092 77.3109 1.73092C73.7586 1.73092 72.0822 3.92863 72.0822 8.60347V23.4221Z"
                fill="#232C89" />
              <path
                d="M100.036 30.9926C100.036 31.5073 99.8007 31.7409 99.2877 31.7409C99.0958 31.7337 98.9146 31.6511 98.7833 31.5109C98.652 31.3707 98.5815 31.1845 98.5867 30.9926V22.9991C98.5867 19.12 96.4364 16.9697 92.6962 16.9697H88.4886V30.9926C88.4886 31.5073 88.255 31.7409 87.7403 31.7409C87.5486 31.7332 87.3676 31.6504 87.2364 31.5104C87.1053 31.3703 87.0344 31.1844 87.0393 30.9926V0.935355C87.0439 0.75005 87.1199 0.573682 87.2514 0.443069C87.3829 0.312455 87.5599 0.237689 87.7452 0.234375H92.6962C97.6978 0.234375 99.9886 2.80463 99.9886 8.55463C99.9886 11.4059 99.4282 13.5105 98.2583 14.8651C97.7452 15.4841 97.0867 15.9664 96.3416 16.2687C96.9431 16.5718 97.4942 16.9659 97.9756 17.437C99.3301 18.7442 100.033 20.6151 100.033 22.9926L100.036 30.9926ZM88.4886 1.68371V15.5219H92.6962C96.7632 15.5219 98.4919 13.6053 98.4919 8.55626C98.4919 3.69515 96.8089 1.69352 92.6962 1.69352L88.4886 1.68371Z"
                fill="#232C89" />
              <path
                d="M122.758 30.9008C122.899 31.275 122.571 31.6492 122.196 31.7897H122.01C121.844 31.801 121.68 31.7495 121.55 31.6454C121.42 31.5413 121.334 31.3921 121.309 31.2276L119.206 22.159H110.932L108.824 31.2276C108.683 31.6018 108.309 31.9302 107.935 31.7897C107.515 31.6492 107.234 31.275 107.374 30.9008L114.339 0.83545C114.364 0.671205 114.45 0.522477 114.58 0.418691C114.71 0.314906 114.874 0.263625 115.039 0.274992C115.461 0.274992 115.693 0.461267 115.788 0.83545L122.758 30.9008ZM115.044 4.24884L111.258 20.7096H118.878L115.044 4.24884Z"
                fill="#232C89" />
              <path
                d="M126.077 31.7893C125.562 31.7893 125.33 31.554 125.33 31.041V0.980512C125.337 0.788939 125.42 0.607935 125.559 0.476725C125.699 0.345514 125.885 0.274666 126.077 0.279532C126.261 0.284089 126.437 0.359404 126.567 0.489888C126.698 0.620373 126.773 0.796035 126.778 0.980512V30.34H136.642C136.834 30.3351 137.02 30.406 137.159 30.5372C137.299 30.6684 137.382 30.8495 137.389 31.041C137.389 31.554 137.155 31.7893 136.642 31.7893H126.077Z"
                fill="#232C89" />
              <path
                d="M139.869 31.79C139.356 31.79 139.122 31.5547 139.122 31.0416V0.981154C139.13 0.790157 139.213 0.609984 139.353 0.479471C139.492 0.348958 139.678 0.278556 139.869 0.283441C140.052 0.288374 140.227 0.363417 140.357 0.493165C140.487 0.622914 140.563 0.797525 140.568 0.981154V30.3406H150.431C150.623 30.3357 150.809 30.4065 150.949 30.5377C151.089 30.6689 151.172 30.8499 151.179 31.0416C151.179 31.5547 150.946 31.79 150.431 31.79H139.869Z"
                fill="#232C89" />
              <path
                d="M158.187 19.0758H164.409V26.2653C164.409 26.9799 164.84 27.3367 165.705 27.3356C166.538 27.3356 166.955 26.9789 166.955 26.2653V22.5496C166.955 21.4145 166.2 20.0828 164.691 18.5545C161.776 15.7157 159.963 13.8094 159.252 12.8356C158.558 11.9099 158.184 10.7834 158.187 9.62642V6.10518C158.187 2.04417 160.83 0.0136719 166.118 0.0136719C170.853 0.0136719 173.221 1.86226 173.222 5.55943V10.9745H166.994V5.20976C166.994 4.58557 166.646 4.27293 165.951 4.27185C165.138 4.27185 164.731 4.87587 164.731 6.08394L164.861 8.11499C164.861 9.28056 165.633 10.634 167.173 12.1754C170.038 14.9271 171.86 16.8486 172.638 17.9401C173.412 19.021 173.821 20.3212 173.804 21.6509V25.6542C173.804 29.8176 171.154 31.8993 165.852 31.8993C160.737 31.8993 158.181 30.0067 158.182 26.2212L158.187 19.0758Z"
                fill="#232C89" />
              <path
                d="M175.201 26.2588V6.10518C175.201 2.04417 177.824 0.0136719 183.067 0.0136719C188.239 0.0136719 190.825 2.02892 190.824 6.05943V26.2147C190.824 28.0349 190.103 29.4364 188.662 30.4189C187.221 31.4014 185.342 31.8916 183.023 31.8895C177.809 31.8916 175.203 30.0148 175.201 26.2588ZM184.144 26.5856V5.25388C184.144 4.6286 183.775 4.31597 183.035 4.31597C182.265 4.31597 181.879 4.6286 181.881 5.25388V26.5856C181.881 27.2119 182.265 27.5251 183.035 27.5251C183.778 27.5251 184.151 27.2119 184.151 26.5856H184.144Z"
                fill="#232C89" />
              <path
                d="M207.273 11.2375H201.051V5.03655C201.051 4.52675 200.735 4.27185 200.105 4.27185C199.444 4.27185 199.114 4.52675 199.114 5.03655V26.7686C199.114 27.2784 199.444 27.5333 200.105 27.5333C200.735 27.5333 201.051 27.2784 201.051 26.7686V19.0398H207.273V26.9875C207.273 28.4712 206.547 29.6612 205.098 30.5578C203.618 31.4612 201.913 31.9269 200.18 31.9009C194.9 31.9009 192.261 30.023 192.26 26.2669V6.10518C192.26 2.04417 194.9 0.0136719 200.18 0.0136719C204.908 0.0136719 207.273 1.86226 207.273 5.55943V11.2375Z"
                fill="#232C89" />
              <path d="M215.523 31.6073H208.66V0.296875H215.523V31.6073Z" fill="#232C89" />
              <path
                d="M235.409 31.6073H228.616L227.459 23.2021H224.418L223.247 31.609H216.456L221.582 0.296875H230.273L235.409 31.6073ZM226.912 18.8622L225.932 9.51745L224.997 18.8622H226.912Z"
                fill="#232C89" />
              <path d="M249.951 31.6073H236.553V0.296875H243.415V24.9995H249.951V31.6073Z" fill="#232C89" />
              <path
                d="M28.9037 14.5727L24.9707 0.285156H17.1504V31.6136H22.8955V14.9224L27.5703 31.6136H34.6488V0.285156H28.9037V14.5727Z"
                fill="#232C89" />
              <path
                d="M43.0379 25.6855V19.6838H48.8942V12.7819H43.0379V6.59398H49.5265V0.285156H36.1572V31.6136H49.6147V25.6855H43.0379Z"
                fill="#232C89" />
              <path
                d="M7.89706 0C2.63235 0 0 2.03158 0 6.09477V26.2598C0 30.018 2.61819 31.8966 7.85458 31.8954C10.1814 31.8954 12.0681 31.4052 13.5147 30.4248C14.9613 29.4444 15.6852 28.0413 15.6863 26.2157V6.04575C15.6863 2.01524 13.0899 0 7.89706 0ZM10.4052 27.6225H5.24183V10.3971H3.26797V6.96569C4.01743 6.96569 4.65033 6.64325 5.16667 5.99837C5.68301 5.35348 5.94064 4.72495 5.93954 4.11274H10.4052V27.6225Z"
                fill="#232C89" />
            </svg>
          </a>
        </div>
        <h1>Grow your audience on social media and beyond</h1>
        <div class="auth_left_img">
          <img src="../../../../assets/images/new_login_bg.png" alt="">
        </div>
        <div class="auth_nav">
          <a [href]="sharedService.linkPrivacy" target="_blank">PRIVACY POLICY</a>
          <a [href]="sharedService.linkTerms" target="_blank">TERMS OF SERVICE</a>
        </div>
      </div>
      <div class="login_right">
        <div class="login_right_in">
          <div class="res_logo">
            <a href="javascript:;">
              <svg width="250" height="33" viewBox="0 0 250 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M57.9181 15.2877H67.0325C67.5472 15.2877 67.7808 15.5214 67.7808 16.0361C67.7737 16.228 67.691 16.4092 67.5508 16.5405C67.4106 16.6717 67.2243 16.7423 67.0325 16.7371H57.9181V31.0459C57.9225 31.2379 57.8514 31.4239 57.7199 31.5639C57.5885 31.7039 57.4073 31.7866 57.2155 31.7943C56.7024 31.7943 56.4688 31.559 56.4688 31.0459V0.980556C56.4759 0.78898 56.5585 0.607979 56.6982 0.476768C56.838 0.345557 57.0238 0.274709 57.2155 0.279575H67.0325C67.2243 0.274271 67.4106 0.344925 67.5508 0.476181C67.691 0.607438 67.7737 0.788691 67.7808 0.980556C67.7808 1.49526 67.5472 1.72729 67.0325 1.72729H57.9181V15.2877Z"
                  fill="#232C89" />
                <path
                  d="M70.6328 8.60347C70.6328 3.22765 72.8763 0.283203 77.3175 0.283203C81.8044 0.283203 84.0478 3.18027 84.0478 8.60347V23.4221C84.0478 26.927 83.1606 28.9384 81.8518 30.1999C80.5429 31.4613 78.8599 31.7897 77.3175 31.7897C75.8224 31.7897 74.1394 31.4629 72.8305 30.1999C71.5217 28.9368 70.6328 26.9319 70.6328 23.4221V8.60347ZM72.0822 23.4221C72.0822 28.1427 73.8109 30.3404 77.3109 30.3404C80.7227 30.3404 82.5397 28.2832 82.5397 23.4221V8.60347C82.5397 3.78811 80.8109 1.73092 77.3109 1.73092C73.7586 1.73092 72.0822 3.92863 72.0822 8.60347V23.4221Z"
                  fill="#232C89" />
                <path
                  d="M100.036 30.9926C100.036 31.5073 99.8007 31.7409 99.2877 31.7409C99.0958 31.7337 98.9146 31.6511 98.7833 31.5109C98.652 31.3707 98.5815 31.1845 98.5867 30.9926V22.9991C98.5867 19.12 96.4364 16.9697 92.6962 16.9697H88.4886V30.9926C88.4886 31.5073 88.255 31.7409 87.7403 31.7409C87.5486 31.7332 87.3676 31.6504 87.2364 31.5104C87.1053 31.3703 87.0344 31.1844 87.0393 30.9926V0.935355C87.0439 0.75005 87.1199 0.573682 87.2514 0.443069C87.3829 0.312455 87.5599 0.237689 87.7452 0.234375H92.6962C97.6978 0.234375 99.9886 2.80463 99.9886 8.55463C99.9886 11.4059 99.4282 13.5105 98.2583 14.8651C97.7452 15.4841 97.0867 15.9664 96.3416 16.2687C96.9431 16.5718 97.4942 16.9659 97.9756 17.437C99.3301 18.7442 100.033 20.6151 100.033 22.9926L100.036 30.9926ZM88.4886 1.68371V15.5219H92.6962C96.7632 15.5219 98.4919 13.6053 98.4919 8.55626C98.4919 3.69515 96.8089 1.69352 92.6962 1.69352L88.4886 1.68371Z"
                  fill="#232C89" />
                <path
                  d="M122.758 30.9008C122.899 31.275 122.571 31.6492 122.196 31.7897H122.01C121.844 31.801 121.68 31.7495 121.55 31.6454C121.42 31.5413 121.334 31.3921 121.309 31.2276L119.206 22.159H110.932L108.824 31.2276C108.683 31.6018 108.309 31.9302 107.935 31.7897C107.515 31.6492 107.234 31.275 107.374 30.9008L114.339 0.83545C114.364 0.671205 114.45 0.522477 114.58 0.418691C114.71 0.314906 114.874 0.263625 115.039 0.274992C115.461 0.274992 115.693 0.461267 115.788 0.83545L122.758 30.9008ZM115.044 4.24884L111.258 20.7096H118.878L115.044 4.24884Z"
                  fill="#232C89" />
                <path
                  d="M126.077 31.7893C125.562 31.7893 125.33 31.554 125.33 31.041V0.980512C125.337 0.788939 125.42 0.607935 125.559 0.476725C125.699 0.345514 125.885 0.274666 126.077 0.279532C126.261 0.284089 126.437 0.359404 126.567 0.489888C126.698 0.620373 126.773 0.796035 126.778 0.980512V30.34H136.642C136.834 30.3351 137.02 30.406 137.159 30.5372C137.299 30.6684 137.382 30.8495 137.389 31.041C137.389 31.554 137.155 31.7893 136.642 31.7893H126.077Z"
                  fill="#232C89" />
                <path
                  d="M139.869 31.79C139.356 31.79 139.122 31.5547 139.122 31.0416V0.981154C139.13 0.790157 139.213 0.609984 139.353 0.479471C139.492 0.348958 139.678 0.278556 139.869 0.283441C140.052 0.288374 140.227 0.363417 140.357 0.493165C140.487 0.622914 140.563 0.797525 140.568 0.981154V30.3406H150.431C150.623 30.3357 150.809 30.4065 150.949 30.5377C151.089 30.6689 151.172 30.8499 151.179 31.0416C151.179 31.5547 150.946 31.79 150.431 31.79H139.869Z"
                  fill="#232C89" />
                <path
                  d="M158.187 19.0758H164.409V26.2653C164.409 26.9799 164.84 27.3367 165.705 27.3356C166.538 27.3356 166.955 26.9789 166.955 26.2653V22.5496C166.955 21.4145 166.2 20.0828 164.691 18.5545C161.776 15.7157 159.963 13.8094 159.252 12.8356C158.558 11.9099 158.184 10.7834 158.187 9.62642V6.10518C158.187 2.04417 160.83 0.0136719 166.118 0.0136719C170.853 0.0136719 173.221 1.86226 173.222 5.55943V10.9745H166.994V5.20976C166.994 4.58557 166.646 4.27293 165.951 4.27185C165.138 4.27185 164.731 4.87587 164.731 6.08394L164.861 8.11499C164.861 9.28056 165.633 10.634 167.173 12.1754C170.038 14.9271 171.86 16.8486 172.638 17.9401C173.412 19.021 173.821 20.3212 173.804 21.6509V25.6542C173.804 29.8176 171.154 31.8993 165.852 31.8993C160.737 31.8993 158.181 30.0067 158.182 26.2212L158.187 19.0758Z"
                  fill="#232C89" />
                <path
                  d="M175.201 26.2588V6.10518C175.201 2.04417 177.824 0.0136719 183.067 0.0136719C188.239 0.0136719 190.825 2.02892 190.824 6.05943V26.2147C190.824 28.0349 190.103 29.4364 188.662 30.4189C187.221 31.4014 185.342 31.8916 183.023 31.8895C177.809 31.8916 175.203 30.0148 175.201 26.2588ZM184.144 26.5856V5.25388C184.144 4.6286 183.775 4.31597 183.035 4.31597C182.265 4.31597 181.879 4.6286 181.881 5.25388V26.5856C181.881 27.2119 182.265 27.5251 183.035 27.5251C183.778 27.5251 184.151 27.2119 184.151 26.5856H184.144Z"
                  fill="#232C89" />
                <path
                  d="M207.273 11.2375H201.051V5.03655C201.051 4.52675 200.735 4.27185 200.105 4.27185C199.444 4.27185 199.114 4.52675 199.114 5.03655V26.7686C199.114 27.2784 199.444 27.5333 200.105 27.5333C200.735 27.5333 201.051 27.2784 201.051 26.7686V19.0398H207.273V26.9875C207.273 28.4712 206.547 29.6612 205.098 30.5578C203.618 31.4612 201.913 31.9269 200.18 31.9009C194.9 31.9009 192.261 30.023 192.26 26.2669V6.10518C192.26 2.04417 194.9 0.0136719 200.18 0.0136719C204.908 0.0136719 207.273 1.86226 207.273 5.55943V11.2375Z"
                  fill="#232C89" />
                <path d="M215.523 31.6073H208.66V0.296875H215.523V31.6073Z" fill="#232C89" />
                <path
                  d="M235.409 31.6073H228.616L227.459 23.2021H224.418L223.247 31.609H216.456L221.582 0.296875H230.273L235.409 31.6073ZM226.912 18.8622L225.932 9.51745L224.997 18.8622H226.912Z"
                  fill="#232C89" />
                <path d="M249.951 31.6073H236.553V0.296875H243.415V24.9995H249.951V31.6073Z" fill="#232C89" />
                <path
                  d="M28.9037 14.5727L24.9707 0.285156H17.1504V31.6136H22.8955V14.9224L27.5703 31.6136H34.6488V0.285156H28.9037V14.5727Z"
                  fill="#232C89" />
                <path
                  d="M43.0379 25.6855V19.6838H48.8942V12.7819H43.0379V6.59398H49.5265V0.285156H36.1572V31.6136H49.6147V25.6855H43.0379Z"
                  fill="#232C89" />
                <path
                  d="M7.89706 0C2.63235 0 0 2.03158 0 6.09477V26.2598C0 30.018 2.61819 31.8966 7.85458 31.8954C10.1814 31.8954 12.0681 31.4052 13.5147 30.4248C14.9613 29.4444 15.6852 28.0413 15.6863 26.2157V6.04575C15.6863 2.01524 13.0899 0 7.89706 0ZM10.4052 27.6225H5.24183V10.3971H3.26797V6.96569C4.01743 6.96569 4.65033 6.64325 5.16667 5.99837C5.68301 5.35348 5.94064 4.72495 5.93954 4.11274H10.4052V27.6225Z"
                  fill="#232C89" />
              </svg>
            </a>
          </div>
          <div class="auth_title">
            <h2>We are so happy to see you!</h2>
            <p>Your email is : {{ email}}</p>
          </div>
          <div class="auth_form_main">
            <form nz-form [formGroup]="registrationForm" (ngSubmit)="submitForm()">
              <div class="form_section">

                <nz-form-item class="password_input">
                  <nz-input-group [nzSuffix]="suffixPasswordTemplateInfo"
                                  [class.hasclass]="registrationForm.value?.password?.length>0">
                    <nz-form-control [nzErrorTip]="passwordError">
                      <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                             (click)="updateConfirmValidator()" />
                      <ng-template #passwordError let-control>
                        <ng-container *ngIf="control.hasError('required')">
                          This Password Field is required!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('minlength')">
                          Your password needs to be at least 8 characters!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('pattern')">
                          Password should contain one number,one uppercase character and one special
                          character
                        </ng-container>
                        <ng-container *ngIf="control.errors?.serverErrors">
                          <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverErrors">{{
                            serverError }}</div>
                        </ng-container>
                      </ng-template>
                      <ng-template #suffixPasswordTemplateInfo>
                        <i *ngIf="!passwordVisible" nz-icon nzType="eye" nzTheme="outline"
                           (click)="passwordVisible = !passwordVisible"></i>
                        <i *ngIf="passwordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
                           (click)="passwordVisible = !passwordVisible"></i>
                      </ng-template>
                    </nz-form-control>
                  </nz-input-group>
                  <nz-form-label>Create Password</nz-form-label>
                </nz-form-item>

                <nz-form-item class="password_input">
                  <nz-input-group [nzSuffix]="suffixConfirmPasswordTemplateInfo"
                                  [class.hasclass]="registrationForm.value?.password_confirmation?.length>0">
                    <nz-form-control [nzErrorTip]="passwordConfirmError">
                      <input [type]="confirmPasswordVisible ? 'text' : 'password'" nz-input
                             formControlName="password_confirmation" />
                      <ng-template #passwordConfirmError let-control>
                        <ng-container *ngIf="control.hasError('required')">
                          This Password Field is required!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('confirm')">
                          Password and Confirm Password must match!
                        </ng-container>
                        <ng-container *ngIf="control.errors?.serverErrors">
                          <div class="invalid-feedback" *ngFor="let serverError of control.errors.serverErrors">{{
                            serverError }}</div>
                        </ng-container>
                      </ng-template>
                      <ng-template #suffixConfirmPasswordTemplateInfo>
                        <i *ngIf="!confirmPasswordVisible" nz-icon nzType="eye" nzTheme="outline"
                           (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                        <i *ngIf="confirmPasswordVisible" nz-icon nzType="eye-invisible" nzTheme="outline"
                           (click)="confirmPasswordVisible = !confirmPasswordVisible"></i>
                      </ng-template>
                    </nz-form-control>
                  </nz-input-group>
                  <nz-form-label>Confirm Password</nz-form-label>
                </nz-form-item>

                <div class="strong_password">
                  <h3>Please use at least:</h3>
                  <div class="strong_password_list">
                    <div class="strong_password_list_in" [class.active]="registrationForm.value.password?.length>=8">
                      <span>8 characters</span>
                    </div>
                    <div class="strong_password_list_in" [class.active]="passwordPattern.special_char">
                      <span>1 special character</span>
                    </div>
                    <div class="strong_password_list_in" [class.active]="passwordPattern.digit">
                      <span>1 number</span>
                    </div>
                    <div class="strong_password_list_in" [class.active]="passwordPattern.uppercase">
                      <span>1 uppercase letter</span>
                    </div>
                    <div class="strong_password_list_in" [class.active]="passwordPattern.lowercase">
                      <span>1 lowercase letter</span>
                    </div>
                  </div>
                </div>

                <div class="action_area">
                  <button type="submit" nz-button nzType="primary" nzBlock
                          [disabled]="isLoading">Join Team
                    <ng-container *ngIf="isLoading">
                      <span nz-icon nzType="loading"></span>
                    </ng-container>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
