import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  constructor(private http: HttpClient, private helper: HelperService) { }

  instagramRequestsToken(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('instagram_request_token'));
  }

  instagramAuthenticatedRequests(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('instagram_auth'), postValues);
  }

  saveInstagramAccounts(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('save_instagram_accounts'), postValues);
  }

  getInstagramAccounts(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_instagram_accounts'));
  }

  sendInstagramMessage(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('send_instagram_message'), postValues);
  }

  addCommentInstagramPost(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('add_comment_instagram_post'), postValues);
  }

  deleteInstagramComment(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('delete_instagram_comment'), postValues);
  }
  markMessageAsSeen(postValues): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('instagram_mark_message_as_seen'), postValues);
  }

  /* Ads */
  getInstagramPosts(social_id, page=1): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('instagram_posts') + '?social_id=' + social_id + '&page=' + page);
  }
  /* Ads */
}
