import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TeamsService } from "../../../../services/teams.service";
import { SharedService } from "../../../../services/shared.service";
import { FormBuilder } from "@angular/forms";
import { Constants } from "../../../../constants";
import { SocialAccountService } from "../../../../services/social-account.service";
import { AuthState } from "../../../../action-state/states/auth.state";
import { Store } from "@ngxs/store";
import { AppWarningModalComponent } from "../../../../component/app-warning-modal/app-warning-modal.component";
import { NzModalService } from "ng-zorro-antd/modal";
import {TeamState} from "../../../../action-state/states/team.state";
import {TeamGet} from "../../../../action-state/actions/team.action";
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.less']
})


export class TeamDetailsComponent implements OnInit, OnDestroy {

  staticFeeds: any = Array(8).fill(0).map((x, i) => i);

  id: string = null;
  teamData: any = [];
  isDeleting = false;
  deleteUserId = 0;
  deleteSocialId = 0;
  isLoadingPosts = true;

  permissionModal = false;
  team_ids = [];
  selectedUser: any = [];
  userPermissions: any = [];

  addToTeamForm: any;
  teamMembersList: any[] ;
  socialAccounts = [];
  selectedMembers = [];
  selectedSocialAccounts:any = [];

  addToTeamModal = false;
  addToTeamModalType = 'member';
  modalTitle = '';
  isAddingToTeam = false;
  isUpdatingSocialAccess = false;
  existMemberIds = [];
  existSocialIds = [];
  user = null;
  userId = null;
  userSocialProfiles = [];
  userSocialProfileAccess = [];
  isEditPermission = true;

  isResending = false;
  resendUserId = 0;

  // destroy method
  httpSubscribeSaveToTeam: any = null;

  permissions: any =[];

  constructor(
    public router: Router,
    private activeRoute: ActivatedRoute,
    public sharedService: SharedService,
    private formBuilder: FormBuilder,
    private accountService: SocialAccountService,
    public store: Store,
    private modal: NzModalService,
    private teamService: TeamsService) {
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });
  }

  ngOnInit(): void {
    //console.log("ngOnInit id : ", this.id);

    this.permissions = this.store.selectSnapshot(TeamState.permissions);

    //teams
    let teams = this.sharedService.getTeamList();
    let teamExist = false;
    teams.map((team) =>{
      if(team.id == this.id){
        teamExist = true;
        return;
      }
    });

    if(!teamExist){
      this.router.navigate(['/organization','teams']);
      return;
    }


    this.getTeamDetails();
    this.user = this.store.selectSnapshot(AuthState).user;
    //this.userId = this.store.selectSnapshot(AuthState).user.id;
    this.initForm();
    this.getSocialActiveAccount();
    this.getMemberList();
    this.getUserSocialProfiles();
    this.editPermission(this.user, false);
  }

  ngOnDestroy(): void {
    if (this.httpSubscribeSaveToTeam != null) {
      this.httpSubscribeSaveToTeam.unsubscribe();
    }
  }

  initForm(): void {
    this.addToTeamForm = this.formBuilder.group({
      social_networks: [],
      team_members: [],
    });
  }

  goBackToAllTeams() {
    this.router.navigate(['/', 'organization', 'teams']);
  }

  getTeamDetails() {
    this.isLoadingPosts = true;
    this.teamService.teamDetails(this.id).subscribe((res: any) => {
      this.teamData = res.data;
      console.log("this.teamData:-", this.teamData);

      this.isLoadingPosts = false;
      //this.userSocialProfiles = this.teamData?.social_accounts;
      if (this.teamData?.members?.length > 0) {
        this.existMemberIds = this.teamData.members.map((item) => {
          return item.user_id
        });
      }

      this.existSocialIds = [];
      if (this.teamData?.social_accounts?.length > 0) {
        this.existSocialIds = this.teamData.social_accounts.map((item) => {
          return item.social_connection_id
        });
        console.log("getTeamDetails existSocialIds --> in:", this.existSocialIds)
      }

      //console.log("teamData : ", this.teamData)
      //console.log("getTeamDetails existMemberIds:", this.existMemberIds)
      //console.log("getTeamDetails existSocialIds:", this.existSocialIds)

      //add team permission
      this.permissions = this.teamData.permissions;

    }, error => {
      this.isLoadingPosts = false;
    });
  }

  resetDeleteTeam() {
    this.deleteSocialId = 0;
    this.deleteUserId = 0;
  }

  /*deleteUser(user_id){
    this.resetDeleteTeam();
    this.deleteUserId = user_id;
    this.deleteFromTeam();
  }*/

  deleteUser(user_id) {

    this.modal.confirm({
      nzTitle: 'Delete this Member?',
      nzClassName: 'app_warning_modal_main',
      nzContent: AppWarningModalComponent,
      nzOkText: this.isDeleting ? 'Please wait...' : 'Yes, Delete It',
      nzOkDisabled: this.isDeleting,
      nzCancelDisabled: this.isDeleting,
      nzOkDanger: true,
      nzCentered: true,
      nzAutofocus: null,
      nzCancelText: 'Cancel',
      nzIconType: null,
      nzComponentParams: {
        message: "Are you sure?"
      },
      nzOnOk: () => {
        this.resetDeleteTeam();
        this.deleteUserId = user_id;
        this.deleteFromTeam();
      },
    });
  }

  /*deleteSocialAccount(social_id){
    this.resetDeleteTeam();
    this.deleteSocialId = social_id;
    this.deleteFromTeam();
  }*/

  deleteSocialAccount(social_id) {

    this.modal.confirm({
      nzTitle: 'Delete this social network account?',
      nzClassName: 'app_warning_modal_main',
      nzContent: AppWarningModalComponent,
      nzOkText: this.isDeleting ? 'Please wait...' : 'Yes, Delete It',
      nzOkDisabled: this.isDeleting,
      nzCancelDisabled: this.isDeleting,
      nzOkDanger: true,
      nzCentered: true,
      nzAutofocus: null,
      nzCancelText: 'Cancel',
      nzIconType: null,
      nzComponentParams: {
        message: "Are you sure?"
      },
      nzOnOk: () => {

        this.resetDeleteTeam();
        this.deleteSocialId = social_id;
        this.deleteFromTeam();

      },
    });
  }


  deleteFromTeam() {
    let postData = {
      'team_id': this.id,
      'social_connection_id': this.deleteSocialId,
      'user_id': this.deleteUserId,
    };

    this.isDeleting = true;
    this.teamService.deleteFromTeam(postData).subscribe((res: any) => {
      this.isDeleting = false;
      this.resetDeleteTeam();

      setTimeout(()=>{
        this.getTeamDetails();
      },1000);

      this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);

    }, error => {
      this.isDeleting = false;
    });

  }

  editPermission(user, openModal = true) {
    console.log("editPermission : ", user)
    this.team_ids = [];
    let team_id = this.id;
    this.team_ids.push(team_id);

    this.selectedUser = user;
    let postData = {
      'user_id': this.selectedUser.id,
      team_id
    }
    this.isEditPermission = true;
    this.teamService.getUserPermission(postData).subscribe((res: any) => {
      this.isEditPermission = false;
      console.log(" editPermission res:", res.data);
      this.userPermissions = res.data.user_permissions;
      this.userSocialProfileAccess = res.data.social_accounts;
      this.userSocialProfiles = res.data.social_accounts;
      if (openModal) {
        this.permissionModal = true;
      }

    }, error => {
      this.isEditPermission = false;
    });

  }

  permissionChange(event) {
    console.log("permissionChange : ", event);
    if (event.is_canceled) {
      this.permissionModal = false;
      this.selectedUser = [];
    }

    if (event.code === 200) {
      this.permissionModal = false;
      this.selectedUser = [];
      if(event.message!='') {
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, event.message);
        this.getTeamDetails();
      }
    }

    if (event.code === 500) {
      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, event.message);
    }
  }


  /*Add to team*/

  getMemberList() {
    this.teamService.getOrganizationMembersList().subscribe((res) => {
      this.teamMembersList = res.data.data;
      // this.teamMembersList = Object.keys(res.data).map(key => ({type: key, value: res.data[key]}));
    }, error => {

    });
  }

  getSocialActiveAccount() {
    this.accountService.getSocialActiveAccount().subscribe((res) => {
      this.socialAccounts = res.data;

      this.socialAccounts.forEach(account => {
        account.social_access_id = 0;
      });
    }, error => {

    });
  }

  closeAddToTeamModal() {
    if (this.httpSubscribeSaveToTeam != null) {
      this.httpSubscribeSaveToTeam.unsubscribe();
    }
    this.addToTeamModal = false;
    this.addToTeamModalType = 'member';
    this.modalTitle = '';
    this.addToTeamForm.reset();
    this.selectedMembers = [];
    this.selectedSocialAccounts = [];


  }

  updateMemberAccount(event: any): void {
    console.log("updateMemberAccount : ", event);
    //this.selectedMembers = event;
    if (event) {
      this.selectedMembers = event.map(acc => {
        return acc.id
      });
    }

  }

  updateSocialAccounts(event: any): void {
    console.log("updateSocialAccounts 1 : ", event);
    //this.selectedSocialAccounts = event;
    if (event) {
      this.selectedSocialAccounts = event.map(acc => {
        return acc.id
      });
    }
  }

  addMemberToTeam() {
    console.log("open model");

    this.addToTeamModal = true;
    this.addToTeamModalType = 'member';
    this.modalTitle = 'Add Team Member';
    this.addToTeamForm.reset();
  }

  addSocialToTeam() {
    this.addToTeamModal = true;
    this.addToTeamModalType = 'social';
    this.modalTitle = 'Add Social Account';
    this.addToTeamForm.reset();
  }

  saveToTeam() {

    let postValue = new FormData();
    postValue.append('team_id', this.id);

    if (this.selectedMembers.length > 0 && this.addToTeamModalType == "member") {
      postValue.append('team_members', JSON.stringify(this.selectedMembers));
    }

    if (this.selectedSocialAccounts.length > 0 && this.addToTeamModalType == "social") {
      postValue.append('social_networks', JSON.stringify(this.selectedSocialAccounts));
    }

    this.isAddingToTeam = true;

    this.httpSubscribeSaveToTeam = this.teamService.addToTeamData(postValue).subscribe((res) => {
      this.isAddingToTeam = false;
      if (res.code == 200) {
        this.addToTeamModal = false;
        this.getTeamDetails();
        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      } else {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }
    }, error => {
      this.isAddingToTeam = false;
      this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, Constants.ERROR_MSG);
    });
  }

  getUserSocialProfiles() {
    /*let postData = { 'team_id' : this.id };
    this.teamService.getUserSocialProfiles(postData).subscribe((res) => {
      this.userSocialProfiles = res.data;
        this.socialAccounts.forEach((account,index) => {
            this.userSocialProfiles.forEach(profile => {
              if(account.id === profile.social_connection_id){
                account.social_access_id = profile.access_type;
              }else{
                //this.socialAccounts.splice(index,1);
              }
            });
        });
      }, error => {});*/
  }

  updateUserSocialAccess() {

    let postData = {
      'user_id': this.userId,
      'team_id': this.id,
      'social_accounts': JSON.stringify(this.userSocialProfiles),
    };

    this.isUpdatingSocialAccess = true;
    this.teamService.updateUserSocialAccess(postData).subscribe((res: any) => {
      this.isUpdatingSocialAccess = false;

      if (res.code === 200) {

        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      }

      if (res.code === 500 || res.code === 401) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }

    }, error => {
      this.isUpdatingSocialAccess = false;
    });
  }

  resendInvite(user_id) {
    this.resendUserId = user_id;
    let postData = {
      'user_id': user_id,
      'team_id': this.id
    };

    this.isResending = true;
    // return;
    this.teamService.resedUserInvitation(postData).subscribe((res: any) => {
      this.isResending = false;
      this.resendUserId = 0;

      if (res.code === 200) {

        this.sharedService.displayNotification(this.sharedService.messageTypeSuccess, this.sharedService.defaultMessageSuccess, res.message);
      }

      if (res.code === 500 || res.code === 401) {
        this.sharedService.displayNotification(this.sharedService.messageTypeError, this.sharedService.defaultMessageError, res.message);
      }

    }, error => {
      this.resendUserId = 0;
      this.isResending = false;
    });
  }

  hasPermission(permissionName){
    let is_super_admin = this.store.selectSnapshot(AuthState.user)?.is_super_admin;
    if(is_super_admin){ return true; }
    return this.permissions?.indexOf(permissionName) !== -1;
  }

}
