import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {

  constructor(private http: HttpClient, private helper: HelperService) {
  }

  twitterConnect(): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('request_token'));
  }

  twitterAuthenticatedRequests(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('authenticated_requests'), postValue);
  }

  getTwitterAccount(): Observable<any>{
    return this.http.get(this.helper.createAPIUrl('get_twitter_accounts'));
  }

  twitterReconnect(postValue): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('reconnect_twitter_account'), postValue);
  }

  twitterPinnedLastTweet(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_pinned_and_last_tweet'), data);
  }

  twitterProfileData(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_twitter_profile_data'), data);
  }

  unfollowUser(data): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('unfollow_user'), data);
  }

  followUser(data): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('follow_user'), data);
  }

  getNextTweets(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_tweets_by_token'), data);
  }

  tweetLike(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('like_tweet'), data);
  }

  tweetUnlike(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('unlike_tweet'), data);
  }

  twitterAccountProfile(id): Observable<any> {
    return this.http.get(this.helper.createAPIUrl('get_twitter_account_profile') + '/' + id);
  }

  getLikedTweets(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_user_liked_tweet'), data);
  }

  getLikedTweetsByToken(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_user_liked_tweet_by_token'), data);
  }

  getTweetStatus(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('get_tweet_status'), data);
  }

  getSearchUsers(data): Observable<any> {
    return this.http.post(this.helper.createAPIUrl('search_people'), data);
  }

  removeIgnoreListUser(data): Observable<any>{
    return this.http.post(this.helper.createAPIUrl('remove_ignored_user'), data);
  }

}
