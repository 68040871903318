<nz-table #accountsTable [nzData]="members" class="accounts_list_table table_default team_member_table"
          nzShowSizeChanger [nzFrontPagination]="false" [nzPageSize]="selectedPageSize" [nzPageIndex]="selectedPageIndex"
          [nzTotal]="totalData" [nzPageSizeOptions]=[10,20,50,100] (nzQueryParams)="onQueryParamsChange($event)">
  <thead *ngIf="team_account === '' || sharedService.isMobile">
  <tr>
    <th>Name</th>
    <th class="tbl_member_role">Role</th>
    <th class="org_action" *ngIf="isUpdatePermission">Actions</th>
  </tr>
  </thead>

  <tbody>
  <ng-container *ngFor="let member of members">
    <tr>
      <td>
        <div class="user_account_name">
          <div class="img_sec">
            <app-image-preview [isVideo]="false" [imageUrl]="member.user.profile_pic" [class]="'account-list-image'"
                               *ngIf="member?.user.profile_pic !== null"></app-image-preview>
            <img src="assets/images/Profile.png" *ngIf="member?.user.profile_pic === null"
                 class="default-profile-img">
          </div>
          <div class="name_wrp">
            <span>{{member?.user.name}}</span>
            <!--<span class="res_role_name">{{member.role.role_name}}</span>-->
          </div>
        </div>
      </td>
      <td class="tbl_member_role">
        <!--{{member.role.role_name}}-->
      </td>
      <td *ngIf="isUpdatePermission">
        <!-- *ngIf="checkAccess(member)" -->
        <div class="btn_wrap">
          <a [class.disabled]="!checkAccess(member)" (click)="deleteTeamModal(member.id)" class="btn delete-account"
             nzTooltipTitle="Delete Member" nz-tooltip nzTooltipColor="#002244">

            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 4L6.544 2.368C6.67664 1.96975 6.93123 1.62333 7.27169 1.37781C7.61216 1.13229 8.02124 1.00011 8.441 1H10.558C10.9779 0.999899 11.3872 1.13198 11.7279 1.37752C12.0686 1.62305 12.3233 1.96959 12.456 2.368L13 4M3.5 4L4.434 17.071C4.45194 17.323 4.56462 17.5589 4.74939 17.7313C4.93417 17.9036 5.17733 17.9996 5.43 18H13.568C13.8208 17.9999 14.0643 17.904 14.2492 17.7316C14.4342 17.5592 14.547 17.3232 14.565 17.071L15.5 4H3.5ZM9.5 9V13V9ZM17.5 4H1.5H17.5Z"
                stroke="#494ADF" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Delete Member</span>
          </a>


          <a  [class.disabled]="!checkAccess(member)" (click)="editMember(member.user.id)" class="btn edit-account"
              nzTooltipTitle="Edit Member" nz-tooltip nzTooltipColor="#002244">

            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.1163 4.13023L17.8698 6.88238L15.1163 4.13023ZM16.887 1.70569L9.44173 9.15092C9.05703 9.53507 8.79467 10.0245 8.68771 10.5575L8 14L11.4425 13.311C11.9755 13.2044 12.4643 12.9431 12.8491 12.5583L20.2943 5.11305C20.518 4.88932 20.6955 4.62371 20.8166 4.33139C20.9377 4.03907 21 3.72577 21 3.40937C21 3.09296 20.9377 2.77966 20.8166 2.48734C20.6955 2.19502 20.518 1.92942 20.2943 1.70569C20.0706 1.48196 19.805 1.30448 19.5127 1.1834C19.2203 1.06232 18.907 1 18.5906 1C18.2742 1 17.9609 1.06232 17.6686 1.1834C17.3763 1.30448 17.1107 1.48196 16.887 1.70569V1.70569Z"
                stroke="#F5F6F2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M17.7652 15.4119V18.7649C17.7652 19.3578 17.5296 19.9263 17.1104 20.3455C16.6912 20.7648 16.1227 21.0003 15.5298 21.0003H3.23535C2.6425 21.0003 2.07393 20.7648 1.65472 20.3455C1.23551 19.9263 1 19.3578 1 18.7649V6.47046C1 5.87761 1.23551 5.30904 1.65472 4.88983C2.07393 4.47062 2.6425 4.23511 3.23535 4.23511H6.58838"
                stroke="#F5F6F2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span>Edit Permissions
                <ng-container *ngIf="isLoading">
                  <span nz-icon nzType="loading"></span>
                </ng-container>
              </span>
          </a>
        </div>
      </td>
    </tr>
  </ng-container>
  </tbody>
</nz-table>

<!-- Start delete team -->
<nz-modal [(nzVisible)]="deleteTeamvisible" nzClassName="delete_team_modal" [nzFooter]=null>
  <ng-container *nzModalContent>
    <div class="delete_team_hdr">
      <h4>Delete Member?</h4>
    </div>
    <div class="delete_team_body">
      <p><b>Please note</b> - when you remove this member, member's posting history and future scheduled posts will no
        longer be available and will not be sent. Are you sure you want to remove this member? </p>
      <img src="../../../../assets/images/delete_img_1.png" alt="">

      <div class="organization_form_action">
        <span>Are you sure you want to delete this member?</span>
        <div class="btn_group">
          <button class="btn" nz-button nzType="primary" (click)="deleteTeamModalClose()"
                  [disabled]="isLoadingDelete">Cancel</button>
          <button class="btn btn_apply" nz-button nzType="primary" (click)="deleteMember(team_id_delete)"
                  [disabled]="isLoadingDelete">Yes, Delete It
            <ng-container *ngIf="isLoadingDelete">
              <span nz-icon nzType="loading"></span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>
<!-- End delete team -->
